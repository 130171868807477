import { axiosOption } from "../axios";
import { getUserObject } from "../Service/auth.service";
let userObj:any = getUserObject();

export function getListOfTradesOptions(startDate:any,endDate:any, version: any,tradetype:any,asset_type:any){
        let optionObj:any = {
                startDate: startDate,
                endDate: endDate,
                version  : version,
                tradetype: tradetype,
                asset    : asset_type
        }
    optionObj["username"] = userObj.email_id
    return axiosOption.post(`/options/get_trades`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) =>{ 
                console.log(err)
                return []}
        )
}
export function getCallsOptions(startDate:any,endDate:any, version: any,tradetype:any,asset_type:any)
{
        let optionObj:any = {
                startDate: startDate,
                endDate: endDate,
                version  : version,
                tradetype: tradetype,
                asset    : asset_type
        }
    optionObj["username"] = userObj.email_id    
    return axiosOption.post(`/options/get_calls`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export function getSrOptions(startDate:any,endDate:any, version: any ,tradetype:any,asset_type:any)
{
        let optionObj:any = {
                startDate: startDate,
                endDate:endDate,
                version  : version,
                tradetype: tradetype,
                asset    : asset_type
        }
optionObj["username"] = userObj.email_id
    return axiosOption.post(`/options/get_sr`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export function getPlOptions(startDate:any,endDate:any, version: any ,tradetype:any,asset_type:any)
{
        let optionObj:any = {
                startDate: startDate,
                endDate:endDate,
                version   : version,
                tradetype : tradetype,
                asset     : asset_type
        }
optionObj["username"] = userObj.email_id
    return axiosOption.post(`/options/get_pl`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export function getPlPercent(startDate:any,endDate:any,version:any,tradetype: any,asset_type:any)
{
        let optionObj:any = {
                startDate: startDate,
                endDate:endDate,
                version   : version,
                tradetype : tradetype,
                asset     : asset_type
        }
optionObj["username"] = userObj.email_id
    return axiosOption.post(`/options/get_pl_percent`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}


export function getTargetSlOptions(date:any,asset:any,id:any,version:any,tradetype:any)
{
      
        let optionObj:any = {
                "date" : date ,
                "asset": asset,
                "id"   : id,
                "version":version,
                "trade_type":tradetype
                
        }
    return axiosOption.post(`/options/get_target_stoploss`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export function getMtmDrawdownOptions(date:any,asset:any,id:any, version:any)
{
        let optionObj:any = {
                "date" : date ,
                "asset": asset,
                "id"   : id,
                "version":version
      }
    return axiosOption.post(`/options/get_m2m_drawdown`,optionObj)
            .then((res) => {
                return res?.data;
        }).catch((err) => console.log(err))
}

export function getOptionsParameter(id:any,asset:any,date:any,version:any)
{
        
        let optionObj:any = {
                id     : id,
                asset  : asset,
                date   : date ,
                version: version
        }
    optionObj["username"] = userObj.email_id   
    return axiosOption.post(`/options/get_parameter`,optionObj)
            .then((res) => {
                return res?.data;
        }).catch((err) => console.log(err))
}

export function getOptionsContractDate()
{
        return axiosOption.post(`/options/get_Contract_Date_options`)
                .then((res) => {
                    return res.data;
            }).catch((err) => console.log(err))
}


export function getLiveListOfTradesOptions(version: any,tradetype:any,asset:any)
{
        let optionObj:any = {
                version  : version,
                tradetype: tradetype,
                asset    : asset
        }
        optionObj["username"] = userObj.email_id    
    return axiosOption.post(`/options/get_live_trades`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export function getLiveCallsOptions(version: any,tradetype: any,asset:any)
{
        let optionObj:any = {
                version  : version,
                tradetype: tradetype,
                asset    : asset
        }
        optionObj["username"] = userObj.email_id   
    return axiosOption.post(`/options/get_live_calls`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export function getLiveSrOptions(version: any,tradetype: any,asset:any)
{
        let optionObj:any = {
                version  : version,
                tradetype: tradetype,
                asset    : asset
        }
        optionObj["username"] = userObj.email_id
    return axiosOption.post(`/options/get_live_sr`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export function getLivePlOptions(version: any,tradetype: any,asset:any)
{
        let optionObj:any = {
                version  : version,
                tradetype: tradetype,
                asset    : asset
        }
        optionObj["username"] = userObj.email_id
    return axiosOption.post(`/options/get_live_pl`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export function getLivePlPercent(version: any,tradetype: any,asset:any)
{
        let optionObj:any = {
                version  : version,
                tradetype: tradetype,
                asset    : asset
        }
    optionObj["username"] = userObj.email_id    
    return axiosOption.post(`/options/get_live_pl_percent`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}


export function live_calls_assets()
{
        return axiosOption.post(`/options/live_calls_assets`)
                .then((res:any)=>{
                      return res.data 
                }).catch((err)=>console.log(err))
}