import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import './style.css'

interface CandlestickData {
  open: number;
  close: number;
  high: number;
  low: number;
  buy?: boolean;
  sell?: boolean;
  theme: any
}

interface CandlestickChartProps {
  data: any;
  theme: any
}

export const CandlestickChart: React.FC<CandlestickChartProps> = ({ data, theme }) => {

  const canvasRef: any = useRef(null);
  const containerRef: any = useRef(null);
  const [hoveredCandle, setHoveredCandle] = useState<any>(null);
  const [chartOffsetX, setChartOffsetX] = useState<any>(10);
  const [chartOffsetY, setChartOffsetY] = useState<any>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<any>(0);
  const [startY, setStartY] = useState<any>(0);

  //new changes added
  const [canvasHeight, setCanvasHeight] = useState<any>(400)

  function chartData(){
    if (canvasRef.current && containerRef.current && data) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (ctx ) {
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          drawChart(ctx, data);
          const candleWidth = 10; // Adjust as needed
          const candleSpacing = 5; // Adjust as needed
          if (data.length > 90) {
            if(canvasHeight === 400)
            {
              let candles =  data?.length - 60;
              setChartOffsetX(-(candles * (candleWidth + candleSpacing)));
            }
            else{
              let candles =  data?.length - 90;
              setChartOffsetX(-(candles * (candleWidth + candleSpacing)));
            }
          }
          else {
              setChartOffsetX(0);
          }
      }
    }
  }

  window.addEventListener('resize',()=>{
    let newsChartSize:any = document.getElementById('newsChartMainDiv');
    if(newsChartSize){
      setCanvasHeight(newsChartSize.offsetHeight);
    }else{
      setCanvasHeight(400);
    }
    chartData();
  })

  useEffect(() => {
    let newsChartSize:any = document.getElementById('newsChartMainDiv');
    if(newsChartSize){
      setCanvasHeight(newsChartSize.offsetHeight);
    }else{
      setCanvasHeight(400);
    }
    chartData()
  }, [data]);



  
  // window.addEventListener('resize', ()=>{
  //   setCanvasHeight((window.matchMedia("screen and (max-width: 1280px)").matches) === true || (window.matchMedia("screen and (max-width: 1366px)").matches) === true || (window.matchMedia("screen and (max-width: 1400px)").matches) === true ? 400 : (window.matchMedia("screen and (max-width: 1490px)").matches) === true ? 480 : 600)
  //   chartData();
  // })


  // useEffect(() => {
  //   chartData()
  // }, [data]);

  // useEffect(() => {
  //   if (canvasRef.current && containerRef.current && data) {
  //       const canvas = canvasRef.current;
  //       const ctx = canvas.getContext('2d');
  //       // const containerWidth = containerRef.current.clientWidth;
  //       if (ctx ) {
  //           ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  //           drawChart(ctx, data);
  //           const candleWidth = 10; // Adjust as needed
  //           const candleSpacing = 5; // Adjust as needed
  //           if (data.length > 90) {
  //             if(canvasHeight === 400)
  //             {
  //               let candles =  data?.length - 60;
  //               setChartOffsetX(-(candles * (candleWidth + candleSpacing)));
  //             }
  //             else{
  //               let candles =  data?.length - 90;
  //               setChartOffsetX(-(candles * (candleWidth + candleSpacing)));
  //             }
  //           } 
  //           else {
  //               setChartOffsetX(0);
  //           }
  //       }
  //   }
  // }, [data]);




  useEffect(() => {
    if (canvasRef.current && data) {
      const canvas: any = canvasRef.current;
      const ctx: any = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        drawChart(ctx, data);
      }
    }
  }, [data, chartOffsetX, chartOffsetY, theme]);




  const handleHoverCandlestick = (e: any) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - rect.left - chartOffsetX;
    const mouseY = e.clientY - rect.top + chartOffsetY;
    const candleWidth = 10; // Adjust as needed   
    const candelSpacing = 5;
    const candleIndex = Math.floor(mouseX / (candleWidth + candelSpacing));
    if (candleIndex >= 0 && candleIndex < data.length) {
      setHoveredCandle(data[candleIndex]);
    } else {
      setHoveredCandle(null);
    }

  };

  const handleLeaveCandlestick = () => {
    setHoveredCandle(null);
  };



  const drawChart = (ctx: CanvasRenderingContext2D | null, data: CandlestickData[]) => {
    if (!ctx) return;
    // Clear canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);


    // Chart dimensions and properties
    const candleWidth = 10;
    const candleSpacing = 5;
    const chartHeight = ctx.canvas.height - 20;
    // const chartWidth = ctx.canvas.width - 20;
    const maxPrice = Math.max(...data.map(point => point.high));
    const minPrice = Math.min(...data.map(point => point.low));
    // const visibleCandles = Math.floor((chartWidth) / (candleWidth + candleSpacing));
    const startIndex = Math.max(0, Math.floor(-chartOffsetX / (candleWidth + candleSpacing)));
    // const endIndex = Math.min(startIndex + visibleCandles, data.length);


    // Draw grid lines
    ctx.strokeStyle = theme === "light" ? 'black' : theme === 'b/w' ? 'white' : '#d7d7d7';
    // ctx.strokeStyle = '#918b95'
    ctx.lineWidth = theme === 'b/w' ? 0.2 : 0.1;

    // Draw vertical grid lines
    for (let i = startIndex; i < 390; i++) {
      if (i % 15 === 0) {
        const x_axis = i * (candleWidth + candleSpacing) + 5 + chartOffsetX;
        ctx.beginPath();
        ctx.moveTo(x_axis, 0);
        ctx.lineTo(x_axis, ctx.canvas.height);
        ctx.stroke();
      }
    }

    // Draw horizontal grid lines
    // const priceInterval = (maxPrice - minPrice) / 5; 
    for (let i = 0; i <= ctx.canvas.height; i += 60) {
      ctx.beginPath();
      ctx.moveTo(0, i);
      ctx.lineTo(ctx.canvas.width, i);
      ctx.stroke();
    }
    ctx.closePath()

    // Draw candles
    data.forEach((item: any, index: any) => {
      const x = index * (candleWidth + candleSpacing) + chartOffsetX;
      const yOpen = chartHeight - ((item.open - minPrice) / (maxPrice - minPrice)) * chartHeight + 10 + chartOffsetY;
      const yClose = chartHeight - ((item.close - minPrice) / (maxPrice - minPrice)) * chartHeight + 10 + chartOffsetY;
      const yHigh = chartHeight - ((item.high - minPrice) / (maxPrice - minPrice)) * chartHeight + 10 + chartOffsetY;
      const yLow = chartHeight - ((item.low - minPrice) / (maxPrice - minPrice)) * chartHeight + 10 + chartOffsetY;


      // Draw candlestick
      ctx.beginPath()
      ctx.fillStyle = item.open > item.close ? '#ff2222' : '#2fc457';
      ctx.fillRect(x, yOpen, candleWidth, (yClose - yOpen));
      // ctx.closePath()
      // ctx.stroke();

      ctx.beginPath()
      ctx.fillStyle = item.open > item.close ? '#ff2222' : '#2fc457';
      ctx.strokeStyle = item.open > item.close ? '#ff2222' : '#2fc457';
      ctx.lineWidth = 1.5;
      // ctx.closePath()
      // ctx.stroke()

      // Draw high and low wicks
      ctx.beginPath();
      ctx.moveTo(x + candleWidth / 2, yHigh);
      ctx.lineTo(x + candleWidth / 2, yLow);
      ctx.stroke();


      // x axis time labels
      if (index % 15 === 0) {
        ctx.font = "12px Arial";
        const labelX: any = x + candleWidth / 2 - 20;
        ctx.fillStyle = String(theme) === 'light' ? 'black' : 'white';
        ctx.fillText(item.date.split(' ')[1], labelX, ctx.canvas.height)
        // ctx.stroke();
      }
      // if (item.buy === true) {
      //   let text_color: any = String(theme) === 'light' ? '#318931' : '#2fc457'
      //   drawPoint(ctx, x, yLow + 20, text_color, index, 'B');
      // }
      // if (item.sell === true) {
      //   drawPoint(ctx, x, yHigh - 5, '#ff0000', index, 'S');
      // }
      if (item.buy === true) {
        let text_color: any = String(theme) === 'light' ? '#318931' : '#2fc457'
        drawPoint(ctx, x + 5, yLow + 15, text_color, 'buy');
      }
      if (item.sell === true) {
        drawPoint(ctx, x +5, yHigh - 25, '#ff0000', 'sell');
      }
    });

  };


  // const drawPoint = (ctx: CanvasRenderingContext2D, x: number, y: number, color: string, index: any, text: any) => {
  //   ctx.font = "bold 15px Arial";
  //   ctx.fillStyle = color
  //   ctx.fillText(text, x, y)
  // };
  const drawPoint = (ctx: CanvasRenderingContext2D, x: number, y: number, color: string, signal:any) => {
    ctx.fillStyle = color
    const size = 12; // Size of the triangle
    if(signal === 'buy')
    {
      ctx.beginPath();
      ctx.moveTo(x - size / 2, y + size); // Move to the bottom point of the triangle
      ctx.lineTo(x + size / 2, y + size); // Draw line to bottom-right point
      ctx.lineTo(x, y); // Draw line to top point
      ctx.closePath();
      ctx.fill();

    }
    else{
      ctx.beginPath();
      ctx.moveTo(x - size / 2, y); // Move to the top point of the triangle
      ctx.lineTo(x + size / 2, y); // Draw line to top-right point
      ctx.lineTo(x, y + size); // Draw line to bottom point
      ctx.closePath();
      ctx.fill();
    }
  };

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setStartX(e.clientX);
    setStartY(e.clientY);
  };

  const handleMouseMove = (e: any) => {
    if (!isDragging) return;
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const dx = mouseX - startX;
    const dy = mouseY - startY;
    const candleWidth = 10;
    const candleSpacing = 5;

    // Calculate the total width covered by all candles
    const totalCandlesWidth = data.length * (candleWidth + candleSpacing);

    // Calculate the maximum offset to prevent dragging beyond the last candle
    const maxOffsetX = Math.max(0, totalCandlesWidth - (containerRef.current.clientWidth - 100));
    const newChartOffsetX = chartOffsetX + dx;

    // Set the new chart offset within the valid range
    if (newChartOffsetX > 0) {
      setChartOffsetX(20);
    }
    else {
      setChartOffsetX(Math.min(0, Math.max(-maxOffsetX, newChartOffsetX)));
    }
    setChartOffsetY(chartOffsetY + dy);
    setStartX(mouseX);
    setStartY(mouseY);
  };



  const handleMouseLeave = () => {
    setIsDragging(false);
  };



  const handleMouseUp = () => {
    setIsDragging(false);
  };


  return (
  <>
    <div ref={containerRef}
      className='charts_container'
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}>
      {hoveredCandle && (
        <div className='show_ohlc'
        >
          <p className='text_box_time'><span className='ohlc_header'>Time</span> {hoveredCandle !== null ? ((hoveredCandle.date).split(' ')[1]) : data[(data?.length - 1)].date.split(' ')[1]}</p>
          <p className='text_box'><span className='ohlc_header'>O</span>{hoveredCandle !== null ? hoveredCandle.open : ''}</p>
          <p className='text_box'><span className='ohlc_header'>H</span>{hoveredCandle !== null ? hoveredCandle.high : ''}</p>
          <p className='text_box'><span className='ohlc_header'>L</span>{hoveredCandle !== null ? hoveredCandle.low : ''}</p>
          <p className='text_box'><span className='ohlc_header'>C</span>{hoveredCandle !== null ? hoveredCandle.close : ''}</p>
        </div>
      )}
      <canvas ref={canvasRef} height={canvasHeight} width={1685} onMouseMove={(e) => handleHoverCandlestick(e)} onMouseLeave={() => handleLeaveCandlestick()}
        style={{ display: 'block' }}
      />
    </div>
  </>);
};
