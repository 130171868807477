import React from 'react';
import { FeedSense } from '../../../../Models/FeedSense';
import { add3Dots, getColorBySentiment, parseDateInDisplayFormat2 } from '../../../../util/utils';
import './FeedSenseItem.css';
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface FeedSenseItemProps {
    feedSense: FeedSense
}

export const FeedSenseItem: React.FC<FeedSenseItemProps> = ({feedSense}) => {
    let barColor = '';
    if(feedSense.sentiment === 0) { 
        barColor = "#fdfd9f";
    }
    if(feedSense.sentiment > 0) {
        barColor = "rgb(88 192 88)";
    }
    if(feedSense.sentiment < 0) {
        barColor = "#f18585";
    }

    return (
        <>
            <div className="feedsense-thumbnail-type1 single_post widgets_small">
                <div className="post_img">
                    <div className="img_wrap">
                        <a href="#">
                            <img src={feedSense.displayUrl}/>
                        </a>
                    </div>
                </div>
                <div className="single_post_text">
                    <div className="meta2 meta_separator1">
                        <a href="#">{feedSense.userName}</a>
                        <a href="#" className="meta-date">{parseDateInDisplayFormat2(feedSense.createdAt)}</a>
                        
                        <FontAwesomeIcon icon={faTwitter} style={{float: "right", color:"#17222b", margin: "4px 0px 0px 0px"}}></FontAwesomeIcon>
                    </div>
                    <h4 style={{marginTop: "4px"}}>
                    <FontAwesomeIcon icon={faCircle} style={{color: getColorBySentiment(feedSense.sentiment), 
                                margin: "0px 5px 0px 0px"}}></FontAwesomeIcon>
                        {add3Dots(feedSense.fullText, 150)}
                    </h4>
                    <h4 className="user-url">
                        <a href={feedSense.userUrl}>{feedSense.userUrl}</a>
                    </h4>
                </div>
            </div>
            {/* <div className="sentiment-color" style={{borderRight: `6px dotted ${barColor}`}}></div> */}
        </>
    )
}