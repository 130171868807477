import './style.css';
import { checkValid, doIsValidFalse, getSimultaneousValue, logout,matchPrivilege } from '../../../Api/Service/auth.service';
import { useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import { set_Cookie } from '../../../Cookies/set_cookie';
import { remove_Cookie } from '../../../Cookies/remove_cookie';
import { Container } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'; 
import { LeftNavigation } from '../LeftNavigation';
import { getUserSubscription } from '../../../Api/buySell/portal';



interface TopBarProps {
    isLoggedIn: boolean;
    user: any;
    theme: any;
    setTheme: any
    setPara:any
    para:any

}

const logoutChannel = new BroadcastChannel('logout');

export const TopBar: React.FC<TopBarProps> = ({ isLoggedIn, user, theme, setTheme, setPara,para }) => {
    let token: any = window.localStorage.getItem('token');
    let userName: any = user.email_id;
    

    useEffect(() => {
        hasSubscriptionExpired()
    },[])


    const hasSubscriptionExpired = async () => {
            let userSubscription = await getUserSubscription(userName)
            if((userSubscription[0]?.is_active === false) && (userSubscription[0]?.expiry === 1))
            {
                handleLogout()
            }
        }

    const handleLogout = async () => {
        logoutChannel.postMessage('logout');
        await logout(token);
        window.location.href = '/portal';
    }

    const logoutAllTabs = () => {
        logoutChannel.onmessage = () => {
            handleLogout();
            logoutChannel.close();
        }
    }

    useEffect(() => {
        logoutAllTabs();
    }, [])
    
    const hasSamePrivilege = async () => {
        let hasValidPrivilage = await matchPrivilege(userName, token);
        if(!hasValidPrivilage) {
            handleLogout();
        }
    }

    const isUserValid = async () => {
        let is_valid = await checkValid(token, userName);
        if(is_valid.isValid === "FALSE" && (is_valid.loginCount == '1' || is_valid.loginCount == '0')) {
            handleLogout();
        }
    }
    const checkSimultaneous = async()=>{
        let res:any = await getSimultaneousValue(userName);
        if(res?.simul === false && res?.userCount !== '1'){
           await doIsValidFalse(userName);
        }
    } 


    useEffect(() => {
        if (isLoggedIn ) {
            const interval = setInterval(async () => {
                isUserValid();
                hasSamePrivilege();
                checkSimultaneous();
            }, 60 * 1000)
            return () => {
                clearInterval(interval);
            }
        }
    }, []);

    let history = useHistory()



    useEffect(() => {
        document.body.setAttribute('data-theme', theme)
    }, [theme]);

    const handleThemeChange = () => {
        remove_Cookie('Selected_theme')
        if(theme === 'dark'){
            setTheme('light')
            set_Cookie('Selected_theme', 'light')
        }else if(theme === 'light'){
            setTheme('b/w')
            set_Cookie('Selected_theme', 'b/w')
        }else{
            setTheme('dark')
            set_Cookie('Selected_theme', 'dark')
        }
    }

    return <>
        <div className="top-bar" >
            <div style={{width:"50%",display:"flex"}}>

        {isLoggedIn ? <div className='dropdown_navContainer' >
            <Container fluid >
                <div className="navContainer" style={{ width: "5%" }}>
                    <input className="checkBox" type="checkbox" name="" id="myCheckbox1" />
                    <div className="hamburger-lines">
                        <span className="line line1"></span>
                        <span className="line line2"></span>
                        <span className="line line3"></span>
                    </div>
                    <div className="menu-items">
                        <LeftNavigation activeMenu={para[2]} isLoggedIn={user.isLoggedIn} user={user} setPara={setPara}/>
                    </div>
                </div>
            </Container>
            </div> :""
            }
            {isLoggedIn?
            <div className='Vista_logo'>
                <div className="logo " style={{height:"100%"}}>
                    <img src="assets/icon/vista_logo.png"   /> 
                </div>
            </div>:
            <div className='Vista_logo_logout'>
                <div className="logo " style={{height:"100%"}}>
                    <img src="assets/icon/vista_logo.png" />
                </div>
            </div>}
            <div className="feedsense_title">
                <h2>FeedSense</h2>
            </div>
            </div>
            <div style={{width:"50%",marginRight: "140px"}}>
            <div className='sebi_id_upperDiv' >
                <div className='sebi_id' >
                    <span>SEBI RA : INH000010557</span>
                </div>
                <div className='main-div' style={{float:"right", boxShadow:'none!important'}}>
                    <div className='logoutButton' >
                        {isLoggedIn && <Dropdown className='dropdown-button' style = {{boxShadow:'none!important'}} >
                            <div>
                                <Dropdown.Toggle id="dropdown-basic" className="dropdown-toggle caret-off" >
                                    <div style={{}}>
                                        <img className="profile_img" style={{ height:  "2.3rem" }} src="assets/icon/profile.gif" />
                                    </div>
                                </Dropdown.Toggle>
                            </div>
                            <Dropdown.Menu className="profile-dropdown">
                                <Dropdown.Item onClick={() => history.push('/pages/profile')}>Profile</Dropdown.Item>
                                <Dropdown.Item onClick={handleLogout} >Signout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>}
                    </div>
                    {/* <div className='theme_div'> */}
                        <button className="theme" onClick={() => handleThemeChange()} >
                            <img src={`${theme}` == 'light' ? "assets/icon/light.png" : `${theme}` == 'dark' ? "assets/icon/dark.png" : "assets/icon/bw.png"} alt="" className="themelogo" />
                        </button>
                    {/* </div> */}
                </div>
            </div>
            </div>
            {/* <div className='sideBar'>
                <div className='float-button-div'>
                    <button className="float" id="" onClick={() => setVisibleLeft(true)} >
                        <img style={{ maxWidth: "3.5rem", borderRadius: "1rem" }} src="./assets/icon/globe-lineal.gif" alt="" />
                    </button>

                    
                    <Sidebar visible={visibleLeft} position="right" onHide={() => setVisibleLeft(false)} className="sidebar" id='sidebar'>
                        <div className="Trending_news" >
                            Trending News:
                            <div className="topbartweet">
                                {newsData?.map((item: any, index:any) => {
                                    if (item?.title?.length > 54) {
                                        item.title = (item?.title).slice(0, 54) + "..."
                                    } else if (item?.title?.length < 40) {
                                        item.title = (item?.title) + ".      "
                                    }
                                    return (<div className="tilt-newsbox-wrap" key={index}>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <div className="tilt-box">
                                                <strong style={{ fontWeight: "unset" }}>
                                                    <div className="topbarnews" style={{ border: `${"2px solid"+getColorBySentiment(item?.sentiment)}` }}>
                                                        <div className="topbarnews_upperDiv">
                                                            <div className='topbarnews_imgLink' >
                                                                <img src={item?.image_link} />
                                                            </div>
                                                            <div style={{ marginLeft: "1rem" }}>
                                                                <div className='topbarnews_publisher' >{item?.publisher}</div>
                                                                <p style={{float:"left"}}>{moment(item?.datePublished).format('DD MMM YYYY')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row" id="topbarnews_title" >
                                                            {item?.title}
                                                        </div>
                                                    </div>
                                                </strong>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="Trending_tweets" >
                            Trending Tweets:
                            <div className="tweet_section " style={{ marginTop: "4%", width: "60%" }}>
                                {allTweets?.map((item: any, index:any) => {
                                    if (item.full_text.length > 150) {
                                        item.full_text = item.full_text.slice(0, 156) + "...."
                                    }
                                    return (<div className="tilt-box-wrap" key={index} >
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <span className='t_over'></span>
                                            <div className="tilt-box">
                                                <strong style={{ fontWeight: "unset" }}>
                                                    <div className="tweetdiv tweetTopBar" id="tweetdiv" style={{ border: `${"2px solid"+getColorBySentiment(item?.sentiment)}` }}>
                                                        <div style={{ marginTop: "0.8rem" , position:"relative"}}>
                                                            <div className="tweetdiv_img_upperdiv">
                                                                <div className="" style={{ padding: "0px", width: "3.5rem" }}>
                                                                    <img className="tweet_profile_pic" src={item?.user?.display_url} />
                                                                </div>
                                                                <div style={{margin:"0.5rem 0rem 0rem 0.5rem", fontSize: "1.3rem", fontWeight: "bold" }}>
                                                                    {item.user.name}
                                                                </div>
                                                                <div style={{position:"absolute", top:"6%", right:"4%"}}>
                                                                    <FontAwesomeIcon icon={faTwitter} style={{ float: "right", color: "#1890ff" }} />
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: "0.8rem", color: "rgb(58 56 56) !important", textAlign:"start", marginLeft:"4%"  }}>
                                                                <div className="col-lg-12">
                                                                    <p style={{ fontSize: "1.32rem", fontWeight: "lighter" }}>{item.sentiment > 0 ? <FontAwesomeIcon icon={faCircle} style={{ color: "green" }} /> : item.sentiment == 0 ? <FontAwesomeIcon icon={faCircle} style={{ color: "#ffcc00" }} /> : <FontAwesomeIcon icon={faCircle} style={{ color: "red" }} />}<span style={{ marginLeft: "5px" }}>{item.full_text}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="" style={{ margin: "0.5rem 0rem 0rem 1.6rem ", float:"left" }}>
                                                                <a href={item.user.user_url} target="_blank" rel="noopener noreferrer">{item.user.user_url}</a>
                                                            </div>
                                                            <div className="col-lg-1"></div>
                                                        </div>
                                                    </div>
                                                </strong>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Sidebar>
                </div>
            </div> */}
        </div>
    </>
}