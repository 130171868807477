import {axiosFXOption} from "../axios"
import { getUserObject } from "../Service/auth.service";
let userObj:any = getUserObject();

export function getLiveListOfTradesFXOptions(version:any,tradetype:any,asset:any)
{
        let optionObj:any = {
                version  : version,
                tradetype: tradetype,
                asset    : asset
        }
        optionObj["username"] = userObj.email_id    
    return axiosFXOption.post(`/fx-option/get_live_trades`,optionObj)
            .then((res:any) => {
                return res?.data;
        }).catch((err:any) => console.log(err))
}

export function getLiveFXOptionCalls(version:any,tradetype:any,asset:any){
    let optionObj:any = {
        version  : version,
        tradetype: tradetype,
        asset    : asset
    }
    optionObj["username"] = userObj.email_id    
    return axiosFXOption.post(`/fx-option/get_live_calls`,optionObj)
    .then((res:any) => {
        return res?.data;
    }).catch((err:any) => console.log(err))
}

export function getLiveFXOptionSr(version:any,tradetype:any,asset:any){
    let optionObj:any = {
        version  : version,
        tradetype: tradetype,
        asset    : asset
    }
    optionObj["username"] = userObj.email_id
    return axiosFXOption.post(`/fx-option/get_live_sr`,optionObj)
        .then((res:any) => {
            return res?.data;
    }).catch((err:any) => console.log(err))
}

export function getLiveFXOptionPl(version:any,tradetype:any,asset:any){
    let optionObj:any = {
        version  : version,
        tradetype: tradetype,
        asset    : asset
    }
    optionObj["username"] = userObj.email_id
    return axiosFXOption.post(`/fx-option/get_live_pl`,optionObj)
        .then((res:any) => {
            return res?.data;
    }).catch((err:any) => console.log(err))
}

export function get_Live_pl_percentage_FXOption(version: any,tradetype: any,asset:any){
        let optionObj:any = {
                version  : version,
                tradetype: tradetype,
                asset    : asset
        }
    optionObj["username"] = userObj.email_id    
    return axiosFXOption.post(`/fx-option/get_live_pl_percent`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}


export async function getMtmDrawdownFXOptions(date:any,asset:any,id:any, version:any)
{
        let optionObj:any = {
                "date" : date ,
                "asset": asset,
                "id"   : id,
                "version":version
        }
        return await axiosFXOption.post(`/fx-option/get_m2m_drawdown`,optionObj)
            .then((res:any) => {
                return res?.data;
        }).catch((err:any) => console.log(err))
}

export async function getFXOptionsParameter(id:any,asset:any,date:any,version:any)
{
        let optionObj:any = {
                id     : id,
                asset  : asset,
                date   : date ,
                version: version
        }
    optionObj["username"] = userObj.email_id  
    return axiosFXOption.post(`/fx-option/get_parameter`,optionObj)
            .then((res) => {
                return res?.data;
        }).catch((err) => console.log(err))
}


export async function getFXOptionAdviceStatus(startDate:any,endDate:any, version: any,tradetype:any,asset_type:any){
    let optionObj:any = {
            startDate: startDate,
            endDate: endDate,
            version  : version,
            tradetype: tradetype,
            asset    : asset_type
    }
    optionObj["username"] = userObj.email_id
    return await axiosFXOption.post(`/fx-option/get_trades`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export async function getFXOptionPlPercentage(startDate:any,endDate:any,version:any,tradetype: any,asset_type:any){
        let optionObj:any = {
                startDate: startDate,
                endDate:endDate,
                version   : version,
                tradetype : tradetype,
                asset     : asset_type
        }
        optionObj["username"] = userObj.email_id
        return axiosFXOption.post(`/fx-option/get_pl_percent`,optionObj)
                .then((res:any) => {
                    return res.data;
            }).catch((err:any) => console.log(err))
}

export function getCallsFXOptions(startDate:any,endDate:any, version: any,tradetype:any,asset_type:any)
{
        let optionObj:any = {
                startDate: startDate,
                endDate: endDate,
                version  : version,
                tradetype: tradetype,
                asset    : asset_type
        }
    optionObj["username"] = userObj.email_id    
    return axiosFXOption.post(`/fx-option/get_calls`,optionObj)
            .then((res) => {
                return res?.data;
        }).catch((err) => console.log(err))
}

export function getSrFXOptions(startDate:any,endDate:any, version: any ,tradetype:any,asset_type:any){
    let optionObj:any = {
            startDate: startDate,
            endDate: endDate,
            version  : version,
            tradetype: tradetype,
            asset    : asset_type
    }
    optionObj["username"] = userObj.email_id
    return axiosFXOption.post(`/fx-option/get_sr`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export function getPlFXOptions(startDate:any,endDate:any, version: any ,tradetype:any,asset_type:any)
{
        let optionObj:any = {
                startDate: startDate,
                endDate:endDate,
                version   : version,
                tradetype : tradetype,
                asset     : asset_type
        }
optionObj["username"] = userObj.email_id
    return axiosFXOption.post(`/fx-option/get_pl`,optionObj)
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

