export const frontendRoutes: {
    home: string,
    newsHome: string,
    newsByKeyword: string,
    portalHome: string
} = {
    home: '/',
    newsHome: '/news/',
    newsByKeyword: '/news/keyword/',
    portalHome: '/portal/'
}