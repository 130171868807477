import React from 'react';
import { LogoContainer } from '../../components/shared/LogoArea/LogoContainer';
import { CategoriesNavBar } from '../../components/NewsHome/NavBar/CategoriesNavBar';
import './style.css';
import { TwitterStreamContainer } from '../../components/NewsHome/TweetsBar/TwitterStreamContainer';
import { NewsContainerType2 } from '../../components/NewsHome/NewsContainers/ContainerType2/container';
import { NewsContainerType3 } from '../../components/NewsHome/NewsContainers/ContainerType3/container';
import { NewsContainerType4 } from '../../components/NewsHome/NewsContainers/ContainerType4/container';
import { NewsContainerType5 } from '../../components/NewsHome/NewsContainers/ContainerType5/container';
import { NewsContainerType6 } from '../../components/NewsHome/NewsContainers/ContainerType6/container';
import { NewsContainerType7 } from '../../components/NewsHome/NewsContainers/ContainerType7/NewsContainerType7';
import NewsContainerType1 from '../../components/NewsHome/NewsContainers/ContainerType1/container';
import { axios } from '../../Api/axios';
import { getFeedsenseData, getNewsBySector, getNewsSectors, requestLogin } from '../../Api/News/news';
import { getTokenFromStorage, setTokenToStorage } from '../../Api/Service/auth.service';

interface NewsHomeLayoutProps {}
interface NewsHomeLayoutState {
    newsSectors: any[];
    recomendedNews: any[];
    sectorWiseNews: any[];
    feedSenseData: any[],
    newsContainersToRender: any[],
    isLoggedIn: boolean
}

export class NewsHomeLayout extends React.Component<NewsHomeLayoutProps, NewsHomeLayoutState> {
    constructor(props: any) {
        super(props)
        this.state = {
            newsSectors: [],
            recomendedNews: [],
            sectorWiseNews: [],
            feedSenseData: [],
            newsContainersToRender: [],
            isLoggedIn: false
        }
    }

    async getNewsContainer(params: {type: number, newsArticles1: any, title1: string, newsArticles2: any, title2: string}) {
        
        const containers = [
            {type: 2, container: <NewsContainerType2 title={params.title1} articles={params.newsArticles1}/>},
            {type: 3, container: <NewsContainerType3 title1={params.title1} title2={params.title2} articles1={params.newsArticles1} 
            articles2 = {params.newsArticles2} twitterFeedSenseArr={this.state.feedSenseData}/>},
            {type: 4, container: <NewsContainerType4 title={params.title1} articles={params.newsArticles1}/>},
            {type: 5, container: <NewsContainerType5 title={params.title1} articles={params.newsArticles1}/>},
            {type: 6, container: <NewsContainerType6 title1={params.title1} title2={params.title2} articles1={params.newsArticles1} 
            articles2 = {params.newsArticles2}/>},
            {type: 7, container: <NewsContainerType7 title={params.title1} articles={params.newsArticles1}/>}
        ]
        return containers[params.type]
    }

    async returnNewsContainersWithRandomSector() {
        const newsSectors = await getNewsSectors();        
        let news1: any = []
        let news2: any = []
        let newsContainers: any = []
        
        for(let i = 0; i <= 5; i++){
            
            if(i === 0) {
                news1 = await getNewsBySector(newsSectors[0].sector)
                newsContainers.push(<NewsContainerType2 title={newsSectors[0].sector} articles={news1} />)
                newsSectors.splice(0, 1)
            }
            if(i === 1) {
                news1 = await getNewsBySector(newsSectors[0].sector)
                news2 = await getNewsBySector(newsSectors[1].sector)
                newsContainers.push(<NewsContainerType3 title1={newsSectors[0].sector} title2={newsSectors[1].sector} articles1={news1} 
                    articles2 = {news2} twitterFeedSenseArr = {this.state.feedSenseData}/>)
                newsSectors.splice(0, 2)
            }
            if(i === 2) {
                news1 = await getNewsBySector(newsSectors[0].sector)
                newsContainers.push(<NewsContainerType4 title={newsSectors[0].sector} articles={news1} />)
                newsSectors.splice(0, 1)
            }
            if(i === 3) {
                news1 = await getNewsBySector(newsSectors[0].sector)
                newsContainers.push(<NewsContainerType5 title={newsSectors[0].sector} articles={news1} />)
                newsSectors.splice(0, 1)
            }
            if(i === 4) {
                news1 = await getNewsBySector(newsSectors[0].sector)
                news2 = await getNewsBySector(newsSectors[1].sector)
                newsContainers.push(<NewsContainerType6 title1={newsSectors[0].sector} title2={newsSectors[1].sector} articles1={news1} 
                    articles2 = {news2}/>)
                newsSectors.splice(0, 2)
            }
            if(i === 5) {
                news1 = await getNewsBySector(newsSectors[0].sector)
                newsContainers.push(<NewsContainerType7 title={newsSectors[0].sector} articles={news1} />)
                newsSectors.splice(0, 1)
            }

            this.setState({newsContainersToRender: newsContainers}) 
        }

        for(let i = 0; i < newsSectors.length; i+=3){
            
            news1 = await getNewsBySector(newsSectors[i].sector)
            if((i + 1) < newsSectors.length){
                news2 = await getNewsBySector(newsSectors[i+1].sector)
                newsContainers.push(<NewsContainerType6 title1={newsSectors[i].sector} title2={newsSectors[i+1].sector} articles1={news1} 
                    articles2 = {news2}/>)
                this.setState({newsContainersToRender: newsContainers})
            }else {
                newsContainers.push(<NewsContainerType7 title={newsSectors[i].sector} articles={news1} />)
                this.setState({newsContainersToRender: newsContainers})
            }
            if((i + 2) < newsSectors.length){
                let news3 = await getNewsBySector(newsSectors[i+2].sector)
                newsContainers.push(<NewsContainerType7 title={newsSectors[i+2].sector} articles={news3} />)
                this.setState({newsContainersToRender: newsContainers})
                this.setState({newsContainersToRender: newsContainers})
            }
                        
             
        }
    }

    componentDidMount() {
        axios.get("/news?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoic3VydS5nQGdtYWlsLmNvbSIsInNlc3Npb25fdG9rZW4iOiI3Mzg1Mjg4MzIiLCJpYXQiOjE1OTMwNzI5NjF9.RN03IEpWbLIUuexFdKnUFdVEUltmwR-RHdYCpRRytto")
        .then((res) => {
            this.setState({recomendedNews: res.data.recommended_articles});
        }
        )
        
        axios.get("/news?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoic3VydS5nQGdtYWlsLmNvbSIsInNlc3Npb25fdG9rZW4iOiI3Mzg1Mjg4MzIiLCJpYXQiOjE1OTMwNzI5NjF9.RN03IEpWbLIUuexFdKnUFdVEUltmwR-RHdYCpRRytto")
        .then((res) => {
            this.setState({recomendedNews: res.data.recommended_articles});
        }
        )
        getFeedsenseData().then((res) => {
            this.setState({feedSenseData: res.tweets})
        })
        
        this.returnNewsContainersWithRandomSector();

        let token = getTokenFromStorage();
        if(token && token != "") {
            this.setState({isLoggedIn: true})
        }
    }

    handleLogin = () => {
        let email: any = document.getElementById("email")
        let password: any = document.getElementById("password")

        requestLogin(email.value, password.value).then((res) => {
            if(res.access_token) {
                setTokenToStorage(res.access_token)
                this.setState({isLoggedIn: true})
            }
        })
    }

    handleLogout = () => {
        window.localStorage.removeItem("token")
        this.setState({isLoggedIn: false})
    }

    render() {
        return(
            <div>
                <TwitterStreamContainer />
                <LogoContainer/> 
                <CategoriesNavBar isLoggedIn={this.state.isLoggedIn} handleLogout={this.handleLogout} handleLogin={this.handleLogin}/>
                <NewsContainerType1 articles={this.state.recomendedNews}/>
                <>
                {this.state.newsContainersToRender.map((data) => {return data})}
                </>
                {/* 
                <NewsContainerType2 articles={this.state.recomendedNews}/>
                <NewsContainerType3 articles={this.state.recomendedNews} twitterFeedSenseArr={this.state.feedSenseData}/>
                <NewsContainerType5 articles={this.state.recomendedNews}/>
                <NewsContainerType4 articles={this.state.recomendedNews}/>
                <NewsContainerType6 articles={this.state.recomendedNews}/>
                <NewsContainerType7 articles={this.state.recomendedNews}/> */}
            </div>
        )
    }
}