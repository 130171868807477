import './container.css';
import { MediumNewsItemType5 } from './MediumNewsItemType5';
import Carousel from 'react-multi-carousel';
import { LeftArrow } from '../../../Carousel/ArrowButtons/LeftArrow';
import { RightArrow } from '../../../Carousel/ArrowButtons/RightArrow';

interface NewsContainerType5Props {
    articles: any
    title: string
}

export const NewsContainerType5: React.FC<NewsContainerType5Props> = ({articles, title}) => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
        const { carouselState: { currentSlide } } = rest;
        return (
          <div className="carousel-button-group">
            <LeftArrow onClick={() => previous()} />
            <RightArrow onClick={() => next()} />
          </div>
        );
    };

    return (
        <div className="setction-type5 mb20 fifth_bg">
            <div className="container" id={title.split(" ").join('')}>
                <div className="row">
                    <div className="col-12" style={{paddingLeft:0}}>
                        <div className="mix_carousel">
                            <Carousel ssr
                                partialVisbile
                                deviceType={"desktop"}
                                itemClass="image-item"
                                responsive={responsive} 
                                arrows={true} renderButtonGroupOutside={false}
                            >
                                {articles.map((article: any) => {
                                    return(<MediumNewsItemType5 article={{id: article._id, title: article.title, imageLink: article.image_link, category: article.category,
                                        publishedDate: article.datePublished, sentiment: article.sentiment, summary: article.summary,
                                        logoUrl: article.logo, publisher: article.publisher}} />)
                                })}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}