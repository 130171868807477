export enum FeedsenseModules {
    Overview = "overview",
    StrategyTracker = "strategy-tracker",
    HistoricalPerformance = "historical-performance",
    PortfolioAnalytics = "portfolio-analytics",
    NewsRecomendation = "news",
    Subscription = "Subscription",
    Profile = "profile",
    Settings = "settings",
    News = "news",
    Backoffice = "back-office"
}

export const CommonModules = [FeedsenseModules.Overview, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription, FeedsenseModules.Backoffice]