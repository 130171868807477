import "./style.css"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "primereact/button";
import { Row } from "react-bootstrap";
import React from "react";
import { remove_Cookie } from "../../../../Cookies/remove_cookie";
import { get_Cookie } from "../../../../Cookies/get_cookie";
import csvDownload from 'json-to-csv-export'

interface AdviceStatusProps {
    advices: any[],
    handleAdviceClick: any,
    type: any
    selectedAsset: any
    asset_key_value: any
    theme: any
    version: any
    dropdownversion: any
    selectedAdviceStatus: any
    setSelectedAdviceStatus: any
}

export const AdviceStatus: React.FC<AdviceStatusProps> = ({ advices, version, dropdownversion, handleAdviceClick, selectedAsset, asset_key_value,
     type, theme, selectedAdviceStatus, setSelectedAdviceStatus }) => {

    const [selectedColumns, setSelectedColumns] = useState<any>();
    const [columnSelection, setColumnSelection] = useState<any>(false);
    const [selectedHeader, setSelectedheader] = useState<any>([])

    let current_date:any = new Date().toLocaleDateString()
    let current_time:any = new Date().toLocaleTimeString() 

    useEffect(() => {
        let index: any = 0;

        if (advices.length > 0) {
            index = advices.findIndex(x => x.uuid === selectedAdviceStatus.uuid && x.identifier === selectedAdviceStatus.identifier);
            handleAdviceClick(index)
        }
    }, [selectedAdviceStatus])


    const cols = [
        { field: 'asset', header: 'Symbol', isChecked: true },
        { field: 'identifier', header: 'Code', isChecked: true, disabled: true },
        { field: 'tradetype', header: 'Type', isChecked: true },
        { field: 'transaction', header: 'Transaction Price', isChecked: true },
        { field: 'target', header: 'Target', isChecked: true },
        { field: 'stoploss', header: 'Stoploss', isChecked: true },
        { field: 'exit_price', header: 'Exit Price', isChecked: true },
        { field: 'trade_pl', header: 'P/L', isChecked: true },
        { field: 'trade_pl_rs', header: 'P/L (₹)', isChecked: true },
        { field: 'open_date', header: 'Open Date', isChecked: true },
        { field: 'trade_close_date', header: 'Close Date', isChecked: true },
        { field: 'close_reason', header: 'Close Reason', isChecked: true }
    ];

    const optionCols = [
        { field: 'asset', header: 'Symbol', isChecked: true },
        { field: 'identifier', header: 'Code', isChecked: true, disabled: true },
        { field: 'tradetype', header: 'Type', isChecked: true },
        { field: 'strike_price_ce', header: 'CE Strike', isChecked: true },
        { field: 'strike_price_pe', header: 'PE Strike', isChecked: true },
        { field: 'transaction', header: 'Transaction Price', isChecked: true },
        { field: 'target', header: 'Target', isChecked: true },
        { field: 'stoploss', header: 'Stoploss', isChecked: true },
        { field: 'exit_price', header: 'Exit Price', isChecked: true },
        { field: 'trade_pl', header: 'P/L', isChecked: true },
        { field: 'trade_pl_rs', header: 'P/L (₹)', isChecked: true },
        { field: 'open_date', header: 'Open Date', isChecked: true },
        { field: 'trade_close_date', header: 'Close Date', isChecked: true },
        { field: 'expiry_date', header: 'Expiry Date', isChecked: true },
        { field: 'close_reason', header: 'Close Reason', isChecked: true }
    ];

    function setCookie(cname: any, cvalue: any, exdays: any) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    let obj: any = window.localStorage.getItem("HistColReorder");
    let parsedObj = JSON.parse(obj);
    let obj_option: any = window.localStorage.getItem("HistOptionColReorder");
    let parsedObj_option = JSON.parse(obj_option);

    let cookies_obj: any = [];
    let jsonObj: any = [];
    if (type === "options-stock" || type === "options-index" || type === "fx-option") {

        cookies_obj = get_Cookie('HistoricalOptionStorageCol');
        if (cookies_obj != undefined) {
            jsonObj = JSON.parse(cookies_obj);
        }
    } else {
        cookies_obj = get_Cookie('HistoricalStorageCol');
        if (cookies_obj != undefined) {

            jsonObj = JSON.parse(cookies_obj);
        }
    }


    document.onclick = function (event: any) {
        if (event.target.closest(".mHeaderbox") || event.target.closest(".list")) return
        else {
            setColumnSelection(false)
        }
    }

    useEffect(() => {
        if (type === "options-stock" || type === "options-index" || type === "fx-option") {
            let container1: any = [];
            let container2: any = [];
            if (cookies_obj !== undefined) {
                container1 = jsonObj.filter((item: any) => {
                    return optionCols.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })

                container2 = optionCols.filter((item: any) => {
                    return !jsonObj.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                let newContainer: any = [...container1, ...container2]

                setSelectedheader(newContainer)

            } else {
                setSelectedheader(optionCols)
            }
        }
        else {
            let container1: any = [];
            let container2: any = [];
            if (cookies_obj != undefined) {
                container1 = jsonObj.filter((item: any) => {
                    return cols.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })

                container2 = cols.filter((item: any) => {
                    return !jsonObj.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                let newContainer: any = [...container1, ...container2]
                if (type === "multiday-equity") {
                    newContainer = [...newContainer, { field: 'pl_without_dividend', header: 'P/L (Exclusive of Dividend)', isChecked: true }]
                    setSelectedheader(newContainer)
                } else {
                    setSelectedheader(newContainer)
                }
            } else {
                if (type === "multiday-equity") {
                    let newContainer: any = [...cols, { field: 'pl_without_dividend', header: 'P/L (Exclusive of Dividend)', isChecked: true }]
                    setSelectedheader(newContainer)
                } else {
                    setSelectedheader(cols)
                }
            }
        }

    }, [type, version])


    useEffect(() => {
        if (type === "options-stock" || type === "options-index" || type === "fx-option") {
            let container1: any = [];
            let container2: any = [];
            if (parsedObj_option != undefined && parsedObj_option !== null) {
                container1 = parsedObj_option.filter((item: any) => {
                    return selectedHeader.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })

                container2 = selectedHeader.filter((item: any) => {
                    return !parsedObj_option.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                let newContainer: any = [...container1, ...container2]
                newContainer.map((item: any) => {
                    selectedHeader.map((arr: any) => {
                        if (item.header === arr.header && item.field === arr.field) {
                            item.isChecked = arr.isChecked
                        }
                    })
                })

                setSelectedColumns(newContainer)
            }
            else {
                setSelectedColumns(selectedHeader)
            }
        } else {
            if (parsedObj != undefined && parsedObj !== null) {
                let container1: any = [];
                let container2: any = [];
                container1 = parsedObj.filter((item: any) => {
                    return selectedHeader.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })

                container2 = selectedHeader.filter((item: any) => {
                    return !parsedObj.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                let newContainer: any = [...container1, ...container2]
                newContainer.map((item: any) => {
                    selectedHeader.map((arr: any) => {
                        if (item.header === arr.header && item.field === arr.field) {
                            item.isChecked = arr.isChecked
                        }
                    })
                })

                setSelectedColumns(newContainer)
            }
            else {
                setSelectedColumns(selectedHeader)
            }
        }
    }, [selectedHeader])


    const defaultRow = (item: any) => {
        return {
            'row-coloring': (item.uuid === advices[0].uuid)
        }
    }



    const handleAsset = (item: any) => {

        let assetname = item.asset
        if (type === 'intraday-stock-index' || type === 'fx-future' || type === 'comodity-future' ) {

            return assetname;
        }
        else if (type === "options-stock" || type === "options-index") {
            let asset: any = Object.values(asset_key_value)[Object.keys(asset_key_value).indexOf(assetname)]
            return asset
        }
        else {
            if (assetname?.search("_") != -1) {
                let assets = assetname?.split("_")
                assetname = assets[0] + "  " + assets[1]
                return assetname
            }
            else {
                return assetname
            }
        }
    }


    const handleDate = (value: any) => {
        let changed_date_format = moment(value).format("MMM Do, YYYY")
        return changed_date_format
    }
    const handlevalue = (identifier: any, value: any, valuesType: any) => {
        let returnValue: any;
        if (value === '' || value === undefined || value === null) {
            return ''
        }
        if (identifier?.slice(1, 2) === 'F') {
            returnValue = Number(value)?.toFixed(4)
        }
        else {
            returnValue = Number(value)?.toFixed(2)
        }
        if (valuesType === 'pl') {
            if (returnValue >= 0) {
                if (theme == 'dark' || theme == 'b/w') {
                    return <span style={{ color: "lightgreen", fontWeight: 'bold' }}>{returnValue}</span>
                } else {
                    return <span style={{ color: "green", fontWeight: 'bold' }}>{returnValue}</span>
                }
            }
            else if (returnValue < 0) {
                return <span style={{ color: "orangered", fontWeight: 'bold' }}>{returnValue}</span>
            }
            else if (returnValue == '') {
                return <span></span>

            }
            else {
                return <span style={{ color: "whitesmoke", fontWeight: 'bold' }}>{returnValue}</span>
            }
        } else {
            return returnValue
        }
    }
    const handleReason = (str: any) => {
        str = str?.toLowerCase().split('_').join(' ')
        return str
    }

    const selectedRow = () => {
        var selectedRow: any = document.getElementById("selected_row")
        selectedRow.classList.add("selected");

    }

    let headers:any  = [
        'Open Date',
        'Open Time',
        'Symbol',
        'Type',
        'Transaction Price',
        'Target',
        'Stoploss',
        'P/L',
        'P/L (₹)',
        'Code',
        'Close Time',
        'Exit Price',
        'Close Reason',
        'Close Date',
    ];

    let spot_headers:any  = [
        'Open Date',
        'Open Time',
        'Symbol',
        'Type',
        'Transaction Price',
        'Target',
        'Stoploss',
        'P/L',
        'P/L(Exclusive of Dividend)',
        'Code',
        'Close Time',
        'Exit Price',
        'Close Reason',
        'Close Date'
    ];

    let option_headers:any  = [
        'Open Date',
        'Open Time',
        'Symbol',
        'Type',
        'Transaction Price',
        'Target',
        'Stoploss',
        'P/L',
        'P/L (₹)',
        'Code',
        'Close Time',
        'Exit Price',
        'CE Strike',
        'PE Strike',
        'Option Type',
        'Expiry Date',
        'Close Reason',
        'Close Date',
    ];

    const csvDataMaker = (e:any) => {

        e.preventDefault();
        let result:any = []
        if(type == 'options-stock' || type === 'options-index' || type === 'fx-option')
        {
            result = advices?.reduce((res:any, item:any) => {
                res.push({ open_date : item?.open_date,
                     dt_open_time: item?.dt_open_time,
                     asset: item?.asset, tradetype:item?.tradetype, transaction: item?.transaction,
                     target: (item?.target === undefined || item?.target === null || item?.target === '')?'':item?.target,
                     stoploss: (item?.stoploss === undefined || item?.stoploss === null || item?.stoploss === '')? '' : item?.stoploss,
                     trade_pl: item?.trade_pl,
                     trade_pl_rs: item?.trade_pl_rs,
                     identifier: item?.identifier,
                     dt_end_time: (item?.dt_end_time === null || item?.dt_end_time === '') ? '' : item?.dt_end_time,
                     exit_price: (item?.exit_price === null || item?.exit_price === '')? '' : item?.exit_price,
                     strike_price_ce: (item?.strike_price_ce === null || item?.strike_price_ce === '')?'':item?.strike_price_ce,
                     strike_price_pe: (item?.strike_price_pe === null || item?.strike_price_pe === '')?'':item?.strike_price_pe,
                     option_type: (item?.option_type === undefined || item?.option_type === null || item?.option_type === '')?'':item?.option_type,
                     expiry_date: item?.expiry_date, 
                     close_reason: item?.close_reason, 
                     trade_close_date: item?.trade_close_date });
                return res;
            }, []);

        }
        else if (type == "multiday-equity")
        {
            result = advices?.reduce((res:any, item:any) => {
                res.push({ open_date : item?.open_date, 
                    dt_open_time: item?.dt_open_time, 
                    asset: item?.asset,
                    tradetype: item?.tradetype, 
                    transaction: item?.transaction, 
                    target: item?.target, 
                    stoploss: item?.stoploss, 
                    trade_pl: item?.trade_pl, 
                    pl_without_dividend:item?.pl_without_dividend,
                    identifier: item?.identifier,
                    dt_end_time: (item?.dt_end_time === null || item?.dt_end_time === '') ? '' : item?.dt_end_time,
                    exit_price: (item?.exit_price === null || item?.exit_price === '')? '' : item?.exit_price,
                    close_reason: (item?.close_reason === null || item?.close_reason === '')?'':item?.close_reason,
                    trade_close_date: (item?.trade_close_date === null || item?.trade_close_date === '')? '' : item?.trade_close_date});
                return res;
            }, []);
        }
        else
        {
            result = advices?.reduce((res:any, item:any) => {
                res.push({ open_date : item?.open_date, dt_open_time: item?.dt_open_time, asset: item?.asset,
                    tradetype: item?.tradetype, transaction: item?.transaction, target: item?.target, stoploss: item?.stoploss, 
                    trade_pl: item?.trade_pl,trade_pl_rs: item?.trade_pl_rs, identifier: item?.identifier,
                    dt_end_time: (item?.dt_end_time === null || item?.dt_end_time === '') ? '' : item?.dt_end_time,
                    exit_price: (item?.exit_price === null || item?.exit_price === '')? '' : item?.exit_price,
                    close_reason: (item?.close_reason === null || item?.close_reason === '')?'':item?.close_reason,
                    trade_close_date: (item?.trade_close_date === null || item?.trade_close_date === '')? '' : item?.trade_close_date});
                return res;
            }, []);
        }

        const dataToConvert = {
            data: result,
            filename: `${type}_historical_trades_for_${current_date}_at_${current_time}`,
            delimiter: ',',
            headers: (type == 'options-stock' || type === 'options-index' || type === 'fx-option')? option_headers :type === "multiday-equity"? spot_headers: headers
          }

          csvDownload(dataToConvert)
    
    }


    const onColumnToggle = (event: any) => {
        const { name, checked } = event.target;


        if (name === "allSelect") {
            let tempCode = selectedHeader.map((item: any) => {
                if (item?.header === 'Code') {
                    return { ...item, isChecked: true };
                }
                return { ...item, isChecked: checked };
            });

            setSelectedheader(tempCode)
            if (type === "options-stock" || type === "options-index" || type === "fx-option") {
                remove_Cookie('HistoricalOptionStorageCol')
                setCookie('HistoricalOptionStorageCol', JSON.stringify(tempCode), 30)
            } else {
                remove_Cookie('HistoricalStorageCol')
                setCookie('HistoricalStorageCol', JSON.stringify(tempCode), 30)
            }
        } else {
            let tempCode = selectedHeader.map((item: any) =>
                item.header === name ? { ...item, isChecked: checked } : item
            );

            setSelectedheader(tempCode);
            if (type === "options-stock" || type === "options-index" || type === "fx-option") {
                remove_Cookie('HistoricalOptionStorageCol')
                setCookie('HistoricalOptionStorageCol', JSON.stringify(tempCode), 30)
            } else {
                remove_Cookie('HistoricalStorageCol')
                setCookie('HistoricalStorageCol', JSON.stringify(tempCode), 30)
            }
        }
    }


    const header = (
        <div>
            <Row className="align-items-center export-buttons">
                <div className="d-flex" style={{ width: "45%" }}>
                    <h5 className="float-left advise_header">Advice Status</h5>
                </div>
                <div className="d-flex justify-content-end" style={{ width: "55%", position: "relative" }}>
                    <div style={{ marginRight: "2rem" }}>
                        {advices.length > 0 ?
                            columnSelection == true ?
                                <div className='list' style={{ top: "4rem", overflowY: "auto", minHeight: "20rem", height: "22rem" }}>
                                    <form >
                                        <div>
                                            <div className='form-check'>
                                                <input className='form-mcheckbox' type='checkbox' name="allSelect" checked={selectedHeader.filter((item: any) => item?.isChecked !== true).length < 1} onChange={onColumnToggle} />
                                                <label className='form-value' htmlFor="">All</label>
                                            </div>
                                        </div>
                                        <div>
                                            {selectedHeader.map((item: any) => (
                                                <div className='form-check'>
                                                    <input className='form-input' type='checkbox' name={item.header} checked={item?.isChecked || false} onChange={onColumnToggle} disabled={item?.disabled} />
                                                    <label className='form-value' htmlFor={item.header} >{item.header}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </form>
                                </div>
                                :
                                <div>
                                </div> : ""
                        }
                    </div>
                    <div className="d-flex align-items-center">
                        {advices.length > 0 ?
                            <Button type="button" className="mHeaderbox advicesBTN" onClick={() => { setColumnSelection(!columnSelection) }} style={{ boxShadow: "rgb(220,153,72) 0.1rem 0.1rem 2rem", margin: '0 0.8rem 0 0.3rem', background: "beige", border: "none", width: "3.2rem", padding: "0.3rem" }}>
                                <img src="./assets/icon/preference_icon.png" />
                            </Button>
                            : ""}
                        <Button type="button" icon="pi pi-download " onClick={(e:any) => {csvDataMaker(e)}} className="p-button-success btn-lg float-right advicesBTN" style={{ boxShadow: "rgb(104 159 56) 0.1rem 0.1rem 2rem", width: "3.2rem", height: "3.2rem" }} ></Button>
                    </div>
                </div>
            </Row>

        </div>
    );

    const onColReorder = (item: any) => {
        let colreordered: any = []
        item.map((col: any) => {
            let colOptions = {
                "field": col.props.field, "header": col.props.header, isChecked: true
            }

            colreordered.push(colOptions)
        });
        if (type === 'options') {
            window.localStorage.setItem("HistOptionColReorder", JSON.stringify(colreordered))
        }
        else {
            window.localStorage.setItem("HistColReorder", JSON.stringify(colreordered))
        }

    }

    const columnComponents = selectedColumns?.map((col: any) => {
        if (type === "options-stock" || type === "options-index" || type === "fx-option") {
            if (col.field === "asset" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '12rem' }} body={(e) => handleAsset(e)} />;
            }
            else if (col.field === "identifier" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '18rem'}} />;
            }
            else if (col.field === "tradetype" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '12rem' }} />;
            }
            else if (col.field === "strike_price_ce" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '12rem' }} body={(e) => (e.strike_price_ce)} />;
            }
            else if (col.field === "strike_price_pe" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '12rem' }} body={(e) => (e.strike_price_pe)} />;
            }
            else if (col.field === "transaction" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '18rem' }} body={(e) => handlevalue(e.identifier, e.transaction, "not_pl")} />;
            }
            else if (col.field === "target" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '10rem' }} body={(e) => handlevalue(e.identifier, e.target, "not_pl")} />;
            }
            else if (col.field === "stoploss" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '10rem' }} body={(e) => handlevalue(e.identifier, e.stoploss, "not_pl")} />;
            }
            else if (col.field === "exit_price" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '12rem' }} body={(e) => handlevalue(e.identifier, e?.exit_price, "not_pl")} />;
            }
            else if (col.field === "trade_pl" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '10rem' }} body={(e) => handlevalue(e.identifier, e.trade_pl, "pl")} />;
            }
            else if (col.field === "trade_pl_rs" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '14rem' }} body={(e) => handlevalue(e.identifier, e.trade_pl_rs, "pl")} />;
            }
            else if (col.field === "open_date" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '15rem' }} body={(e) => handleDate(e.open_date)} />;
            }
            else if (col.field === "trade_close_date" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '15rem' }} body={(e) => handleDate(e.trade_close_date)} />;
            }
            else if (col.field === "expiry_date" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '15rem' }} body={(e) => handleDate(e.expiry_date)} />;
            }
            else if (col.field === "close_reason" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '20rem' }} body={(e) => handleReason(e.close_reason)} />;
            }
        }
        else {
            if (col.field === "asset" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center', textTransform: "capitalize", minWidth: '12rem' }} body={(e) => handleAsset(e)} />;
            }
            else if (col.field === "identifier" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textAlign: 'center' ,textTransform: "capitalize", minWidth: '18rem' }} />;
            }
            else if (col.field === "tradetype" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '12rem', textAlign: 'center' }} />;
            }
            else if (col.field === "transaction" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '18rem', textAlign: "center" }} body={(e) => handlevalue(e.identifier, e.transaction, "not_pl")} />;
            }
            else if (col.field === "target" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '10rem', textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e.target, "not_pl")} />;
            }
            else if (col.field === "stoploss" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '10rem', textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e.stoploss, "not_pl")} />;
            }
            else if (col.field === "exit_price" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '12rem', textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e?.exit_price, "not_pl")} />;
            }
            else if (col.field === "trade_pl" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '10rem',textAlign:'center' }} body={(e) => handlevalue(e.identifier, e.trade_pl, "pl")} />;
            }
            else if (col.field === "trade_pl_rs" && type !== "multiday-equity" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '14rem',textAlign:'center'}} body={(e) => handlevalue(e.identifier, e.trade_pl_rs, "pl")} />;
            }
            else if (col.field === "open_date" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem' }} body={(e) => handleDate(e.open_date)} />;
            }
            else if (col.field === "trade_close_date" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem' }} body={(e) => handleDate(e.trade_close_date)} />;
            }
            else if (col.field === "close_reason" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem' }} body={(e) => handleReason(e.close_reason)} />;
            }
            else if (col.field === "pl_without_dividend" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ minWidth: '27rem', textAlign: "center" }} body={(e) => handlevalue(e.identifier, e.pl_without_dividend, "pl")} />;
            }

        }
    })


    return (<div className="advice-card datatable-selection-demo">

        {selectedAdviceStatus === 0 ?
            <div className="advisetable" >
                {(type === "options-stock" || type === "options-index" || type === "fx-option") ?
                    <DataTable value={advices} header={header} id="selected_row" selectionMode="single" reorderableColumns onColReorder={(e: any) => { onColReorder(e.columns) }} stripedRows scrollable scrollHeight="40rem" style={{ margin: "1.5rem" }}
                        className="advice_row" rowClassName={defaultRow} selection={selectedAdviceStatus} onSelectionChange={(e: any) => { setSelectedAdviceStatus(e.value); selectedRow(); }} >
                        {columnComponents}
                    </DataTable> :
                    <DataTable value={advices} header={header} id="selected_row" selectionMode="single" reorderableColumns onColReorder={(e: any) => { onColReorder(e.columns) }} stripedRows scrollable scrollHeight="40rem" style={{ margin: "1.5rem" }}
                        className="advice_row" rowClassName={defaultRow} selection={selectedAdviceStatus} onSelectionChange={(e: any) => { setSelectedAdviceStatus(e.value); selectedRow(); }} >
                        {columnComponents}
                    </DataTable>}
            </div>
            :
            <div className="advisetable" >
                {type === "options-stock" || type === "options-index" || type === "fx-option" ?
                    <DataTable value={advices} header={header} id="selected_row" selectionMode="single" reorderableColumns onColReorder={(e: any) => { onColReorder(e.columns) }} stripedRows scrollable scrollHeight="40rem" style={{ margin: "1.5rem" }}
                        className="advice_row" selection={selectedAdviceStatus} onSelectionChange={(e: any) => { setSelectedAdviceStatus(e.value); selectedRow(); }} >
                        {columnComponents}
                    </DataTable> :
                    <DataTable value={advices} header={header} id="selected_row" selectionMode="single" reorderableColumns onColReorder={(e: any) => { onColReorder(e.columns) }} stripedRows scrollable scrollHeight="40rem" style={{ margin: "1.5rem" }}
                        className="advice_row" selection={selectedAdviceStatus} onSelectionChange={(e: any) => { setSelectedAdviceStatus(e.value); selectedRow(); }} >
                        {columnComponents}
                    </DataTable>}
            </div>}

    </div>

    )
}


