import { getColorBySentiment } from "../../util/utils";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function sentimentIcon(sentiment: number) {
    let sentimenArr: any = []
    switch (true) {
        case sentiment < 0:
            sentimenArr['icon'] = <FontAwesomeIcon icon={faMinusCircle} style={{color: getColorBySentiment(sentiment), 
                margin: "0px 5px 0px 0px"}}></FontAwesomeIcon>
            sentimenArr['text'] = "Negative"
            break;

        case sentiment === 0:
            sentimenArr['icon'] = <FontAwesomeIcon icon={faCircle} style={{color: getColorBySentiment(sentiment), 
                margin: "0px 5px 0px 0px"}}></FontAwesomeIcon>
            sentimenArr['text'] = "Neutral"
            break;
        case sentiment > 0:
            sentimenArr['icon'] = <FontAwesomeIcon icon={faPlusCircle} style={{color: getColorBySentiment(sentiment), 
                margin: "0px 5px 0px 0px"}}></FontAwesomeIcon>
            sentimenArr['text'] = "Positive"
            break;
        default:
            break;
    }
    return sentimenArr;
}