import './Filter.css';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { TagCloud } from 'react-tagcloud'
import { useState } from 'react';
import { Redirect } from 'react-router';
import { frontendRoutes } from '../../../../constants/frontendRoutes';
import { useHistory } from "react-router-dom";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface FilterProps {
    keywordTags: any,
    publisherTags: any,
    companyTags: any,
    categoryTags: any
}

export const Filter: React.FC<FilterProps> = ({keywordTags, publisherTags, companyTags, categoryTags}) => {
    const [filterBy, setFilterBy] = useState('keyword');
    let history = useHistory()

    let handleChangeFilterBy = (filterBy: string) => {
        setFilterBy(filterBy)
    }

    let seed = 1337
    function random() {
        const x = Math.sin(seed++) * 10000
        return x - Math.floor(x)
    }

    const applyFilter = (tag: any, filterBy: string) => {
            const win = window.open(frontendRoutes.newsByKeyword + tag.value + "/" + filterBy, "_blank");
    }
    
    return (
        <div className="filter-container" >
            <Form >
                <div className="row">
                    <Col>
                        <Form.Label style={{fontSize: 17, padding: '10px 0px 0px 12px', color: '#747373', opacity: .7}}>
                            <FontAwesomeIcon icon={faFilter} /></Form.Label>
                    </Col>
                    <Col>
                        <Form.Control as="select" className="custom-form-control" style={{width: 274, borderRadius: '.2em'}}
                        onChange={(e) => handleChangeFilterBy(e.target.value)} value={filterBy}>
                            <option value="publisher">Publisher</option>
                            <option value="keyword">Keyword</option>
                            <option value="company">Company</option>
                            <option value="category">Category</option>
                        </Form.Control>
                    </Col>
                </div>
                <div style={{position: 'relative', height: 380}}>
                    <TagCloud
                        minSize={12}
                        maxSize={35}
                        tags={filterBy === 'publisher' ? publisherTags : filterBy === 'keyword' ? keywordTags : filterBy === 'company' ? companyTags : 
                        filterBy == 'category' ? categoryTags : ''}
                        onClick={(tag: any) => applyFilter(tag, filterBy)}
                    />
                </div>
            </Form>
        </div>
    )
}