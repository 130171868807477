// import ReactApexChart from 'react-apexcharts'
// import './news_style.css';
// import Chart, {
//   CommonSeriesSettings,
//   Series,Reduction,ArgumentAxis,Label,Format,ValueAxis,Title,Legend,Export,Tooltip,Tick,Crosshair,Font,Annotation,Image,CommonAnnotationSettings
// } from 'devextreme-react/chart';
// import { annotationSources } from './data';
// import AnnotationTemplate from './AnnotationTemplate';
import { CandlestickChart } from '../CandleChart';

// import {data} from '../../../../../../src/nifty_data'



// @ts-ignore
// import CanvasJSReact from '@canvasjs/react-charts';
// let CanvasJS:any = CanvasJSReact.CanvasJS;
// let CanvasJSStockChart:any = CanvasJSReact.CanvasJSStockChart;


interface NewsSentimentProps{
    theme:any
    newsSignals:any
}

const customizeTooltip = (arg:any) => (
  {text: `Open: ${arg.openValue}<br/>
          Close: ${arg.closeValue}<br/>
          High: ${arg.highValue}<br/>
          Low: ${arg.lowValue}<br/>`,
          }
  );
// const customizeAnnotation = (annotation:any) => ({
//   html: `<div class='tooltip'>${annotation.description}</div>`,
// });

const customAnnotation = (annotation:any)=>(
  {
  html: `<image href="${annotation?.image.url}" width="60" height="40" />`
  })
// const fill = {
//     fillId: customPatternId
// };
export const NewsSentimentChart: React.FC<NewsSentimentProps> = ({theme, newsSignals})=>{

  let axis_lable_color :any
  let border_color :any
  let axis_border_color :any
  let line_color:any



  // console.log("CanvasJSStockChart",CanvasJSStockChart);
  
  

  if(theme === 'dark'){
    axis_lable_color = '#fff'
    border_color = '#262626'
    axis_border_color = '#ffffff'
    line_color = '#d9d7ca'
  }
  else if (theme === 'light'){
      axis_lable_color = '#000'
      border_color = '#a2cdf4'
      axis_border_color = '#659ac2'
      line_color = '#9cc1e369'
  }
  else if(theme === 'b/w'){
    axis_lable_color = '#fff'
    border_color = '#ffffff30'
    axis_border_color = '#ffffff'
    line_color = '#e8e6d8'
  }

  let news_obj:any = []
  let chartOptions:any 
  let totalNewsSignals:any = []

  // let nifty_data:any = niftyData.map((item:any)=>{
  //   return {...item,item["date"] : new Date(item['date'])}
  // })

    // for (let item of niftyData)
    // {
    //   item.date = new Date(item.date)
    // }


    if (newsSignals.length > 0){
      // for (let item of newsSignals)
      // {
      //   if (item.value > 0)
      //   {
      //     item.type = 'image',
      //     item.url = "/assets/icon/sell1.png",
      //     item.width = 30
      //     item.height = 40
      //   }
      //   else{
      //     item.type = 'image',
      //     item.url = "/assets/icon/buy.png",
      //     item.width = 30
      //     item.height = 40
      //   }
      // }


      newsSignals.map((item:any)=>{
            if (item.value > 0)
            {
             let obj:any = { 
                              "x" : item['date'],
                              "y" : item['o'],
                              "date" : item['date'],
                              "text" : "buy",
                              "type" :'image',
                              "url" : "/assets/icon/buy.png",
                              "width" : 30,
                              "height" : 40
                            }
              totalNewsSignals.push(obj)
            }
            else{
              let obj:any = {
                            "x" : item['date'],
                            "y" : item['o'],
                            "date" : item['date'],
                            "text" : "buy",
                            "type" : 'image',
                            "url" : "/assets/icon/buy.png",
                            "width" : 30,
                            "height" : 40
                          }
              totalNewsSignals.push(obj)
            }


      })
    }

    // console.log("newsSignals",totalNewsSignals);
    



// if ((newsSignals.length > 0) && (niftyData.length > 0)){
    
//     newsSignals.map((item:any)=>{
//         if (item['value'] > 0 )
//         {
//             let obj:any = {
//                 "x" : item['x'],
//                 "y" : item['y'],
//                 "lebel": {
//                     "text": "buy",
//                     "style": {
//                         "color": "#339933",
//                         "background": '#00E396'
//                     }
//                 },
//                 "marker":{
//                   "size": 0
//                 },
//                 "image": {
//                   path:"/assets/icon/buy.png",
//                   width: 12,
//                   height: 12,
//                   offsetY: 6,
//                   offsetX: 0
//                 }
//             }  
//             news_obj.push(obj)
//         }
//         else{
//             let obj:any = {
//                 "x" : item['x'],
//                 "y" : item['y'],
//                 "lebel": {
//                     "text": "sell",
//                     "borderColor": false,
//                     "style": {
//                         "color": "#ff471a",
//                         "background": '#00E396'
//                     }
//                   },
//                   "marker":{
//                     "size": 0
//                   },
//                   "image": {
//                     path:"/assets/icon/sell1.png",
//                     width: 12,
//                     height: 12,
//                     offsetY: -6,
//                     offsetX: 0
//                   }
//             }  
//             news_obj.push(obj)
//         }

//   })
// }
//   console.log("news_obj",news_obj);
  
//   chartOptions = {
//     series: [
//       {
//         data: niftyData
//       }
//     ],
//     options: {
//       chart: {
//         type: 'candlestick',
//         height: 600,
//         // toolbar:{
//         //   show: false
//         // },
//         toolbar: {
//           show: true,
//           offsetX: 0,
//           offsetY: 0,
//           tools: {
//             download: false,
//             selection: true,
//             zoom: true,
//             zoomin: true,
//             zoomout: true,
//             pan: false
//           },
//         },
//         animations: {
//           enabled: false
//         },
//       },
//       // title: {
//       //   text: 'Nifty 50 sentiments',
//       //   align: 'left',
//       //   style: {
//       //     fontSize: '35',
//       //     color: axis_lable_color
//       //   }
//       // },
//       annotations: {
//         points: news_obj,
//       },
//       colors: ['#f7eb9c',line_color],
//       grid: {
//         borderColor: border_color,
//         row: {
//           colors: ['transparent', 'transparent'],
//           opacity: 0.5
//         },
//       },
//       // plotOptions: {
//       //   bar:{
//       //     columnWidth:'12rem',
//       //   },
//       //   candlestick: {
//       //     colors: {
//       //       upward: '#00B746',
//       //       downward: '#EF403C'
//       //     },
//       //     wick: {
//       //       useFillColor: true
//       //     }
//       //   }
//       // },
//       xaxis: {
//         type: 'category',
//         title: {
//           show: false
//         },
//         tickAmount:10,
//         dataLabels: {
//           enabled: false
//         },
//         labels: {
//           show: true,
//           rotate: -45,
//           style: {
//             colors: axis_lable_color
//           }
//         },
//         // axisBorder: {
//         //   show: true,
//         //   color: axis_border_color,
//         //   height: 1,
//         //   width: '100%',
//         //   offsetX: 0,
//         //   offsetY: 0
//         // },
//         // axisTicks: {
//         //   show: false,
//         //   borderType: 'solid',
//         //   color: axis_border_color,
//         //   height: 6,
//         //   offsetX: 0,
//         //   offsetY: 0
//         // }
//       },
//       yaxis: {
//         tooltip: {
//           enabled: false
//         },
//         labels:{
//           style:{
//             colors:axis_lable_color
//           }
//         }
  
//       },
//       // tooltip: {
//       //   custom: function({dataPointIndex, w}:any) {
//       //     return (
//       //       '<div class="arrow_box">' +
//       //       "<div class='ohlc'>"+
//       //         "<span>"+"O: "+ w.config.series[0].data[dataPointIndex].y[0].toFixed(2)+"</span>"+
//       //         "<span>"+"H: "+ w.config.series[0].data[dataPointIndex].y[1].toFixed(2)+"</span>"+
//       //         "<span>"+"L: "+ w.config.series[0].data[dataPointIndex].y[2].toFixed(2)+"</span>"+
//       //         "<span>"+"C: "+ w.config.series[0].data[dataPointIndex].y[3].toFixed(2)+"</span>"
//       //       +"</div>"+
//       //       "</div>"
//       //     );
//       //   }
//       // },
      
//       dataLabels: {
//         enabled: false
//       },
//       legend:{
//         show:false,
//         labels:{
//           colors: axis_lable_color
//         }
//       }
  
//     },
    
//   }
// }



  // const dataPoints:any = [
  //   // Your stock data points here
  //   { x: new Date(2022, 1, 1), y: 100 },
  //   // ...

  //   // Buy and Sell points
  //   { x: new Date(2022, 2, 1), y: 120, markerColor: 'green', markerType: 'triangle' }, // Buy
  //   { x: new Date(2022, 3, 1), y: 90, markerColor: 'red', markerType: 'triangle' },    // Sell
  // ];

  // const options:any = {
  //   // Chart options
  //   title: {
  //     text: 'Stock Chart with Buy/Sell Points',
  //   },
  //   axisX: {
  //     valueFormatString: 'DD MMM YYYY',
  //   },
  //   data: [{
  //     type: 'ohlc', // Use 'ohlc' type for stock chart
  //     dataPoints,
  //   }],
  // };

  return(<div style={{height:"58rem"}} id='newsChartMainDiv' className="newsChartMain">
          <div>
          {newsSignals?.length > 0 ? <CandlestickChart data={newsSignals} theme={theme}/> : <></>}
          </div>
          <div>

          </div>
        {/* <div className="SentimentChartTitle">Nifty 50 sentiments</div> */}
        {/* <CanvasJSStockChart options={options} /> */}

      {/* <Chart id="chart" title="Stock Price" dataSource={niftyData}>
        <CommonSeriesSettings argumentField="date" type="candlestick" />
        <Series 
          openValueField="o"
          highValueField="h"
          lowValueField="l"
          closeValueField="c"
        >
        </Series>
        <ArgumentAxis tickInterval={1}>
          <Tick visible={true} />
          <Label format="longTime" />
        </ArgumentAxis>
        <Crosshair
          enabled={true}
          color="#949494"
          width={1}
          dashStyle="dash"
        >
          <Label
            visible={true}
            backgroundColor="#949494"
          >
            <Font
              color="#fff"
              size={10}
            />
          </Label>
        </Crosshair>
        <ValueAxis tickInterval={10}>
          <Label>
            <Format />
          </Label>
        </ValueAxis>
        <Legend itemTextPosition="left" />
        <Export enabled={true} />
        <Tooltip
        enabled={true}
        customizeTooltip={customizeTooltip}
      />
        {
          newsSignals.map((item:any,index:number) => <Annotation
            key={index}
            argument={item.date}
            type={item.type}
            text={item.text}
            paddingTopBottom = {10}
            render={AnnotationTemplate}
            >
            <Image url={item.url} />
          </Annotation>)
        }
        <Legend visible={true}></Legend>
      </Chart> */}

  </div>)
}