import { useState } from 'react';
import './NewsContainerType7.css';
import { MediumNewsItemType7 } from './NewsItem';

interface NewsContainerType7Props {
    articles: any
    title: string
}

export const NewsContainerType7: React.FC<NewsContainerType7Props> = ({articles, title}) => {
    const [noOfItems, setNoOfItems] = useState(4)

    return (
        <div className="setction-type7 mb40 fifth_bg">
            <div className="container">
                <div className="row">
                    <div className="heading" id={title.split(" ").join('')}>
                        <h2 className="widget-title">
                            {title}
                        </h2>
                        <a href={"/news/keyword/"+title+"/category"} target="_blank" rel="noopener noreferrer" className="read-more" 
                        >SEE ALL</a>
                    </div>
                </div>
                <div className="row">
                    {articles.map((data: any, i: number) => {
                        if(i < noOfItems)
                            return <MediumNewsItemType7 article={{id: data._id, title: data.title, imageLink: data.image_link, category: data.category,
                            publishedDate: data.datePublished, sentiment: data.sentiment, logoUrl:data.logo, publisher: data.publisher,
                            summary: data.summary}} />
                    })}
                </div>
            </div>
        </div>
    )
}