import './container.css';
import { NiftySentimentChart } from './NiftySentimentChart';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { News } from '../../../../Models/News';
import { SmaillIconsSlide } from '../ContainerType3/SmaillIconsSlide';
import Carousel from 'react-multi-carousel';
import { useEffect, useState } from 'react';
import { ArrowButtonGroup } from '../../../shared/ArrowButtonGroup';
import sentiment_data_0 from '../../../../dummy_data/sentiment_chart_0.json';
import sentiment_data_7 from '../../../../dummy_data/sentiment_chart_7.json';
import sentiment_data_14 from '../../../../dummy_data/sentiment_chart_14.json';
import sentiment_data_28 from '../../../../dummy_data/sentiment_chart_28.json';
import { getNiftySentimentGraph } from '../../../../Api/News/news';

interface NewsContainerType4Props {
    articles: any[],
    title: string
}

export const NewsContainerType4: React.FC<NewsContainerType4Props> = ({articles, title}) => {
    const [noOfSmaillIconsInACol, setnoOfSmaillIconsInACol] = useState(4);
    const sentimentDurations = ["today", "week", "fortnight", "month"]
    const[sentimentDuration, setSentimentDuration] = useState(sentimentDurations[0]);
    const[sentimentDataArr, setDataArr] = useState([]);
    const[labels, setLabels] = useState([]);

    useEffect(() => {
        getNiftySentimentGraph(0).then((res) => {
            handleSentimentData(res)
        })
    }, [])
  
      let handleSentimentData = (sentimentData: any) => {
        let dataArr: any = []
        let labels: any = []
        sentimentData.map((data: any) => {
          dataArr.push(data.y)
          labels.push(data.x)
        })
        setDataArr(dataArr)
        setLabels(labels)
      }

      let handleDurationChange = async (duration: string) => {
        
        if(duration === sentimentDurations[0]){
            getNiftySentimentGraph(0).then((res) => {
                handleSentimentData(res)
            })
        }
        if(duration === sentimentDurations[1]){
            getNiftySentimentGraph(7).then((res) => {
                handleSentimentData(res)
            })
        }
        if(duration === sentimentDurations[2]){
            getNiftySentimentGraph(14).then((res) => {
                handleSentimentData(res)
            })
        }
        if(duration === sentimentDurations[3]){
            getNiftySentimentGraph(28).then((res) => {
                handleSentimentData(res)
            })
        }

        setSentimentDuration(duration)
      }

    const responsiveSmallIcons = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    let smallIconsSlides:any = [];
    for(let i = 0; i < articles.length; i += noOfSmaillIconsInACol){
        let articleArr:News[] = []
        for(let j = i; j < i + noOfSmaillIconsInACol; j++){
            let data = articles[j]
            if(!data) continue
            let article:News = {id: data._id, title: data.title, imageLink: data.image_link, category: data.category,
                publishedDate: data.datePublished, sentiment: data.sentiment, logoUrl:data.logo, publisher: data.publisher}
            articleArr.push(article)
        }
        smallIconsSlides.push(
            <SmaillIconsSlide articleSubArr={articleArr} />
        )
    }

    return (
        <div className="setction-type4 mb20 fifth_bg ">
            <div className="container">
                <div className="row">
                    <div className="col-8 canvas-container">
                        <div className="heading">
                            <h2 className="widget-title">
                                Nifty Sentiments
                            </h2>
                        </div>
                        <div className="heading">
                        <ButtonGroup aria-label="Basic example" size="sm" style={{width: "100%"}} className="nifty-sentiment-buttons"> 
                            <Button variant="secondary" active={sentimentDuration === sentimentDurations[0]}
                            onClick={()=>handleDurationChange(sentimentDurations[0])}>Today</Button>
                            <Button variant="secondary" active={sentimentDuration === sentimentDurations[1]}
                            onClick={()=>handleDurationChange(sentimentDurations[1])}>Weekly</Button>
                            <Button variant="secondary" active={sentimentDuration === sentimentDurations[2]}
                            onClick={()=>handleDurationChange(sentimentDurations[2])}>Fortnightly</Button>
                            <Button variant="secondary" active={sentimentDuration === sentimentDurations[3]}
                            onClick={()=>handleDurationChange(sentimentDurations[3])}>Monthly</Button>
                        </ButtonGroup>
                        </div>
                        <NiftySentimentChart dataArr={sentimentDataArr} labelsArr={labels}/>
                    </div>
                    <div className="col-4 " >
                         <div className="heading" id={title.split(" ").join('')}>
                            <h2 className="widget-title">
                                {title}
                            </h2>
                        </div>
                        <Carousel ssr
                        partialVisbile
                        deviceType={"desktop"}
                        itemClass="small-icon-type3-carousel-item"
                        responsive={responsiveSmallIcons} 
                        customButtonGroup={<ArrowButtonGroup />}
                        arrows={false} renderButtonGroupOutside={true}
                        >
                            {smallIconsSlides}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}