import { axiosAuthService, axiosBuySell, axiosLocal5000Local } from "../axios";

export function getOpenCalls() {
    return axiosLocal5000Local.get(`/live-trade/open-calls`)
        .then((res) => {
            return res.data;
    }).catch((err) => console.log(err))
}

// export function requestLogin(user: Object) {
//     return axiosLocal4000.post(`/live-trade/user_login`, user)
//         .then((res) => {
//             return res.data;
//     }).catch((err) => console.log(err))
// }

export function requestLogin(user: any) {
    return axiosAuthService.get(`/user/user_login?email_id=${user.userId}&pass=${user.password}`)
        .then((res) => {
            return res?.data;
    }).catch((err) => console.log(err))
}
export async function requestValid(token: any, userName: any) {
    return await axiosAuthService.post(`/user/getValidUser`,{"token":token, "userName": userName})
    .then((res)=>{
        return res?.data;
    }).catch((e)=>{
        console.log(e);
        return {'isValid':'TRUE',
        'loginCount':'0',
        };
    });
    
}

export async function getPreviousPrivilege(userName:any) {
    return await axiosAuthService.post(`/user/getUserPrivilage`,
    {"username": userName})
    .then((res)=>{
        return {"arr":res?.data,
    "on":true}
    }).catch((e)=>{
        console.log(e);
        return {"arr":[],
        "on":false}
    })
}

export async function requestIsActiveFalse(userName:any){
    return await axiosAuthService.post(`user/do_isActive_False`,{"userName":userName})
    .then((res)=>{
        return res?.data;
    }).catch((e)=>{
        console.log(e);
        // return false;
    })
}

export async function reqLogoutByToken(token:any){
    return await axiosAuthService.post(`user/reqLogoutByToken`,{'token':token})
    .then((res)=>{
        return res?.data
    }).catch((e)=>{
        console.log(e)
        // return true
    })
}

export async function reqSimultaneous(userName:any){
    return await axiosAuthService.post(`user/getSimultaniousLogin`,{"userName":userName})
    .then((res)=>{
        return res?.data;
    }).catch((e)=>{
        console.log(e)
        return [{"count": '0'}]
        
    })
}

export async function reqlogoutByUserName(userName:any){
    return await axiosAuthService.post(`user/reqlogoutByUserName`,{"userName":userName})
    .then((res)=>{
        return res.data;
    }).catch((e)=>{
        console.log(e)
        return {"simul": true,
         "userCount" : '0'}
    })
}
export async function reqTokenIsActiveFalse(token:any){
    return await axiosAuthService.post(`user/token_false`,{"token":token})
    .then((res)=>{
        return res?.data;
    }).catch((e)=>{
        console.log(e);
    })
}

export async function getNotifi(userName:any) {
    return await axiosAuthService.post(`user/getNotificationValue`,{"username": userName})
    .then((res)=>{
        return res.data;
    }).catch((e)=>console.log(e)
    )
}

export async function requestSimultaneous(userName:any){
    return await axiosAuthService.post(`user/getSimultaneous`,{"userName":userName})
    .then((res:any)=>{
        return res?.data;
    }).catch((e:any)=>{
        console.log(e)
        return {"simul": true,
         "userCount" : '0'}
    })
}

export async function requestIsValidFalse(userName:any){
    return await axiosAuthService.post(`user/doIsActiveFalse`,{"userName":userName})
    .then((res:any)=>{
        // return res?.data[0]?.simultaneous_flag;
    }).catch((e:any)=>console.log(e))
}

export async function getNotificationStatus(userName:any)
{
    return await axiosAuthService.post(`user/User_Notification_Status`,{"username": userName})
    .then((res)=>{
        return res.data;
    }).catch((e)=>console.log(e))
}


export async function getReleaseNote(version:any)
{
    return await axiosBuySell.post(`/generalise_api/releaseNote`,{"version":version})
    .then((res)=>{
        return res.data;
    }).catch((e)=>console.log(e))
}

export async function getUserSubscription(userName:any)
{
    return await axiosAuthService.post(`user/user_subscription`,{
        "email" : userName
    }).then((res:any)=>{
        return res?.data;
    }).catch((error:any)=>{
        console.log("Error in getUserSubscription : ", error)
        return false;
    })
}

