import { News } from '../../../../Models/News';
import { SmallNewsItemType1 } from '../ContainerType1/SmallNewsItemType1';
import './SmaillIconsSlide.css';

interface SmaillIconsSlideProps {
    articleSubArr: News[]
}

export const SmaillIconsSlide: React.FC<SmaillIconsSlideProps> = ({articleSubArr}) => {

    return (
        <>
            {articleSubArr.map((data, i) => {
                if(i < articleSubArr.length - 1) {
                    return (
                        <>
                            <SmallNewsItemType1 article={data} />
                            <div className="space-15"></div>
                            <div className="border_black"></div>
                            <div className="space-15"></div>
                        </>
                    )
                }else
                    return (
                        <SmallNewsItemType1 article={data} />
                    )
            }) }
        </>
    )
}