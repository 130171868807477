import { News } from '../../../../Models/News';
import { add3Dots, getColorBySentiment, parseDateInDisplayFormat2 } from '../../../../util/utils';
import './MediumNewsItemType3.css';
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { frontendRoutes } from '../../../../constants/frontendRoutes';

interface MediumNewsItemType3Props {
    article: News
}

export const MediumNewsItemType3: React.FC<MediumNewsItemType3Props> = ({article}) => {  
    let summary = ''
    if(article.summary) summary = article.summary
    return (
        <div className="medium-type3-item" style={{height: `${!article.summary ? '460px' : '514px'}`}}>
            <div className="single_post " style={{width: "100%"}}>
                <div className="post_img " style={{borderLeft: `5px solid ${getColorBySentiment(article.sentiment)}`}}>
                    <img src={article.imageLink} width="410" height="280"/> 
                </div>
                <div className="single_post_text">
                    <div className="meta3">
                        <a href={"/news/keyword/"+article.category+"/category"} target="_blank" rel="noopener noreferrer">{article.category}</a>
                        <a href="#" className="meta-date">{parseDateInDisplayFormat2(article.publishedDate)}</a>
                        <p className="meta-publisher">{article.publisher}</p>
                        {/* <FontAwesomeIcon icon={faCircle} style={{color: getColorBySentiment(article.sentiment), 
                                margin: "5px 0px 0px 4px"}}></FontAwesomeIcon> */}
                    </div>
                    <h4>
                        <a target="_blank" rel="noopener noreferrer" href={frontendRoutes.newsHome + article.id}>
                            {add3Dots(article.title, 75)}
                        </a>
                    </h4>
                    <div className="space-10"></div>
                    <p className="post-p">
                        {add3Dots(summary, 80)}
                    </p>
                </div>
            </div>
            
        </div>
    )
}