import React, { useEffect, useState } from 'react';
import { Modal} from 'react-bootstrap';
import moment from 'moment';
import '../BackOfficeLayout/style.css'
import { InputText } from 'primereact/inputtext';
import Calendar from 'react-calendar';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import ModalForm from './modalform';
import { get_Identifier } from '../../Api/buySell/strategyTracker';
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer } from 'react-toastify';
import { getColorAccordingWithHistPL } from '../../util/utils';

function MyVerticallyCenteredModal(props: any) {
  
    return (
        <Modal
            className='modelCal'
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body >
                <div style={{ borderRadius: '0.5rem' }} >
                    <Calendar onChange={props.selectDate} value={props.selectedDate}
                        maxDate={new Date()} prev2Label={null}
                        next2Label={null}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

interface BackOfficeLayoutProps {
    advices: any,
    theme : any,
    setCalenderpickerdate:any, 
    calenderpickerdate:any,
    setAdvices:any,
    feedsensePl:any,
    tradePl:any,
    selectedDate:any,
    setSelectedDate:any,
    updateRowInDatabase:any,
    setEditBoolean:any,
    setRowUpdateBoolean:any,
    totalMargin:any,
    totaltradePlAfterCost:any,
    totalFeedsensePlAfterCost:any,
    loading:any,
    modalIsOpen:any,
    setModalIsOpen:any,
    setTradedPl :any,
    setFeedsensePl :any,
    setTotalMargin :any,
    setTotalTradedPlAfterCost :any,
    setTotalFeedsensePlAfterCost :any,
    backOfficeAdvices:any,
    setSelectedTradeType:any,
    selectedTradeType:any,
    calculatingPlAndMargin:any,
    feedsenseLiveAdvices:any
}

export const BackofficeLayout: React.FC<BackOfficeLayoutProps> = ({ advices,theme, setCalenderpickerdate, calenderpickerdate,setAdvices,feedsensePl,tradePl,selectedDate,setSelectedDate,updateRowInDatabase,setEditBoolean ,setRowUpdateBoolean,totalMargin,totaltradePlAfterCost,totalFeedsensePlAfterCost,loading,modalIsOpen,setModalIsOpen,setTradedPl,
  setFeedsensePl,  setTotalMargin,  setTotalTradedPlAfterCost,  setTotalFeedsensePlAfterCost,
  backOfficeAdvices, setSelectedTradeType, selectedTradeType, calculatingPlAndMargin, feedsenseLiveAdvices}) => {

  const [visibleLeft, setVisibleLeft] = useState<any>(false)
  const [refreshClicked, setRefreshClicked] = useState(false);
  const [data, setData] = useState([]);
  const [isCostChecked, setIsCostChecked] = useState(false);
  const [totalIdentifier,setTotalIdentifier] = useState<any>()
  const [refreshTime,setRefreshTime] = useState<any>()
  const [selectedAdvice, setSelectedAdvice] = useState<any>([]) 




  const types_of_trading:any = ['Intraday', 'Multiday' ]

  
  useEffect(() => {
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();
      const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      setRefreshTime(formattedTime)
      get_Identifier()
        .then((res) => {
          setTotalIdentifier(res);
        })
        .catch((error) => {
          console.error('Error fetching Identifier:', error);
        });
    }, []);

    useEffect(() => {
      let formattedTime:any = new Date().toLocaleTimeString()
      setRefreshTime(formattedTime)
    },[refreshClicked])
  
  const liveModelsIdentifier = async () => {
    try {
      let response:any= get_Identifier()
      const finalRes = response.map((item:any) => item.s_code);
    } catch (error) {
      console.error('Error fetching product codes:', error);
    }
  };


  useEffect(()=>{
    if (modalIsOpen){
      liveModelsIdentifier()
    }
  },[modalIsOpen])




  const tradeStatusOptions = ['Taken', 'Not Taken'];

  const live_columns: any = [
      { field: 'edit', header: 'Edit', isChecked: true, disabled: true }, 
      { field: 's_product_code', header: 'Code', isChecked: true},
      { field: 'trade_status', header: 'Trade Status', isChecked: true},
      { field: 'n_lot_advised', header: 'Advised Lots', isChecked: true },
      { field: 'n_lot_traded', header: 'Manual lots', isChecked: true},
      { field: 'dt_date', header: 'Date', isChecked: true},
      { field: 'dt_open_time', header: 'FS Open Time', isChecked: true },
      { field: 'dt_trade_entry_time', header: 'Manual Open Time', isChecked: true },
      { field: 'trade_close_date', header: 'Exit Date', isChecked: true },
      { field: 'dt_end_time', header: 'FS Close Time', isChecked: true },
      { field: 'traded_exit_time', header: 'Manual Close Time', isChecked: true },
      { field: 's_type', header: 'Type', isChecked: true },
      { field: 'n_open_transaction_price', header: 'FS Transaction Price', isChecked: true },
      { field: 'n_trade_entry_price', header: 'Manual Transaction Price', isChecked: true },
      { field: 'exit_price', header: 'FS Close Price', isChecked: true },
      { field: 'traded_exit_price', header: 'Manual Close Price', isChecked: true },
      { field: 'trade_pl', header: 'FS P/L', isChecked: true },
      { field: 'n_trade_pl', header: 'Manual P/L', isChecked: true },
      { field: 'feedsense_total_pl', header: 'FS Total P/L (₹)', isChecked: true },
      { field: 'traded_total_pl', header: 'Manual Total P/L (₹)', isChecked: true },
      { field: 'return_per', header: 'FS Return (%)', isChecked: true },
      { field: 'traded_return_per', header: 'Manual Return (%)', isChecked:true},
      { field: 'return_after_cost', header: 'FS Return After Cost (%)', isChecked: true },
      { field: 'traded_return_after_cost', header: 'Manual Return After Cost (%)', isChecked: true},
      { field: 'n_margin_blocked', header: 'Margin Blocked', isChecked: true},  
      { field: 's_comment', header: 'Comment', isChecked: true}
      
  ];
 
  const selectDateFunc = (date: any) => {
    setCalenderpickerdate((moment(date).format('YYYY-MM-DD')))
    setSelectedDate(new Date(moment(date).format('YYYY-MM-DD')))
    setVisibleLeft(false)
  }

  const handleDownload = () => {
    const headers:any =["Code",
                    "Advised_Lots",
                    "Manual_lots",
                    "Feedsense_Open_Time",
                    "Manual_Open_Time",
                    "Exit_Date",
                    "Feedsense_Close_Time",
                    "Manual_Close_Time",
                    "Type",
                    "Feedsense_Transaction_Price",
                    "Manual_Transaction_Price",
                    "Feedsense_Close_Price",
                    "Manual_Close_Price",
                    "Feedsense_P/L",
                    "Manual_P/L",
                    "Feedsense_Total_P/L",
                    "Manual_Total_P/L",
                    "Feedsense_Return",
                    "Manual_Return",
                    "Feedsense_Return_After_Cost",
                    "Date",
                    "Margin_Blocked",
                    "Comment"]
    const dataToDownload:any = advices?.map((advice:any) => ({Code: advice?.s_product_code,
                            Advised_Lots: advice.n_lot_advised,
                            Manual_lots:  advice.n_lot_traded,
                            Feedsense_Open_Time:  advice.dt_open_time,
                            Manual_Open_Time:  advice.dt_trade_entry_time,
                            Exit_Date:  advice.trade_close_date,
                            Feedsense_Close_Time:  advice.dt_end_time,
                            Manual_Close_Time:  advice.traded_exit_time,
                            Type:  advice.trade_type,
                            Feedsense_Transaction_Price:  advice.n_open_transaction_price,
                            Manual_Transaction_Price:  advice.n_trade_entry_price,
                            Feedsense_Close_Price:  advice.exit_price,
                            Manual_Close_Price:  advice.traded_exit_price,
                            Feedsense_PL:  advice.trade_pl,
                            Manual_PL:  advice.n_trade_pl,
                            Feedsense_Total_PL:  advice.feedsense_total_pl,
                            Manual_Total_PL:  advice.traded_total_pl,
                            Feedsense_Return:  advice.return_per,
                            Manual_Return:  advice.traded_return_per,
                            Feedsense_Return_After_Cost:  advice.return_after_cost,
                            Date:  advice.dt_date,
                            Margin_Blocked: advice.n_margin_blocked,
                            Status:advice.s_comment
                          }));

    downloadCSV(dataToDownload,headers)
  }
 
  const downloadCSV = (data:any ,headers: any) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", "_");
 
    const fileName = `Back_Office_Report_${formattedDate}.csv`;
    const csvContent =
    "data:text/csv;charset=utf-8" +','+
    headers.join(",") + "\n" +
    data.map((row:any) => Object.values(row).join(",")).join("\n");
    

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href",encodedUri);
    link.setAttribute("download",fileName);
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
 
  const onRowEditComplete = async (e:any) => {

    let _advices = [...advices];
    let { newData, index } = e;

    _advices[index] = newData;
    setAdvices(_advices);
    updateRowInDatabase(e?.newData);
    setEditBoolean(true)
    setRowUpdateBoolean(true)
  };

 
  const handleValue = (value:any, asset:any) => {
    if (asset === 'USDINR-I.CDS') {
      return Number(value).toFixed(4);
    } else {
      return parseFloat(value).toFixed(2);
    }
  };
  const textEditor = (options:any) => {
    return   <div style ={{color : "black"}}>
          <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} style={{width:'20rem',height:'3rem',justifyContent: "center"}} />
    </div>
   
  };

  const priceEditor = (options:any) => {
    return (
      <div style ={{color : "black"}}>
          <input
          type="number"
          value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}  
        style={{height:'3rem',justifyContent: "center", border: "1px solid #ced4da", borderRadius : "3px"}}
      />
      </div>
      
    );
  };


  const handleMargin = (value:any) =>{
    if (value === undefined || value === null || value == 0){
      return 0.0
    }
    else{
      return Number(value).toFixed(2)
    }
  }

  const handleRefresh = (e:any) => {
    e.preventDefault()

    setRefreshClicked(true);
    feedsenseLiveAdvices(calenderpickerdate)
    calculatingPlAndMargin()


    setTimeout(() => {
      setRefreshClicked(false);
    }, 600);
  }

  

  const handleTradeStatusChange = (rowData:any, e:any) => {
    rowData.trade_status = e.value;
    setData([...data]); 
  };

  const statusEditor = (props:any) => {
    const default_value = 'Taken'
    
    return (
      <Dropdown
        value={props?.rowData.trade_status || default_value}
        options={tradeStatusOptions}
        onChange={(e) => handleTradeStatusChange(props.rowData, e)}
        style={{ width: '100%' , height : "3rem", alignItems:"center"}}
      />
    );
  };

  const timeEditor = (options:any) => {
    return (
      <InputText
        type="time"
        value={options.value}          
        onChange={(e) => {options.editorCallback(e.target.value +':00'); }}
        style={{width:'12rem',height:'3rem', justifyContent: "center"}}
      />
    );
  };
 
  const columnComponents = live_columns?.map((col: any) => {
        if(col.field === "edit" && col.isChecked){   
          return <Column key={col.field} rowEditor columnKey={col.field} field={col.field} header={col.header} style={{minWidth:'8rem',padding: '1rem 1rem 1rem 1rem',height:'5rem',display:'flex',flexDirection:'row', alignItems:'center', justifyContent: 'space-around'}} frozen />
        }
        else if (col.field === "s_product_code" && col.isChecked === true  ) {
          return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{minWidth:'15rem',padding: '1rem' , textTransform: 'capitalize', textAlign: 'start'}} frozen/>
        }
        else if (col.field ==="trade_status" && col.isChecked === true){
          return <Column columnKey={col.field} editor={(options => statusEditor(options))} field = {col.field} header = {col.header} style={{minWidth:'16rem', textTransform: 'capitalize', textAlign: 'center'}} 
          />
        }
        else if (col.field === "n_lot_advised" && col.isChecked === true) {
          return <Column sortable key={col.field} columnKey={col.field} field ={col.field} header={col.header} style={{minWidth:'18rem', textAlign: 'center'}}/>
        }
        else if (col.field === "n_lot_traded" && col.isChecked === true  ) {
          return <Column sortable key={col.field} editor={(options) => priceEditor(options)} columnKey={col.field} field ={col.field} header={col.header} style={{minWidth:'15rem', textAlign: 'center'}}/>
        }
        else if (col.field === "dt_date" && col.isChecked === true) {
          return <Column sortable key={col.field} columnKey={col.field} field ={col.field} header={col.header} style={{minWidth:'15rem', textAlign: 'center'}}/>
        }
        else if (col.field === "dt_open_time" && col.isChecked === true) {
          return <Column sortable key={col.field} columnKey={col.field} field ={col.field} header={col.header} style={{minWidth:'24rem', textAlign: 'center'}}/>
        }
        else if (col.field === "dt_trade_entry_time" && col.isChecked === true) {
          return <Column sortable key={col.field} columnKey={col.field}  editor={(options) => timeEditor(options)} field ={col.field} header ={col.header} style={{minWidth:'24rem', textAlign: 'center'}}/>
        }
        else if (col.field ==="trade_close_date" && col.isChecked === true) {
          return <Column sortable key={col.field} columnKey={col.field} field ={col.field} header = {col.header} style={{minWidth:'15rem', textAlign: 'center'}}/>
        }
        else if (col.field ==="dt_end_time" && col.isChecked === true) {
          return <Column sortable key={col.field} columnKey={col.field} field ={col.field} header = {col.header} style={{minWidth:'26rem',  textAlign: 'center'}} body ={(rowData: any) => rowData['s_reason'] === '' || rowData['s_reason'] === null ? "Trade Open" : rowData[col.field]}/>
        }
        else if (col.field ==="traded_exit_time" && col.isChecked === true) {
          return <Column sortable key={col.field} columnKey={col.field}  editor={(options) => timeEditor(options)} field = {col.field} header = {col.header} style={{minWidth:'24rem', textAlign: 'center'}} body ={(rowData: any) => rowData['s_reason'] === '' || rowData['s_reason'] === null ? "Trade Open" : rowData[col.field]}/>
        }
        else if (col.field ==="s_type" && col.isChecked === true) {
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'15rem',  textAlign: 'center'}}/>
        }
        else if (col.field ==="n_open_transaction_price" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'28rem',  textAlign: 'center'}}  body={(rowData: any) => rowData[col.field] === undefined || rowData[col.field] === null ? "":handleValue(rowData[col.field],rowData['s_symbol'])}/>
        }
        else if (col.field ==="n_trade_entry_price" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} editor={(options => priceEditor(options))} field = {col.field} header = {col.header} style={{minWidth:'28rem',  textAlign: 'center'}} body={(rowData: any) => rowData[col.field] === undefined || rowData[col.field] === null ? "":handleValue(rowData[col.field],rowData['s_symbol'])}/>
        }
        else if (col.field ==="exit_price" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'24rem', textAlign: 'center'}} 
                                        body={(rowData: any) => rowData[col.field] === undefined || rowData[col.field] === null ? "":handleValue(rowData[col.field],rowData['s_symbol']) } />  
        }
        else if (col.field ==="traded_exit_price" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} editor={(options => priceEditor(options))} field = {col.field} header = {col.header} style={{minWidth:'25rem', textAlign: 'center'}}
          body={(rowData: any) => rowData[col.field] === undefined || rowData[col.field] === null ? "":handleValue(rowData[col.field],rowData['s_symbol']) }/>
        }
        else if (col.field ==="trade_pl" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'16rem', textAlign: 'center'}} 
                                        body={(rowData: any) =>(
                                          <span className={rowData[col.field] >= 0 ? 'positive': 'negative'}>
                                              {rowData[col.field] === undefined || rowData[col.field] === null ? "":handleValue(rowData[col.field],rowData['s_symbol'])}
                                          </span>
                                        )} /> 
        }
        else if (col.field ==="n_trade_pl" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'17rem', textAlign: 'center'}}
                                        body={(rowData: any) =>(
                                          <span className={rowData[col.field] >= 0 ? 'positive': 'negative'}>
                                              {rowData[col.field] === undefined || rowData[col.field] === null ? "":handleValue(rowData[col.field],rowData['s_symbol'])}
                                          </span>
                                        )} /> 
      
        }
        else if (col.field ==="feedsense_total_pl" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'22rem', textAlign: 'center'}} 
                                        body={(rowData: any) =>(
                                          <span className={rowData[col.field] >= 0 ? 'positive': 'negative'}>
                                              {rowData[col.field] === undefined || rowData[col.field] === null ? "":handleValue(rowData[col.field],rowData['s_symbol'])}
                                          </span>
                                        )} />
        }
        else if (col.field ==="traded_total_pl" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'22rem',  textAlign: 'center'}} 
          body={(rowData: any) =>(
            <span className={rowData[col.field] >= 0 ? 'positive': 'negative'}>
                {rowData[col.field] === undefined || rowData[col.field] === null ? "":handleValue(rowData[col.field],rowData['s_symbol'])}
            </span>
          )} />
        }
        else if (col.field ==="return_per" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'22rem', textAlign: 'center'}} 
          body={(rowData: any) =>(
            <span className={rowData[col.field] >= 0 ? 'positive': 'negative'}>
                {rowData[col.field] === undefined || rowData[col.field] === null ? "":handleValue(rowData[col.field],rowData['s_symbol'])}
            </span>
          )} />
        }
        else if (col.field ==="traded_return_per" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'22rem',  textAlign: 'center'}}
          body={(rowData: any) =>(
            <span className={rowData[col.field] >= 0 ? 'positive': 'negative'}>
                {rowData[col.field] === undefined || rowData[col.field] === null ? "":handleValue(rowData[col.field],rowData['s_symbol'])}
            </span>
          )} /> 
        }
        else if (col.field ==="return_after_cost" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'26rem',  textAlign: 'center'}}  
          body={(rowData: any) =>(
            <span className={rowData[col.field] >= 0 ? 'positive': 'negative'}>
                {rowData[col.field] === undefined || rowData[col.field] === null ? "" :handleValue(rowData[col.field],rowData['s_symbol'])}
            </span>
          )} />
        }

        else if (col.field ==="traded_return_after_cost" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} field = {col.field} header = {col.header} style={{minWidth:'29rem',  textAlign: 'center'}}  
          body={(rowData: any) =>(
            <span className={rowData[col.field] >= 0 ? 'positive': 'negative'}>
                {rowData[col.field] === undefined || rowData[col.field] === null ? "" :handleValue(rowData[col.field],rowData['s_symbol'])}
            </span>
          )} /> 
        }

        else if (col.field ==="n_margin_blocked" && col.isChecked === true){
          return <Column sortable key={col.field} columnKey={col.field} editor={(options => priceEditor(options))} field = {col.field} header = {col.header} style={{minWidth:'22rem',textAlign: 'center'}} 
                                         body={(rowData: any) => handleMargin(rowData[col.field])}/>
        }
       
        else if (col.field ==="s_comment" && col.isChecked === true){
          return <Column className='comment_input' columnKey={col.field} editor={(options => textEditor(options))} field = {col.field} header = {col.header} style={{minWidth:'25rem', textAlign: 'left'}} />
        }

  });

  const handleCheckboxChange = () => {
    setIsCostChecked(!isCostChecked);
  };  


  return (
    <div className="container-custom strategy-tracker" >
        <ToastContainer className="toast_container"/>
        <div className ='product-type-upper-div'>
          <span className='product-type'>Back Office</span>
          <div className = 'product-type-upper-div-right'>
            <div>
              <span className="RefreshTimeDiv">Last refreshed on  {refreshTime}</span>
            </div>
            <div className='refresh-section'>
                <button className='refresh-button advicesBTN' onClick={(e: any) => handleRefresh(e)}><i className={refreshClicked ? 'rotating pi pi-refresh refresh-icon' : 'pi pi-refresh refresh-icon'} ></i></button>
            </div>
            <div >
              {<span onClick={() => setVisibleLeft(true)}>
                  <img className='calanderDiv' src="./assets/icon/icons-view-schedule.png" alt="" />
              </span>} 
              <MyVerticallyCenteredModal calenderpickerdate={calenderpickerdate} selectDate={selectDateFunc} selectedDate={selectedDate} onHide={() => setVisibleLeft(false)} show={visibleLeft} />
            </div>  
          </div> 
        </div>
        <ModalForm modalIsOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}  totalIdentifier = {totalIdentifier} setModalIsOpen={setModalIsOpen}/>
        <div className='datatable-selection-demostyle' style={{borderRadius: "2rem" ,paddingBottom: "2rem"}}>
          <div className='backOfficeTableHeading' >
                <span>Back Office Trades 
                <span className="advise_p">({moment(selectedDate).format("MMM Do, YYYY") == moment(new Date()).format("MMM Do, YYYY") ? "Today" : moment(selectedDate).format("MMM Do, YYYY")})</span>
                </span>
                <div className='advice-table-upper-div-right'>
                <Button className='add-trade-button' onClick={()=>setModalIsOpen(true)} style={{marginRight : "1rem", marginLeft :"1rem", padding : 0, height :"3rem", width:"8rem", display :"flex", justifyContent :"center", alignItems :"center"}}>
                      <img src="./assets/icon/plus.png" style={{width :"1rem", marginRight :"0.7rem"}} /> 
                      <span>Trade</span>
                    </Button>
                <Dropdown value={selectedTradeType || 'Intraday'} defaultValue={selectedTradeType} onChange={(e) => setSelectedTradeType(e.target.value)} options={types_of_trading} className="w-full md:w-14rem" style={{height : "3.2rem", display :"flex", justifyContent :"center", alignItems :"center", marginRight :"1rem"}}/>
                    {/* <Button className='add-trade-button' onClick={()=>setModalIsOpen(true)} style={{marginRight : "1rem", marginLeft :"1rem", padding : 0, height :"3rem", width:"8rem", display :"flex", justifyContent :"center", alignItems :"center"}}>
                      <span>Trade</span>
                      <img src="./assets/icon/plus.png" style={{width :"1.2rem", marginLeft :"0.7rem"}} /> 
                    </Button> */}
                    <Button type="button" icon="pi pi-download " onClick={handleDownload} className="p-button-success btn-lg float-right advicesBTN" style={{ boxShadow: "rgb(104 159 56) 0.1rem 0.1rem 2rem", width: "3.2rem", height: "3.2rem" ,marginRight: "1rem" }} ></Button>

                </div>
          </div>
          {loading?<div className="HeatMap-spinner-container" >
                          <BeatLoader color="#dc9948" size={10} />
                      </div> :
          <div>
            <div className= "tablecomponents back-office" >
              {/* {loading?<div className="HeatMap-spinner-container" >
                          <BeatLoader color="#dc9948" size={10} />
                      </div> : */}
                <DataTable value={advices} onSelectionChange={(e: any) => { {setSelectedAdvice(e.value)}}}  editMode="row" dataKey="s_trade_id" id="selected_row" selectionMode="single"  onRowEditComplete={onRowEditComplete} stripedRows scrollable scrollHeight="42rem" style={{ margin: "1.5rem" ,borderRadius: "10px"}} className="advice_row" selection={selectedAdvice}>
                    {columnComponents}
                </DataTable>
            </div> 
            <div className="total-pl-info">
                  <p className="pl-info" >Manual P/L (₹) : {isCostChecked ? <span style={{color:`${getColorAccordingWithHistPL(Number(totaltradePlAfterCost).toFixed(2),theme)}`}} > {advices?.length !== 0 ? selectedTradeType === 'Intraday'? Number(totaltradePlAfterCost).toFixed(2):Number(totaltradePlAfterCost).toFixed(4) :''}</span>:<span style={{color:`${getColorAccordingWithHistPL(Number(tradePl).toFixed(2),theme)}`}}>{advices?.length !== 0? selectedTradeType === 'Intraday'? Number(tradePl).toFixed(2):Number(tradePl).toFixed(4) : ''}</span>}</p>
                  <p className="pl-info" >Feedsense P/L (₹) : {isCostChecked ? <span style={{color:`${getColorAccordingWithHistPL(Number(totalFeedsensePlAfterCost).toFixed(2),theme)}`}} > { advices?.length !== 0?  Number(totalFeedsensePlAfterCost).toFixed(2) : ''}</span>:<span style={{color:`${getColorAccordingWithHistPL(Number(feedsensePl).toFixed(2),theme)}`}}>{advices?.length !== 0? Number(feedsensePl).toFixed(2) : ''}</span>}</p>
                  <p className="pl-info" >Total Margin Used (₹) : <span>{advices?.length !== 0? totalMargin : ''}</span></p>
            </div>
          {/* </div> } */}
            <label className='back-office-cost-checkbox'>
              <input
                type="checkbox"
                checked={isCostChecked}
                onChange={handleCheckboxChange}
              />{' '}
              Inclusive of Cost
            </label>
            </div> }
            <div className='back-note'>
                Note: Blank cells in the "Feedsense Close Price" and "Manual Close Price" column indicates an open trade. The close price will be updated once the trade is closed. (Exclusive of Multiday Trades)
            </div>
          </div>
            <div>

            </div>
      </div>
      
          
    )
}



