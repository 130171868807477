import React from 'react';
import '../Arrow.css';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface RightArrowProps {
    onClick: any
}

export const RightArrow:React.FC<RightArrowProps> = ({onClick}) => {
    return (
        <div className="right-arrow arrow-button" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight}/>
        </div>
    )
}