
import Cookie from "js-cookie"

export const set_Cookie =(cookiename:any, userin:any)=> {
    Cookie.set(cookiename,userin ,{
      expires : 365,
      secure:true,
      sameSite :'strict',
      path : '/'
    })
    
  }