import { HeatMapData } from "./HeatMapData.interface"
import "./style.css"

interface HeatMapItemProps {
    heatMapData: HeatMapData
    selectedAsset: string
    heatmapItemClick: any
    type:any
    version:any
    tradetype:any
    theme :any
    identifier:any
}
const asset_key_value: any ={
   'GOLD-I.MCX':'GOLD',
   'SILVER-I.MCX':'SILVER',
   'CRUDEOIL-I.MCX':'CRUDEOIL',
   'NIFTY-I.NFO':'NIFTY',
   'BANKNIFTY-I.NFO':'BANKNIFTY',
   'USDINR-I.CDS':'USDINR',
   'NIFTY-I.NFO_TCS-I.NFO' : 'NIFTY_TCS',
   'INFY-I.NFO_TCS-I.NFO' : 'INFY_TCS' ,
   'NIFTY-I.NFO_BANKNIFTY-I.NFO' : 'NIFTY_BANKNIFTY' ,
   'overall':'overall',
   'comodity-future':'comodity-future',
   'fx-future':'fx-future',
   'stock':'stock',
   'stock-future':'stock-future',
   'intraday-stock-index':'intraday-stock-index',
   'stock-index':'stock-index',
    'INFY-I.NFO' :'INFY',
    'RELIANCE-I.NFO':'RELIANCE',
    'DRREDDY-I.NFO':'DRREDDY'  // Change for - ASFDRRRKI Add  
    }
    
// Returns rgb code
function greenColorShadeRgbByPercentage(percent: number) {
    const lightest = 158;
    let redAndBlueCode = Math.round(158 - ((158 * Math.abs(percent)) / 100));
    return redAndBlueCode + ", 255, " + redAndBlueCode; // Dark green 0, 255, 0
}

// Returns rgb code
function redColorShadeRgbByPercentage(percent: number) {
    const lightest = 158;
    let greenAndBlueCode = Math.round(158 - ((158 * Math.abs(percent)) / 100));
    return "255, " + greenAndBlueCode + ", " + greenAndBlueCode; // Dark green 0, 255, 0
}

export const HeatMapItem: React.FC<HeatMapItemProps> = ({heatMapData, selectedAsset, heatmapItemClick, type,version,tradetype,theme,identifier}) => {
    
    function getBackgroundColor() {
        if(parseFloat(heatMapData.percentage) < 0) {
            return redColorShadeRgbByPercentage(parseFloat(heatMapData.percentage));
        } else {
            return greenColorShadeRgbByPercentage(parseFloat(heatMapData.percentage));
        }
    }

        const algo = {
            fontSize: "1.5rem",		
            top:"-2.4rem",
            left:"2.8rem",
            position: "absolute" as "absolute",
            color: '#fdb55d',
            fontFamily: 'Nunito-bold',
            textShadow: '0.5px 0 #000, -0.5px 0 #000, 0 0.5px #000, 0 -0.5px #000, 0.5px 0.5px #000, -0.5px -0.5px #000, 0.5px -0.5px #000, -0.5px 0.5px #000',
        }
        const algo_quant1 = {
            fontSize: "1.5rem",		
            top:"-2.4rem",
             left:"2.8rem",
            position: "absolute" as "absolute",
            color: '#fdb55d',
            fontFamily: 'Nunito-bold',
            textShadow: '0.5px 0 #000, -0.5px 0 #000, 0 0.5px #000, 0 -0.5px #000, 0.5px 0.5px #000, -0.5px -0.5px #000, 0.5px -0.5px #000, -0.5px 0.5px #000',
        }
        const algo_quant2 = {
            fontSize: "1.5rem",		
            top:"-2.4rem",
             left:"2.8rem",
            position: "absolute" as "absolute",
            color: '#fdb55d',
            fontFamily: 'Nunito-bold',
            textShadow: '0.5px 0 #000, -0.5px 0 #000, 0 0.5px #000, 0 -0.5px #000, 0.5px 0.5px #000, -0.5px -0.5px #000, 0.5px -0.5px #000, -0.5px 0.5px #000',
        }
        
   
        let theme_shadow= theme == 'dark' ?  "1px 2px 20px rgb(207 134 44 / 90%)" : theme == 'light' ? "1px 2px 20px #73b0e7" : theme == 'b/w' ? "rgb(0 0 0 / 22%) 1px 2px 20px" :''
        let theme_border_selected = theme == 'dark' ? "0.6rem solid #cf862c" : theme == 'light' ? "0.6rem solid #769fcd" : theme == 'b/w' ? "0.6rem solid #605f5fdb" :''
        let theme_border =  theme == 'dark' ? "1px solid #cf862c" : theme == 'light' ? "1px solid #3d85c6" : theme == 'b/w' ? "1px solid #444444" :''
        
        let border:any
        let boxShadow:any
  
    if(type === "options-stock" || type === "options-index" || type === "fx-option")
    {
        let heatMapDataname:any;
        let assetname:any = heatMapData?.name.split("-")[0]
        if(assetname === 'ALL')
        {
            heatMapDataname = 'all'
        }
        else{
            heatMapDataname = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(assetname)]
        }

        border = (selectedAsset ===  heatMapDataname) && (tradetype === heatMapData.tradetype) && (version === heatMapData.version)? theme_border_selected: selectedAsset !== heatMapDataname && selectedAsset == heatMapData.type ? theme_border_selected : theme_border
        boxShadow = (selectedAsset === heatMapDataname)&& (tradetype === heatMapData.tradetype) && (version === heatMapData.version)? theme_shadow : " "
    }
    else{
        let heatMapDataname:any;
        if(type === "multiday-equity"){
            if((heatMapData?.name) === 'ALL')
            {
                heatMapDataname = 'all'
            }
            else{
                heatMapDataname = (heatMapData?.name)
            }
        }else{
            if((heatMapData?.name) === 'ALL')
            {
                heatMapDataname = 'all'
            }
            else
            {
                heatMapDataname = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(heatMapData?.name)]
            }
        }

        border = (selectedAsset ===  heatMapDataname) && (version == heatMapData?.version)? theme_border_selected: selectedAsset !== heatMapDataname && selectedAsset == heatMapData.type? theme_border_selected : theme_border
        boxShadow = (selectedAsset === (heatMapData.name)) && (version == heatMapData?.version)? theme_shadow : " "
    }
    let assetName:any
    if (heatMapData?.name != undefined){
        assetName =heatMapData?.name.split("-")[0];
    }
  
    
    
    return <div className="heat-map-item" style={{backgroundColor: "rgb(" + getBackgroundColor() + ")", 
            border: border, boxShadow:boxShadow}} onClick={() => {heatmapItemClick( heatMapData)}} >
                <span className="algo" style={heatMapData?.version == 'AI'?algo:heatMapData?.version == 's_markov_0.1'? algo_quant1:algo_quant2}>{identifier}</span>
                <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                {(type === "options-stock" || type === "options-index" || type === "fx-option") && heatMapData.name != 'ALL' && (heatMapData?.version == 'QIOVTI' || heatMapData?.version == 'QIOSVI' || heatMapData?.version == 'AIOABI' || heatMapData?.version == 'QFFNBI2' || heatMapData?.version == 'QSOVTI' || heatMapData?.version=='ASODDI' || heatMapData?.version=='QFOSBI' || heatMapData?.version=='QIOSMI' || heatMapData?.version=='AIOMMI'|| heatMapData?.version == 'AIODDI'  )?assetName+'-'+heatMapData?.tradetype?.toUpperCase():heatMapData.name}
                </div>
                {type != "multiday-equity" ? <div className="heatmaphover" data-hover={heatMapData?.name != 'ALL'?(assetName == 'USDINR'? Number(heatMapData?.valueInPts).toFixed(4) :Number(heatMapData?.valueInPts).toFixed(2)) + " pts":''}>
                    {heatMapData?.name != 'ALL'?(assetName == 'USDINR'? "₹ " + Number(heatMapData?.value).toFixed(2):"₹ " +Number(heatMapData?.value).toFixed(2)):' '}</div> :
                    <div>{heatMapData?.name != 'ALL'?(assetName == 'USDINR'? "₹ " + Number(heatMapData?.value).toFixed(2):"₹ " +Number(heatMapData?.value).toFixed(2)):' '}</div>} 
                 <p style={{borderRadius:"0.3rem" ,padding:"0rem 0.2rem",backgroundColor: "rgb(" + getBackgroundColor() + ")"}}>
                 {heatMapData?.plPercentage && parseFloat(heatMapData?.plPercentage)?.toFixed(2) + "%"}</p>
            </div>
}

