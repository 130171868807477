import './style.css';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getSentimentChartColor } from '../../../../../util/utils';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface DrawdownChartProps {
  dataset: any,
  type: any
  theme: any
}

export const DrawdownChart: React.FC<DrawdownChartProps> = ({ dataset, type, theme }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  let axis_lable_color: any
  let border_color: any
  let fill_color: any
  let axis_border_color: any

  if (theme == 'dark') {
    axis_lable_color = '#fff'
    border_color = '#262626'
    fill_color = ['#ffffff', '#ffffff']
    axis_border_color = '#ffffff'
  }
  else if (theme == 'light') {
    axis_lable_color = '#000'
    border_color = '#a2cdf4'
    fill_color = ['#0a3154', '#0a3154']
    axis_border_color = '#659ac2'
  }
  else if (theme == 'b/w') {
    axis_lable_color = '#fff'
    border_color = '#ffffff30'
    fill_color = ['#ffffff', '#ffffff']
    axis_border_color = '#ffffff'
  }



  let labelsArr: any = []
  let dataArr: any = []
  let colors: any = []
  for (let i in dataset) {
    if (type === 'fx-option' || type === 'intraday-stock-index' || type === "intraday-stock-future" || type === 'comodity-future' || type === 'fx-future' || type === "options-stock" || type === "options-index") {
      labelsArr.push(moment(new Date(dataset[i]?.dt_date + " " + dataset[i]?.time)).format('DD\'MMM HH:mm').toString())
      dataArr.push(Number(dataset[i]?.drawdowns?.toFixed(4)))
      if (dataset[i]?.drawdowns < 0) {
        colors.push('red')
      } else {
        colors.push('green')
      }
    }
    else if (  type === "fx-future-multiday" || type === "multiday-equity") {
      labelsArr.push(moment(new Date(dataset[i]?.time)).format('DD\'MMM HH:mm').toString())
      dataArr.push(Number(dataset[i]?.drawdowns?.toFixed(2)))
    }
   
    else if (type === "stock-index" || type === "stock-future" || type === "overall" || type === "all") {
      labelsArr.push(moment(new Date(dataset[i]?.dt_date)).format('DD\'MMM HH:mm'))
      dataArr.push(dataset[i]?.draw_down)

    }
    if (dataset[i].draw_down < 0) {
      colors.push('red')
    } else {
      colors.push('green')
    }
  }


  const data1: any = {

    series: [
      {
        name: "Drawdown",
        data: dataArr
      }
    ],
    options: {
      chart: {
        type: 'area',
        dropShadow: {
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: [`${getSentimentChartColor(dataArr, 'bg')}`, '#38fffc'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'square',
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
      title: {
        text: 'Drawdown (Percentage/Lot)',
        align: 'middle',
        style: {
          fontSize: '18px',
          color: axis_lable_color
        }
      },
      grid: {
        borderColor: border_color,
        row: {
          colors: ['transparent', 'transparent'], 
          opacity: 0.5
        },
      },
      markers: {
        size: 0
      },
      fill: {
        colors: fill_color
      },
      xaxis: {
        categories: labelsArr,
        title: {
          show: false
        },

        tickPlacement: 'between',
        tickAmount: 8,
        labels: {
          show: true,
          rotate: -45,
          style: {
            colors: axis_lable_color
          }
        },
        axisBorder: {
          show: true,
          color: axis_border_color,
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: false,
          borderType: 'solid',
          color: axis_border_color,
          height: 6,
          offsetX: 0,
          offsetY: 0
        },

      },
      yaxis: {
        opposite: false,
        title: {
          show: false,
        },
        labels: {
          show: true,
          align: 'center',
          offsetX: 0,
          position: "center",
          style: {
            colors: axis_lable_color
          }
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
        labels: {
          colors: 'white'
        }
      }
    },
  };

  return (<>
    <div style={{ margin: "1rem", height: "5%" }}>
      <span className="chart_view_button" style={{ float: "right", padding: "0rem", border: "1px solid white" }} onClick={handleShow}>
        <FontAwesomeIcon icon={faExpandArrowsAlt} />
      </span>
    </div>
    <div className="drawdown-chart" style={{ marginTop: "1.2rem", marginBottom: "4.5rem" }}>
      <ReactApexChart id="drawdownApexChart" options={data1.options} series={data1.series} type="area" height="100%" />
      <Modal show={show} onHide={handleClose} style={{ backdropFilter: "blur(3px)" }} size="xl" >
        <Modal.Header closeButton>
          <Modal.Title style={{ width: "100%" }}>
            <div style={{ width: "30%" }}>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='chartItem' style={{ width: "87%", height: "80%", margin: "2% 7%", position: "absolute" }}>
            <ReactApexChart options={data1.options} series={data1.series} type="area" height='100%' />
          </div>
        </Modal.Body>

      </Modal>
    </div>
  </>)
}