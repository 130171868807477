import { FeedSense } from '../../../../Models/FeedSense';
import './FeedSenseSlide.css';
import { FeedSenseItem } from './FeedSenseItem';

interface FeedSenseSlideProps {
    feedSenseSubArr: FeedSense[]
}

export const FeedSenseSlide: React.FC<FeedSenseSlideProps> = ({feedSenseSubArr}) => {

    return (
        <>
            {feedSenseSubArr.map((data, i) => {
                if(i < feedSenseSubArr.length - 1) {
                    return (
                        <>
                            <FeedSenseItem feedSense={data} />
                            <div className="space-15"></div>
                            <div className="border_black"></div>
                            <div className="space-15"></div>
                        </>
                    )
                }else
                    return (
                        <FeedSenseItem feedSense={data} />
                    )
                }
            ) }
        </>
    )
}