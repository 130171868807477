import {  axiosLocal5000, axiosLocal5000Local,axiosESMultiday} from "../axios";
import { getUserObject } from "../Service/auth.service";

let userObj: any = getUserObject();

export async function getLiveEquityCAlls(type: any, version: any) {
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_live_calls`, {
            "version": version,
            "asset": type,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
}

export async function getLiveEquityPl(type:any, version:any){
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_live_pl`, {
            "version": version,
            "asset": type,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
}

export async function getLiveEquitySR(type:any, version:any){
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_live_sr`, {
            "version": version,
            "asset": type,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
}

export async function getLiveEquityAdviceStatus(type:any, version:any){
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_live_trades`, {
            "version": version,
            "asset": type,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
}

export async function getLiveEquityPlPercentage(type:any, version:any){
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_live_pl_percent`, {
            "version": version,
            "asset": type,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
}

export async function getEquityParameterlist(id: any, asset: any, version: any, date: any){
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_parameter`, {
            "id": id,
            "asset": asset,
            "version": version,
            "date": date,
        });
        
        return res?.data;
    } catch (err) {
        return console.log(err);
    }

}

export async function getEquityMtmDrawdown(id: any, asset: any, version: any, date: any){
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_m2m_drawdown`, {
            "id": id,
            "asset": asset,
            "version": version,
            "date": date,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
    
}

export async function getEquityTsl(id: any, asset: any, version: any, date: any){
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_target_stoploss`, {
            "id": id,
            "asset": asset,
            "version": version,
            "date": date,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
    
}


export async function getHistEquityCAlls(type: any,version: any,startDate:any,endDate:any) {
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_calls`, {
            "version": version,
            "asset": type,
            "startDate":startDate,
            "endDate":endDate,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
}

export async function getHistEquitySR(type: any,version: any,startDate:any,endDate:any) {
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_sr`, {
            "version": version,
            "asset": type,
            "startDate":startDate,
            "endDate":endDate,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
}

export async function getHistEquityPl(type: any,version: any,startDate:any,endDate:any) {
        return await axiosESMultiday.post(`/equity-spot-multiday/get_pl`, {
            "version": version,
            "asset": type,
            "startDate":startDate,
            "endDate":endDate,
            "username": userObj.email_id
        }).then((res:any)=>{
            return res?.data;
        }).catch ((err:any)=>{
            return console.log(err);
        }) 
}

export async function getHistEquityAdvicesStatus(startDate:any,endDate:any,type: any,version: any) {
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_trades`, {
            "version": version,
            "asset": type,
            "startDate":startDate,
            "endDate":endDate,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
}

export async function getHistEquityPlPercentage(startDate:any,endDate:any,type: any,version: any) {
    try {
        const res = await axiosESMultiday.post(`/equity-spot-multiday/get_pl_percent`, {
            "version": version,
            "asset": type,
            "startDate":startDate,
            "endDate":endDate,
            "username": userObj.email_id
        });
        return res?.data;
    } catch (err) {
        return console.log(err);
    }
}