import {axiosPortfolio} from "../axios"
import { getUserObject } from "../Service/auth.service";
let userObj:any = getUserObject();



export function getAdvicePortfolio(selectedDate:any)
{
    return axiosPortfolio.post(`/portfolio/get_advice_portfolio`,{
        "selectedDate": selectedDate,
        "username": userObj.email_id
    })
            .then((res:any) => {
                return res?.data;
        }).catch((err:any) => console.log(err))
}


export function getAdvicePortfolioForRange(startDate:any,endDate:any)
{
    
    return axiosPortfolio.post(`/portfolio/get_advice_portfolio_for_range`,{
        "startDate": startDate,
        "endDate": endDate,
        "username": userObj.email_id
    })
            .then((res:any) => {
                return res?.data;
        }).catch((err:any) => console.log(err))
}

export function getPreviousDayStatus(selectedDate:any)
{
    return axiosPortfolio.post(`/portfolio/get_previous_day_status`,{
        "selectedDate": selectedDate,
        "username": userObj.email_id
    })
            .then((res:any) => {
                return res?.data;
        }).catch((err:any) => console.log(err))
}
