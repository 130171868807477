import React, { useEffect, useState } from 'react'
import { Sidebar } from 'primereact/sidebar';
import moment from 'moment';
import { useHistory } from 'react-router-dom';


interface RightSidebarProps {
    visibleSidebar: any,
    setVisibleSidebar: any,
    newsData: any,
    allTweets: any,
    theme: any,
    alltradeDetails: any,
    alltradeDetailsWithCost:any,
    selectedDate: any,
    advicesStatusType:any,
    type:any,
    rwCost:any,
    setRwCost:any,
    minChargeDate:any

    
}

export const RightSidebar: React.FC<RightSidebarProps> = ({ visibleSidebar, setVisibleSidebar, newsData, allTweets, theme, alltradeDetails,alltradeDetailsWithCost, 
    selectedDate,advicesStatusType, type ,rwCost,setRwCost,minChargeDate}) => {
    const[highestTradeReturn, setHighestTradeReturn] = useState<any>({});
    const[longestTradeDuration, setLongestTradeDuration] = useState<any>({});
    const[shortestTradeDuration, setShortestTradeDuration] = useState<any>({});
    const[modelWiseReturn, setModelWiseReturn] = useState<any>({});
    const[positiveSuccessRatio, setPositiveSuccessRatio] = useState<any>();
    const[positiveCalls, setPositiveCalls] = useState<any>(0);
    const[negativeCalls, setNegativeCalls] = useState<any>(0);
    const[noOfTslHits, setNoOfTslHits] = useState<any>();
    const [tradeCount, setTradeCount] = useState<any>({})
    const[highestTradeReturnWithCost, setHighestTradeReturnWithCost] = useState<any>({});
    const[modelWiseReturnWithCost, setModelWiseReturnWithCost] = useState<any>({});
    const[positiveSuccessRatioWithCost, setPositiveSuccessRatioWithCost] = useState<any>();
    const[positiveCallsWithCost, setPositiveCallsWithCost] = useState<any>(0);
    const[negativeCallsWithCost, setNegativeCallsWithCost] = useState<any>(0);

    let history = useHistory()


    useEffect(()=>{
        setHighestTradeReturn(alltradeDetails?.highestTradeReturn);
        setLongestTradeDuration(alltradeDetails?.longestTradeDuration);
        setShortestTradeDuration(alltradeDetails?.shortestTradeDuration);
        setPositiveSuccessRatio(alltradeDetails?.positiveSuccessRatio);
        setPositiveCalls(alltradeDetails?.positiveCalls);
        setNegativeCalls(alltradeDetails?.negativeCalls);
        setNoOfTslHits(alltradeDetails?.noOfTslHits);
        setModelWiseReturn(alltradeDetails?.modelWiseReturn);
        setModelWiseReturnWithCost(alltradeDetails?.modelWiseReturnWithCost);
        setTradeCount(alltradeDetails?.tradeCount);
    },[alltradeDetails])

    useEffect(()=>{
        setHighestTradeReturnWithCost(alltradeDetailsWithCost?.highestTradeReturnWithCost);
        setModelWiseReturnWithCost(alltradeDetailsWithCost?.modelWiseReturnWithCost)
        setPositiveSuccessRatioWithCost(alltradeDetailsWithCost?.positiveSuccessRatioWithCost)
        setPositiveCallsWithCost(alltradeDetailsWithCost?.positiveCallsWithCost);
        setNegativeCallsWithCost(alltradeDetailsWithCost?.negativeCallsWithCost);
    },[alltradeDetailsWithCost])

    setTimeout(()=>{
        const rows = Array.from(document.querySelectorAll('.trr'));
        function slideOut(row:any) {
            row.classList.add('slide-out');
        }
        function slideIn(row:any, index:any) {
            setTimeout(function() {
                row.classList.add('slide-in');
            }, (index + 5) * 30);  
        }
        rows.forEach(slideOut);
        rows.forEach(slideIn);
    },1)

    const background_Color = () => {
        if (theme == 'dark') {
            return 'rgb(63 43 76 / 55%)'
        }
        else if (theme == 'light') {
            return 'rgb(223 234 248 / 51%)'
        }
        else {
            return 'rgb(255 255 255 / 7%)'
        }
    }


    const displayColor = ()=>{
        if (theme == 'dark') {
            return 'rgb(13 6 16 / 35%)'
        }
        else if (theme == 'light') {
            return 'rgb(203 226 251 / 77%)'
        }
        else {
            return 'rgb(12 12 12 / 47%)'
        }
    }

    const test_color = () => {
        if (theme == 'dark') {
            return 'white'
        }
        else if (theme == 'light') {
            return '#081828 !important'
        }
        else {
            return 'white'
        }
    }

    function color(returnValue:any){
        if (returnValue >= 0) {
            if (theme == 'dark' || theme == 'b/w') {
                return "lightgreen"
            } else {
                return "darkgreen"
            }
        }
        else {
            return "orangered"
        }
    }
    
    const handleRightsideBar =()=>{
        window.localStorage.setItem("open_Rightsidebar", 'yes')
        history.push('/portal/strategy-tracker/?all')
    }

    const handleHidesidebar = ()=>{
        
        if (window.localStorage.getItem('open_Rightsidebar') == 'yes'){
            window.localStorage.removeItem('open_Rightsidebar')
        }
        setVisibleSidebar(false)
        
    }

    return (<>{alltradeDetails!='undefined' && 
        <Sidebar visible={visibleSidebar} position="right" onHide={() => {handleHidesidebar()} }className="pieSidebar unselectable " id='sidebar'>
        {((moment(selectedDate).format('YYYY-MM-DD') == '2024-01-20') || (selectedDate.getDay() != 6 && selectedDate.getDay() != 0 )) &&  <>
            {type !== 'all' ? <>
                <p className="view_stat" onClick={()=>handleRightsideBar()} > Click here to view statistics of the day </p>
             </>:
                type=='all' && <>
                {(positiveCalls + negativeCalls)>0 ? 
                    <div style={{ width:"100%", height:"66rem"}}>
                        <div style={{width:"100%",padding:"0rem 2rem",marginBottom: '2rem', display:"flex"}}>
                                <span className='bodyfont' style={{ color: "white", width: "50%" }}>{moment(selectedDate).format("MMM Do, YYYY") == moment(new Date()).format("MMM Do, YYYY") ? `${moment(selectedDate).format("MMM Do, YYYY")}  (Today)` : moment(selectedDate).format("MMM Do, YYYY")}</span>
                            {(moment(selectedDate).format('YYYY-MM-DD')) > minChargeDate?<div style={{display: 'flex',flexDirection: 'column',width: '50%'}}>
                                <p className='bodyfont' style={{ fontWeight:400,color: "#d7d7d7", width: "100%",textAlign: 'end' }}><input type='checkbox' checked={rwCost} style={{cursor: 'pointer' ,height: '50%',width: '1.7rem'}} onChange={()=>setRwCost(!rwCost)}/> Inclusive of cost</p>
                            </div>:""}
                        </div>
                        <div className='pieChart' style={{backgroundColor: `${background_Color()}`, width: "98%", height: "12%", color: "black"}}>
                            {rwCost === false ?<div style={{width:"50%",marginLeft:'0.5rem'}}>
                                <p className='bodyfont' style={{ color: `${test_color()}`, width: "100%",height:"10%", fontSize:"1.65rem" }}>Success ratio : {positiveSuccessRatio==100?positiveSuccessRatio:positiveSuccessRatio?.toFixed(2)}%</p>
                                <p className='bodyfont' style={{ color: `${test_color()}`, width: "100%",height:"10%", fontSize:"1.65rem", marginTop:"2rem" }}>Total calls : {negativeCalls+positiveCalls}</p>
                            </div> :<div style={{width:"50%",marginLeft:'0.5rem'}}>
                                <p className='bodyfont' style={{ color: `${test_color()}`, width: "100%",height:"10%", fontSize:"1.65rem" }}>Success ratio : {positiveSuccessRatioWithCost==100?positiveSuccessRatioWithCost:positiveSuccessRatioWithCost?.toFixed(2)}%</p>
                                <p className='bodyfont' style={{ color: `${test_color()}`, width: "100%",height:"10%", fontSize:"1.65rem", marginTop:"2rem" }}>Total calls : {negativeCallsWithCost+positiveCallsWithCost}</p>
                            </div>}
                            <div style={{display:"flex", flexDirection:"column",height:"100%", width:"58%"}}>
                               
                                <div style={{display: 'flex'}}>
                                        <p className='green_dot'></p> <p style={{ color: `${test_color()}`, width: "100%",height:"10%", fontSize:"1.65rem",marginLeft: "1rem" }} >Positive calls : {rwCost === false ?positiveCalls : positiveCallsWithCost}</p>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <a className='red_dot'></a>  <p style={{ color: `${test_color()}`, width: "100%",height:"10%", fontSize:"1.65rem",marginLeft: "1rem" }}>Negative calls : {rwCost === false ?negativeCalls:negativeCallsWithCost}</p>
                                </div>
                              
                            </div>
                        </div>
                        <div className='overviewDiv'>
                            
                            <div className='modelWiseReturn' style={{ backgroundColor: `${background_Color()}`, color: `${test_color()}`, position:"relative" }}>
                                <span>
                                    <p className='bodyfont' style={{fontSize:"1.65rem", marginLeft:"0.5rem"}}>Model returns</p>
                                </span>
                                <div className='modelWiseReturnTable ' style={{color: `${test_color()}`}}>
                                    {rwCost === false ? <>{(modelWiseReturn!='undefined' && modelWiseReturn!=null)&& Object.keys(modelWiseReturn).map((item:any, index:any)=>{
                                        return(<p key={index} className='tableElem trr' style={{backgroundColor: `${displayColor()}`}}>{item} ({ tradeCount[item]})<span className='bodyfont' style={{color: `${color(Number(modelWiseReturn[item]))}`, marginRight:`${item.length==10?"-0.3rem":item.length==11?"-0rem":"-0.6rem"}`}}>{modelWiseReturn[item].toFixed(2)}%</span></p>)
                                    })}</>:<>{(modelWiseReturnWithCost!='undefined' && modelWiseReturnWithCost!=null)&& Object.keys(modelWiseReturnWithCost).map((item:any, index:any)=>{
                                        return(<p key={index} className='tableElem trr' style={{backgroundColor: `${displayColor()}`}}>{item} ({ tradeCount[item]})<span className='bodyfont' style={{color: `${color(Number(modelWiseReturnWithCost[item]))}`, marginRight:`${item.length==10?"-0.3rem":item.length==11?"-0rem":"-0.6rem"}`}}>{modelWiseReturnWithCost[item].toFixed(2)}%</span></p>)
                                    })}
                                    </>
                                    }
                                </div>
                
                            </div>
          
                            <div className='highestReturn hovertext' data-hover="According to trade's highest return" style={{ backgroundColor: `${background_Color()}`, color: `${test_color()}` }}>
                                <div style={{width: "50%",height: "3rem", textAlign:"start",fontSize:"1.65rem"}}>
                                    <span  className=" bodyfont" >Highest return</span>
                                </div>
                                <div className='elems slide-left' style={{backgroundColor: `${displayColor()}`}}>
                                {rwCost === false ?<>{(highestTradeReturn!='undefined' && highestTradeReturn!=null)&& Object.keys(highestTradeReturn).map((item:any, index:any)=>{
                                    return(<p className='bodyfont' key={index} style={{width: "20rem",display:"flex",justifyContent:"center",alignItems:"center" }}><span className='bodyfont' style={{color: `${color(highestTradeReturn[item])}`}}>{highestTradeReturn[item].toFixed(2)}%</span> &nbsp;<small>({item})</small></p>)
                                })}</> :<>{(highestTradeReturnWithCost!='undefined' && highestTradeReturnWithCost!=null)&& Object.keys(highestTradeReturnWithCost).map((item:any, index:any)=>{
                                    return(<p className='bodyfont' key={index} style={{width: "20rem",display:"flex",justifyContent:"center",alignItems:"center" }}><span className='bodyfont' style={{color: `${color(highestTradeReturnWithCost[item])}`}}>{highestTradeReturnWithCost[item].toFixed(2)}%</span> &nbsp;<small>({item})</small></p>)
                                })}
                                
                                </>}
                                </div>
                            </div>
                            <div className='highestReturn' style={{ backgroundColor: `${background_Color()}`, color: `${test_color()}` }}>
                                <div style={{width: "50%",height: "3rem",fontSize:"1.65rem"}}>
                                    <p className='bodyfont'> No of TSL hits </p>
                                </div>
                                <div className='elems slide-left' style={{backgroundColor: `${displayColor()}`}}>
                                    {(noOfTslHits!='undefined' && noOfTslHits!=null)&& <p className='bodyfont' style={{width: "20rem",display:"flex",justifyContent:"center",alignItems:"center" }}>{noOfTslHits}</p>}
                                </div>
                            </div>
                            <div className='longShortTrade'>
                                    <span className='bodyfont longShortNote' >*Note: Multiday calls excluded</span>
                                <div className='subLongShort'>   
                                    <div className='overviewSubDiv' style={{ backgroundColor: `${background_Color()}`, color: `${test_color()}` }}>
                                        <div>
                                            <p className='bodyfont' style={{fontSize:"1.65rem",marginLeft:'0.5rem'}}> Longest trade </p>
                                        </div>
                                        <div className='ele slide-right' style={{backgroundColor: `${displayColor()}`, width:"97%"}}>
                                            {(longestTradeDuration!='undefined' && longestTradeDuration!=null)&& Object.keys(longestTradeDuration).map((item:any, index:any)=>{
                                                return(<p className='bodyfont' style={{padding:"0% 1%"}} ><span className='bodyfont'>{item} : </span>{longestTradeDuration[item]}</p>)
                                            })}
                                        </div>
                                    </div>
                                    <div className='overviewSubDiv' style={{ backgroundColor: `${background_Color()}`, color: `${test_color()}` }}>
                                        <div>
                                            <p className='bodyfont' style={{fontSize:"1.65rem",marginLeft:'0.5rem'}}> Shortest trade </p>
                                        </div>
                                        <div className='ele slide-left' style={{backgroundColor: `${displayColor()}`, width:"97%"}}>
                                            {(shortestTradeDuration!='undefined' && shortestTradeDuration!=null)&& Object.keys(shortestTradeDuration).map((item:any)=>{
                                                return(<p className='bodyfont' style={{padding:"0% 1%"}} ><span className='bodyfont'>{item} : </span>{shortestTradeDuration[item]}</p>)
                                            })}
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            {/* <div className='highestReturn' style={{ backgroundColor: `${background_Color()}`, color: `${test_color()}`}}>
                                <div style={{width: "50%",height: "3rem",fontSize:"1.65rem"}}>
                                    <span className="hovertext bodyfont" data-hover="Total profit / total loss"> P/L ratio </span>
                                </div>
                                <div className='elems slide-left' style={{backgroundColor: `${displayColor()}`}}>
                                    {(plRatio!='undefined' && plRatio!=null)&& <p className='bodyfont' style={{color: `${color(plRatio.toFixed(4))}`}}>{plRatio.toFixed(4)}</p>}
                                </div>
                                
                            </div> */}
              
                        </div>
                    </div>
                :<>
                    <div style={{width:"100%",padding:"0rem 2rem", display:"flex", justifyContent:"space-between"}}>
                        <span className='bodyfont' style={{ color: "white", width: "48%" }}>{moment(selectedDate).format("MMM Do, YYYY") == moment(new Date()).format("MMM Do, YYYY") ? `${moment(selectedDate).format("MMM Do, YYYY")}  (Today)` : moment(selectedDate).format("MMM Do, YYYY")}</span>
                    </div>
                    <div style={{ width:"100%", height:"40%", position:"relative", backgroundColor:"#cfcfcf6e", borderRadius:"0.5rem"}}>
                        
                        <div className='noTradeImgDiv'>
                            <img src="./assets/images/noTradeImg.png" alt="No trade image" />

                            <h5 style={{margin:"1rem 0rem 0rem 3rem", fontSize:"2rem", color: `${test_color()}`, width:"100%", fontWeight:400, fontFamily: 'Abril Fatface'}}>
                                <span className="text_1">Waiting for trades...</span>
                                <span className="text_2">Waiting for trades...</span>
                            </h5>
                        </div>
                        
                    </div>  
                </>}
            </>}
     
        </>}
        
        {/* <div className="Trending_news" >
            Trending News:
            <div className="topbartweet">
                {newsData?.map((item: any, index: any) => {
                    if (item?.title?.length > 54) {
                        item.title = (item?.title).slice(0, 54) + "..."
                    } else if (item?.title?.length < 40) {
                        item.title = (item?.title) + ".      "
                    }
                    return (<div className="tilt-newsbox-wrap" key={index}>
                        <span className='t_over'></span>
                        <span className='t_over'></span>
                        <span className='t_over'></span>
                        <span className='t_over'></span>
                        <span className='t_over'></span>
                        <span className='t_over'></span>
                        <span className='t_over'></span>
                        <span className='t_over'></span>
                        <span className='t_over'></span>
                        <div className="tilt-box">
                            <strong style={{ fontWeight: "unset" }}>
                                <div className="topbarnews" style={{ border: `${"2px solid" + getColorBySentiment(item?.sentiment)}` }}>
                                    <div className="topbarnews_upperDiv">
                                        <div className='topbarnews_imgLink' >
                                            <img src={item?.image_link} />
                                        </div>
                                        <div style={{ marginLeft: "1rem" }}>
                                            <div className='topbarnews_publisher' >{item?.publisher}</div>
                                            <p style={{ float: "left" }}>{moment(item?.datePublished).format('DD MMM YYYY')}</p>
                                        </div>
                                    </div>
                                    <div className="row" id="topbarnews_title" >
                                        {item?.title}
                                    </div>
                                </div>
                            </strong>
                        </div>
                    </div>
                    )
                })}
            </div>
        </div> */}

        {/* <div className="row" style={{ minHeight: "30rem" }}>
            <div className="col-lg-12" style={{ marginTop: "1rem"}}>
                <div className="Trending_tweets" style={{marginLeft:'-1.5rem'}} >
                    Trending Tweets:
                    <div className="tweet_section " style={{ alignItems: "center" }}>
                        {allTweets?.map((item: any, index: any) => {
                            if (item.full_text.length > 150) {
                                item.full_text = item.full_text.slice(0, 156) + "...."
                            }
                            return (<div className="tilt-box-wrap" key={index} >
                                <span className='t_over'></span>
                                <span className='t_over'></span>
                                <span className='t_over'></span>
                                <span className='t_over'></span>
                                <span className='t_over'></span>
                                <span className='t_over'></span>
                                <span className='t_over'></span>
                                <span className='t_over'></span>
                                <span className='t_over'></span>
                                <div className="tilt-box">
                                    <strong style={{ fontWeight: "unset" }}>
                                        <div className="tweetdiv tweetTopBar" id="tweetdiv" style={{ border: `${"2px solid" + getColorBySentiment(item?.sentiment)}` }}>
                                            <div style={{ marginTop: "0.8rem", position: "relative" }}>
                                                <div className="tweetdiv_img_upperdiv">
                                                    <div className="" style={{ padding: "0px", width: "3.5rem" }}>
                                                        <img className="tweet_profile_pic" src={item?.user?.display_url} />
                                                    </div>
                                                    <div style={{ margin: "0.5rem 0rem 0rem 0.5rem", fontSize: "1.3rem", fontWeight: "bold" }}>
                                                        {item.user.name}
                                                    </div>
                                                    <div style={{ position: "absolute", top: "6%", right: "4%" }}>
                                                        <img alt= "twitter" style={{ float: "right", color: "#1890ff", width:"1.75rem", height:'1.75rem' }} src='../assets/icon/x-twitter.png'/>
                                                        
                                                    </div>
                                                </div>
                                                <div className="row" style={{ marginTop: "0.8rem", color: "rgb(58 56 56) !important", textAlign: "start", marginLeft: "4%" }}>
                                                    <div className="col-lg-12">
                                                        <p style={{ fontSize: "1.32rem", fontWeight: "lighter" }}>{item.sentiment > 0 ? <FontAwesomeIcon icon={faCircle} style={{ color: "green" }} /> : item.sentiment == 0 ? <FontAwesomeIcon icon={faCircle} style={{ color: "#ffcc00" }} /> : <FontAwesomeIcon icon={faCircle} style={{ color: "red" }} />}<span style={{ marginLeft: "5px" }}>{item.full_text}</span></p>
                                                    </div>
                                                </div>
                                                <div className="" style={{ margin: "0.5rem 0rem 0rem 1.65rem ", float: "left" }}>
                                                    <a href={item.user.user_url} target="_blank" rel="noopener noreferrer">{item.user.user_url}</a>
                                                </div>
                                                <div className="col-lg-1"></div>
                                            </div>
                                        </div>
                                    </strong>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div> */}
        </Sidebar>
    }</>
        
    )
}