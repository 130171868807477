import { IonList } from '@ionic/react';
import { useParams } from 'react-router-dom';
import './style.css';
import dummyArticlesData from '../../dummy_data/data.json'
import { News } from '../../Models/News';
import { add3Dots, getColorBySentiment, parseDateInDisplayFormat2 } from '../../util/utils';
import { FeedSense } from '../../Models/FeedSense';
import { useEffect, useState } from 'react';
import { FeedSenseSlide } from '../../components/NewsHome/NewsContainers/ContainerType3/FeedSenseSlide';
import Carousel from 'react-multi-carousel';
import { ArrowButtonGroup } from '../../components/shared/ArrowButtonGroup';
import sentimentAnalytics1week from '../../dummy_data/sentiment-analytics-1week.json'
import sentimentAnalytics2weeks from '../../dummy_data/sentiment-analytics-2weeks.json'
import sentimentAnalytics3weeks from '../../dummy_data/sentiment-analytics-3weeks.json'
import { LogoContainer } from '../../components/shared/LogoArea/LogoContainer';
import { SentimentChart } from '../../components/shared/SentimentChart';
import { frontendRoutes } from '../../constants/frontendRoutes';
import { getAllRecomendedNews, getFeedsenseData, getResultsByCategory } from '../../Api/News/news';

interface ArticlesByKeywordProps {}

export const ArticlesByKeyword: React.FC<ArticlesByKeywordProps> = (props) => {
    const params: any = useParams(); 
    const [twitterFeedSenseArr, setTwitterFeedSenseArr] = useState<any>({tweets: []})
    const [noOfFeedSenseIcons, setnoOfFeedSenseIcons] = useState(4);
    const [articlesData, setArticlesData] = useState([{}])

    const[labels, setLabels] = useState([]);
    const[negative, setNegative] = useState([]);
    const[neutral, setNeutral] = useState([]);
    const[positive, setPositive] = useState([]);

    useEffect(() => {
        getFeedsenseData().then((res) => {
            
            setTwitterFeedSenseArr(res)
        })
    },[])

    useEffect(() => {
        if(params.type === "category") {
            if(params.keyword === "recomended") {
                getAllRecomendedNews().then((res) => {
                    setArticlesData(res)
                })
            }else {
                getResultsByCategory(params.keyword).then((res) => {
                    setArticlesData(res)
                })
            }
        }else{
            setArticlesData(dummyArticlesData)
        }
        handleSentimentData(sentimentAnalytics1week.sentiments)
    }, labels)

    interface ArticleItemProps {
        article: News,
        index: number
    }
    const ArticleItem = (props: ArticleItemProps) => {
        return (
            <a href={frontendRoutes.newsHome + props.article.id} className="article-item" style={{marginTop: `${props.index === 0 ? '0px' : '15px'}`}} target="_blank" rel="noopener noreferrer">
               <div className="articles__img" style={{borderRight: `8px solid ${getColorBySentiment(props.article.sentiment)}`}}>
                   <img src={props.article.imageLink} />
               </div>
               <div className="article-rightside">
                   <div className="meta">
                       <span>
                           {props.article.category}
                       </span>
                       <span className="meta-date">
                           {parseDateInDisplayFormat2(props.article.publishedDate)}
                       </span>
                       <span className="meta-publisher">
                           {props.article.publisher}
                       </span>
                   </div>
                   <h4>
                       <a >{props.article.title}</a>
                   </h4>
                   <p>
                       {add3Dots(props.article.summary || '', 100)} Read More
                   </p>
               </div>
            </a>
        );
    };

    const responsiveFeedSense = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    let twitterFeedSenseSlides:any = []
    let feedSense: FeedSense;
    let feedSenseArr: FeedSense[];
    let data: any;
    for(let i = 0; i < twitterFeedSenseArr.tweets.length; i += noOfFeedSenseIcons){
        feedSenseArr = []
        for(let j = i; j < i + noOfFeedSenseIcons; j++){
            data = twitterFeedSenseArr.tweets[j]
            if(!data) continue
            feedSense = {userName: data.user.name, createdAt: data.created_at, displayUrl: data.user.display_url, 
                fullText: data.full_text, userUrl: data.user.user_url, sentiment: data.sentiment}
            feedSenseArr.push(feedSense)
        }
        twitterFeedSenseSlides.push(
            <FeedSenseSlide feedSenseSubArr={feedSenseArr} />
        )
    }

    let handleSentimentData = (sentimentData: any) => {
        let negatives: any = []
        let neutrals: any = []
        let positives: any = []
        let labels: any = []
        sentimentData.map((data: any) => {
            negatives.push(data.negative)
            neutrals.push(data.neutral)
            positives.push(data.positive)
            labels.push(data.date)
        })
        
        setNegative(negatives)
        setNeutral(neutrals)
        setPositive(positives)
        setLabels(labels)
    }

    const dataForChart:any = {
        labels: labels,
        datasets: [
            {
              stack: "stack1",
              label: 'Positive',
              data: positive,
              backgroundColor: '#D6E9C6' 
            },
            {
                stack: "stack1",
              label: 'Neutral',
              data: neutral,
              backgroundColor: '#FAEBCC' 
            },
            {
                stack: "stack1",
              label: 'Negative',
              data: negative,
              backgroundColor: '#EBCCD1' 
            }
        ]
    };

    const options:any = {
        responsive: true,
        legend: {
            position: 'right' 
        },
        scales: {
            xAxes: [{
                stacked: true 
            }],
            yAxes: [{
                stacked: true, 
                ticks: {
                    beginAtZero: true,          
                    stepSize: 1
                },
                type: 'linear',
            }]
        },
        maintainAspectRatio: false,
    }

    const handleChartDuration = (week: number) => {
        if(week === 1){
            handleSentimentData(sentimentAnalytics1week.sentiments)
        }
        if(week === 2){
            handleSentimentData(sentimentAnalytics2weeks.sentiments)
        }
        if(week === 3){
            handleSentimentData(sentimentAnalytics3weeks.sentiments)
        }
    }

    return (
        <>
            <div className="articles-by-keyword-page">
                <LogoContainer />
                <div style={{height:20}}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <p className="page-info">Results for Keyword "{params.keyword}"</p>
                            <IonList class="articles-list">
                                {articlesData.map((data: any, i: number) => {
                                    let article:News = {id: data._id, title: data.title, imageLink: data.image_link, category: data.category,
                                    publishedDate: data.datePublished, sentiment: data.sentiment, logoUrl:data.logo, publisher: data.publisher, 
                                    summary: data.summary}
                                    if(!article.id) return ""
                                    return <ArticleItem article={article} index={i}/>
                                })}
                            </IonList>
                        </div>
                        <div className="col-lg-4">
                            <div className="sentiment-portion">
                                <div className="heading">
                                    <h2 className="widget-title">
                                        Sentiment Analytics
                                    </h2>
                                    <SentimentChart dataForChart={dataForChart} options={options} handleChartDuration={handleChartDuration} />
                                </div>
                            </div>
                            <div className="twitter-feed">
                                <div className="heading">
                                    <h2 className="widget-title">
                                        FeedSense
                                    </h2>
                                </div>
                                <div >
                                    <Carousel ssr
                                        partialVisbile
                                        deviceType={"desktop"}
                                        itemClass="image-item"
                                        responsive={responsiveFeedSense} 
                                        customButtonGroup={<ArrowButtonGroup />}
                                        arrows={false} renderButtonGroupOutside={true}
                                    >
                                        {twitterFeedSenseSlides}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}