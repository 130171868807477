import {version}  from '../../package.json';

export const APP_VERSION = version;

export const add3Dots = (string: string | undefined, limit: number) => {
    var dots = "...";
    if (!string) return ''
    if (string.length > limit) {
        string = string.substring(0, limit) + dots;
    }
    return string;
}

export function parseDateInDisplayFormat(dateStr: string) {
    if (!dateStr) return ""

    let newDateStr = '';
    let date = dateStr.split('T');
    let dateOnly = date[0]

    let dateOnlyArr = dateOnly.split('-');
    newDateStr += dateOnlyArr[2] + '/' + dateOnlyArr[1] + '/' + dateOnlyArr[0];

    newDateStr += ' ' + date[1].substring(0, 5);

    return newDateStr;
}

export function parseDateInDisplayFormat2(dateStr: string) {
    if (!dateStr) return ""

    let date = new Date(dateStr);
    let longMonth = date.toLocaleString('en-IN', { month: 'long' });
    let day = date.toLocaleString('en-IN', { day: '2-digit' });
    let year = date.toLocaleString('en-IN', { year: 'numeric' });
    return longMonth + ' ' + day + ', ' + year;
}

export function parseDateInDisplayFormatShortMonth(dateStr: string) {
    if (!dateStr) return ""

    let date = new Date(dateStr); 
    let longMonth = date.toLocaleString('en-IN', { month: 'short' });
    let day = date.toLocaleString('en-IN', { day: '2-digit' });
    let year = date.toLocaleString('en-IN', { year: 'numeric' });
    return longMonth + ' ' + day + ', ' + year;
}

export function parseDateInDisplayFormat3(dateStr: string) {
    if (!dateStr) return ""

    let date = new Date(dateStr); 
    let longMonth = date.toLocaleString('en-us', { month: 'long' });
    let day = date.toLocaleString('en-us', { day: '2-digit' });
    let year = date.toLocaleString('en-us', { year: 'numeric' });

    return day + ' ' + longMonth + ' ' + year;
}



export function getColorBySentiment(sentiment: number) {
    let barColor = '';
    if (sentiment === 0) {
        barColor = "#fcba03";
    }
    if (sentiment > 0) {
        barColor = '#23cf59';
    }
    if (sentiment < 0) {
        barColor = "#ff4d00";
    }
    return barColor;
}

export function getRandomNumber(rangeUpto: number) {
    return (Math.floor(Math.random() * rangeUpto) + 1)
}

export function getNormalized(min: number, max: number, x: number) {
    return ((x - min) / (max - min)) * 100;
}

export function mergeMonthWiseData(dataFor: 'calls' | 'pl' | 'sr', arr1: any, arr2: any) {
    let newArr1: any = arr1?.concat(arr2)
    let newArr2: any = {}
    let newArr3: any = []


    for (let i = 0; i < newArr1?.length; i++) {
        if (newArr2[newArr1[i]?.month + "-" + newArr1[i]?.year]) {
            if (dataFor === "calls" || dataFor === "pl") {
                newArr2[newArr1[i]?.month + "-" + newArr1[i]?.year] += parseFloat(newArr1[i]?.value)
            } else {
                newArr2[newArr1[i]?.month + "-" + newArr1[i]?.year] =
                    (parseFloat(newArr1[i]?.value) + newArr2[newArr1[i]?.month + "-" + newArr1[i]?.year]) / 2
            }
        } else {
            newArr2[newArr1[i]?.month + "-" + newArr1[i]?.year] = parseFloat(newArr1[i]?.value)
        }
    }

    Object.keys(newArr2).map((key: any) => {
        let keySplit = key.split("-")
        newArr3.push({
            month: keySplit[0].slice(0, 3).toUpperCase(),
            year: keySplit[1],
            value: newArr2[key]
        })
    })
    return newArr3;
}


export function isFloat(n1: any) {
    let n = parseFloat(n1)
    return n === +n && n !== (n | 0);
}

export function getMonthYearFromDateString(dateStr: string) {
    if (!dateStr) return ""

    let date = new Date(dateStr);  
    let month = date.toLocaleString('en-IN', { month: 'long' });
    let year = date.toLocaleString('en-IN', { year: 'numeric' });
    return [month, year];
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getSentimentChartColor(data: any, type: string) {

    if (type == 'bg') {

        if (Number(data[0]) > Number(data[data.length - 1])) {
            return 'rgb(230,0,0)'
        }
        else if (Number(data[0]) == Number(data[data.length - 1])) {

            return '#ffbb00'
        }
        else if (Number(data[0]) < Number(data[data.length - 1])) {

            return 'rgb(0,102,0)'
        }

    }

}


export function getNiftySentimentChartColor(data: any) {
    let last_point: any = data[data?.length - 1]
    if (last_point >= 0.5 && 1.0 >= last_point) {
        return 'rgb(0,152,0)'
    }
    else if (last_point >= 0 && 0.5 >= last_point) {

        return 'rgb(252, 186, 3)'
    }
    else if (last_point >= -0.5 && 0 >= last_point) {

        return 'rgb(230, 100, 0)'
    }
    else if (last_point <= -0.5) {
        return 'rgb(230,0,0)'
    }

}
export function sum(list: any) {
    let val = list.reduce((a: any, b: any) => a + b, 0)
    return val
}

export function parameterList(data: any) {
    let arr: any = []
    if (data != null) {
        arr = arr?.concat(Object.keys(data))
        let final: any = []
        arr?.forEach((element: any) => {
            let obj: any = {
                "time": element,
                "Target": data[element]?.Target,
                "Stoploss": data[element]?.Stoploss
            }
            final.push(obj)
        });
        return final.reverse()
    }
}


export function optFutParameterList(data: any) {
    let arr: any = []
    if (data != null) {
        arr = arr?.concat(Object.keys(data))
        let final: any = []
        arr?.forEach((element: any) => {
            let obj: any = {
                "time": element,
                "Target": data[element]?.Target,
                "Stoploss_fut": data[element]?.Stoploss_fut,
                "Stoploss_opt": data[element]?.Stoploss_opt,
            }
            final.push(obj)
        });
        return final.reverse()
    }
}

export function assetArray(data: any) {
    let arr: any = []
    if(data === 'ALL')
    {
        return data
    }
    else{

        arr = data.split("-");
        let arr1 = arr[0] + "-" + arr[1]
        return arr1
    }

}



export function uniqueArray(array: any) {
    const distinctArray: any = array.filter((n: any, i: any) => array.indexOf(n) === i);
    return distinctArray
}


export function formatAssetName(asset_key_value: any, asset_name: any) {
    let name: any = Object.values(asset_key_value)[Object.keys(asset_key_value).indexOf(asset_name)]
    return name
}


export function productTypePair(type: any) {
    let productPair: any = {
        "all": "Live - Calls",
        "comodity-future": 'Commodity Fut - Intraday',
        "fx-future": 'Forex Fut - Intraday',
        "intraday-stock-index": 'Stock Index Fut - Intraday',
        "multiday-stock-index": 'Stock Index Fut - Multiday',
        "options-index": 'Stock Index Opt- Intraday',
        "options-stock": 'Stock Opt - Intraday',
        "stock-pair": 'Stock Pair Fut - Multiday',
        "intraday-stock-future": 'Stock Fut - Intraday',
        "multiday-stock-future": 'Stock Fut - Multiday',
        "fx-option": 'Forex Opt - Intraday',
        "fx-future-multiday": 'Forex Fut - Multiday',
        "multiday-equity": 'Equity - Multiday'
    }
    return productPair[type]
}


export function sortByDate(a: any, b: any) {
    if (a.dt_date < b.dt_date) {
        return 1;
    }
    if (a.dt_date > b.dt_date) {
        return -1;
    }
    return 0;
}


export const get_return_percentage = (advices: any) => {
    let return_percentage = 0;
    let total_sum = 0;
    let count = 0;
    let dt_now: any = new Date().toLocaleDateString('sv-SE')

    if (advices?.length > 0) {
        advices.map((item: any) => {
            if (item.s_asset_group_trade_type === 'multiday-stock-index' && item.open_date <= dt_now && item.s_reason == "") {
                count++;
            }
            else {
                total_sum = total_sum + item.return_per;
            }

        })
        return_percentage = total_sum / (advices?.length - count)
        return return_percentage.toFixed(2)
    }
}
export const getColorAccordingWithPl = (item: any, theme: any) => {

    if (item > 0) {
        if (theme == 'b/w') {
            return "lightgreen"
        }
        else {
            return "darkgreen"
        }
    }
    else if (item < 0) {
        return "orangered"
    }
    else if (item == '') {
        return ""
    }
    else {
        return "whitesmoke"
    }
}
export const getColorAccordingWithHistPL= (item: any, theme: any) => {
    if (item > 0) {
        if (theme == 'b/w') {
            return "lightgreen"
        }
        else if(theme =='dark') {
            return "#5dff8bd4"
        }else{
            return "#016a1f"
        }
    }
    else if (item < 0) {
        return "orangered"
    }
    else if (item == '') {
        return ""
    }
    else {
        return "whitesmoke"
    }
}

export const getColorAccordingWithPlDiv = (item: any, theme: any) => {
    if (item > 0) {
        if (theme == 'b/w') {
            return "#487648"
        }
        else {
            return "#9be09b87"
        }
    }
    else if (item < 0) {
        if (theme == 'b/w') {
            return "#683d3d";
        }
        return "#ffc6be"
    }
    else {
        if (theme == 'b/w') {
            return "#ffffff45";
        }else{
            return "#0000000a"
        }
    }
}
export const getArrowAccordingWithPl = (item: any) => {
    if (item > 0) {
        return "pi pi-arrow-up"
    }
    else if (item < 0) {
        return "pi pi-arrow-down"
    }
    else {
        return ""
    }
}

export const getColorParameterCharts = (item: any, item2: any, theme: any) => {

    if (Number(item) < Number(item2)) {
        return "#008000"
    }
    else if (Number(item) > Number(item2)) {
        return "#ff0000"
    }
    else {
        return "#f5f5f50a"
    }
}


export function selectVersionValues(type: any) {
    let version: any = {
        'comodity-future': 'CFI',
        'fx-future': 'FFI',
        'intraday-stock-future': 'SFI',
        'intraday-stock-index': 'IFI',
        'multiday-stock-index': 'IFM',
        'stock-pair': 'SFM',
        'options-index': 'IOI',
        'options-stock': 'SOI',
        'multiday-equity': 'ESM'

    }
    return version[type]
}
export function removeDuplicates(arr: any) {
    return arr.filter((item: any, index: any) => arr.indexOf(item) === index);
}


export async function sentimentMaker(res:any, selectedDate:any, sentimentType:any){

    
    let n_news_21_acc:any = res.newsAndEco21DaysSentiment[0]["n_news_21_acc"]?.toFixed(2);
    let n_news_252_acc:any = res.newsAndEco252DaysSentiment[0]["n_news_252_acc"]?.toFixed(2);
    let n_eco_21_acc:any= res.newsAndEco21DaysSentiment[0]["n_eco_21_acc"]?.toFixed(2);
    let n_eco_252_acc:any = res.newsAndEco252DaysSentiment[0]["n_eco_252_acc"]?.toFixed(2);
    let news_sentiment:any = res.news_sentiment;
    let niftyData:any = res.niftyData;
    let j_accuracy:any = {}
    let j_pl:any = {}
    try{
        if (res.news_sentiment?.length > 0)
        {
            let premarket_sentiment:any = news_sentiment[0]["n_premarket_sentiment"]
            let accuracy:any = news_sentiment[0]["j_accuracy"] !==null ? news_sentiment[0]["j_accuracy"] : {}
            let totalPL:any = news_sentiment[0]["j_pl"] !==null ? news_sentiment[0]["j_pl"] : {}

            if (Object.keys(accuracy).length > 0){
                let key:any =  Object.keys(accuracy).pop()
                j_accuracy = accuracy[key]
            }
            if (Object.keys(totalPL).length > 0){
                let key:any =  Object.keys(totalPL).pop()
                j_pl = totalPL[key]
            }
            
            let news_Signals:any = news_sentiment[0].j_sentiment !== null ? news_sentiment[0].j_sentiment : {};
            if(sentimentType == 'updated'){
                news_Signals = news_sentiment[0].j_sentiment_updated !== null ? news_sentiment[0].j_sentiment_updated : {};
            }
            
            let signal_data:any = []
            let total_nifty_data:any = niftyData[0]['j_ohlc']

            if ((Object.keys(news_Signals).length > 0) && (Object.keys(total_nifty_data).length > 0)){
                Object.keys(total_nifty_data).map((item:any)=>{
                    if (Object.keys(news_Signals).includes(item)){
                        if (news_Signals[item] > 0){
                           let obj:any = {"date":selectedDate+' '+item,
                                            "open":total_nifty_data[item][0],
                                            "high":total_nifty_data[item][1],
                                            "low":total_nifty_data[item][2],
                                            "close":total_nifty_data[item][3],
                                            "buy"  :  true,
                                            "value" :  news_Signals[item]
                                        }
                            signal_data.push(obj)
                        }
                        else{
                            let obj:any = {"date":selectedDate+' '+item,
                                            "open":total_nifty_data[item][0],
                                            "high":total_nifty_data[item][1],
                                            "low":total_nifty_data[item][2],
                                            "close":total_nifty_data[item][3],
                                            "sell"  :  true,
                                            "value" :  news_Signals[item]
                                        }
                            signal_data.push(obj)
                        }
                    }
                    else{
                        let obj:any = {"date":selectedDate+' '+item,
                                            "open":total_nifty_data[item][0],
                                            "high":total_nifty_data[item][1],
                                            "low":total_nifty_data[item][2],
                                            "close":total_nifty_data[item][3]
                                        }
                        signal_data.push(obj)
                    }
                })

                return {
                    "news_sentiment": {
                                        "n_premarket_sentiment":premarket_sentiment,
                                        "j_accuracy"  : j_accuracy,
                                        "j_pl" : j_pl,
                                        "signal_data" : signal_data,
                                        "j_sentiment_length" : (Object.keys(news_sentiment[0].j_sentiment_updated))?.length
                                        },
                        "n_news_252_acc": n_news_252_acc,
                        "n_eco_252_acc" : n_eco_252_acc,
                        "n_news_21_acc" : n_news_21_acc,
                        "n_eco_21_acc"  : n_eco_21_acc
                    }
            }
            else if((Object.keys(news_Signals)?.length == 0) && (Object.keys(total_nifty_data).length > 0)){
                Object.keys(total_nifty_data).map((item:any)=>{
                    if (Object.keys(news_Signals).includes(item)){
                        if (news_Signals[item] > 0)
                        {
                           let obj:any = {"date":selectedDate+' '+item,
                                            "open":total_nifty_data[item][0],
                                            "high":total_nifty_data[item][1],
                                            "low":total_nifty_data[item][2],
                                            "close":total_nifty_data[item][3],
                                            "buy"  :  true,
                                            "value" :  news_Signals[item]
                                        }
                            signal_data.push(obj)
                        }
                        else{
                            let obj:any = {"date":selectedDate+' '+item,
                                            "open":total_nifty_data[item][0],
                                            "high":total_nifty_data[item][1],
                                            "low":total_nifty_data[item][2],
                                            "close":total_nifty_data[item][3],
                                            "sell"  :  true,
                                            "value" :  news_Signals[item]
                                        }
                            signal_data.push(obj)
                        }
                    }
                    else{
                        let obj:any = {"date":selectedDate+' '+item,
                                            "open":total_nifty_data[item][0],
                                            "high":total_nifty_data[item][1],
                                            "low":total_nifty_data[item][2],
                                            "close":total_nifty_data[item][3]
                                        }
                        signal_data.push(obj)
                    }
                })

                return {
                    "news_sentiment": {
                                        "n_premarket_sentiment":premarket_sentiment,
                                        "j_accuracy"  : j_accuracy,
                                        "j_pl" : j_pl,
                                        "signal_data" : signal_data,
                                        "j_sentiment_length" : news_sentiment[0].j_sentiment_updated !== null ?  (Object.keys(news_sentiment[0].j_sentiment_updated))?.length : 0
                                        },
                        "n_news_252_acc": n_news_252_acc,
                        "n_eco_252_acc" : n_eco_252_acc,
                        "n_news_21_acc" : n_news_21_acc,
                        "n_eco_21_acc"  : n_eco_21_acc
                    }
            }
        }
        else{
            return {
                    "news_sentiment" : {},
                    "n_news_252_acc" : n_news_252_acc,
                    "n_news_21_acc"  : n_news_21_acc,
                    "n_eco_21_acc"   : n_eco_21_acc,
                    "n_eco_252_acc"  : n_eco_252_acc
                }
        }
    }
    catch{
        return {
            "news_sentiment" : {},
            "n_news_252_acc" : n_news_252_acc,
            "n_news_21_acc"  : n_news_21_acc,
            "n_eco_21_acc"   : n_eco_21_acc,
            "n_eco_252_acc"  : n_eco_252_acc
        }
    }
}