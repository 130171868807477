import "./style.css"
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import { remove_Cookie } from "../../../../Cookies/remove_cookie";
import { get_Cookie } from "../../../../Cookies/get_cookie";
import csvDownload from 'json-to-csv-export' 
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";


interface AdviceStatusProps {
    type: any,
    advices: any[],
    setAdvices: any,
    handleAdviceClick: any,
    asset_key_value: any
    setSelectedAdviceStatus: any
    selectedAdviceStatus: any
    theme: any
    version: any
    selectedDate: any
    setColumnSelection: any
    columnSelection: any
    openTradeAdvices: any
    setOpenTradeAdvices: any
    openTradeValue: any
    setOpenTradeValue: any
    totalTrades: any
    setTotalTrades: any
    advicesStatusType: any
    setAdvicesStatusType: any
    setLoading_adv: any
    loading_adv: any
    callsCount:any
    calenderPickerDate:any
    currentTime:any
    minChargeDate:any
}

export const AdviceStatus: React.FC<AdviceStatusProps> = ({ advices, handleAdviceClick, type, version, asset_key_value,
    setSelectedAdviceStatus, selectedAdviceStatus, theme, setAdvices,  selectedDate, setColumnSelection, columnSelection
    , openTradeAdvices, setOpenTradeAdvices, openTradeValue, setOpenTradeValue, totalTrades, setTotalTrades, advicesStatusType, 
    setAdvicesStatusType, setLoading_adv, loading_adv,callsCount, calenderPickerDate, currentTime,minChargeDate}) => {

        


    useEffect(() => {
        let index = 0;
        if (advices.length > 0) {
            index = advices.findIndex(x => x.uuid === selectedAdviceStatus.uuid && x.identifier === selectedAdviceStatus.identifier);
            handleAdviceClick(index)

            if (index === -1) {
                handleAdviceClick(0);
            }

        }
    }, [selectedAdviceStatus])


    const [selectedColumns, setSelectedColumns] = useState<any>();
    const [selectedHeader, setSelectedheader] = useState<any>([])
    const [reorder,setReorder]=useState<any>(false)

    const cols: any = [
        { field: 'asset', header: 'Symbol', isChecked: true },
        { field: 'identifier', header: 'Code', isChecked: true, disabled: true },
        { field: 'tradetype', header: 'Type', isChecked: true },
        { field: 'exit_price', header: 'Exit Price', isChecked: true },
        { field: 'transaction', header: 'Transaction Price', isChecked: true },
        { field: 'stoploss', header: 'Stoploss', isChecked: true },
        { field: 'target', header: 'Target', isChecked: true },
        { field: 'open_date', header: 'Open Date', isChecked: true },
        { field: 'close_reason', header: 'Close Reason', isChecked: true },
        { field: 'trade_pl', header: 'P/L', isChecked: true },
        { field: 'trade_pl_rs', header: 'P/L (₹)', isChecked: true },
        { field: 'trade_close_date', header: 'Close Date', isChecked: true },
    ];

    const live_calls_cols: any = [
        { field: 'asset', header: 'Symbol', isChecked: true },
        { field: 'identifier', header: 'Code', isChecked: true, disabled: true },
        { field: 'trade_type', header: 'Type', isChecked: true },
        { field: 'exit_price', header: 'Exit Price', isChecked: true },
        { field: 'n_open_transaction_price', header: 'Transaction Price', isChecked: true },
        { field: 'open_date', header: 'Open Date', isChecked: true },
        { field: 's_reason', header: 'Close Reason', isChecked: true },
        { field: 'trade_pl', header: 'P/L', isChecked: true },
        { field: 'trade_pl_rs', header: 'P/L (₹)', isChecked: true },
        { field: 'return_per', header: 'Return (%)', isChecked: true },
        { field: 'trade_close_date', header: 'Close Date', isChecked: true },
        { field: 'return_after_cost', header: 'Return After Cost (%)', isChecked: true }
    ];

    const optionCols: any = [
        { field: 'asset', header: 'Symbol', isChecked: true },
        { field: 'identifier', header: 'Code', isChecked: true, disabled: true },
        { field: 'tradetype', header: 'Type', isChecked: true },
        { field: 'strike_price_ce', header: 'CE Strike', isChecked: true },
        { field: 'strike_price_pe', header: 'PE Strike', isChecked: true },
        { field: 'transaction', header: 'Transaction Price', isChecked: true },
        { field: 'target', header: 'Target', isChecked: true },
        { field: 'stoploss', header: 'Stoploss', isChecked: true },
        { field: 'exit_price', header: 'Exit Price', isChecked: true },
        { field: 'trade_pl', header: 'P/L', isChecked: true },
        { field: 'trade_pl_rs', header: 'P/L (₹)', isChecked: true },
        { field: 'open_date', header: 'Open Date', isChecked: true },
        { field: 'trade_close_date', header: 'Close Date', isChecked: true },
        { field: 'expiry_date', header: 'Expiry date', isChecked: true },
        { field: 'close_reason', header: 'Close Reason', isChecked: true }
    ];

    function setCookie(cname: any, cvalue: any, exdays: any) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    let obj: any = window.localStorage.getItem("ColReorder");
    let parsedObj = JSON.parse(obj);
    let liveCallobj: any = window.localStorage.getItem("LiveColReorder");
    let parsedlivecallObj = JSON.parse(liveCallobj);
    let obj_option: any = window.localStorage.getItem("optionColReorder");
    let parsedObj_option = JSON.parse(obj_option);


    let cookies_obj: any = [];
    let jsonObj: any = [];
    if (type === 'all') {
        cookies_obj = get_Cookie('LiveColumns');
        if (cookies_obj != undefined) {
            jsonObj = JSON.parse(cookies_obj);
        }
    } else if (type === "options-stock" || type === "options-index" || type === "fx-option") {
        cookies_obj = get_Cookie('optionStorageCol');
        if (cookies_obj != undefined) {
            jsonObj = JSON.parse(cookies_obj);
        }
    } else {
        cookies_obj = get_Cookie('StorageCol');
        if (cookies_obj != undefined) {
            jsonObj = JSON.parse(cookies_obj);
        }
    }


    useEffect(() => {
        if (type === "options-stock" || type === "options-index" || type === 'fx-option')
        {
            let container1: any = [];
            let container2: any = [];
            if (cookies_obj !== undefined) {
                container1 = jsonObj.filter((item: any) => {
                    return optionCols.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                container2 = optionCols.filter((item: any) => {
                    return !jsonObj.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                let newContainer: any = [...container1, ...container2]

                setSelectedheader(newContainer)
            } else {
                setSelectedheader(optionCols)
            }
        } 
        else if (type === 'all') {
            let container1: any = [];
            let container2: any = [];
            if (cookies_obj !== undefined) {
                container1 = jsonObj.filter((item: any) => {
                    return live_calls_cols.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })

                container2 = live_calls_cols.filter((item: any) => {
                    return !jsonObj.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                let newContainer: any = [...container1, ...container2]
                if (advicesStatusType === "spot") {
                    let add_column :any = [{ field: 'pl_without_dividend', header: 'P/L (Exclusive of Dividend)', isChecked: true },
                                            { field: 'oneDaypl', header: '1D P/L', isChecked: true },
                                            { field: 'oneDay_return_per', header: '1D Return', isChecked: true }]

                    newContainer = [...newContainer, ...add_column]

                    setSelectedheader(newContainer)
                } else {
                    setSelectedheader(newContainer)
                }
            } else {
                if (advicesStatusType === "spot") {
                    let add_column:any = [  { field: 'pl_without_dividend', header: 'P/L (Exclusive of Dividend)', isChecked: true },
                                            { field: 'oneDaypl', header: '1D P/L', isChecked: true },
                                            { field: 'oneDay_return_per', header: '1D Return (%)', isChecked: true }]

                    let container: any = [...live_calls_cols, ...add_column]
                    setSelectedheader(container)
                } else {
                    setSelectedheader(live_calls_cols)
                }
            }
        }
        else 
        {
            let container1: any = [];
            let container2: any = [];
            if (cookies_obj != undefined) {
                container1 = jsonObj.filter((item: any) => {
                    return cols.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                container2 = cols.filter((item: any) => {

                    return !jsonObj.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                let newContainer: any = [...container1, ...container2]
                if (type === "multiday-equity") {
                    let add_column:any = [  { field: 'pl_without_dividend', header: 'P/L (Exclusive of Dividend)', isChecked: true },
                                            { field: 'oneDaypl', header: '1D P/L', isChecked: true },
                                            { field: 'oneDay_return_per', header: '1D Return', isChecked: true }
                                        ]
                    newContainer = [...newContainer,...add_column ]
            
                    setSelectedheader(newContainer)
                } else {
                    setSelectedheader(newContainer)
                }

            } else {
                if (type === "multiday-equity") {
                    let add_column:any = [  { field: 'pl_without_dividend', header: 'P/L (Exclusive of Dividend)', isChecked: true },
                                            { field: 'oneDaypl', header: '1D P/L', isChecked: true },
                                            { field: 'oneDay_return_per', header: '1D Return', isChecked: true }
                                        ]
                    let container: any = [...cols, ...add_column]
                   
                    setSelectedheader(container)
                } else {
                    setSelectedheader(cols)
                }
            }
        }

    }, [type, version, advicesStatusType])


    useEffect(() => {
        if (type === "options-stock" || type === "options-index" || type === "fx-option") {
            let container1: any = [];
            let container2: any = [];
            if (parsedObj_option != undefined && parsedObj_option !== null) {
                container1 = parsedObj_option.filter((item: any) => {
                    return selectedHeader.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })

                container2 = selectedHeader.filter((item: any) => {
                    return !parsedObj_option.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                let newContainer: any = [...container1, ...container2]
                newContainer.map((item: any) => {
                    selectedHeader.map((arr: any) => {
                        if (item.header === arr.header && item.field === arr.field) {
                            item.isChecked = arr.isChecked
                        }
                    })
                })
                setSelectedColumns(newContainer)
            }
            else {
                setSelectedColumns(selectedHeader)
            }
        }
        else if (type === "all") 
        {
            let container1: any = [];
            let container2: any = [];
            if (parsedlivecallObj !== undefined && parsedlivecallObj !== null) {
                container1 = parsedlivecallObj.filter((item: any) => {
                    return selectedHeader.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                container2 = selectedHeader.filter((item: any) => {
                    return !parsedlivecallObj.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                let newContainer: any = [...container1, ...container2]
                newContainer.map((item: any) => {
                    selectedHeader.map((arr: any) => {
                        if (item.header === arr.header && item.field === arr.field) {
                            item.isChecked = arr.isChecked
                        }
                    })
                })
                setSelectedColumns(newContainer)
            }
            else {
                setSelectedColumns(selectedHeader)
            }
        }
        else 
        {
            if (parsedObj !== undefined && parsedObj !== null) {
                let container1: any = [];
                let container2: any = [];
                container1 = parsedObj.filter((item: any) => {
                    return selectedHeader.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                container2 = selectedHeader.filter((item: any) => {
                    return !parsedObj.some((arr: any) => {
                        return (item?.header === arr?.header && item?.field === arr?.field)
                    })
                })
                let newContainer: any = [...container1, ...container2]
                newContainer.map((item: any) => {
                    selectedHeader.map((arr: any) => {
                        if (item.header === arr.header && item.field === arr.field) {
                            item.isChecked = arr.isChecked
                        }
                    })
                })

                setSelectedColumns(newContainer)
            }
            else {
                setSelectedColumns(selectedHeader)
            }
        }
    }, [selectedHeader,reorder])



    const OpenTraderow = (item: any) => {
        if(type ==='all')
        {
            return {
                'row-accessories': (item?.s_reason === null || item?.s_reason == '')
            }
        }
        else
        {
            return {
                'row-accessories': (item?.close_reason === null || item?.close_reason == '')
            }
        }
    }

   
    const handleAsset = (item: any) => {

        let name: any = Object.values(asset_key_value)[Object.keys(asset_key_value).indexOf(item.asset)]
        if (type === 'intraday-stock-index' || type === 'fx-future' || type === 'comodity-future') {
                return name
        }
        else if (type === "options-stock" || type === "options-index") {
            let asset: any = Object.values(asset_key_value)[Object.keys(asset_key_value).indexOf(item.asset)]
            return asset
        }
        else if (type === 'all') {
            if (item?.s_asset_group_trade_type === 'intraday-stock-index' || item?.s_asset_group_trade_type === 'fx-future' || item?.s_asset_group_trade_type === 'comodity-future') {
                    return name
            }
            
            else if (item?.s_asset_group_trade_type === "options-stock" || item.s_asset_group_trade_type === "options-index") {

                let asset: any = Object.values(asset_key_value)[Object.keys(asset_key_value).indexOf(item.asset)]
                return asset
            }
            else {
                if (name == undefined) {
                    return item.asset
                }
                else if (name?.search("_") != -1) {
                    let assets: any = name?.split("_")
                    name = assets[0] + "  " + assets[1]
                    return name
                }
                else {
                    return name
                }
            }
        }
        else {
            if (name == undefined) {
                return item.asset
            }
            else if (name?.search("_") != -1) {
                let assets: any = name?.split("_")
                name = assets[0] + "  " + assets[1]
                return name
            }
            else {
                return name
            }
        }
    }


    const handleDate = (value: any) => {
        let changed_date_format = moment(value).format("MMM Do, YYYY")
        return changed_date_format
    }

    const handleCloseDate = (value: any) => {
        if (value != '') {
            let changed_date_format = moment(value).format("MMM Do, YYYY")
            return changed_date_format
        }
        else {
            return 'Open'
        }
    }

    const handlevalue = (identifier: any, value: any, valuesType: any) => {
        let returnValue: any=0;
 
        if (value === '' || value === undefined || value === null) {
            return ''
        }
        if (identifier?.slice(1, 2) === 'F' && valuesType !== 'return_per') {
            returnValue = Number(value)?.toFixed(4)
        }
        else {
            returnValue = Number(value)?.toFixed(2)
        }
        
        if (valuesType === 'pl' || valuesType === 'return_per') {
            if (value >= 0) {
                if (theme == 'dark' || theme == 'b/w') {
                    return <span style={{ color: "lightgreen", fontWeight: 'bold' }}>{returnValue}</span>
                } else {
                    return <span style={{ color: "darkgreen", fontWeight: 'bold' }}>{returnValue}</span>
                }
            }
            else if (value < 0) {

                return <span style={{ color: "orangered", fontWeight: 'bold' }}>{returnValue}</span>
            }
            else if (value == '') {
                return <span></span>
            }
            else {
                return <span style={{ color: "whitesmoke", fontWeight: 'bold' }}>{returnValue}</span>
            }
        } else {
            return returnValue
        }
    }

    const handlehovervalue = (value: any) => {
        let returnValue: any=0;

        if (value === '' || value === undefined || value === null) {
            return ''
        }

        returnValue = Number(value)?.toFixed(4)
        
        if (returnValue >= 0) {
            if (theme == 'dark' || theme == 'b/w') {
                return "lightgreen"
            } else {
                return "darkgreen"
            }
        }
        else if (returnValue < 0) {
            return "orangered"
        }
        else if (returnValue == '') {
            return "white"
        }
        else {
            return "whitesmoke"
        }
    }

    const handleReason = (str: any) => {
        if (str === null || str == '') {
            return 'Open'
        }
        else {
            str = str?.toLowerCase().split('_').join(' ')
            return str
        }
    }


    const selectedRow = () => {
        var selectedRow: any = document.getElementById("selected_row")
        selectedRow.classList.add("selected");
    }
    let live_headers:any  = [
        'Open Date',
        'Open Time',
        'Symbol',
        'Type',
        'Transaction Price',
        'Target',
        'Stoploss',
        'P/L',
        'Code',
        'Risk Reward',
        'Return',
        'Return After Cost',
        'Strike price',
        'Option Type',
        'Close Time',
        'Exit Price',
        'Close Reason',
        'Close Date'
    ];

    let live_headers_spot:any  = [
        'Open Date',
        'Open Time',
        'Symbol',
        'Type',
        'Transaction Price',
        'Target',
        'Stoploss',
        'P/L',
        'P/L(Exclusive of Dividend)',
        'Code',
        'Return',
        'Return After Cost',
        '1D P/L',
        '1D Return',
        'Risk Reward',
        'Close Time',
        'Exit Price',
        'Close Reason',
        'Close Date'
    ];

    let headers:any  = [
        'Open Date',
        'Open Time',
        'Symbol',
        'Type',
        'Transaction Price',
        'Target',
        'Stoploss',
        'P/L',
        'P/L (₹)',
        'Code',
        'Close Time',
        'Exit Price',
        'Close Reason',
        'Close Date',
    ];

    let spot_headers:any  = [
        'Open Date',
        'Open Time',
        'Symbol',
        'Type',
        'Transaction Price',
        'Target',
        'Stoploss',
        'P/L',
        'P/L(Exclusive of Dividend)',
        'Code',
        'Close Time',
        'Exit Price',
        'Close Reason',
        'Close Date'
    ];

    let option_headers:any  = [
        'Open Date',
        'Open Time',
        'Symbol',
        'Type',
        'Transaction Price',
        'Target',
        'Stoploss',
        'P/L',
        'P/L (₹)',
        'Code',
        'Close Time',
        'Exit Price',
        'CE',
        'PE',
        'Expiry Date',
        'Close Reason',
        'Close Date'
    ];
    
    const csvDataMaker = (e:any) => {
        e.preventDefault();
        let result:any = []
        if(type === 'all')
        {
            result = advices?.reduce((res:any, item:any) => {
                if(advicesStatusType === 'spot')
                {
                    res.push({  open_date: item.open_date, 
                                dt_open_time: item.dt_open_time, 
                                asset: item.asset,
                                trade_type: item?.trade_type, 
                                n_open_transaction_price: item?.n_open_transaction_price,
                                n_target: item?.n_target,
                                n_stoploss: item?.n_stoploss,
                                trade_pl: item?.trade_pl,
                                pl_without_dividend:item?.pl_without_dividend,
                                identifier: item?.identifier,
                                return_per: item?.return_per,
                                return_after_cost :item?.return_after_cost,
                                oneDay_return_per:item?.oneDay_return_per,
                                oneDaypl:item?.oneDaypl,
                                rr:item?.rr,
                                dt_end_time: (item?.s_reason === null || item?.s_reason === '') ? '' : item?.dt_end_time,
                                exit_price: (item?.s_reason === null || item?.s_reason === '') ? '' : item?.exit_price,
                                s_reason : (item?.s_reason === null || item?.s_reason === '')? '' :item?.s_reason,
                                trade_close_date: (item?.s_reason === null || item?.s_reason === '')? '' : item?.trade_close_date});
                    return res
                }
                else{
                    res.push({  open_date: item.open_date,
                                dt_open_time: item.dt_open_time,
                                asset: item.asset,
                                trade_type: item?.trade_type, 
                                n_open_transaction_price: item?.n_open_transaction_price,
                                n_target: item?.n_target, 
                                n_stoploss: item?.n_stoploss, 
                                trade_pl: item?.trade_pl,
                                identifier: item?. identifier,
                                rr:item?.rr,
                                return_per: item?.return_per,
                                return_after_cost :item?.return_after_cost,
                                strike_price: (item?.strike_price === undefined || item?.strike_price === null)? '' : item?.strike_price, 
                                option_type:(item?.option_type === undefined || item?.option_type === null )? '' : item?.option_type ,
                                dt_end_time: (item?.s_reason === null || item?.s_reason === '') ? '' : item?.dt_end_time,
                                exit_price: (item?.s_reason === null || item?.s_reason === '') ? '' : item?.exit_price,
                                s_reason : (item?.s_reason === null || item?.s_reason === '')? '' :item?.s_reason,
                                trade_close_date: (item?.s_reason === null || item?.s_reason === '')? '' : item?.trade_close_date});
                    return res;
                }
            }, []);
        }
        else if(type == 'options-stock' || type === 'options-index' || type === 'fx-option')
        {
            result = advices?.reduce((res:any, item:any) => {
                res.push({ open_date : item?.open_date, dt_open_time: item?.dt_open_time, asset: item?.asset, tradetype:item?.tradetype, transaction: item?.transaction,
                     target: item?.target, stoploss: item?.stoploss, trade_pl: item?.trade_pl,trade_pl_rs: item?.trade_pl_rs,
                     identifier: item?.identifier,
                     dt_end_time: (item?.close_reason === null || item?.close_reason === '') ? '' : item?.dt_end_time,
                     exit_price: (item?.close_reason === null || item?.close_reason === '') ? '' :item?.exit_price,
                     strike_price_ce: (item?.strike_price_ce === null || item?.strike_price_ce === '')?'':item?.strike_price_ce,
                     strike_price_pe: (item?.strike_price_pe === null || item?.strike_price_pe === '')?'':item?.strike_price_pe,
                     expiry_date: item?.expiry_date, 
                     close_reason: item?.close_reason, 
                     trade_close_date: (item?.close_reason === null || item?.close_reason === '') ? '' :item?.trade_close_date });
                return res;
            }, []);
        }
        else if(type == "multiday-equity")
        {
            result = advices?.reduce((res:any, item:any) => {
                res.push({ open_date : item?.open_date,
                    dt_open_time: item?.dt_open_time,
                    asset: String(item?.asset),
                    tradetype: item?.tradetype,
                    transaction: item?.transaction, 
                    target: item?.target, 
                    stoploss: item?.stoploss, 
                    trade_pl: item?.trade_pl, 
                    pl_without_dividend:item?.pl_without_dividend,
                    identifier: String(item?.identifier), 
                    dt_end_time: (item?.close_reason === null || item?.close_reason === '')?'':item?.dt_end_time, 
                    exit_price: (item?.close_reason === null || item?.close_reason === '')?'':item?.exit_price,
                    close_reason: (item?.close_reason === null || item?.close_reason === '')?'':item?.close_reason,
                    trade_close_date: (item?.close_reason === null || item?.close_reason === '')?'':item?.trade_close_date });
                return res;
            }, []);
        }
        else
        {
            result = advices?.reduce((res:any, item:any) => {
                res.push({ open_date : item?.open_date,
                    dt_open_time: item?.dt_open_time,
                    asset: String(item?.asset),
                    tradetype: item?.tradetype,
                    transaction: item?.transaction, 
                    target: item?.target, 
                    stoploss: item?.stoploss, 
                    trade_pl: item?.trade_pl, 
                    trade_pl_rs: item?.trade_pl_rs,
                    identifier: String(item?.identifier), 
                    dt_end_time: (item?.close_reason === null || item?.close_reason === '')?'':item?.dt_end_time, 
                    exit_price: (item?.close_reason === null || item?.close_reason === '')?'':item?.exit_price,
                    close_reason: (item?.close_reason === null || item?.close_reason === '')?'':item?.close_reason,
                    trade_close_date: (item?.close_reason === null || item?.close_reason === '')?'':item?.trade_close_date });
                return res;
            }, []);
        }
        const dataToConvert = {
            data: result,
            filename: `${type === 'all'?(advicesStatusType == 'hft'?'lft':(advicesStatusType == 'spot')?'equity':advicesStatusType):type}_trades_for_${calenderPickerDate}_at_${currentTime}`,
            delimiter: ',',
            headers: (type == 'options-stock' || type === 'options-index' || type === 'fx-option')? option_headers :(type === 'all')? (advicesStatusType ==='spot'?live_headers_spot : live_headers): (type === "multiday-equity")?spot_headers:headers
          }
          csvDownload(dataToConvert)
    }

    const onColumnToggle = (event: any) => {
        const { name, checked } = event.target;
        if (name === "allSelect") {
            let tempCode = selectedHeader.map((item: any) => {
                if (item?.header === 'Code') {
                    return { ...item, isChecked: true };
                }
                return { ...item, isChecked: checked };
            });
            setSelectedheader(tempCode)
            if (type === "options-stock" || type === "options-index") {
                remove_Cookie('optionStorageCol')
                setCookie('optionStorageCol', JSON.stringify(tempCode), 30)
            } else if (type === 'all') {
                remove_Cookie('LiveColumns')
                setCookie('LiveColumns', JSON.stringify(tempCode), 30)
            } else {
                remove_Cookie('LiveColumns')
                setCookie('StorageCol', JSON.stringify(tempCode), 30)
            }

        } else {
            let tempCode = selectedHeader.map((item: any) =>
                item.header === name ? { ...item, isChecked: checked } : item
            );
            setSelectedheader(tempCode);
            if (type === "options-stock" || type === "options-index") {
                remove_Cookie('optionStorageCol')
                setCookie('optionStorageCol', JSON.stringify(tempCode), 30)
            } else if (type === 'all') {
                remove_Cookie('LiveColumns')
                setCookie('LiveColumns', JSON.stringify(tempCode), 30)
            } else {
                remove_Cookie('StorageCol')
                setCookie('StorageCol', JSON.stringify(tempCode), 30)
            }
        }
    }

    const header = (
        <div>
            <Row className="align-items-center export-buttons">
                <div className="d-flex advicesName" style={{ width: "45%", alignItems: "center" }}>
                {type === 'all'? <p className="float-left advise_header">Live Advice</p>: <p className="float-left advise_header">Advice Status</p>}
                    {type === "all" ?
                        <p className="advise_p">({moment(selectedDate).format("MMM Do, YYYY") == moment(new Date()).format("MMM Do, YYYY") ? "Today" : moment(selectedDate).format("MMM Do, YYYY")})</p>
                        : ""}
                </div>
                {type !== 'all' ? <div className="d-flex justify-content-end" style={{ width: "55%", position: "relative" }}>
                    <div style={{ marginRight: "2rem" }}>
                        {advices.length > 0 ?
                            columnSelection == true ?
                                <div className='columnlist' style={{ top: "4rem", overflowY: "auto", minHeight: "22rem", height: "22rem" }}>
                                    <form >
                                        <div>
                                            <div className='form-check'>
                                                <input className='form-mcheckbox' type='checkbox' name="allSelect" checked={selectedHeader.filter((item: any) => item?.isChecked !== true).length < 1} onChange={onColumnToggle} />
                                                <label className='form-value' htmlFor="">All</label>
                                            </div>
                                        </div>
                                        <div>
                                            {selectedHeader.map((item: any) => (
                                                <div className='form-check'>
                                                    <input className='form-input' type='checkbox' name={item.header} checked={item?.isChecked || false} onChange={onColumnToggle} disabled={item?.disabled} />
                                                    <label className='form-value' htmlFor={item.header} >{item.header}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </form>
                                </div>
                                :
                                <div>
                                </div> : ""
                        }
                    </div>
                    <div className="d-flex align-items-center">
                        {type === 'all' ?
                            openTradeValue == false ?
                                <button type="button" className="btn-live advicesBTN" onClick={() => { setOpenTradeValue(true) }}>
                                    Live<span className="live-icon" ></span>
                                </button>
                                :
                                <button type="button" className="btn-live-open advicesBTN" onClick={() => { setOpenTradeValue(false) }}>
                                    Live<span className="live-icon"></span>
                                </button>
                            : ''}
                        <Button type="button" className="mHeaderbox advicesBTN" onClick={() => { setColumnSelection(!columnSelection) }} style={{ boxShadow: "rgb(220,153,72) 0.1rem 0.1rem 2rem", margin: '0 0.8rem 0 0.3rem', background: "beige", border: "none", width: "3.2rem", padding: "0.3rem" }}>
                            <img src="./assets/icon/preference_icon.png" />
                        </Button>
                        {/* <Button type="button" icon="pi pi-download " onClick={exportPdf} className="p-button-success btn-lg float-right advicesBTN" style={{ boxShadow: "rgb(104 159 56) 0.1rem 0.1rem 2rem", width: "3.2rem", height: "3.2rem" }} data-pr-tooltip="PDF" ></Button> */}
                        <Button type="button" icon="pi pi-download " onClick={(e:any) => {csvDataMaker(e)}} className="p-button-success btn-lg float-right advicesBTN" style={{ boxShadow: "rgb(104 159 56) 0.1rem 0.1rem 2rem", width: "3.2rem", height: "3.2rem" }} ></Button>
                    </div>
                </div> : ""}
            </Row>
            {type === 'all' ? <div className="selectSlide">
                <Row style={{ width: "100%" }}>
                    <div className="d-flex" style={{ width: "100%", position: "relative", height: "100%" }}>
                        <div className="childSelectSlide1">
                            <div className={advicesStatusType === "derivative" ? "childSelectSlide2" : "childSelectSlide3"}>
                                <button className={advicesStatusType === "derivative" ? "advicesStatusTypeDiv1" : "advicesStatusTypeDiv2"} id={advicesStatusType === "derivative" ? "derivativeShadow" : "derivativeNoShadow"} style={{ borderTopLeftRadius: "0.5rem" ,fontSize: '1.5rem'}} onClick={() => { setAdvicesStatusType("derivative") }}>F&O ({callsCount['derivative'] != undefined ? callsCount['derivative'] : 0 })</button>
                                <button className={advicesStatusType === "hft" ? "advicesStatusTypeDiv1" : "advicesStatusTypeDiv2"} id={advicesStatusType === "hft" ? "spotShadow" : "spotNoShadow"} style={{fontSize: '1.5rem'}} onClick={() => { setAdvicesStatusType("hft") }}>LFT ({callsCount['hft'] != undefined ? callsCount['hft'] : 0})</button>
                                <button className={advicesStatusType === "spot" ? "advicesStatusTypeDiv1" : "advicesStatusTypeDiv2"} id={advicesStatusType === "spot" ? "spotShadow" : "spotNoShadow"} style={{fontSize: '1.5rem'}} onClick={() => { setAdvicesStatusType("spot") }}>Equity ({callsCount['spot'] != undefined ? callsCount['spot'] : 0})</button>
                            </div>
                            <div className={advicesStatusType === "spot" ? "advicesStatusFalseDiv1" : ""} id={advicesStatusType === "spot" ? "advicesStatusFalseDiv1" : "advicesStatusFalseDiv2"}>
                                <div style={{ marginRight: "2rem" }}>
                                    {advices.length > 0 ?
                                        columnSelection == true ?
                                            <div className='columnlist' style={{ top: "4rem", overflowY: "auto", minHeight: "22rem", height: "22rem" }}>
                                                <form >
                                                    <div>
                                                        <div className='form-check'>
                                                            <input className='form-mcheckbox' type='checkbox' name="allSelect" checked={selectedHeader.filter((item: any) => item?.isChecked !== true).length < 1} onChange={onColumnToggle} />
                                                            <label className='form-value' htmlFor="">All</label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {selectedHeader.map((item: any) => (
                                                            <div className='form-check'>
                                                                <input className='form-input' type='checkbox' name={item.header} checked={item?.isChecked || false} onChange={onColumnToggle} disabled={item?.disabled} />
                                                                <label className='form-value' htmlFor={item.header} >{item.header}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </form>
                                            </div>
                                            :
                                            <div>
                                            </div> : ""
                                    }
                                </div>
                                <div className="d-flex align-items-center" >
                                    {type === 'all' ?
                                        openTradeValue == false ?
                                            <button type="button" className="btn-live advicesBTN" onClick={() => { setOpenTradeValue(true) }}>
                                                Live<span className="live-icon" ></span>
                                            </button>
                                            :
                                            <button type="button" className="btn-live-open advicesBTN" onClick={() => { setOpenTradeValue(false) }}>
                                                Live<span className="live-icon"></span>
                                            </button>
                                        : ''}
                                    <Button type="button" className="mHeaderbox advicesBTN" onClick={() => { setColumnSelection(!columnSelection) }} style={{ boxShadow: "rgb(220,153,72) 0.1rem 0.1rem 2rem", margin: '0 0.8rem 0 0.3rem', background: "beige", border: "none", width: "3.2rem", padding: "0.3rem" }}>
                                        <img src="./assets/icon/preference_icon.png" />
                                    </Button>
                                    {/* <Button type="button" icon="pi pi-download " onClick={exportPdf} className="p-button-success btn-lg float-right advicesBTN" style={{ boxShadow: "rgb(104 159 56) 0.1rem 0.1rem 2rem", width: "3.2rem", height: "3.2rem" }} data-pr-tooltip="PDF" ></Button> */}
                                    <Button type="button" icon="pi pi-download " onClick={(e:any) => {csvDataMaker(e)}} className="p-button-success btn-lg float-right advicesBTN" style={{ boxShadow: "rgb(104 159 56) 0.1rem 0.1rem 2rem", width: "3.2rem", height: "3.2rem" }} data-pr-tooltip="PDF" ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
                : ""}


        </div>
    );

    const onColReorder = (item: any) => {

        let colreordered: any = []
        item.map((col: any) => {
            let colOptions1 = {
                "field": col.props.field, "header": col.props.header, isChecked: true
            }

            colreordered.push(colOptions1)
        });
        setReorder(!reorder)
        if (type === "options-stock" || type === "options-index") {
            window.localStorage.setItem("optionColReorder", JSON.stringify(colreordered))
        }
        else if (type === "all") {
            window.localStorage.setItem("LiveColReorder", JSON.stringify(colreordered))
        }
        else {
            window.localStorage.setItem("ColReorder", JSON.stringify(colreordered))
        }

    }

    const onRowReorder = (e: any) => {

        if (openTradeValue) {
            setOpenTradeAdvices(e.value)
        }
        else {
            setAdvices(e.value);
        }
        window.localStorage.removeItem("StorageRow");
        window.localStorage.setItem("StorageRow", JSON.stringify(e.value));

    }

    const ValueChecking = () => {
        let objrow: any = window.localStorage.getItem("StorageRow");
        let parsedObjRow: any
        if (objrow != null) {
            parsedObjRow = JSON.parse(objrow)
            let difference: any = [];
            let advices_val: any = [...advices]
            let find: any = false;
            let container: any = [];

            parsedObjRow = JSON.parse(objrow)
            for (let p_obj = 0; p_obj < parsedObjRow.length; p_obj++) {
                for (let adv = 0; adv < advices_val.length; adv++) {
                    find = ((parsedObjRow[p_obj]?.asset === advices_val[adv]?.asset) && (parsedObjRow[p_obj]?.identifier === advices_val[adv]?.identifier) && (parsedObjRow[p_obj]?.instrument === advices_val[adv]?.instrument) && (parsedObjRow[p_obj]?.trade_type === advices_val[adv]?.trade_type) && (parsedObjRow[p_obj]?.open_date === advices_val[adv]?.open_date) && (parsedObjRow[p_obj]?.uuid === advices_val[adv]?.uuid))
                    if (find === true) {
                        container?.push(advices_val[adv]);
                        find = false;
                        break;
                    }
                }
            }

            const getDifference = (array1: any, array2: any) => {
                return array1.filter((object1: any) => {
                    return !array2.some((object2: any) => {
                        return (object1?.asset === object2?.asset && object1?.identifier === object2?.identifier && object1?.instrument === object2?.instrument && object1?.trade_type === object2?.trade_type && object1?.open_date === object2?.open_date && object1?.uuid === object2?.uuid);
                    });
                });
            }
            difference = getDifference(advices_val, container);

            if (difference.length !== 0) {
                const result = [...container, ...difference];
                setTotalTrades(result);
            }
            else {
                setTotalTrades(container);
            }
        }
        else {
            setTotalTrades(advices)
        }
    }

    useEffect(() => {
        if (type == 'all') {
            ValueChecking()
        }
    }, [advices, openTradeValue])

    const columnComponents = selectedColumns?.map((col: any) => {
        if (type === "options-stock" || type === "options-index" || type === "fx-option") 
        {
            if (col.field === "asset" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '13rem',textAlign: 'left' }} body={(e) => handleAsset(e)} />;
            }
            else if (col.field === "identifier" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '18rem', textAlign: 'center' }} />;
            }
            else if (col.field === "tradetype" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '13rem', textAlign: 'center' }} />;
            }
            else if (col.field === "strike_price_ce" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '13rem', textAlign: "center" }} body={(e) => (e.strike_price_ce)} />;
            }
            else if (col.field === "strike_price_pe" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '13rem', textAlign: "center" }} body={(e) => (e.strike_price_pe)} />;
            }
            else if (col.field === "transaction" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '18rem', textAlign: "center" }} body={(e) => handlevalue(e.identifier, e.transaction, 'not_pl')} />;
            }
            else if (col.field === "target" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '10rem' }} body={(e) => handlevalue(e.identifier, e.target, 'not_pl')} />;
            }
            else if (col.field === "stoploss" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '10rem' }} body={(e) => handlevalue(e.identifier, e.stoploss, 'not_pl')} />;
            }
            else if (col.field === "exit_price" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '12rem', textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e.exit_price, 'not_pl')} />;
            }
            else if (col.field === "trade_pl" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem', textAlign: "center" }} body={(e) => handlevalue(e.identifier, e.trade_pl, 'pl')} />;
            }
            else if (col.field === "trade_pl_rs" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem', textAlign: "center" }} body={(e) => handlevalue(e.identifier, e.trade_pl_rs, 'pl')} />;
            }
            else if (col.field === "open_date" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem' }} body={(e) => handleDate(e.open_date)} />;
            }
            else if (col.field === "trade_close_date" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem' }} body={(e) => handleCloseDate(e.trade_close_date)} />;
            }
            else if (col.field === "expiry_date" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem' }} body={(e) => handleDate(e.expiry_date)} />;
            }
            else if (col.field === "close_reason" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '20rem' }} body={(e) => handleReason(e.close_reason)} />;
            }
        }
        else if (type === 'all') {
            if (col.field === "asset" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '13rem' , textAlign: 'left'}} body={(e) => handleAsset(e)} />;
            }
            else if (col.field === "identifier" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '18rem', textAlign: 'center' }} />;
            }
            else if (col.field === "trade_type" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '12rem', textAlign: 'center'  }} />;
            }
            else if (col.field === "n_open_transaction_price" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '18rem', textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e.n_open_transaction_price, 'not_pl')} />;
            }
            else if (col.field === "exit_price" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '12rem', textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e.exit_price, 'not_pl')} />;
            }
            else if (col.field === "trade_pl" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem', textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e.trade_pl, 'pl')} />;
            }
            else if (col.field === "trade_pl_rs" && col.isChecked === true &&(advicesStatusType === 'derivative' || advicesStatusType === 'hft')) { 
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem', textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e.trade_pl_rs, 'pl')} />;
            }
            else if (col.field === "return_per" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize",minWidth: '15rem', textAlign: 'center' }} body={(e) => <div style={{height: '2.2rem',border: 'none',fontSize: '1.45rem',color:`${handlehovervalue(e.return_per)}`}} className='hovertextdiv hoverd' data-hover={e.return_per != undefined ? e.return_per.toFixed(4):""} >{handlevalue(e.identifier, e.return_per, 'return_per')} </div>}/>;
            }
            else if (col.field === "return_after_cost" && col.isChecked === true ) {
                if(minChargeDate < (moment(selectedDate).format('YYYY-MM-DD'))){
                    return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ minWidth: '27rem', textAlign: 'center' }} body={(e) => <div style={{height: '2.2rem',border: 'none',fontSize: '1.45rem',color:`${handlehovervalue(e.return_after_cost)}`}} className='hovertextdiv hoverdiv' data-hover={e.return_after_cost != undefined ? e.return_after_cost.toFixed(4):""} >{handlevalue(e.identifier, e.return_after_cost, 'return_per')} </div>}/>;
                }
                else{
                    return <Column  key={col.field}  columnKey={col.field} field={col.field} header={col.header} style={{  minWidth: '27rem', textAlign: 'center' }} body={(e) => 'Not Available'} />;
                }
            }
            else if (col.field === "open_date" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem', textAlign: 'center' }} body={(e) => handleDate(e.open_date)} />;
            }
            else if (col.field === "trade_close_date" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem', textAlign: 'center' }} body={(e) => handleCloseDate(e.trade_close_date)} />;
            }
            else if (col.field === "pl_without_dividend" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ minWidth: '27rem', textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e.pl_without_dividend, 'pl')} />;
            }
            else if (col.field === "oneDaypl" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ minWidth: '18rem', textAlign: "center" }} body={(e) => handlevalue(e.identifier, e.oneDaypl, 'pl')} />;
            }
            else if (col.field === "oneDay_return_per" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ minWidth: '18rem', textAlign: "center" }} body={(e) => <div style={{height: '2.2rem',border: 'none',fontSize: '1.45rem',color:`${handlehovervalue(e.oneDay_return_per)}`}} className='hovertextdiv hoverDiv' data-hover={e.oneDay_return_per != undefined ? e.oneDay_return_per.toFixed(4):""} >{handlevalue(e.identifier, e.oneDay_return_per, 'return_per')} </div>}/>;
                
            }
            else if (col.field === "s_reason" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '18rem', textAlign: 'center'  }} body={(e) => handleReason(e.s_reason)} />;
            }
            else if (col.field === "rr" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem', textAlign: "center" }} />;
            }
        }
        else {
            if (col.field === "asset" && col.isChecked === true) {
                return <Column  key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem' , textAlign: 'left'  }} body={(e) => handleAsset(e)} />;
            }
            else if (col.field === "identifier" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '18rem', textAlign: 'center'}} />;
            }
            else if (col.field === "tradetype" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '13rem', textAlign: 'center'}} />;
            }
            else if (col.field === "transaction" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '18rem', textAlign: 'center'}} body={(e) => handlevalue(e.identifier, e.transaction, 'not_pl')} />;
            }
            else if (col.field === "target" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '10rem' , textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e.target, 'not_pl')} />;
            }
            else if (col.field === "stoploss" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '10rem' , textAlign: 'center'  }} body={(e) => handlevalue(e.identifier, e.stoploss, 'not_pl')} />;
            }
            else if (col.field === "exit_price" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '12rem', textAlign: 'center' }} body={(e) => handlevalue(e.identifier, e.exit_price, 'not_pl')} />;
            }
            else if (col.field === "trade_pl" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem', textAlign: "center" }} body={(e) => handlevalue(e.identifier, e.trade_pl, 'pl')} />;
            }
            else if (col.field === "trade_pl_rs" &&type != "multiday-equity" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem', textAlign: "center" }} body={(e) => handlevalue(e.identifier, e.trade_pl_rs, 'pl')} />;
            }
            else if (col.field === "pl_without_dividend" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ minWidth: '27rem', textAlign: "center" }} body={(e) => handlevalue(e.identifier, e.pl_without_dividend, 'pl')} />;
            }
            else if (col.field === "open_date" && col.isChecked === true) {
                return <Column sortable key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem' , textAlign: 'center' }} body={(e) => handleDate(e.open_date)} />;
            }
            else if (col.field === "trade_close_date" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '15rem' , textAlign: 'center'  }} body={(e) => handleCloseDate(e.trade_close_date)} />;
            }
            else if (col.field === "close_reason" && col.isChecked === true) {
                return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={{ textTransform: "capitalize", minWidth: '20rem', textAlign: 'center'  }} body={(e) => handleReason(e.close_reason)} />;
            }
        }
    });

    return <div className="advice-card datatable-selection-demo">
        <div className="card" >
            {loading_adv === true ? <div className="Strategy_tracker_spinner-container" >
                <BeatLoader color="#dc9948" loading={loading_adv} />
            </div> :
                <div >
                    {type === "options-stock" || type === "options-index" || type === "fx-option" ?
                        <DataTable value={advices} reorderableColumns header={header} onColReorder={(e: any) => { onColReorder(e.columns) }} id="selected_row" selectionMode="single" stripedRows scrollable scrollHeight="37rem" style={{ margin: "1rem"}}
                            rowClassName={OpenTraderow} className="advice_row"  reorderableRows selection={selectedAdviceStatus == 0 ? advices[0] : selectedAdviceStatus} onSelectionChange={(e: any) => { {e.value.length > 0 ? setSelectedAdviceStatus(e.value[0]):setSelectedAdviceStatus(e.value) }; selectedRow();}} >
                            {columnComponents}
                        </DataTable>
                        : type === 'all' ?
                        
                        <div className="liveoverallAdviceDiv">
                                {openTradeValue == true ?
                                    <DataTable value={openTradeAdvices} reorderableColumns  header={header} onColReorder={(e: any) => { onColReorder(e.columns) }} id="selected_row" selectionMode="single" stripedRows scrollable scrollHeight="37rem" style={{ margin: "1rem" }}
                                    rowClassName={OpenTraderow} reorderableRows className="advice_row" onRowReorder={(e: any) => { onRowReorder(e) }} selection={selectedAdviceStatus == 0 ? advices[0] : selectedAdviceStatus} onSelectionChange={(e: any) => { {e.value.length > 0 ? setSelectedAdviceStatus(e.value[0]):setSelectedAdviceStatus(e.value) }; selectedRow();}} >
                                        <Column rowReorder style={{ width: "3em"}} />
                                        {columnComponents}
                                    </DataTable>
                                    :
                                    <DataTable value={totalTrades} reorderableRows reorderableColumns header={header} onColReorder={(e: any) => { onColReorder(e.columns) }} id="selected_row" selectionMode="single" stripedRows scrollable scrollHeight="37rem" style={{ margin: "1rem" }}
                                        rowClassName={OpenTraderow}  className="advice_row"  onRowReorder={(e: any) => onRowReorder(e) } selection={selectedAdviceStatus == 0 ? advices[0] : selectedAdviceStatus} onSelectionChange={(e: any) => { {e.value.length > 0 ? setSelectedAdviceStatus(e.value[0]):setSelectedAdviceStatus(e.value) }; selectedRow();}} >
                                        <Column rowReorder style={{ width: "3em"}} />
                                        {columnComponents}
                                    </DataTable>}
                            </div>
                            :
                            <DataTable value={advices} reorderableColumns header={header} onColReorder={(e: any) => onColReorder(e.columns) } id="selected_row" selectionMode="single" stripedRows scrollable scrollHeight="37rem" style={{ margin: "1rem" }}
                                rowClassName={OpenTraderow} reorderableRows className="advice_row" selection={selectedAdviceStatus == 0 ? advices[0] : selectedAdviceStatus} onSelectionChange={(e: any) => { {e.value.length > 0 ? setSelectedAdviceStatus(e.value[0]):setSelectedAdviceStatus(e.value) }; selectedRow();}}>
                                {columnComponents}
                            </DataTable>
                    }
                </div>}
        </div>

    </div>
}


        