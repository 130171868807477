
interface PLStatusCardProps {
    name: any,
    timePeriod: string,
    profitPercentage: string,
    profitAmount: any,
    plInPts:any
    selected: boolean, 
    handleAssetClick: any
    version:any
    asset_key_value:any
    type:any
    selectedCard:any
    identifier:any
}
function greenColorShadeRgbByPercentage(percent: number) {
    const lightest = 158;
    let redAndBlueCode = Math.round(158 - ((158 * Math.abs(percent)) / 100));
    return redAndBlueCode + ", 255, " + redAndBlueCode; 
}

function redColorShadeRgbByPercentage(percent: number) {
    const lightest = 158;
    let greenAndBlueCode = Math.round(158 - ((158 * Math.abs(percent)) / 100));
    return "255, " + greenAndBlueCode + ", " + greenAndBlueCode; 
}
export const PLStatusCard: React.FC<PLStatusCardProps> = ({name, timePeriod, profitPercentage, profitAmount,plInPts, selected,identifier, handleAssetClick,version,asset_key_value,type,selectedCard}) => {

    let assetname:any;
    if(type === "multiday-equity"){
        assetname = name?.asset_name
    }else{
        assetname = Object.values(asset_key_value)[Object.keys(asset_key_value).indexOf(name?.asset_name)]
    }
        let Assetname1:any = "NIFTY BANK"
        let Assetname2:any = "NIFTY 50"
        function getBackgroundColor(item:any) {
            if(parseFloat(item) < 0) {
                return redColorShadeRgbByPercentage(parseFloat(item));
            } else {
                return greenColorShadeRgbByPercentage(parseFloat(item));
            }
        }
        const algo = {
            fontSize: "1.5rem",
            top:"-2.4rem",
            left:"2.8rem",
            position: "absolute" as "absolute",
            color: '#fdb55d',
            fontFamily: 'Nunito-bold',
            textShadow: '0.5px 0 #000, -0.5px 0 #000, 0 0.5px #000, 0 -0.5px #000, 0.5px 0.5px #000, -0.5px -0.5px #000, 0.5px -0.5px #000, -0.5px 0.5px #000',
        }
        const algo_quant = {
            fontSize: "1.5rem",
            top:"-2.4rem",
            left:"2.8rem",
            position: "absolute" as "absolute",
            color: '#fdb55d',
            fontFamily: 'Nunito-bold',
            textShadow: '0.5px 0 #000, -0.5px 0 #000, 0 0.5px #000, 0 -0.5px #000, 0.5px 0.5px #000, -0.5px -0.5px #000, 0.5px -0.5px #000, -0.5px 0.5px #000',
        }

    return <div  className={`pl-status-card ${selected ? "selected" : ""}`} onClick={() => {handleAssetClick(name,version)}} >
                {type === "options-stock" || type === "options-index" || type === "fx-option"?
                    <div>
                        {type === "options-stock" || type === "options-index" || type === "fx-option"?
                            <span className="algo" style={version == 'AI'?algo:algo_quant}>{assetname == 'all' || assetname == 'ALL' ?'':identifier}</span>:
                            <span className="algo" style={version == 'AI'?algo:algo_quant}>{assetname == 'all' || assetname == 'ALL' ?'':identifier}</span>}
                            <div style={{fontWeight:"bold", textAlign:"center"}}>
                            {assetname == "BANKNIFTY-I.NFO"? Assetname1:assetname == "NIFTY-I.NFO"? Assetname2:version == 's_opt_strangle' && assetname != 'ALL' ? assetname:assetname}
                            </div>
                            <div >{timePeriod}</div>
                            <div className="stheatmaphover" data-hover={name?.asset_name == "ALL" || name?.asset_name == "all" ?'      ':plInPts} style={{fontWeight:"bold", textAlign:"center"}}>{name?.asset_name == "ALL" || name?.asset_name == "all" ?'      ':profitAmount}</div>
                            <p style={{borderRadius:"0.3rem" ,textAlign:"center",padding:"0rem 0.2rem",backgroundColor: "rgb(" + getBackgroundColor(profitPercentage) + ")"}}>
                            {type != 'all' || type != 'ALL'? profitPercentage && parseFloat(profitPercentage).toFixed(2) + "%":''}</p>
                    </div>:
                    <div>
                        <span className="algo" style={version == 'AI'?algo:algo_quant}>{assetname == 'all' || assetname == 'ALL' ?'':identifier}</span>
                        <div style={{fontWeight:"bold", textAlign:"center"}}>
                            {assetname == "BANKNIFTY-I.NFO"? Assetname1:assetname == "NIFTY-I.NFO"? Assetname2:assetname}
                        </div>
                        <div >{timePeriod}</div>
                        {type != "multiday-equity" ?<div className="stheatmaphover" data-hover={name?.asset_name == "ALL" || name?.asset_name == "all" ?' ':plInPts} style={{fontWeight:"bold", textAlign:"center"}}>{name?.asset_name == "ALL" || name?.asset_name == "all" ?' ':profitAmount}</div>:
                        <div style={{fontWeight:"bold", textAlign:"center"}}>{name?.asset_name == "ALL" || name?.asset_name == "all" ?' ':profitAmount}</div>}
                        <p style={{borderRadius:"0.3rem" ,textAlign:"center",padding:"0rem 0.2rem",backgroundColor: "rgb(" + getBackgroundColor(profitPercentage) + ")"}}>
                        {type != 'all' || type != 'ALL'? profitPercentage && parseFloat(profitPercentage).toFixed(2) + "%":''}</p>
                </div>}
            </div>
}