import './MediumNewsItemType5.css';
import { News } from '../../../../Models/News';
import { add3Dots, getColorBySentiment, parseDateInDisplayFormat2 } from '../../../../util/utils';
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { frontendRoutes } from '../../../../constants/frontendRoutes';

interface MediumNewsItemType5Props {
    article: News
}

export const MediumNewsItemType5: React.FC<MediumNewsItemType5Props> = ({article}) => {  
    let summary = ''
    if(article.summary) summary = article.summary
    return (
        <div className="medidum-type5-item">
            <div className="single_post " style={{width: "100%"}}>
                <div className="post_img gradient1">
                    <img src={article.imageLink} width="" height="440"/> 
                </div>
                <div className="single_post_text">
                    <div className="meta5">
                        <a href={"/news/keyword/"+article.category+"/category"} target="_blank" rel="noopener noreferrer">{article.category}</a>
                        <a href="#" className="meta-date">{parseDateInDisplayFormat2(article.publishedDate)}</a>
                        <a href="#" className="meta-date">{article.publisher}</a>
                        
                    </div>
                    <h4 style={{marginTop:4}}>
                        <a target="_blank" rel="noopener noreferrer" href={frontendRoutes.newsHome + article.id}>
                            <FontAwesomeIcon icon={faCircle} style={{color: getColorBySentiment(article.sentiment), 
                                margin: "0px 10px 0px 0px"}}></FontAwesomeIcon>
                            {add3Dots(article.title, 75)}
                        </a>
                    </h4>
                    
                </div>
            </div>
            
        </div>
    )
}