import './SmallNewsItemType6.css';
import React from 'react';
import { News } from '../../../../Models/News';
import { add3Dots, getColorBySentiment, parseDateInDisplayFormat2 } from '../../../../util/utils';
import { frontendRoutes } from '../../../../constants/frontendRoutes';

interface SmallNewsItemType6Props {
    article: News 
}

export const SmallNewsItemType6: React.FC<SmallNewsItemType6Props> = ({article}) => {

    return (
        <>
            <div className="small-thumbnail-type6 single_post widgets_small">
                <div className="post_img">
                    <div className="img_wrap" style={{border: `4px solid ${getColorBySentiment(article.sentiment)}`}}>
                        <a href={frontendRoutes.newsHome + article.id}>
                            <img src={article.imageLink}/>
                        </a>
                    </div>
                </div>
                <div className="single_post_text">
                    <div className="meta2 meta_separator1">
                        <a href={"/news/keyword/"+article.category+"/category"} target="_blank" rel="noopener noreferrer">{article.category}</a>
                        <a href="#">{parseDateInDisplayFormat2(article.publishedDate)}</a>
                    </div>
                    <h4 style={{marginTop: "4px"}}>
                        <a target="_blank" rel="noopener noreferrer" href={frontendRoutes.newsHome + article.id}>{add3Dots(article.title, 58)}</a> 
                    </h4>
                </div>
                <div className="publisher-area">
                    {/* <img src={article.logoUrl} /> */}
                    <p >{article.publisher}</p>
                </div>
            </div>
            {/* <div className="sentiment-color" style={{borderRight: `5px dotted ${barColor}`}}></div> */}
        </>
    )
}