import React, { useEffect, useState } from 'react';
import Card from "react-bootstrap/Card";
import { AdviceStatus } from '../../components/PortalHome/StrategyTracker/AdviceStatus';
import { PLCarousel } from '../../components/PortalHome/StrategyTracker/PLCarousel';
import { ChartsCard } from '../../components/PortalHome/StrategyTracker/ChartsCard';
import { Col, Modal, Row } from 'react-bootstrap';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter ,faArrowAltCircleRight,  faCircle} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'primeicons/primeicons.css';
import { APP_VERSION, getColorAccordingWithPl, getColorAccordingWithPlDiv, productTypePair } from '../../util/utils';
import Calendar from 'react-calendar';
import ParameterCard from '../../components/PortalHome/StrategyTracker/ParameterCard/ParameterCard';
import { useInView } from "react-intersection-observer";
import { RightSidebar } from '../../components/PortalHome/TopBar/RightSideBar';
import { getNewsBySectorForTopbar } from '../../Api/News/news';
import { NewsSentimentChart } from '../../components/PortalHome/StrategyTracker/ChartsCard/NewsSentimentChart';
import { setItemToStorage } from '../../Api/Service/auth.service';
import BeatLoader from "react-spinners/BeatLoader"

function MyVerticallyCenteredModal(props: any) {
    return (
        <Modal
            className='modelCal'
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body >
                <div style={{ borderRadius: '0.5rem' }} >
                    <Calendar onChange={props.selectDate} value={props.selectedDate}
                        maxDate={new Date()} prev2Label={null}
                        next2Label={null}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}


interface StrategyTrackerLayoutProps {
    type: any;
    handleAssetClick: any,
    selectedAsset: any,
    assets: any,
    advices: any,
    handleAdviceClick: any,
    selectedAdvice: number,
    parameters: any,
    noOfCalls: any,
    pL: any;
    successRatio: any
    targetSlChartData: any;
    mtmDrawdownChartData: any;
    allTweets: any
    setVersion: any
    version: any
    asset_key_value: any
    currentTime: any
    setDropdownVersion: any
    revisedDatalist: any
    setSelectedAdviceStatus: any
    selectedAdviceStatus: any
    setTradeType: any
    theme: any
    handleRefresh: any
    setAdvices: any
    loading_tarsl: any
    loading_param: any
    loading_mtm: any
    clicked: any
    calenderPickerDate: any
    setCalenderPickerDate: any
    backupDate: any
    returnPercentage: any
    trade_type: any
    setSelectedcode: any
    versionsDataLive: any
    sentimentScore: any
    selectedTrade: any
    advicesStatusType: any
    setAdvicesStatusType: any
    setLoading_adv: any
    loading_adv: any
    disableRefreshButton: any
    alltradeDetails: any
    alltradeDetailsWithCost:any
    allReturnper: any
    allHeader: any
    setAllHeader: any
    mainAdvices: any
    callsCount: any
    selectedTradeDetails: any
    viewCharts: any
    setViewCharts: any
    rwCost:any
    setRwCost:any
    minChargeDate:any
    releaseNoteShow:any
    setReleaseNoteShow:any
    releaseDetails:any
    newsPremarketSentiment:any
    accuracy:any
    newsSignals:any
    niftyData:any
    returnPerc21:any
    returnPerc252:any
    loadR21:any
    loadR252:any
    loadReturn:any
    privileges:any
    setTodaysLiveIdentifiers:any
    loadCalls:any
    loadSr:any
    loadPl:any
    news21acc:any
    news252acc:any
    eco21acc:any
    eco252acc:any
    jSentimentLength:any
    totalPL:any
    setSentimentType:any
    sentimentType:any
    
}

export const StrategyTrackerLayout: React.FC<StrategyTrackerLayoutProps> = ({ type, handleAssetClick, selectedAsset, assets,
    advices, handleAdviceClick, selectedAdvice, parameters, noOfCalls, pL, successRatio, targetSlChartData, mtmDrawdownChartData, allTweets, 
    setVersion, version, asset_key_value,
    currentTime, setDropdownVersion, revisedDatalist, setSelectedAdviceStatus, selectedAdviceStatus, setTradeType,
    trade_type, theme, handleRefresh, setAdvices, loading_tarsl, loading_param, loading_mtm, clicked,
    calenderPickerDate, setCalenderPickerDate, backupDate, returnPercentage, setSelectedcode, versionsDataLive,  selectedTrade,
    advicesStatusType, setAdvicesStatusType, loading_adv, setLoading_adv, disableRefreshButton, alltradeDetails,alltradeDetailsWithCost, allHeader,
    setAllHeader, mainAdvices, callsCount, selectedTradeDetails,
    viewCharts, setViewCharts,rwCost,setRwCost,minChargeDate, releaseNoteShow,setReleaseNoteShow,releaseDetails, accuracy,newsSignals, 
    returnPerc21,returnPerc252,loadR21,loadR252,loadReturn,privileges,setTodaysLiveIdentifiers,loadCalls,loadSr,
    loadPl, jSentimentLength,totalPL, setSentimentType, sentimentType}) => {

    // const [dropdownData, setDropdownData] = useState<any>('all')
    const [visibleLeft, setVisibleLeft] = useState<any>(false)
    const [selectedDate, setSelectedDate] = useState<any>(new Date(backupDate));
    const [selectedHeader, setSelectedheader] = useState<any>([])
    const [selectClick, setSelectClick] = useState<any>(false);
    const [columnSelection, setColumnSelection] = useState<any>(false);
    const [openTradeAdvices, setOpenTradeAdvices] = useState<any>()
    const [openTradeValue, setOpenTradeValue] = useState<boolean>(false)
    const [totalTrades, setTotalTrades] = useState<any>(advices)
    const [visibleSidebar, setVisibleSidebar] = useState<any>(window.localStorage.getItem('open_Rightsidebar') !== null ? true : false)
    const [newsData, setNewsData] = useState<any>()
    const [selectedRPidentifer, setSelectedRPIdentifiier] = useState<any>(['f&o', 'lft', 'equity'])
    const [multiselectClick, setMultiselectClick] = useState<any>(false)
    const [futureArr, setFutureArr] = useState<any>([])
    const [optionsArr, setOptionsArr] = useState<any>([])
    const [equityArr, setEquityArr] = useState<any>([])
    const [lftArr, setLftArr] = useState<any>([])
    const [retGroupType,setRetGroupType] = useState<any>({"futures":true,"options":true,"lft":true,"equity":true})
    const [releaseNoteChkBox,setReleaseNoteChkBox] = useState<any>(false)


    const handleClose = () => setReleaseNoteShow(false);


    const { ref: myRef, inView: myElementIsVisisble } = useInView();

    const selectDate = (date: any) => {
        setCalenderPickerDate((moment(date).format('YYYY-MM-DD')))
        setSelectedDate(new Date(moment(date).format('YYYY-MM-DD')))
        setVisibleLeft(false)
    }

    document.onclick = function (event: any) {
        if (event.target.closest(".mInstrumentBox") || event.target.closest(".list")) return
        else {
            setSelectClick(false)
        }
        if (event.target.closest(".mHeaderbox") || event.target.closest(".list")) return
        else {
            setColumnSelection(false)
        }
    }





    useEffect(() => {
        window.localStorage.removeItem("SelectableIdentifier");
        window.localStorage.removeItem("unSelectableIdentifier");
        window.localStorage.removeItem("unSelectableIdentifierSpot"); 
        setEquityArr([]);setOptionsArr([]);setFutureArr([]);setLftArr([])
        setRetGroupType({"futures":true,"options":true,"lft":true,"equity":true})
    }, [calenderPickerDate])

    useEffect(() => {
        if (advices[0] !== undefined) {
            if (type === 'all' && selectedAdvice !== 0) {
                setSelectedAdviceStatus(selectedTrade)
            }
        }
    }, [type, advices, selectedAdviceStatus])



    useEffect(() => {
        if (type == "all") {
            let identifierUnSelectedObj: any = window.localStorage.getItem("unSelectableIdentifier");
            let identifierUnSelectedSpotObj: any = window.localStorage.getItem("unSelectableIdentifierSpot");
            let parsedUnSelectedIdentifierObj = JSON.parse(identifierUnSelectedObj);
            let parsedUnSelectedIdentifierSpotObj = JSON.parse(identifierUnSelectedSpotObj);
            let headerCode: any = []

            let uniqueHeader:any = []
            let live_header:any
            privileges.map(async (item: any) => {
                if (item != 'PORTFOLIO'){
                    if (item?.slice(2,3) == 'F'){
                        const isDuplicate = uniqueHeader.includes(item);
                        if (!isDuplicate){
                            live_header = { 'header': item, 'isChecked': true };
                            uniqueHeader.push(item)
                        }
                    }else if (item?.slice(2,3) == 'O'){
                        const isDuplicate = uniqueHeader.includes(`${item}S`);
                        if (!isDuplicate){
                            live_header = { 'header': `${item}S`, 'isChecked': true };
                            uniqueHeader.push(`${item}S`)
                        }
                    }
                    
                    else{
                        live_header = { 'header': item, 'isChecked': true };
                    }
                    headerCode.push(live_header)
                }
        
            })

            if (parsedUnSelectedIdentifierObj != undefined) {
                headerCode.map((item: any) => {
                    parsedUnSelectedIdentifierObj.map((e: any) => {
                        if (item.header === e) {
                            item.isChecked = false;
                        }
                    })
                })
            }
            if (parsedUnSelectedIdentifierSpotObj != undefined) {
                headerCode.map((item: any) => {
                    parsedUnSelectedIdentifierSpotObj.map((e: any) => {
                        if (item.header === e) {
                            item.isChecked = false;
                        }
                    })
                })
            }

            let code = headerCode;

            let unique: any = [];
            let futures_code: any = []
            let options_code: any = []
            let equity_code: any = []
            let lft_code: any = []

           
            const selectedColumns = code.filter((element: any) => {
                const isDuplicate = unique.includes(element.header);
                if (!isDuplicate) {
                    unique.push(element.header);
                    return true;
                }
                return false;
            });
          
            setAllHeader(unique)
            selectedColumns.sort(
                (p1: any, p2: any) =>
                    (p1.header < p2.header) ? -1 : (p1.header > p2.header) ? 1 : 0);

            let identifier_obj: any = []

            selectedColumns.map((item: any) => {
                if (item.header.slice(0, -1) === 'AIOBANDDI') {
                    item['title'] = 'lft'
                    lft_code.push(item.header)
                    setLftArr(lft_code)
                }
                else if ((item.header.slice(1, 3) === 'IF'  || item.header.slice(1, 3) == 'CF' || item.header.slice(1, 3) == 'SF' || item.header.slice(1, 3) == 'FF')) {
                    item['title'] = 'futures'
                    futures_code.push(item.header)
                    setFutureArr(futures_code)
                }
                else if ((item.header.slice(1, 3) === 'IO' || item.header.slice(1, 3) == 'SO' || item.header.slice(1, 3) == 'FO') && item.header.slice(0, -1) !== 'AIOBANDDI') {
                    item['title'] = 'options'
                    options_code.push(item.header)
                    setOptionsArr(options_code)
                }
                else if (item.header.slice(1, 3) === 'ES') {
                    item['title'] = 'equity'
                    equity_code.push(item.header)
                    setEquityArr(equity_code)
                }

                if (item.isChecked === true) {
                    identifier_obj.push(item.header)
                }
            })
            let liveTradesIdentifiers:any = mainAdvices?.map((item:any)=>{
                return (item?.identifier.slice(2,3) == 'O')? item?.identifier: item?.identifier.slice(0,-1)
            })
            let array:any = Array.from(new Set(liveTradesIdentifiers.map((item: any) => item)));
         
            setTodaysLiveIdentifiers(array)

            setSelectedcode(identifier_obj);

            let return_per_headers: any = []

            return_per_headers = selectedColumns.filter((item: any) => {
                return (item?.header)
            })
            setSelectedheader(return_per_headers);
            
        }
    }, [type, mainAdvices])


  
    useEffect(() => {
        if (disableRefreshButton === true) {
            let count = 5;
            const interval = setInterval(() => {
                if (count > 0) {
                    count--;
                } else {
                    clearInterval(interval);
                }
            }, 1000)
        }
    }, [disableRefreshButton])

    useEffect(() => {

        let allIdentifier: any = []
        let identifierUnSelectedObj: any = window.localStorage.getItem("unSelectableIdentifier");
        let identifierUnSelectedSpotObj: any = window.localStorage.getItem("unSelectableIdentifierSpot");
        let parsedUnSelectedIdentifierObj = JSON.parse(identifierUnSelectedObj);
        let parsedUnSelectedIdentifierSpotObj = JSON.parse(identifierUnSelectedSpotObj);

        
        if (parsedUnSelectedIdentifierObj !== null && parsedUnSelectedIdentifierSpotObj !== null) {
            
            allIdentifier = [...parsedUnSelectedIdentifierObj, ...parsedUnSelectedIdentifierSpotObj]

            let selectedIdentifier: any = allHeader?.filter((item: any) => {
                    return !allIdentifier?.includes(item)
            })
            setSelectedcode(selectedIdentifier)
        } 
        else if (parsedUnSelectedIdentifierObj !== null) {
            let selectedIdentifier: any = allHeader?.filter((item: any) => {
                    return !parsedUnSelectedIdentifierObj?.includes(item)
            })
            setSelectedcode(selectedIdentifier)
        } 
        else if (parsedUnSelectedIdentifierSpotObj !== null) {
            let selectedIdentifier: any = allHeader?.filter((item: any) => {
                    return !parsedUnSelectedIdentifierSpotObj?.includes(item)
            })
            setSelectedcode(selectedIdentifier)
        }
        else {
            setSelectedcode(allHeader)
        }

    }, [multiselectClick])


    const handleChange = (e: any) => {
        const { name, checked } = e.target;
       
        if (name === "allSelect") {
            let tempCode = selectedHeader.map((item: any) => {
                return { ...item, isChecked: checked };
            });
            setSelectedheader(tempCode);

            let selected_identifier: any = []
            let unselected_identifier: any = []
            tempCode.filter((item: any) => {
                if (item?.isChecked === true) {
                    selected_identifier.push(item.header)
                }
                else {
                    unselected_identifier.push(item.header)
                }
            })

            setSelectedRPIdentifiier(['f&o', 'lft', 'equity'])
            setRetGroupType({"futures":checked,"options":checked,"lft":checked,"equity":checked})
            setMultiselectClick(!multiselectClick)

            window.localStorage.removeItem("unSelectableIdentifier")
            window.localStorage.setItem("unSelectableIdentifier", JSON.stringify(unselected_identifier))
          
            window.localStorage.removeItem("unSelectableIdentifierSpot")
            window.localStorage.setItem("unSelectableIdentifierSpot", JSON.stringify(unselected_identifier))
          
        }
        else if (name === "equity" || name === "futures" || name === "options" || name === "lft" ) {
                
            let tempCode = selectedHeader.map((item: any) => {
                if(item.title === name){
                    return { ...item, isChecked: !retGroupType[name]};
                }else{
                    return { ...item};
                }
            });
            setSelectedheader(tempCode);
          
            let selected_identifier: any = []
            let unselected_identifier: any = []
            tempCode.filter((item: any) => {
                if (item?.isChecked === true) {
                    selected_identifier.push(item.header)
                }
                else {
                    unselected_identifier.push(item.header)
                }
            })

            if (futureArr.some((item: any) => selected_identifier.includes(item)) && optionsArr.some((item: any) => selected_identifier.includes(item))) {
                setSelectedRPIdentifiier(['f&o', 'lft', 'equity'])
            }
            else if (optionsArr.some((item: any) => !selected_identifier.includes(item)) && futureArr.some((item: any) => selected_identifier.includes(item))) {
                setSelectedRPIdentifiier(['futures', 'lft', 'equity'])
            }
            else if (futureArr.some((item: any) => !selected_identifier.includes(item)) && optionsArr.some((item: any) => selected_identifier.includes(item))) {
                setSelectedRPIdentifiier(['options', 'lft', 'equity'])
            }
            setMultiselectClick(!multiselectClick)
            window.localStorage.removeItem("unSelectableIdentifier")
            window.localStorage.setItem("unSelectableIdentifier", JSON.stringify(unselected_identifier))
            window.localStorage.removeItem("unSelectableIdentifierSpot")
            window.localStorage.setItem("unSelectableIdentifierSpot", JSON.stringify(unselected_identifier))
            let typeselector:any = retGroupType
            typeselector[name] = !retGroupType[name]
            setRetGroupType(typeselector)
        }
        else {
            let tempCode = selectedHeader.map((item: any) => {
                return (item.header === name ? { ...item, isChecked: checked } : item)
            });
            setSelectedheader(tempCode);
            let selected_identifier: any = []
            let unselected_identifier: any = []
            tempCode.filter((item: any) => {
                if (item?.isChecked === true) {
                    selected_identifier.push(item.header)
                }
                else {
                    unselected_identifier.push(item.header)
                }
            })


            let selector:any = retGroupType
            let equityTitle:any = []
            let lftTitle:any = []
            let futureTitle:any = []
            let optionsTitle:any = []

            selected_identifier.filter((item:any)=>{
                if(equityArr.includes(item)){
                    equityTitle.push(item)
                    {equityTitle.length === equityArr.length? selector['equity'] = true : selector['equity'] === false && equityTitle.length === equityArr.length ? selector['equity'] = true : selector['equity']= false}
                    setRetGroupType(selector)
                }
                else if(lftArr.includes(item)){
                    lftTitle.push(item)
                    {lftTitle.length === lftArr.length? selector['lft'] = true :selector['lft'] === false && lftTitle.length === lftArr.length ? selector['lft'] = true : selector['lft'] = false}
                    setRetGroupType(selector)
                }
                else if(futureArr.includes(item)){
                    futureTitle.push(item)
                    {futureTitle.length === futureArr.length? selector['futures'] = true :selector['futures'] === false && futureTitle.length === futureArr.length ? selector['futures'] = true : selector['futures'] = false}
                    setRetGroupType(selector)
                }
                else if(optionsArr.includes(item)){
                    optionsTitle.push(item)
                    {optionsTitle.length === optionsArr.length? selector['options'] = true :selector['options'] === false && optionsTitle.length === optionsArr.length ? selector['options'] = true : selector['options'] = false}
                    setRetGroupType(selector)
                }
            })

            if(lftTitle.length === 0 ){
                selector["lft"] = false
                setRetGroupType(selector)
            }
            if(equityTitle.length === 0){
                selector["equity"] = false
                setRetGroupType(selector)
            }
            if(futureTitle.length === 0){
                selector["futures"] = false
                setRetGroupType(selector)
            }
            if(optionsTitle.length === 0){
                selector["options"] = false
                setRetGroupType(selector)
            }

            if (futureArr.some((item: any) => selected_identifier.includes(item)) && optionsArr.some((item: any) => selected_identifier.includes(item))) {
                setSelectedRPIdentifiier(['f&o', 'lft', 'equity'])
            }
            else if (optionsArr.some((item: any) => !selected_identifier.includes(item)) && futureArr.some((item: any) => selected_identifier.includes(item))) {
                setSelectedRPIdentifiier(['futures', 'lft', 'equity'])
            }
            else if (futureArr.some((item: any) => !selected_identifier.includes(item)) && optionsArr.some((item: any) => selected_identifier.includes(item))) {
                setSelectedRPIdentifiier(['options', 'lft', 'equity'])
            }

            setMultiselectClick(!multiselectClick)
    
            window.localStorage.removeItem("unSelectableIdentifier")
            window.localStorage.setItem("unSelectableIdentifier", JSON.stringify(unselected_identifier))
           
            window.localStorage.removeItem("unSelectableIdentifierSpot")
            window.localStorage.setItem("unSelectableIdentifierSpot", JSON.stringify(unselected_identifier))

        }
    }

  
    const handleLive = () => {
        
        let openTradeAdvicesArray:any = totalTrades.filter((item: any) => {
            if (item?.s_reason == null || item?.s_reason == '') {
                return item;
            }
        })
        setOpenTradeAdvices(openTradeAdvicesArray)
        if(openTradeAdvicesArray?.length === 0){            
            setSelectedAdviceStatus(0)
        }
        else{
            if(openTradeAdvicesArray.includes(selectedAdviceStatus) && (clicked && disableRefreshButton === true) ){
                setSelectedAdviceStatus(selectedAdviceStatus)
            }else if(!openTradeAdvicesArray.includes(selectedAdviceStatus) && (clicked === false && disableRefreshButton === false )){
                setSelectedAdviceStatus(openTradeAdvicesArray[0])
            }else if(disableRefreshButton === true){
                setSelectedAdviceStatus(openTradeAdvicesArray[0])

            }
        } 
    }

    useEffect(() => {
        if (openTradeValue == true) {
            handleLive()
        }
        else {
            setOpenTradeAdvices(advices)
            setSelectedAdviceStatus(selectedTrade)
        }
    }, [openTradeValue, advices, totalTrades])

    useEffect(() => {
        getNewsBySectorForTopbar().then((res) => {
            setNewsData(res)
        })

    }, [])


    const handleReleaseCheck= (event:any) =>{
        setReleaseNoteChkBox(!releaseNoteChkBox)
        setItemToStorage("release_show_count","3")
    }


    const background_Color = () => {
        if (theme == 'dark') {
            return 'rgb(29 6 44 / 96%)'
        }
        else if (theme == 'light') {
            return 'rgb(156 193 227 / 41%)'
        }
        else {
            return '#311616ba'
        }
    }
 

    if((newsSignals!=null) && (newsSignals?.length > 0)){
        let toggleID:any = document.getElementById('switch-rounded')
        if(toggleID !=null){
            if(sentimentType=='updated'){
                toggleID.checked = true;
            }else{
                toggleID.checked = false;
            }
        }
    }

    return <>
        <div className="container-custom strategy-tracker" >
            {/* {myElementIsVisisble === false && type == 'all' ? <span style={{ justifyContent: "end", width: "100%", display: "flex", position: "sticky", top: "9rem", zIndex: 10000 }} onClick={() => setVisibleLeft(true)}>
                <img style={{ margin: "-7.5rem 15rem 0% 0%" }} className='calanderDiv' src="./assets/icon/icons-view-schedule.png" alt="" />
            </span> : ""} */}
             {myElementIsVisisble === false && type == 'all' ? <span style={{ justifyContent: "end", width: "100%", display: "flex", position: "sticky", top: "9rem", zIndex: 10000}}>
                <img style={{ margin: "-7.4rem 2rem 0% 0%" , height :"3.3rem"}} className='calanderDiv' src="./assets/icon/icons-view-schedule.png" alt=""  onClick={() => setVisibleLeft(true)}/>
                {disableRefreshButton === true ? <button  style={{ margin: "-7.5rem 15rem 0% 0%" }} className='desable_refresh_button' disabled ><i className='pi pi-refresh refresh-icon'></i></button>
                : <button style={{ margin: "-7.5rem 15rem 0% 0%" }} className='refresh-button advicesBTN' onClick={(e: any) => handleRefresh(e)}><i className={clicked ? 'rotating pi pi-refresh refresh-icon' : 'pi pi-refresh refresh-icon'} ></i></button>}
            </span> : ""}
            <span style={{ justifyContent: "end", width: "100%", display: "flex", position: "sticky", top: "9rem", zIndex: 10000 }} >
                <div className='sideBar' style={{ margin: "-11.5rem -2rem 0% 0%" }}>
                    <div className='float-button-div'>
                        <button className="float" id="" onClick={() => setVisibleSidebar(true)} >
                            <div className="arrow1">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                        </button>
                        <RightSidebar visibleSidebar={visibleSidebar} setVisibleSidebar={setVisibleSidebar} newsData={newsData} allTweets={allTweets} theme={theme}
                            alltradeDetails={alltradeDetails} alltradeDetailsWithCost={alltradeDetailsWithCost} selectedDate={selectedDate} advicesStatusType={advicesStatusType} 
                            type={type} rwCost={rwCost} setRwCost={setRwCost} minChargeDate = {minChargeDate} />
                    </div>
                </div>
            </span>
            {type === "all" &&<div style={{ height: '5.5rem' }}>
            <div className='disMain'>
                    <span className='disclaimer_title'> Disclaimer:</span>
                    <div className='disclaimerDiv'>
                        {/* //changes have added here 17 Feb 2024  remove disclaimer_text css from the className*/}
                        <p className=''>
                        We are using statistical, artificial intelligence models, etc. which are never error-free. Please apply your own discretions, while using this information.
                        </p>
                    </div>
                </div>
            </div>}
            <div className='strategy-tracker-upper-div' >
            {type === 'all' ?<>
                <div className = 'returnMainDiv'>
                        <div className='calls_card' id='return_main_div' >
                            <div className='row' style={{ height: "100%", width: "100%" }}>
                       <div className='returnPercentage'>
                        <label className='returnLable'>Return (%)</label>
                                    <div className='return252_div texthover' data-hover={returnPerc252?.start_date != undefined && loadR252 == false ? `${moment(new Date(returnPerc252?.start_date)).format("MMM Do, YYYY")} -> ${moment(new Date(returnPerc252?.end_date)).format("MMM Do, YYYY")}`:'Waiting for period'}>
                                        <span className='returnSubTitle' >252 D </span> 
                                        {rwCost === false ?<>
                                            <span style={{ width:'',color: `${getColorAccordingWithPl(returnPerc252?.return_per, theme)}`, marginLeft: "3%",textAlign:'right'}}>
                                                {returnPerc252?.return_per != null ? Number(returnPerc252?.return_per).toFixed(2) : ""}
                                            </span>
                                        </>:
                                        <>
                                            <span style={{ width:'',color: `${getColorAccordingWithPl(returnPerc252?.return_after_cost_perc, theme)}`, marginLeft: "3%",textAlign:'right'}}>
                                                {returnPerc252?.return_after_cost_perc != null ? Number(returnPerc252?.return_after_cost_perc).toFixed(2) : ""}
                                            </span>
                                        </>}
                                       
                                    </div>
                                    <div className='return21_div texthover'  data-hover={returnPerc21?.start_date != undefined && loadR21 === false ? `${moment(new Date(returnPerc21?.start_date)).format("MMM Do, YYYY")} -> ${moment(new Date(returnPerc21?.end_date)).format("MMM Do, YYYY")}`:'Waiting for period'}>
                                        <span className='returnSubTitle' >21 D </span>
                                        {rwCost === false ?<><span style={{ width:'',textAlign:'right',color: `${getColorAccordingWithPl(returnPerc21?.return_per, theme)}`, marginLeft: "3%" }}>
                                            {returnPerc21?.return_per != null ? Number(returnPerc21?.return_per).toFixed(2) : ""}
                                        </span></>:
                                        <>
                                            <span style={{ width:'',textAlign:'right',color: `${getColorAccordingWithPl(returnPerc21?.return_after_cost_perc, theme)}`, marginLeft: "3%" }}>
                                                {returnPerc21?.return_after_cost_perc != null ? Number(returnPerc21?.return_after_cost_perc).toFixed(2) : ""}
                                            </span>
                                        </>}
                                    </div>
                                    <div className='returnToday' >
                                        <span className='returnSubTitle'>{moment(selectedDate).format("MMM Do, YYYY") == moment(new Date()).format("MMM Do, YYYY") ? 'Today' : moment(selectedDate).format("MMM Do, YYYY")} </span> 
                                        {rwCost === false ?<><span style={{width:'',textAlign:'right',color: `${getColorAccordingWithPl(returnPercentage?.return_per, theme)}`, marginLeft: "3%" }}>
                                            {returnPercentage?.return_per != null ? Number(returnPercentage?.return_per).toFixed(2) : ""}
                                        </span></>:
                                        <> 
                                            <span style={{width:'',textAlign:'right',color: `${getColorAccordingWithPl(returnPercentage?.return_after_cost_perc, theme)}`, marginLeft: "3%" }}>
                                                {returnPercentage?.return_after_cost_perc != null ? Number(returnPercentage?.return_after_cost_perc).toFixed(2) : ""}
                                            </span>
                                        </>}
                                    </div>
                            </div> 
                                <div style={{width:"60%", display:"flex", justifyContent:"space-between"}}>  
                                <div className='returnpercentageSubDiv' >
                                        <table>
                                                    <tr>
                                                        <th className='heading'>
                                                        {returnPerc252 != undefined ?
                                                            Object.keys(returnPerc252?.risk_reward[0]).map((item: any) => {
                                                                if (selectedRPidentifer.includes(item)) {
                                                                return<>
                                                                    <td style={{width: '7.5rem'}}> {item.toUpperCase()}</td> 
                                                                </>}}):<>
                                                            <td className='title'> F&O</td>
                                                            <td className='title'> LFT</td>
                                                            <td className='title'> EQUITY</td>
                                                        </>}
                                                        </th>
                                                    </tr>
                                                <tbody >
                                                   
                                                    <tr className='returnRow'>
                                                    {
                                                        loadR252 === false && returnPerc252 != undefined ?
                                                            Object.keys(returnPerc252?.risk_reward[0]).map((item: any) => {
                                                                if (selectedRPidentifer.includes(item)) {
                                                                    return <>
                                                                        {rwCost === false ? <div className='returnClassification' style={{backgroundColor: `${getColorAccordingWithPlDiv(returnPerc252?.risk_reward[0][item], theme)}` }}>
                                                                            <div style={{ display: "flex", flexDirection: "row", minHeight: '2.2rem', height: "auto", color: `${getColorAccordingWithPl(returnPerc252?.risk_reward[0][item], theme)}`, justifyContent: "space-evenly", fontSize: "1.25rem", marginTop: "3%" }}>
                                                                                <span>{returnPerc252?.risk_reward[0][item] != null ? Number(returnPerc252?.risk_reward[0][item]).toFixed(2) : ""}</span> 
                                                                            </div>
                                                                        </div>  :
                                                                            <div className='returnClassification'  style={{ backgroundColor: `${getColorAccordingWithPlDiv(returnPerc252?.return_after_cost[0][item], theme)}` }}>
                                                                                    <div style={{minHeight: '2.2rem', display: "flex", flexDirection: "row", height: "auto", color: `${getColorAccordingWithPl(returnPerc252?.return_after_cost[0][item], theme)}`, justifyContent: "space-evenly", fontSize: "1.25rem", marginTop: "3%" }}>
                                                                                        <span>{returnPerc252?.return_after_cost[0][item] != null ? Number(returnPerc252?.return_after_cost[0][item]).toFixed(2) : ""}</span> 
                                                                            </div>
                                                                            </div>
                                                                                }
                                                                    </>
                                                                }
                                                            })
                                                            :loadR252 === true ? <div style={{height:'0.2rem',width:"100%" }}>
                                                                    <BeatLoader color="#dc9948" size={11} margin={5}/>
                                                            </div>:""
                                                    }
                                                    </tr>
                                                    <tr className='returnRow'>
                                                    {
                                                         loadR21 === false && returnPerc21 != undefined ?
                                                            Object.keys(returnPerc21?.risk_reward[0]).map((item: any) => {
                                                                if (selectedRPidentifer.includes(item)) {
                                                                    return <>
                                                                {rwCost === false ? <div className='returnClassification' style={{backgroundColor: `${getColorAccordingWithPlDiv(returnPerc21?.risk_reward[0][item], theme)}` }}>
                                                                            <div style={{ display: "flex", flexDirection: "row", minHeight: '2.2rem', height: "auto", color: `${getColorAccordingWithPl(returnPerc21?.risk_reward[0][item], theme)}`, justifyContent: "space-evenly", fontSize: "1.25rem", marginTop: "3%" }}>
                                                                                <span>{returnPerc21?.risk_reward[0][item] != null ? Number(returnPerc21?.risk_reward[0][item]).toFixed(2) : ""}</span> 
                                                                            </div>
                                                                        </div>  :
                                                                            <div className='returnClassification'  style={{ backgroundColor: `${getColorAccordingWithPlDiv(returnPerc21?.return_after_cost[0][item], theme)}` }}>
                                                                                    <div style={{minHeight: '2.2rem', display: "flex", flexDirection: "row", height: "auto", color: `${getColorAccordingWithPl(returnPerc21?.return_after_cost[0][item], theme)}`, justifyContent: "space-evenly", fontSize: "1.25rem", marginTop: "3%" }}>
                                                                                        <span>{returnPerc21?.return_after_cost[0][item] != null ? Number(returnPerc21?.return_after_cost[0][item]).toFixed(2) : ""}</span> 
                                                                                    </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            }):loadR21 === true ?  <div style={{height:'0.2rem',width:"100%" }}><BeatLoader color="#dc9948" size={11} margin={5} />
                                                        </div>:""}
                                                    </tr>
                                                    {/* <tr className='returnRow1'>
                                                    {
                                                         loadR7 === false && returnPerc7 != undefined ?
                                                            Object.keys(returnPerc7?.risk_reward[0]).map((item: any) => {
                                                                if (selectedRPidentifer.includes(item)) {
                                                                    return <>
                                                                {rwCost === false ? <div className='returnClassification' style={{backgroundColor: `${getColorAccordingWithPlDiv(returnPerc7?.risk_reward[0][item], theme)}` }}>
                                                                            <div style={{ display: "flex", flexDirection: "row", minHeight: '2.2rem', height: "auto", color: `${getColorAccordingWithPl(returnPerc7?.risk_reward[0][item], theme)}`, justifyContent: "space-evenly", fontSize: "1.25rem", marginTop: "3%" }}>
                                                                                <span>{returnPerc7?.risk_reward[0][item] != null ? Number(returnPerc7?.risk_reward[0][item]).toFixed(2) : ""}</span> 
                                                                            </div>
                                                                        </div>  :
                                                                            <div className='returnClassification'  style={{ backgroundColor: `${getColorAccordingWithPlDiv(returnPerc7?.return_after_cost[0][item], theme)}` }}>
                                                                                    <div style={{minHeight: '2.2rem', display: "flex", flexDirection: "row", height: "auto", color: `${getColorAccordingWithPl(returnPerc7?.return_after_cost[0][item], theme)}`, justifyContent: "space-evenly", fontSize: "1.25rem", marginTop: "3%" }}>
                                                                                        <span>{returnPerc7?.return_after_cost[0][item] != null ? Number(returnPerc7?.return_after_cost[0][item]).toFixed(2) : ""}</span> 
                                                                                    </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            }):loadR7 === true ?  <div style={{height:'0.2rem',width:"100%" }}><BeatLoader color="#dc9948" size={11} margin={5} />
                                                        </div>:""}
                                                    </tr> */}
                                                    <tr className='returnRow1'>
                                                    {
                                                        loadReturn === false && returnPercentage != undefined ?
                                                            Object.keys(returnPercentage?.risk_reward[0]).map((item: any) => {
                                                                if (selectedRPidentifer.includes(item)) {
                                                                    return <>
                                                                    {rwCost === false ? <div className='returnClassification' style={{backgroundColor: `${getColorAccordingWithPlDiv(returnPercentage?.risk_reward[0][item], theme)}` }}>
                                                                            <div style={{ display: "flex", flexDirection: "row", minHeight: '2.2rem', height: "auto", color: `${getColorAccordingWithPl(returnPercentage?.risk_reward[0][item], theme)}`, justifyContent: "space-evenly", fontSize: "1.25rem", marginTop: "3%" }}>
                                                                                <span>{returnPercentage?.risk_reward[0][item] != null ? Number(returnPercentage?.risk_reward[0][item]).toFixed(2) : ""}</span> 
                                                                            </div>
                                                                        </div>  :
                                                                            <div className='returnClassification'  style={{ backgroundColor: `${getColorAccordingWithPlDiv(returnPercentage?.return_after_cost[0][item], theme)}` }}>
                                                                                    <div style={{minHeight: '2.2rem', display: "flex", flexDirection: "row", height: "auto", color: `${getColorAccordingWithPl(returnPercentage?.return_after_cost[0][item], theme)}`, justifyContent: "space-evenly", fontSize: "1.25rem", marginTop: "3%" }}>
                                                                                        <span>{returnPercentage?.return_after_cost[0][item] != null ? Number(returnPercentage?.return_after_cost[0][item]).toFixed(2) : ""}</span> 
                                                                            </div>
                                                                            </div>
                                                                                }
                                                                    </>
                                                                }
                                                            })
                                                            : loadReturn === true ?<div style={{height:'0.2rem',width:"100%" }}>
                                                            <BeatLoader color="#dc9948" size={11} margin={5}/>
                                                    </div>:""
                                        }
                                                    </tr>
                                                </tbody>
                                    </table>
                                    </div>
                                <div className="selectdiv" id="selectdiv" >
                                    <span className='mInstrumentBox advicesBTN' onClick={() => { setSelectClick(!selectClick) }}><FontAwesomeIcon icon={faFilter} style={{ width: "2rem", height: "2rem",cursor:"pointer" }} /></span>
                                    {selectClick == true ? <div className='list' >
                                            <form >
                                                <div className='form-check'>
                                                    <input className='form-mcheckbox' type='checkbox' name="allSelect" checked={selectedHeader.filter((item: any) => item?.isChecked !== true).length < 1} onChange={handleChange} />
                                                    <label className='form-value' htmlFor="">All</label>
                                                </div>
                                                <div> {futureArr.length !== 0 ? <><label className='form_label subtitles' >
                                                <input className='form-input' style={{marginRight: '1rem'}}  type='checkbox' name="futures" checked={retGroupType['futures']} onChange={handleChange}/>
                                                Futures</label>   
                                                    {selectedHeader.map((item: any) => (
                                                        <div>
                                                            {item.title === 'futures' ?
                                                                <div className='form-check' style={{ marginLeft: '20px', fontSize: '1.3rem' }}>
                                                                    <input className='form-input'  type='checkbox' name={item.header} checked={item?.isChecked || false} onChange={handleChange} />
                                                                    <label className='form-value' htmlFor={item.title}>{item.header}</label>
                                                                </div> : ''}
                                                        </div>))}</>: ""}
                                                </div>
                                                <div>{optionsArr.length !== 0 ? <><label className='form_label subtitles'>
                                                <input className='form-input' style={{marginRight: '1rem'}} type='checkbox' name="options" checked={retGroupType['options']} onChange={handleChange}/>
                                                    Options</label>
                                                    {selectedHeader.map((item: any) => (
                                                        <div>
                                                            {item.title === 'options' ?
                                                                <div className='form-check' style={{ marginLeft: '20px', fontSize: '1.3rem' }}>
                                                                    <input className='form-input'  type='checkbox' name={item.header} checked={item?.isChecked || false} onChange={handleChange} />
                                                                    <label className='form-value' htmlFor={item.title}>{item.header}</label>
                                                                </div> : ''}
                                                        </div>))}</>:""}
                                                </div>
                                                <div>{lftArr.length !== 0? <><label className='form_label subtitles' >
                                                <input className='form-input' style={{marginRight: '1rem'}} type='checkbox' name="lft" checked={retGroupType['lft']} onChange={handleChange}/>
                                                    LFT</label>
                                                    {selectedHeader.map((item: any) => (
                                                        <div>
                                                            {item.title === 'lft' ?
                                                                <div className='form-check' style={{ marginLeft: '20px', fontSize: '1.3rem' }}>
                                                                    <input className='form-input' type='checkbox' name={item.header} checked={item?.isChecked || false} onChange={handleChange} />
                                                                    <label className='form-value' htmlFor={item.header}>{item.header}</label>
                                                                </div> : ''
                                                            }
                                                        </div>))}</>:""}
                                                </div>
                                                <div>{equityArr.length !== 0 ? <>
                                                <label className='form_label subtitles' >
                                                <input className='form-input' style={{marginRight: '1rem'}} type='checkbox' name="equity" checked={retGroupType['equity']} onChange={handleChange}/>
                                                    Equity</label>
                                                        {selectedHeader.map((item: any) => (
                                                            <div>
                                                                {item.title === 'equity' ?
                                                                    <div className='form-check' style={{ marginLeft: '20px', fontSize: '1.3rem' }}>
                                                                        <input className='form-input' type='checkbox' name={item.header} checked={item?.isChecked || false} onChange={handleChange} />
                                                                        <label className='form-value' htmlFor={item.header}>{item.header}</label>
                                                                    </div> : ''}
                                                            </div> ))}</>:""}
                                                </div>
                                        </form>
                                    </div> : ""}
                                </div>
                            </div>

                            </div>
                            { (moment(selectedDate).format('YYYY-MM-DD')) > minChargeDate? <div className='inclusive'>
                               <input  checked={rwCost} className='inclText' onChange={()=>setRwCost(!rwCost)}  type='checkbox'/> Inclusive of cost
                            </div> :<div className='withoutInclText'></div>}
                        
                        </div>
                    </div>
                    </>
                    :""}

                    
                <div className='product-type-upper-div' ref={myRef} >{type != 'all' ? <span style={{marginLeft: '2rem'}} className="product-type">{productTypePair(type)}</span>:''}</div>
                <div className='Refresh-button-upper-div'>
                    {type === 'all' ?
                        <div className='Refresh-button-lower-div' ref={myRef}>
                            <span className="RefreshTimeDiv">Last refreshed on {currentTime}</span>
                            {disableRefreshButton === true ?
                                <button className='desable_refresh_button' disabled ><i className='pi pi-refresh refresh-icon'></i></button>
                                : <button className='refresh-button advicesBTN' onClick={(e: any) => handleRefresh(e)}><i className={clicked ? 'rotating pi pi-refresh refresh-icon' : 'pi pi-refresh refresh-icon'} ></i></button>}
                            <div className='calander-upper-div' >
                                {myElementIsVisisble === true ? <span onClick={() => setVisibleLeft(true)}>
                                    <img className='calanderDiv' src="./assets/icon/icons-view-schedule.png" alt="" />
                                </span> : ""}
                                <MyVerticallyCenteredModal calenderPickerDate={calenderPickerDate} selectDate={selectDate} selectedDate={selectedDate} onHide={() => setVisibleLeft(false)} show={visibleLeft} />
                            </div>
                        </div> : ""}
                </div>
            </div>
            {versionsDataLive.length > 1 && <>

                {type == 'fx-future' ? <div className="row" style={{ justifyContent: "flex-end" }}>
                    <div style={{ float: 'right' }} className='select_version'>Select version </div>
                    <div style={{ display: "inline-block" }}>

                        <select name="" id="" className="select_dropdown" style={{ marginTop: "8px" }}
                            onChange={(e) => {
                                setDropdownVersion(e.target.value);
                                setVersion(e.target.value)
                                setSelectedAdviceStatus(0)
                            }}>
                            <option value="all" selected>ALL</option>
                            {versionsDataLive?.map((value: any) => (
                                <option value={value} key={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div> : type === 'intraday-stock-index'  || type ==='intraday-stock-future'? <div className="row" style={{ justifyContent: "flex-end" }}>
                    <div className='select_version'>Select version </div>
                    <div style={{ display: "inline-block" }}>

                        <select name="" id="" className="select_dropdown" style={{ marginTop: "8px" }}
                            onChange={(e) => {
                                setDropdownVersion(e.target.value);
                                setVersion(e.target.value)
                                setSelectedAdviceStatus(0)
                            }}>
                            <option value="all" selected>ALL</option>
                            {versionsDataLive?.map((value: any) => (
                                <option value={value} key={value}>
                                    {value}
                                </option>
                            ))}

                        </select>
                    </div>
                </div> : type === 'multiday-stock-index' ? <div className="row" style={{ justifyContent: "flex-end" }}>
                    <div className='select_version'>Select version </div>
                    <div style={{ display: "inline-block" }}>

                        <select name="" id="" className="select_dropdown" style={{ marginTop: "8px" }}
                            onChange={(e) => {
                                setDropdownVersion(e.target.value);
                                setVersion(e.target.value)
                                setSelectedAdviceStatus(0)
                            }}>
                        </select>
                    </div>
                </div> : type === "options-index" ?
                    <div className="row" style={{ justifyContent: "flex-end" }}>
                        <div className='select_version'>Select version </div>
                        <div style={{ display: "inline-block" }}>

                            <select name="" id="" className="select_dropdown" style={{ marginTop: "8px" }}
                                onChange={(e) => {
                                    setDropdownVersion(e.target.value);
                                    setVersion(e.target.value)
                                    setTradeType("Total")
                                    setSelectedAdviceStatus(0)
                                }}>
                                <option value="all" selected>All</option>
                                {versionsDataLive?.map((value: any) => (
                                    <option value={value} key={value}>
                                        {value}
                                    </option>
                                ))}

                            </select>
                        </div>
                    </div>
                    : type === "options-stock" ?
                        <div className="row" style={{ justifyContent: "flex-end" }}>
                            <div className='select_version'>Select version </div>
                            <div style={{ display: "inline-block" }}>

                                <select name="" id="" className="select_dropdown" style={{ marginTop: "8px" }}
                                    onChange={(e) => {
                                        setDropdownVersion(e.target.value);
                                        setVersion(e.target.value)
                                        setTradeType("Total")
                                        setSelectedAdviceStatus(0)
                                    }}>
                                    <option value="all" selected>All</option>
                                    {versionsDataLive?.map((value: any) => (
                                        <option value={value} key={value}>
                                            {value}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                        : type === "multiday-equity" ?
                            <div className="row" style={{ justifyContent: "flex-end" }}>

                                <div className='select_version'>Select version </div>
                                <div style={{ display: "inline-block" }}>

                                    <select name="" id="" className="select_dropdown" style={{ marginTop: "8px" }}
                                        onChange={(e) => {
                                            setDropdownVersion(e.target.value);
                                            setVersion(e.target.value)
                                            setTradeType("Total")
                                            setSelectedAdviceStatus(0)
                                        }}>
                                        <option value="all" selected>All</option>
                                        {versionsDataLive?.map((value: any) => (
                                            <option value={value} key={value}>
                                                {value}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                            </div> :
                            type === "comodity-future" ?
                                <div className="row" style={{ justifyContent: "flex-end" }}>

                                    <div className='select_version'>Select version </div>
                                    <div style={{ display: "inline-block" }}>

                                        <select name="" id="" className="select_dropdown" style={{ marginTop: "8px" }}
                                            onChange={(e) => {
                                                setDropdownVersion(e.target.value);
                                                setVersion(e.target.value)
                                                setTradeType("Total")
                                                setSelectedAdviceStatus(0)
                                            }}>
                                            <option value="all" selected>All</option>
                                            {versionsDataLive?.map((value: any) => (
                                                <option value={value} key={value}>
                                                    {value}
                                                </option>
                                            ))}

                                        </select>
                                    </div>
                                </div> : ""}
            </>}

            {type != 'all' ? <PLCarousel trade_type={trade_type} selectedCard={selectedAsset} PLStatusData={assets} handleAssetClick={handleAssetClick} type={type} version={version} asset_key_value={asset_key_value} /> : ''}
            <div className="row" style={{ width: "100%", justifyContent: "space-evenly" }}>
                {type != 'all' ? <div className="calls_card col-md-10 col-lg-3 mb-2" id='calls_card2' >
                    
                    {loadCalls === true && (noOfCalls === null || noOfCalls === undefined)?<div className="spinner-container" style={{ height: '4rem',width:"100%",justifyContent:"center",alignItems:"center" }}>
                                        <BeatLoader color="#dc9948" />
                                    </div>:
                    <span>Calls: {noOfCalls != null ? noOfCalls : 0}</span>}
                </div> :
                ""}
                <div className="col-lg-1"></div>
                {type != 'all' ?
                    <div className="calls_card col-md-10 col-lg-3 mb-2" id='calls_card2' >
                    {(successRatio === null || successRatio === undefined) && loadSr === true?<div className="spinner-container" style={{ height: '4rem',width:"100%",justifyContent:"center",alignItems:"center" }}>
                                        <BeatLoader color="#dc9948" />
                                    </div>:
                        <span >Success Ratio: {successRatio != null ? Number(successRatio)?.toFixed(2): 0}{'%'}</span>}
                    </div> :<></>}

                <div className="col-lg-1"></div>
                {type != 'all' ?
                    <div className="calls_card col-md-10 col-lg-3" style={{ width: "100%", padding: "1%", height: "50px" }}>
                        {loadPl === true && (pL === null || pL === undefined)?<div className="spinner-container" style={{ height: '4rem',width:"100%",justifyContent:"center",alignItems:"center" }}>
                                        <BeatLoader color="#dc9948" />
                                    </div>:
                        <span  >P/L: <span style={{ color: `${getColorAccordingWithPl(pL, theme)}` }} >{pL != 0 ? pL >= 0 ? "" : "- " : ''} ₹  {type == 'fx-future-multiday' || type == 'fx-option' || type === 'fx-future' ? Math.abs(Number(pL))?.toFixed(4) : Math.abs(Number(pL))?.toFixed(2)} </span></span>}
                    </div> :
                    <div className='col-lg-3'></div>}
            </div>
            {type === 'all'?<div style={{ height: 10 }}></div>:<div style={{ height: 20 }}></div>}
            
            <Row>
                <Col lg={9} style={{ paddingRight: "0%", minHeight: "15rem", maxHeight:'46rem' }}>
                    <AdviceStatus advices={advices} handleAdviceClick={handleAdviceClick} type={type}
                        version={version} asset_key_value={asset_key_value} setSelectedAdviceStatus={setSelectedAdviceStatus} selectedAdviceStatus={selectedAdviceStatus}
                        theme={theme} setAdvices={setAdvices} selectedDate={selectedDate} setColumnSelection={setColumnSelection} columnSelection={columnSelection}
                        setOpenTradeAdvices={setOpenTradeAdvices} openTradeAdvices={openTradeAdvices} openTradeValue={openTradeValue} setOpenTradeValue={setOpenTradeValue}
                        totalTrades={totalTrades} setTotalTrades={setTotalTrades} advicesStatusType={advicesStatusType} setAdvicesStatusType={setAdvicesStatusType} setLoading_adv={setLoading_adv}
                        loading_adv={loading_adv} callsCount={callsCount} calenderPickerDate={calenderPickerDate} currentTime={currentTime} minChargeDate={minChargeDate}/>
                </Col>
                   
                <Col lg={3} style={{ paddingRight: "0%", minHeight: "15rem" }}>
                    <ParameterCard loading_param={loading_param} type={type} advices={advices} selectedAdviceStatus={selectedAdviceStatus} theme={theme} openTradeAdvices={openTradeAdvices}
                        selectedAdvice={selectedAdvice} parameters={parameters} revisedDatalist={revisedDatalist} advicesStatusType={advicesStatusType}/>
                </Col>
            </Row>

            <div style={{ height: 10 }}></div>
            {(type !== 'all' && viewCharts === false && advices.length > 0 ?
                <>
                <div style={{ height: 10 }}></div>
                    <div className='chart_row' style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor:"pointer"}} onClick={() => { setViewCharts(true) }}>
                        {viewCharts === false ?
                            <div className="view_charts_btn" style={{ width: "17rem", height: "4rem", borderRadius: "0.5rem", color: "white", fontSize: "1.5rem", fontWeight: "bold" }} >
                                <img className="profile_img" src="assets/icon/charts.gif" style={{ height: "4rem", width: "30%", padding: 0, marginLeft: "0.5rem" }}></img>
                                <span className='view_charts' style={{ width: "70%", marginLeft: "1.6rem" }}>View Charts</span>
                            </div> : " "
                        }
                    </div>
                    <div style={{ height: 10 }}></div>
                </>
                : " ")}

            {(type != 'all' && viewCharts === true && advices.length > 0 ? <div className='chart_row'>
                {targetSlChartData != null && mtmDrawdownChartData != null ?
                    <ChartsCard selectedAsset={selectedAsset} targetSlChartData={targetSlChartData} type={type}
                        mtmDrawdownChartData={mtmDrawdownChartData} theme={theme} loading_mtm={loading_mtm} loading_tarsl={loading_tarsl} selectedTradeDetails={selectedTradeDetails}
                        viewCharts={viewCharts} setViewCharts={setViewCharts} />
                    : " "}
            </div> : "")}


            
       

{newsSignals?.length  > 0 && type ==="all"? <Card className="charts-card">
                <div style={{ height: 10 }}></div>
                <div className='chartMainDiv' style={{ height: '73rem' }}>
                {/* <div className='text_title'>Live Sentiments (NIFTY 50 Spot)</div> */}
                <div className='text_title'>
                        <p >Live Sentiments (NIFTY 50 Spot)</p>
                        <div className='toggleDiv'>
                            <span id='sentimentBtnText'>Show reversed signals only</span>
                            <span className="switch">
                                <input id="switch-rounded" type="checkbox" onChange={(e:any)=>{
                                    if (e.target.checked) {
                                        setSentimentType('updated')
                                    } else {
                                        setSentimentType('continuous')
                                    }
                                }}/>
                                <label htmlFor="switch-rounded"></label>
                            </span>
                         </div>
                        
                    </div>

                    <div className='accuracyMainDiv'>
                        {(Object.keys(accuracy).length != 0) && <div className='sentimentScore accuracyTableMain' >
                            <table className="accuracyTable" style={{ backgroundColor: `${background_Color()}` }}>
                                <thead>
                                    <tr className='Trow' style={{ borderBottom: "1px solid rgba(128, 128, 128, 0.51)" }}>
                                        <th className='Thead'>Time (in mins)</th>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>Total Signals</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>3</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>6</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>9</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>12</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>15</td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr className='Trow'>
                                        <th className='Thead'>Accuracy (Only for reversed signals)</th>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{jSentimentLength}</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{accuracy['3']!== null ? (accuracy['3']).toFixed(2) + '%' : ''}</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{accuracy['6']!== null ? (accuracy['6']).toFixed(2) + '%' : ''}</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{accuracy['9']!== null ? (accuracy['9']).toFixed(2) + '%' : ''}</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{accuracy['12']!== null ? (accuracy['12']).toFixed(2) + '%' : ''}</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{accuracy['15']!== null ? (accuracy['15']).toFixed(2) + '%' : ''}</td>
                                    </tr>
                                    {(Object.keys(totalPL).length != 0) &&<tr className='Trow'>
                                        <th className='Thead'>PL</th>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{jSentimentLength}</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{totalPL['3']!== null ? (totalPL['3']).toFixed(2): ''}</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{totalPL['6']!== null ? (totalPL['6']).toFixed(2): ''}</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{totalPL['9']!== null ? (totalPL['9']).toFixed(2): ''}</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{totalPL['12']!== null ? (totalPL['12']).toFixed(2): ''}</td>
                                        <td style={{ borderRight: "1px solid rgba(128, 128, 128, 0.51)" }}>{totalPL['15']!== null ? (totalPL['15']).toFixed(2): ''}</td>
                                    </tr>}
                                </thead>
                            </table>
                        </div>}
                    </div>
                    <div>
                        {newsSignals?.length > 0 ? <NewsSentimentChart theme={theme} newsSignals={newsSignals} /> : <></>}
                    </div>
                </div>
            </Card> : <></>}

            
            <Modal show={releaseNoteShow} className="privacy_policy_modal" onHide={handleClose} style={{ userSelect: "none" }} size="lg"  >
                <Modal.Header closeButton>
                    <Modal.Title style={{ width: "100%", fontWeight: "bolder" }}>
                        <img style={{width:"16%"}} src="assets/icon/vista_logo.png"  />
                        <span style={{fontSize: "2rem",fontWeight: "100"}}>FeedSense - {APP_VERSION} Release Note</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: "96%%", height: "100%", fontFamily: "Nunito",padding:"15px" }}>
                        <div className="privacy-policy-headers"> <FontAwesomeIcon className='font-awesome-header-icon' icon={faArrowAltCircleRight}/> Release Date: {releaseDetails.release_date}</div>
                        <div className="privacy-policy-headers"> <FontAwesomeIcon className='font-awesome-header-icon' icon={faArrowAltCircleRight}/> Added:</div>
                        <div style={{padding:"15px"}}>
                            {releaseDetails?.Added?.map((item:any)=>{
                                return <div style={{textAlign: "left" }}>  <FontAwesomeIcon className='font-awesome-icon' icon={faCircle}/> {item?.s_details}</div>
                            })}
                        </div>
                        <div className="privacy-policy-headers" > <FontAwesomeIcon className='font-awesome-header-icon' icon={faArrowAltCircleRight}/> Improvements:</div>
                        <div style={{padding:"15px"}}>
                            {releaseDetails?.improvements?.map((item:any)=>{
                                return <div style={{textAlign: "left" }}>  <FontAwesomeIcon className='font-awesome-icon' icon={faCircle}/> {item?.s_details}</div>
                            })}
                        </div>
                        <div className="agree-terms" style={{marginTop:"1rem"}}>
                            <input type="checkbox" id="agreeChk" className="agree" checked={releaseNoteChkBox} onChange={(e)=>handleReleaseCheck(e)}/>
                            <span style={{fontWeight:"bold"}}> I read and agree to Vista AI terms of use.</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    </>

}























