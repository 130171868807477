import "./style.css"
import { StopLossChart } from "../ChartsCard/StopLossChart"
import { MTMChart } from "../ChartsCard/MTMChart"
import { DrawdownChart } from "./DrawdownChart"
import { Col, Row } from "react-bootstrap"
import BeatLoader from "react-spinners/BeatLoader"

interface ChartsCardProps {
    type: any,
    selectedAsset: any;
    targetSlChartData: any;
    mtmDrawdownChartData: any
    theme: any
    loading_tarsl: any
    loading_mtm: any
    selectedTradeDetails:any

    viewCharts:any
    setViewCharts:any
}



export const ChartsCard: React.FC<ChartsCardProps> = ({ selectedAsset, type, targetSlChartData, mtmDrawdownChartData, theme, loading_tarsl, loading_mtm ,selectedTradeDetails,viewCharts, setViewCharts}) => {

    
    return <Row style={{marginBottom:"0.5rem"}}>
                {(type !== 'all' ?
                    <>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <div  style={{display:"flex", justifyContent:"center", alignItems:"center", marginRight:"1.5rem",height:"4rem", borderRadius: "0.5rem", color: "white", fontSize: "2.5rem"}} onClick={() => { setViewCharts(false) }}>
                                        <img className="icon_img" src="./assets/icon/close_icon.png" style={{height:"1.5rem", width :"1.5rem", cursor: "pointer"}}/>
                                </div>
                        </div>
                        <div style={{ height: 10 }}></div>
                    </>
                    : " ")}
                {type=='fx-option'?<><Col lg={6}>
                    {loading_mtm ?
                        (type != 'all' ?
                            <div className="spinner-container" style={{display:"flex",alignItems:"center",justifyContent:"center", height: '30rem',width:"100%" }}>
                                <BeatLoader color="#dc9948" />

                            </div>
                            : " ") :
                        mtmDrawdownChartData ? <MTMChart dataset={mtmDrawdownChartData} type={type} theme={theme}  /> : " "}
                </Col>
                <Col lg={6} >
                    {loading_mtm ?
                        (type != 'all' ?
                            <div className="spinner-container" style={{display:"flex",alignItems:"center",justifyContent:"center", height: '30rem',width:"100%" }}>
                                <BeatLoader color="#dc9948" />

                            </div>
                            : " ") :
                        mtmDrawdownChartData ? <DrawdownChart dataset={mtmDrawdownChartData} type={type} theme={theme}  /> : " "}
                </Col> </> :<>
                <Col lg={4}>
                    {loading_tarsl ?
                        (type != 'all' ?
                            <div className="spinner-container" style={{display:"flex",alignItems:"center",justifyContent:"center", height: '30rem',width:"100%" }} >
                                <BeatLoader color="#dc9948" />

                            </div> : " ") :
                        (targetSlChartData ? <StopLossChart dataset={targetSlChartData} type={type} theme={theme} selectedTradeDetails ={selectedTradeDetails} /> : " ")}
                </Col>
                <Col lg={4}>
                    {loading_mtm ?
                        (type != 'all' ?
                            <div className="spinner-container" style={{display:"flex",alignItems:"center",justifyContent:"center", height: '30rem',width:"100%" }}>
                                <BeatLoader color="#dc9948" />

                            </div>
                            : " ") :
                        mtmDrawdownChartData ? <MTMChart dataset={mtmDrawdownChartData} type={type} theme={theme}  /> : " "}
                </Col>
                <Col lg={4} >
                    {loading_mtm ?
                        (type != 'all' ?
                            <div className="spinner-container" style={{display:"flex",alignItems:"center",justifyContent:"center", height: '30rem',width:"100%" }}>
                                <BeatLoader color="#dc9948" />

                            </div>
                            : " ") :
                        mtmDrawdownChartData ? <DrawdownChart dataset={mtmDrawdownChartData} type={type} theme={theme}  /> : " "}
                </Col>
                </>}
            </Row>
}