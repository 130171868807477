import React from 'react'
import Card from "react-bootstrap/Card"
import BeatLoader from "react-spinners/BeatLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import { faHourglassHalf, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

interface ParameterChartProps {
    type: any
    load_hist_param: any
    advices: any
    parameters: any
    selectedAdvice: any
    parameterListChartData: any
    theme:any
}


const ParameterChart: React.FC<ParameterChartProps> = ({ type, load_hist_param, advices, parameters, selectedAdvice, parameterListChartData,theme }) => {

    let arr = [
        { name: 'Risk', value: 12 }, { name: 'Reward', value: 120 }, { name: 'RRR', value: 1.5 }, { name: 'Current Price', value: 16699 }, { name: 'Risk', value: 12 }, { name: 'Reward', value: 120 }, { name: 'RRR', value: 1.5 }, { name: 'Current Price', value: 16699 }
    ]

    const handlevalue = (value: any, valuesType: any) => {

        if (value === '' || value === undefined || value === null) {
            return ''
        }
     
        if (valuesType === 'pl') {
            if (value >= 0) {
                if (theme == 'dark' || theme == 'b/w') {
                    return <span style={{ color: "lightgreen", fontWeight: 'bold' }}>{value.toFixed(2)}</span>
                } else {
                    return <span style={{ color: "darkgreen", fontWeight: 'bold' }}>{value.toFixed(2)}</span>
                }
            }
            else if (value < 0) {

                return <span style={{ color: "orangered", fontWeight: 'bold' }}>{value.toFixed(2)}</span>
            }
            else if (value == '') {
                return <span></span>
            }
            else {
                return <span style={{ color: "whitesmoke", fontWeight: 'bold' }}>{value.toFixed(2)}</span>
            }
        } else {
            return value
        }
    }

    return (
        <Card className="custom-card card2" style={{ height: "100%", width: "100%" }}>
            {load_hist_param ?
                <div className="spinner-container" style={{ height: '100%', width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BeatLoader color="#dc9948" />
                </div> :
                <Card.Body id="parameterCard">
                    <Card.Title style={{ marginTop: "1rem" }}>Parameters</Card.Title>
     
                    <Card.Text style={{ fontSize: '0.9rem', fontFamily: 'Nunito-Regular' }}>
                            {(advices?.length > 0) ? <>
                                <div style={{ fontWeight: 'bold' }}>
                                    <p>{advices[selectedAdvice]?.identifier} </p>
                                    {type === "comodity-future" ? <p><span>Expiry : </span>{parameters[0]?.s_expiry}</p> : ""}
                                </div>

                                <FontAwesomeIcon icon={faHourglassHalf} />
                                <span style={{ fontWeight: 'bold' }}>&nbsp;&nbsp;
                                    {
                                    parameters[0]?.dt_open_time != null ?
                                        <>
                                                {advices[selectedAdvice]?.identifier.slice(8, 9) === 'M' ? (moment(parameters[0]?.dt_open_time)).format('DD \' MMM \YYYY HH:mm'): 
                                                (moment((parameters[0]?.dt_open_time), 'HH:mm:ss').format('HH:mm'))}
                                        </> : ''}


                                    <span style={{ fontSize: '1.2rem' }}> ⇢ </span>
                                    {parameters[0]?.dt_end_time != null ?
                                        <>
                                         {advices[selectedAdvice]?.identifier.slice(8, 9) === 'M' ? (moment(parameters[0]?.dt_end_time)).format('DD \' MMM \YYYY HH:mm'): 
                                                (moment((parameters[0]?.dt_end_time), 'HH:mm:ss').format('HH:mm'))}
                                        </> : 'Trade is open'}
                                </span>
                                <div>
                                    {type === "multiday-equity" ?<>
                                        <span style={{ fontWeight: 900 }} >Total PL : {handlevalue(parameters[0]?.trade_pl,'pl')}</span>
                                        <div>{parameters[0]?.j_dividend_list === undefined || parameters[0]?.j_dividend_list === null ? <span></span> :
                                            <div style={{display: 'flex'}}><span style={{ fontWeight: 900 }}>Dividend : </span> &nbsp;{Object.keys(parameters[0]?.j_dividend_list).map((item: any) => {
                                                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                    <p><span style={{ fontWeight: 900 }}>{moment(item).format('DD\'MMM \YYYY')}</span></p>&nbsp;<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;<p>{handlevalue(parameters[0].j_dividend_list[item],'pl')}</p>
                                                </div>
                                            })}</div>}
                                        </div></> : ""}
                                </div>
                                {type === "options-stock" || type === "options-index" || type === "fx-option" || (type === 'all' && advices[selectedAdvice]?.s_asset_group_trade_type === 'options') ? <>
                                    {parameters[0]?.ce_strike !== null ? (<p><span>CE Strike : </span>{parameters[0]?.ce_strike}</p>) : ''}
                                    {parameters[0]?.pe_strike !== null ? (<p><span>PE Strike : </span>{parameters[0]?.pe_strike}</p>) : ''}
                                    <p><span>Expiry Date : </span>{parameters[0]?.expiry_date}</p></> : ''}
                                    
                                {parameterListChartData?.length > 0 ? <p style={{fontWeight: 900 }}>Target & Stoploss : </p> :""}
                                {parameterListChartData?.length > 0 ? <div  className='parameterScroll'  style={{ fontFamily: 'monospace', maxHeight: "35rem", overflowY: "auto" }}>
                                    {parameterListChartData?.map((element: any, index: any) => {
                                        return <>
                                       
                                            <p>{advices[selectedAdvice]?.identifier.slice(8, 9) === 'M' ?
                                                <><span style={{ fontWeight: 900 }}>{(moment(element?.time).format('DD\'MMM \YYYY HH:mm'))}</span></> :
                                                <><span style={{ fontWeight: 900 }}>{(moment((element?.time), 'HH:mm:ss').format('HH:mm'))}</span></>}
                                                {
                                                        advices[selectedAdvice]?.identifier === 'QFOUSDSBIS' ?<>
                                                        {element?.Target === undefined || element?.Target === null || element?.Target == '' ?
                                                            "" : <span>{', Target(Fut) '}<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;{advices[selectedAdvice]?.identifier?.slice(1, 2) === 'F'?
                                                            Number(element?.Target).toFixed(4):Number(element?.Target).toFixed(2)}</span>}
                                                        {element?.Stoploss_fut === undefined || element?.Stoploss_fut === null || element?.Stoploss_fut === '' ?
                                                            "" : <span>{', Stoploss(Fut) '}<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;{advices[selectedAdvice]?.identifier?.slice(1, 2) === 'F'?
                                                            Number(element?.Stoploss_fut).toFixed(4): Number(element?.Stoploss_fut).toFixed(2)}</span>}
                                                        {element?.Stoploss_opt === undefined || element?.Stoploss_opt === null || element?.Stoploss_opt === '' ?
                                                            "" : <span>{', Stoploss(Opt) '}<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;{advices[selectedAdvice]?.identifier?.slice(1, 2) === 'F'?
                                                            Number(element?.Stoploss_opt).toFixed(4): Number(element?.Stoploss_opt).toFixed(2)}</span>}    
                                                        </>
                                                        :<>
                                                        {element?.Target === undefined || element?.Target === null || element?.Target == '' ?
                                                            "" : <span>{', Target '}<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;{advices[selectedAdvice]?.identifier?.slice(1, 2) === 'F'?
                                                            Number(element?.Target).toFixed(4):Number(element?.Target).toFixed(2)}</span>}
                                                        {element?.Stoploss === undefined || element?.Stoploss === null || element?.Stoploss === '' ?
                                                            "" : <span>{', Stoploss '}<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;{advices[selectedAdvice]?.identifier?.slice(1, 2) === 'F'?
                                                            Number(element?.Stoploss).toFixed(4): Number(element?.Stoploss).toFixed(2)}</span>}</>
                                                }
                                            </p>
                                        </>
                                    })}
                                </div> : ''}</> : ''}
                        </Card.Text>
                </Card.Body>}
        </Card>
    )
}

export default ParameterChart
