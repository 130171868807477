import Card from "react-bootstrap/Card"
import "./style.css"
import React from "react";
import {Chart} from "./Chart"

interface NoOfCallsCardProps {
    calls: any
    theme :any 
    type:any
}

export const NoOfCallsCard: React.FC<NoOfCallsCardProps> = ({calls,theme,type}) => {

    return <Card className="custom-card card2">
                    <div className="total heading" style={{ display:"inline-block" }}>{theme == 'dark' || theme == 'b/w' ?<img style={{  height:"3.2rem",marginRight:"1rem" ,width:"3.2rem", display:"inline-block" }} src="assets/images/calls_dark.png"/> 
                    :theme == 'light' ?  <img style={{  height:"3.2rem",marginRight:"1rem" ,width:"3.2rem", display:"inline-block" }} src="assets/images/calls_light.png"/> : ''}Total Calls: {calls?.total}</div>

                    {type !== "multiday-equity"?
                        <>
                        <div className="months">
                            {calls?.months?.map((data: any, i:any) => {                        
                                return <Card.Subtitle className="mb-2 " key={i}>{data.month} {data.year} : 
                                {" "}{data.value}</Card.Subtitle>
                            })}
                        </div>
                        <Chart  dataset={calls?.months} name="Total Calls" chartFor={"Calls"} theme={theme} />
                        </>
                        :""
                    }
            </Card>
}