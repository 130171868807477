export enum FeedsenseModuleComponents {
    Stock = "stock",
    StockIndex = "multiday-stock-index",
    MultidayEquityFuture = "multiday-equity",
    StockFuture = "stock-future",
    ComodityIndex = "comodity-index",
    ComodityFuture = "comodity-future",
    FXIndex = "fx-index",
    FXFuture = "fx-future",
    FXFutureMultiday = "fx-future-multiday",
    FXOption = "fx-option",
    StockPair = "stock-pair",
    MutualFund = "mf",
    News = "news",
    LiveCalls = "all",
    Overall = "overall",
    IntradayStockIndex = "intraday-stock-index",
    IntradayStockFuture = "intraday-stock-future",
    // Options = "options"
    OptionsIndex="options-index",
    OptionsStock= "options-stock",
    MultidayStockFuture = "multiday-stock-future"
}
// Options = "options"
// All = "all",

