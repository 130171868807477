import Card from "react-bootstrap/Card"
import "./style.css"
import React from "react";
import { Chart } from "./Chart"
import { getColorAccordingWithHistPL} from "../../../../util/utils";

interface PLCardProps {
    plData: any
    type:any
    theme :any 
}

export const PLCard: React.FC<PLCardProps> = ({ plData, type,theme }) => {
    

    return <Card className="custom-card card2">
        <div className="total heading" style={{   display:"inline-block" }}><img style={{  height:"3.5rem",marginRight:"1rem" ,width:"3.5rem", display:"inline-block"  }} src="assets/images/pl.png"/>PL:  {type=='fx-future' || type ==='fx-future-multiday'? " ₹ "+plData?.total?.toFixed(2):" ₹ "+plData?.total?.toFixed(2)} {type == 'fx-future'?"":""} </div>
        {type !== "multiday-equity"?
        <>
        <div className="months">
            {plData?.months?.map((data: any, i:any) => {
                return <Card.Subtitle className="mb-2 " key={i}>{data.month} {data.year} :
                    {" "}<span style={{color:`${getColorAccordingWithHistPL(data.value.toFixed(2),theme)}`,fontWeight:700}}>{(type=='fx-future'|| type ==='fx-future-multiday'?data.value.toFixed(2):data.value.toFixed(2))}</span></Card.Subtitle>
            })}
        </div>
        <Chart dataset={plData?.months} name="P / L" theme={theme} />
        </>:""}
    </Card>
}