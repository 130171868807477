import Card from "react-bootstrap/Card"
import "./style.css"
import React from "react";
import { Chart } from "./Chart"

interface SuccessRatioCardProps {
    sr: any
    theme :any
    type:any 
}

export const SuccessRatioCard: React.FC<SuccessRatioCardProps> = ({ sr,theme,type }) => {

    return <Card className="custom-card card2">
        <div className=" total heading" style={{ display: "inline-block" }}>
        {theme == 'dark' || theme == 'b/w' ?<img style={{ height: "3.2rem", marginRight: "1rem", width: "3.2rem", display: "inline-block" }} src="assets/images/sr_dark.png" />
             : theme = 'light' ? <img style={{ height: "3.2rem", marginRight: "1rem", width: "3.2rem", display: "inline-block" }} src="assets/images/sr_light.png" /> :''}
            Success Ratio: {sr?.total}%
        </div>

        {type !== "multiday-equity"?
        <>
        <div className="months">
            {sr?.months?.map((data: any, index:any) => {
                return <Card.Subtitle className="mb-2 " key={index}>
                    {data.month} {data.year}{" "}: {data.value?.toFixed(2)}</Card.Subtitle>
            })}
        </div>
        <Chart dataset={sr?.months} name="Success Ratio" theme={theme} />
        </>
        :""}
    </Card>
}