import './style.css';
import ReactApexChart from 'react-apexcharts'
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { getSentimentChartColor } from '../../../../../util/utils';
interface MTMChartProps {
  dataset: any,
  type:any
  theme :any
}
export const MTMChart: React.FC<MTMChartProps> = ({ dataset,type,theme }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let dataArr: any = []
  let labels: any = []
  let colors: any = []
  let axis_lable_color :any 
  let border_color :any
  let fill_color :any
  let axis_border_color :any
  
  if(theme == 'dark'){
      axis_lable_color = '#fff'
      border_color = '#262626'
      fill_color =['#ffffff', '#ffffff']
      axis_border_color = '#ffffff'
  }
  else if (theme == 'light'){
      axis_lable_color = '#000'
      border_color = '#a2cdf4'
      fill_color =['#0a3154', '#0a3154']
      axis_border_color = '#659ac2'
  }
  else if(theme == 'b/w'){
    axis_lable_color = '#fff'
    border_color = '#ffffff30'
    fill_color =['#ffffff', '#ffffff']
    axis_border_color = '#ffffff'
}


  dataset?.map((data: any) => {
    
    let mtm:any;
    if(typeof(data?.m2m)==='string'){
        mtm = Number(data?.m2m)
    }
    if( type === 'intraday-stock-index' || type==="intraday-stock-future" || type === 'comodity-future' || type === "options-stock" || type === "options-index")
    {
      labels.push(moment(new Date(data?.dt_date+" "+data?.time)).format('DD\'MMM HH:mm').toString())
      dataArr.push(Number(data?.m2m).toFixed(2))
    }
    else if(type=== 'fx-option' ||type === 'fx-future')
    {
      labels.push(moment(new Date(data?.dt_date+" "+data?.time)).format('DD\'MMM HH:mm').toString())
      dataArr.push(Number(data?.m2m)?.toFixed(4))

    }
    else if(type === "stock-index" || type === "stock-future" || type === "overall" || type === "all"){
      labels.push(moment(new Date(data?.dt_date)).format('DD\'MMM HH:mm'))
      dataArr.push(parseInt(data?.m2m).toFixed(2))
    }
    else if(type ==="fx-future-multiday"){
      labels.push(moment(new Date(data?.time)).format('DD\'MMM HH:mm').toString())
      dataArr.push(Number(data?.m2m).toFixed(4))
    }
    else if(type ==="multiday-equity"){
      labels.push(moment(new Date(data?.time)).format('DD\'MMM HH:mm').toString())
      dataArr.push(Number(data?.m2m).toFixed(4))
    }
    
    if (data.m2m < 0) {
      colors.push('red')
    } else {
      colors.push('#38fffc')
    }
  })

let max:any = Math.max(...dataArr)
let min:any = Math.min(...dataArr)

  const data1: any = {

    series: [
      {
        name: "MTM",
        data: dataArr
      }
    ],
    options: {
      chart: {
        type: 'area',
        dropShadow: {
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: [`${getSentimentChartColor(dataArr, 'bg')}`, '#38fffc'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'square',
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
      title: {
        text: 'MTM',
        align: 'middle',
        style: {
          fontSize: '18px',
          color: axis_lable_color
        }
      },
      grid: {
        borderColor: border_color,
        row: {
          colors: ['transparent', 'transparent'], 
          opacity: 0.5
        },
      },
      markers: {
        size: 0
      },
      fill: {
        colors: fill_color
      },
      xaxis: {
        categories: labels,
        title: {
          show: false
        },

        tickPlacement: 'between',
        tickAmount:8,
        labels: {
          show: true,
          rotate: -45,
          style: {
            colors: axis_lable_color
          }
        },
        axisBorder: {
          show: true,
          color: axis_border_color,
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: false,
          borderType: 'solid',
          color: axis_border_color,
          height: 6,
          offsetX: 0,
          offsetY: 0
        },


      },
      yaxis: {
        opposite: false,
        title: {
          show: false,
        },
        labels: {
          show: true,
          align: 'center',
          offsetX: 0,
          position: "center",
          style: {
            colors: axis_lable_color
          }
        },
         min: min,
         max: max,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
        labels: {
          colors: 'white'
        }
      }
    },
  };

  return (
    <>
      <div style={{ margin: "1rem", height: "5%" }}>
        <span className="chart_view_button" style={{ float: "right", padding: "0rem", border: "1px solid white" }} onClick={handleShow}>
          <FontAwesomeIcon icon={faExpandArrowsAlt} />
        </span>
      </div>
      <div className="mtm-chart" style={{ marginTop: "1.2rem",marginBottom:"4.5rem" }}>
        {/* <Bar type="Bar"  data={data} options={options}/> */}
        <ReactApexChart id="mtmApexChart" options={data1.options} series={data1.series} type="area" height="100%" />
        <Modal show={show} onHide={handleClose} style={{ backdropFilter: "blur(3px)"}} size="xl" >
          <Modal.Header closeButton>
            <Modal.Title style={{width:"100%"}}>
              <div style={{width:"30%"}}>
                {/* {dataset[length-1]?.s_symbol} */}
              </div>
              {/* <div style={{width:"30%",fontSize:"1rem"}}>Total PL for this trade: {dataset[length-1]?.n_pl}</div> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='chartItem' style={{width:"87%",height:"80%",margin:"2% 7%",position:"absolute"}}>
                <ReactApexChart options={data1.options} series={data1.series} type="area" height='100%' />
            </div>
          </Modal.Body>

       </Modal>
      </div>
    </>
  )
}
