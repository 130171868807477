import { frontendRoutes } from '../../../../constants/frontendRoutes';
import { News } from '../../../../Models/News';
import { add3Dots, getColorBySentiment, parseDateInDisplayFormat2 } from '../../../../util/utils';
import './MediumNewsItemType2.css';

interface MediumNewsItemType2Props {
    article: News
}

export const MediumNewsItemType2: React.FC<MediumNewsItemType2Props> = ({article}) => { 

    return (
        <div className="medidum-type2-item" style={{borderBottom: `5px solid ${getColorBySentiment(article.sentiment)}`}}>
            <div className="single_post " style={{width: "100%"}}>
                <div className="post_img gradient1">
                    <img src={article.imageLink} width="280" height="360"/> 
                </div>
            </div>
            <div className="single_post_text">
                <div className="meta2">
                    <a href={"/news/keyword/"+article.category+"/category"} target="_blank" rel="noopener noreferrer">{article.category}</a>
                    <a href="#">{parseDateInDisplayFormat2(article.publishedDate)}</a>
                </div>
                <h4>
                    <a target="_blank" rel="noopener noreferrer" href={frontendRoutes.newsHome + article.id}>
                        {add3Dots(article.title, 120)}
                    </a>
                </h4>
                <p className="publisher-name">{article.publisher}</p>
            </div>
        </div>
    )
}