import React from 'react';
import { frontendRoutes } from '../../../../constants/frontendRoutes';
import { News } from '../../../../Models/News';
import { add3Dots, getColorBySentiment, parseDateInDisplayFormat, parseDateInDisplayFormat2 } from '../../../../util/utils';
import './SmallNewsItemType1.css';

interface SmallNewsItemType1Props {
    article: News 
}

export const SmallNewsItemType1: React.FC<SmallNewsItemType1Props> = ({article}) => {

    return (
        <>
            <div className="small-thumbnail-type1 single_post widgets_small">
                <div className="post_img">
                    <div className="img_wrap" style={{ borderRight: `5px solid ${getColorBySentiment(article.sentiment)}`, borderRadius: 4}}>
                        <a href={frontendRoutes.newsHome + article.id}>
                            <img src={article.imageLink}/>
                        </a>
                    </div>
                </div>
                <div className="single_post_text">
                    <div className="meta2 meta_separator1">
                        <a href={"/news/keyword/"+article.category+"/category"} target="_blank" rel="noopener noreferrer">{article.category}</a>
                        <a href="#">{parseDateInDisplayFormat2(article.publishedDate)}</a>
                    </div>
                    <h4 style={{marginTop: "4px"}}>
                        <a target="_blank" rel="noopener noreferrer" href={frontendRoutes.newsHome + article.id}>{add3Dots(article.title, 58)}</a> 
                    </h4>
                </div>
                <div className="publisher-area">
                    {/* <img src={article.logoUrl} /> */}
                    <p >{article.publisher}</p>
                </div>
            </div>
            {/* <div className="sentiment-color" style={{borderRight: `5px dotted ${barColor}`}}></div> */}
        </>
    )
}