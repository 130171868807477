import './container.css';
import Carousel from 'react-multi-carousel';
import { ArrowButtonGroup } from '../../../shared/ArrowButtonGroup';
import { MediumNewsItemType3 } from '../ContainerType3/MediumNewsItemType3';
import { useState } from 'react';
import { News } from '../../../../Models/News';
import { SmallNewsItemType6 } from './SmallNewsItemType6';

interface NewsContainerType6Props {
    articles1: any
    title1: string
    articles2: any
    title2: string
}

export const NewsContainerType6: React.FC<NewsContainerType6Props> = ({articles1, title1, articles2, title2}) => {
    const [noOfMediumIconsInACol, setNoOfMediumIconsInACol] = useState(2);
    const [noOfSmallIconsInACol, setNoOfSmallIconsInACol] = useState(5);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };  

    const responsiveRightPart = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }; 

    const MediumIconCOl = ({articleSubArr}: any) => {
        return (
            <>
                {articleSubArr.map((data: News) => {
                    return <MediumNewsItemType3 article={data} />
                })}
            </>
        );
    };

    const SmallIconCOl = ({articleSubArr}: any) => {
        return (
            <>
                {articleSubArr.map((data: News, i: number) => {
                    if(i < articleSubArr.length - 1) {
                        return (
                            <>
                                <SmallNewsItemType6 article={data} />
                                <div className="space-15"></div>
                                <div className="border_black"></div>
                                <div className="space-15"></div>
                            </>
                        )
                    }else
                        return (
                            <SmallNewsItemType6 article={data} />
                        )
                    }
                )}
            </>
        );
    };

    let mediumIconsSlides:any = [];
    for(let i = 0; i < articles1.length; i += noOfMediumIconsInACol){
        let articleArr:News[] = []
        for(let j = i; j < i + noOfMediumIconsInACol; j++){
            let data = articles1[j]
            if(!data) continue
            let article:News = {id: data._id, title: data.title, imageLink: data.image_link, category: data.category,
                publishedDate: data.datePublished, sentiment: data.sentiment, logoUrl:data.logo, publisher: data.publisher}
            articleArr.push(article)
        }
        
        mediumIconsSlides.push(
            <MediumIconCOl articleSubArr={articleArr} />
        )
    }

    let smallIconsSlides:any = [];
    for(let i = 0; i < articles2.length; i += noOfSmallIconsInACol){
        let articleArr:News[] = []
        for(let j = i; j < i + noOfSmallIconsInACol; j++){
            let data = articles2[j]
            if(!data) continue
            let article:News = {id: data._id, title: data.title, imageLink: data.image_link, category: data.category,
                publishedDate: data.datePublished, sentiment: data.sentiment, logoUrl:data.logo, publisher: data.publisher}
            articleArr.push(article)
        }
        
        smallIconsSlides.push(
            <SmallIconCOl articleSubArr={articleArr} />
        )
    }

    return (
        <div className="setction-type6 fifth_bg mb20">
            <div className="container">
                <div className="row">
                    <div className="row section-type3-left-part col-8">
                        <div className="top-part" style={{width: "100%"}}>
                            <div className="heading" id={title1.split(" ").join('')}>
                                <h2 className="widget-title">
                                    {title1}
                                </h2>
                            </div>
                            <Carousel ssr
                                partialVisbile
                                deviceType={"desktop"}
                                itemClass="image-item"
                                responsive={responsive} 
                                customButtonGroup={<ArrowButtonGroup />}
                                arrows={false} renderButtonGroupOutside={true}
                            >
                                {mediumIconsSlides}
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-4 section-type6-right-part" style={{marginLeft: 0}}>
                        
                        <div className="heading" id={title2.split(" ").join('')}>
                            <h2 className="widget-title">
                                {title2}
                            </h2>
                            <div className="ad-2">
                                <img src="assets/icon/dummy-ad.jpg" height="250"/>
                            </div>
                        </div>
                        <Carousel ssr
                            partialVisbile
                            deviceType={"desktop"}
                            itemClass="image-item"
                            responsive={responsiveRightPart} 
                            customButtonGroup={<ArrowButtonGroup />}
                            arrows={false} renderButtonGroupOutside={true}
                        >
                            {smallIconsSlides}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}