import { RelatedNews } from '../../Models/RelatedNews';
import './RelatedNewsItem.css';
import Card from 'react-bootstrap/Card'
import { frontendRoutes } from '../../constants/frontendRoutes';

interface RelatedNewsItemProps {
    relatedNews: RelatedNews
}

export const RelatedNewsItem: React.FC<RelatedNewsItemProps> = ({relatedNews}) => {

    return (
        <div className="col-lg-4 col-md-6">
            <a href={frontendRoutes.newsHome + relatedNews.id} target="_blank" rel="noopener noreferrer">
                <Card className="col-12 related-news-item">
                    <Card.Body>
                        <Card.Title>
                            <img src={relatedNews.logoUrl} style={{maxWidth: '70%', maxHeight: 55}}/>
                            <div style={{display: 'inline-block', float: 'right'}}>
                            <p className="time">{relatedNews.dateModified.getHours() + ':' + relatedNews.dateModified.getMinutes()}</p>
                            <p className="date">{relatedNews.dateModified.toLocaleDateString()}</p>
                            </div>
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            <p>
                                <span>{relatedNews.publisher}</span>
                                {' > '}
                                <span>{relatedNews.author}</span>
                            </p>
                        </Card.Subtitle>
                        <Card.Text>
                            <p className="title">{relatedNews.title}</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </a>
        </div>
    )
}