import { axiosLocal5000Local } from "../axios";
import { getUserObject } from "../Service/auth.service";

let userObj:any = getUserObject();


export function getSummaryOfAssets(assetType: string) {
    
    return axiosLocal5000Local.get(`/live-trade/profit_summery_asset_wise?asset=${assetType}&version=${"recommended"}&username=${userObj.email_id}`)
            .then((res) => {
                return res.data;
        }).catch((err) =>console.log(err)
        )
}

export function getAdvices(asset: string) {
    return axiosLocal5000Local.get(`/live-trade/advice_status_params?asset=${asset}&version=${"recommended"}&username=${userObj.email_id}`)
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}

export function getParameters(uuid: any) {
    return axiosLocal5000Local.get(`/live-trade/trade_details_by_uuid_live?uuid=${uuid}&version=${"recommended"}&username=${userObj.email_id}`)
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}

export function getNoOfCalls(asset: string) {
    return axiosLocal5000Local.get(`/live-trade/no_of_trades?asset=${asset}&version=${"recommended"}&username=${userObj.email_id}`)
        .then((res) => {
            return res.data[0].count;
    }).catch((err) =>console.log(err)
    )
}

export function getSR(asset: string) {
    return axiosLocal5000Local.get(`/live-trade/no_of_trades?asset=${asset}&version=${"recommended"}&username=${userObj.email_id}`)
        .then((res) => {
            return res.data[0].count;
    }).catch((err) =>console.log(err)
    )
}

export function getPL(asset: string) {
    return axiosLocal5000Local.get(`/live-trade/p_l_amount?asset=${asset}&version=${"recommended"}&username=${userObj.email_id}`)
        .then((res) => {
            return res.data[0].sum;
    }).catch((err) =>console.log(err)
    ).catch((err) => console.log(err)
    )
}

export function getSuccessRatio(asset: string) {
    return axiosLocal5000Local.get(`/live-trade/success_ratio?asset=${asset}&version=${"recommended"}&username=${userObj.email_id}`)
        .then((res) => {
            return res.data[0].sr;
    }).catch((err) =>console.log(err)
    )
}

export function getTargetSLChartData(uuid: string, asset:any) {
    return axiosLocal5000Local.get(`/live-trade/target_stoploss_chart?uuid=${uuid}&asset=${asset}`)
        .then((res:any) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}

export function getMtmChartData(uuid: string, asset:any) {
    
    return axiosLocal5000Local.get(`/live-trade/m2m_drawdown_live?uuid=${uuid}&asset=${asset}`)
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}



////////////////////////////////////////////PAIR TRADING/////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function getPTNoOfCalls(asset: string) {
    return axiosLocal5000Local.post(`/pairtrading/get_live_total_calls`,{"asset_pair":asset,"username":userObj.email_id})
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}

export function getPTpl(asset: string) {
    return axiosLocal5000Local.post(`/pairtrading/get_live_pt_pl`,{"asset_pair":asset,"username":userObj.email_id})
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
    
}

export function getPTplPercent(asset: string) {
    return axiosLocal5000Local.post(`/pairtrading/get_live_pt_total_pl_percent`,{"asset_pair":asset,"username":userObj.email_id})
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}
export function getPtSuccessRatio(asset: string) {
    return axiosLocal5000Local.post(`/pairtrading/get_live_pt_success_ratio`,{"asset_pair":asset,"username":userObj.email_id})
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}
export function getPtAdvices(asset: string) {
    return axiosLocal5000Local.post(`/pairtrading/get_live_pt_advices`,{"asset_pair":asset,"username":userObj.email_id})
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}
export function getMtmDrawdownChartDataPT(uuid:any,asset:any,open_date:any) {
    return axiosLocal5000Local.post(`/pairtrading/get_mtm_drawdown`,{"asset_pair":asset,"uuid":uuid,"open_date":open_date})
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}
export function getTargetStoplossChartDataPT(uuid:any,asset:any,open_date:any) {
    return axiosLocal5000Local.post(`/pairtrading/get_target_stoploss`,
    {
        "asset_pair":asset,
        "uuid":uuid,
        "open_date":open_date
        // "username":userObj.email_id
    })
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}
export function getParameterPT(uuid:any,asset:any,open_date:any) {
    return axiosLocal5000Local.post(`/pairtrading/getParameter`,{"asset_pair":asset,"uuid":uuid,"username":userObj.email_id})
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}
export function check_contract_period() {
    return axiosLocal5000Local.get(`/live-trade/check_contract_period?username=${userObj.email_id}`) //--------------------
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err)
    )
}

export function FetchliveAssets(type:any) {
    return axiosLocal5000Local.post(`/pairtrading/get_live_pt_assets`,{"asset_pair":type,"username":userObj.email_id})
        .then((res) => {
            return res.data;
    }).catch((err) =>console.log(err))
}

// **************************************************************************************************************************************************
//                                                    Strategy-Tracker-overall
// **************************************************************************************************************************************************
export function liveOpenCalls() {
    return axiosLocal5000Local.post(`/liveOverall/overall_live_total_calls`,{"username":userObj.email_id})
        .then((res) => {
            return res?.data;
    }).catch((err) =>console.log(err))
}
export async function liveOpenListOfTrades(calenderPickerDate:any) {
    try{
        return await axiosLocal5000Local.post(`/liveOverall/overall_live_advice_status`,{"username":userObj.email_id, "selectedDate": calenderPickerDate})
        .then((res:any) => {
            return res?.data;
        }).catch((err:any) =>{
            return {final_response:[]}
        })
    }catch(e:any){
        return {"error":true}
    }
    
}
export async function liveOpenListOfTradesParameter(obj:any) {
    return await axiosLocal5000Local.post(`/liveOverall/overall_live_parameter`,obj)
        .then((res:any) => {
            return res?.data;
    }).catch((err:any) =>{
        return []
    })
}

export function liveOpenSelectedCodeReturnPr(obj:any,backupDate:any){
    return axiosLocal5000Local.post(`/liveOverall/get_overall_live_return_selected_pl_percentage`,{"obj":obj,"selectedDate": backupDate})
    .then((res)=>{
        return res?.data;
    }).catch((err) =>console.log(err))
}

export async function getSentiment(backupDate:any){
    return await axiosLocal5000Local.post(`/liveOverall/getSentiment`,{"selectedDate": backupDate})
    .then((res:any)=>{
        return res?.data
    }).catch((e:any)=>{
        return {"sentiment_score":[{"n_sentiment_score": 'NA'}]}
    })
}


export async function getLiveNewsSentiment(backupDate:any){
    return await axiosLocal5000Local.post(`/liveOverall/getNewsSentiment`,{"selectedDate": backupDate})
    .then((res:any)=>{
        return res?.data
    }).catch((e:any)=>{
        return {"error": true}
       
    })
}

export async function getNifty50IndexData(backupDate:any){
    return await axiosLocal5000Local.post(`/liveOverall/getNifty50IndexData`,{"selectedDate": backupDate})
    .then((res:any)=>{
        return res?.data
    }).catch((e:any)=>{
        return {"error": true}
       
    })
}

export async function getPreviousSentiment(backupDate:any){
    return await axiosLocal5000Local.post(`/liveOverall/getPreviousSentiment`,{"selectedDate": backupDate})
    .then((res:any)=>{
        return res?.data?.PreviousSentiment
    }).catch((e:any)=>{
        return {"PreviousSentiment":[]}
    })
}


export async function getcostDetails(){
    return await axiosLocal5000Local.post(`/liveOverall/get_charge_details`,{})
    .then((res:any)=>{
        return res?.data[0]
    }).catch((e:any)=>{
        return {"cost":[]}
    })
}


// export async function insertData(advices:any){
//     return axiosLocal5000Local.post(`/liveOverall/insertBackOfficeData`,{"advices":advices})
//     .then((res) => {
//         return true;
// }).catch((err) =>console.log(err))
// }

// export async function insertRow(advice:any){
//     return axiosLocal5000Local.post(`/liveOverall/insertBackOfficeExtraTrade`,{"advice":advice})
//     .then((res) => {
//         return true;
// }).catch((err) =>{
//     return false
// })
// }

// export async function fetch_back_advice(calenderPickerDate:any){
//     return axiosLocal5000Local.post('/liveOverall/fetchBackOfficeData',{"date":calenderPickerDate})
//     .then((res)=> {
//         return res?.data;
//     }).catch((err) =>console.log(err))
// }
// export async function update_back_advice(advice_row:any){
//     return axiosLocal5000Local.post(`/liveOverall/updateBackOfficedata`,{"advice_row":advice_row})
//     .then((res) => {
//         return res?.data;
//     }).catch((err) => console.log(err))
// }

export async function getReturnByDays(Object:any,selectedDate:any,no_of_days:any){
    return await axiosLocal5000Local.post(`/liveOverall/get_historical_days_return`,{
        "username":userObj.email_id,
        "adv_object":Object,
        "selectedDate":selectedDate,
        "totalDays":no_of_days
    })
    .then((res:any)=>{
        return res?.data
    }).catch((e:any)=>{
        return {"cost":[]}
    })
}



// export async function get_Identifier(){
//     return await axiosLocal5000Local.post(`/liveOverall/get_Identifier`)
//     .then((res:any)=>{
//         return res?.data
//     }).catch((err) => console.log(err))
// }



//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//                                                                      BACK OFFICE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//back_office
export async function insertData(advices:any){
    return axiosLocal5000Local.post(`/back_office/insertBackOfficeData`,{"advices":advices})
    .then((res) => {
        return res?.data;
}).catch((err) =>console.log(err))
}


//back office
export async function insertRow(advice:any){
    return axiosLocal5000Local.post(`/back_office/insertBackOfficeExtraTrade`,{"advice":advice})
    .then((res) => {
        return true;
}).catch((err) =>{
    return false
})
}


//back_office
export async function fetch_back_advice(calenderPickerDate:any, selectedTradeType:any){

    return axiosLocal5000Local.post('/back_office/fetchBackOfficeData',{"date":calenderPickerDate, "selectedTradeType":selectedTradeType})
    .then((res)=> {
        return res?.data;
    }).catch((err) =>console.log(err))
}

//back_office
export async function update_back_advice(advice_row:any){
    return axiosLocal5000Local.post(`/back_office/updateBackOfficedata`,{"advice_row":advice_row})
    .then((res) => {
        return true;
    }).catch((err) => console.log(err))
}


//back_office
export async function get_Identifier(){
    return await axiosLocal5000Local.post(`/back_office/get_Identifier`)
    .then((res:any)=>{
        return res?.data
    }).catch((err) => console.log(err))
}



//back_office
export async function getInvestment(selectedDate:any)
{
    return await axiosLocal5000Local.post(`/back_office/investment_details`,{"selectedDate" :selectedDate})
    .then((res:any) =>{
        return res?.data
    }).catch((err:any) => console.log(err))
}


//back_office
export async function getLotsizeDetails(selectedDate:any)
{
    return await axiosLocal5000Local.post(`/back_office/lotsize_details`,{"selectedDate" :selectedDate})
    .then((res:any) =>{
        return res?.data
    }).catch((err:any) => console.log(err))
}

