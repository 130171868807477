import React from 'react';
import { useHistory } from 'react-router-dom';
import { FeedsenseModules } from '../../../constants/FeedsenseModules';
import './style.css';
import { PanelMenu } from 'primereact/panelmenu';
import { TieredMenu } from 'primereact/tieredmenu';
import { FeedsenseModuleComponents } from '../../../constants/FeedsenseModuleComponents';
import { UserPrivilegeService } from '../../../util/UserPrivilegeService';
import {Row } from 'react-bootstrap';
interface LeftNavigationProps {
    activeMenu: string,
    isLoggedIn: boolean
    user: any
    setPara:any
}

export const LeftNavigation: React.FC<LeftNavigationProps> = ({ activeMenu, isLoggedIn, user, setPara }) => {

    const items = [
        {
            label: 'Overview',
            template: (item: any, options: any) => {
                return (
                    <a className={options.className} target={item.target} onClick={options.onClick}>
                       <Row style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"left"}}>
                        <div style={{ padding: 0, textAlign: 'center',width:"20%" }}>
                            <span><img style={{ width: "70%" }} src="./assets/icon/home.png" alt="" /></span>
                        </div>
                        <div style={{ width: "60%",justifyContent:"left",marginLeft:"10%" }}>
                            <span style={{ width: "100%" }} className={options.labelClassName}>{item.label}</span>
                        </div>
                        <div style={{width:"10%"}}>
                        </div>
                        </Row>
                    </a>
                );
            },
            disabled: isLoggedIn,
            className: activeMenu === FeedsenseModules.Overview ? "active" : "",
            command: (event: any) => {
                handleHomeMenuClick()
            }
        },

        {
            label: 'Strategy Tracker',
            expanded: activeMenu === FeedsenseModules.StrategyTracker,
            className: activeMenu === FeedsenseModules.StrategyTracker ? "active" : "",
            template: (item: any, options: any) => {
                return (
                    <a className={options.className} target={item.target} onClick={options.onClick}>
                        <Row style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"left"}}>
                        <div style={{ padding: 0, textAlign: 'center',width:"20%" }}>
                            <span><img style={{ width: "70%" }} src="./assets/icon/strategy_tracker.png" alt="" /></span>
                        </div>
                        <div style={{ width: "60%",justifyContent:"left",marginLeft:"10%" }}>
                            <span style={{ width: "100%" }} className={options.labelClassName}>{item.label}</span>
                        </div>
                        <div style={{width:"10%"}}>
                            <span className={(options.iconClassName, 'pi pi-chevron-right')}></span>
                        </div>
                        </Row>
                    </a>
                );
            },
            items: [
                {
                    label: 'All - Live Calls',
                    disabled: false,
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.StrategyTracker, FeedsenseModuleComponents.LiveCalls)
                    }
                },
                {
                    label: 'Commodity Fut - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.ComodityFuture),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.StrategyTracker, FeedsenseModuleComponents.ComodityFuture)
                    }
                },
                {
                    label: 'Equity - Multiday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.MultidayEquityFuture), 
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.StrategyTracker, FeedsenseModuleComponents.MultidayEquityFuture)
                    }
                },
                {
                    label: 'Forex Fut - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.FXFuture),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.StrategyTracker, FeedsenseModuleComponents.FXFuture)
                    }
                },
                {
                    label: 'Forex Fut - Multiday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.FXFutureMultiday),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.StrategyTracker, FeedsenseModuleComponents.FXFutureMultiday)
                    }
                },
                {
                    label: 'Forex Opt - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.FXOption),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.StrategyTracker, FeedsenseModuleComponents.FXOption)
                    }
                },
               
                {
                    label: 'Stock Fut - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.IntradayStockFuture),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.StrategyTracker, FeedsenseModuleComponents.IntradayStockFuture)
                    }
                },
                {
                    label: 'Stock Index Fut - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.IntradayStockIndex),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.StrategyTracker, FeedsenseModuleComponents.IntradayStockIndex)
                    }
                },
                {
                    label: 'Stock Index Opt - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.OptionsIndex),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.StrategyTracker, FeedsenseModuleComponents.OptionsIndex)
                    }
                },
                {
                    label: 'Stock Opt - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.OptionsStock),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.StrategyTracker, FeedsenseModuleComponents.OptionsStock)
                    }
                }
            ]
        },
        {
            label: 'Historical Results',
            disabled: !isLoggedIn || !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModules.HistoricalPerformance),
            expanded: activeMenu === FeedsenseModules.HistoricalPerformance,
            className: activeMenu === FeedsenseModules.HistoricalPerformance ? "active" : "",
            template: (item: any, options: any) => {
                return (
                    <a className={options.className} target={item.target} onClick={options.onClick}>
                        <Row style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"left"}}>
                        <div style={{ padding: 0, textAlign: 'center',width:"20%" }}>
                            <span><img style={{ width: "70%" }} src="./assets/icon/historical_performance.png" alt="" /></span>
                        </div>
                        <div style={{ width: "60%",justifyContent:"left",marginLeft:"10%" }}>
                            <span style={{ width: "100%" }} className={options.labelClassName}>{item.label}</span>
                        </div>
                        <div style={{width:"10%"}}>
                            <span className={(options.iconClassName, 'pi pi-chevron-right')}></span>
                        </div>
                        </Row>
                    </a>
                );
            },
            items: [
                {
                    label: 'Commodity Fut - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.ComodityFuture),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.HistoricalPerformance, FeedsenseModuleComponents.ComodityFuture)
                    }
                },
                {
                    label: 'Equity - Multiday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.MultidayEquityFuture),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.HistoricalPerformance, FeedsenseModuleComponents.MultidayEquityFuture)
                    }
                },
                {
                    label: 'Forex Fut - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.FXFuture),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.HistoricalPerformance, FeedsenseModuleComponents.FXFuture)
                    }
                },
                {
                    label: 'Forex Fut - Multiday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.FXFutureMultiday),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.HistoricalPerformance, FeedsenseModuleComponents.FXFutureMultiday)
                    }
                },
                {
                    label: 'Forex Opt - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.FXOption),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.HistoricalPerformance, FeedsenseModuleComponents.FXOption)
                    }
                },
               { label: 'Stock Fut - Intraday',
                disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.IntradayStockFuture),
                command: (event: any) => {
                    handleMenuClick(FeedsenseModules.HistoricalPerformance, FeedsenseModuleComponents.IntradayStockFuture)
                }
            },
                {
                    label: 'Stock Index Fut - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.IntradayStockIndex),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.HistoricalPerformance, FeedsenseModuleComponents.IntradayStockIndex)
                    }
                },
                {
                    label: 'Stock Index Opt - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.OptionsIndex),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.HistoricalPerformance, FeedsenseModuleComponents.OptionsIndex)
                    }
                },
                {
                    label: 'Stock Opt - Intraday',
                    disabled: !UserPrivilegeService.hasAccessToComponent(user?.privileges, FeedsenseModuleComponents.OptionsStock),
                    command: (event: any) => {
                        handleMenuClick(FeedsenseModules.HistoricalPerformance, FeedsenseModuleComponents.OptionsStock)
                    }
                }
             
            ]
        },
        {
            label: 'Portfolio Analytics',
            disabled: true,
            expanded: activeMenu === FeedsenseModules.PortfolioAnalytics,
            className: activeMenu === FeedsenseModules.PortfolioAnalytics ? "active" : "",
            command: (event: any) => {
                handleMenuItemClick(FeedsenseModules.PortfolioAnalytics)
            },
            template: (item: any, options: any) => {
                return (
                    <a className={options.className} target={item.target} onClick={options.onClick}>
                        <Row style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"left"}}>
                        <div style={{ padding: 0, textAlign: 'center',width:"20%" }}>
                            <span><img style={{ width: "70%" }}  src="./assets/icon/portfolio_analytics.png" alt="" /></span>
                        </div>
                        <div style={{ width: "60%",justifyContent:"left",marginLeft:"10%" }}>
                            <span style={{ width: "100%" }} className={options.labelClassName}>{item.label}</span>
                        </div>
                        <div style={{width:"10%"}}>
                            <span className={(options.iconClassName, 'pi pi-chevron-right')}></span>
                        </div>
                        </Row>
                    </a>
                );
            }
        },
        {
            label: 'Backoffice',
            disabled: !UserPrivilegeService.hasAccessToBackOffice(user?.privileges),
            expanded: activeMenu === FeedsenseModules.Backoffice,       
            className: activeMenu === FeedsenseModules.Backoffice ? "active" : "",
            command: (event: any) => {
                handleMenuItemClick(FeedsenseModules.Backoffice)
            },
            template: (item: any, options : any) => {
                return (
                    <a className={options.className} target={item.target} onClick={options.onClick}>
                        <Row style={{width :"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"left"}}>
                        <div style={{ padding: 0, textAlign: 'center', width:'20%'}}>
                            <span><img style={{ width: "70%" }}  src="./assets/icon/backoffice-icon.png" alt="" /></span>  
                        </div>
                        <div style={{ width: "60%",justifyContent: "left" , marginLeft: "10%"}}>
                            <span style={{ width : "100%"}} className={options.labelClassName}>{item.label}</span>
                        </div>
                        <div style={{width:"10%"}}>
                            <span className= {(options.iconClassName, 'pi pi-chevron-right')}></span>
                        </div>
                        
                        </Row>
                    
                    </a>

                );
            }
            
            

        },
        {
            label: 'News Recommender',
            command: (event: any) => {
                handleNewsMenuClick()
            },
            template: (item: any, options: any) => {
                return (
                    <a className={options.className} target={item.target} onClick={options.onClick}>
                        <Row style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"left"}}>
                        <div style={{ padding: 0, textAlign: 'center',width:"20%" }}>
                            <span><img style={{ width: "65%" }}  src="./assets/icon/newspaper.png" alt="" /></span>
                        </div>
                        <div style={{ width: "60%",justifyContent:"left",marginLeft:"10%" }}>
                            <span style={{ width: "100%" }} className={options.labelClassName}>{item.label}</span>
                        </div>
                        <div style={{width:"10%"}}>
                        </div>
                        </Row>
                    </a>
                );
            },
        },
        {
            label: 'Subscription',
            template: (item: any, options: any) => {
                return (
                    <a className={options.className} target={item.target} onClick={options.onClick}>
                        <Row style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"left"}}>
                        <div style={{ padding: 0, textAlign: 'center',width:"20%" }}>
                            <span><img style={{ width: "70%" }}  src="./assets/icon/subscription.png" alt=""/></span>
                        </div>
                        <div style={{ width: "60%",justifyContent:"left",marginLeft:"10%" }}>
                            <span style={{ width: "100%" }} className={options.labelClassName}>{item.label}</span>
                        </div>
                        <div style={{width:"10%"}}>
                            <span className={(options.iconClassName, 'pi pi-chevron-right')}></span>
                        </div>
                        </Row>
                    </a>
                );
            },
            disabled: true,
        },
        {
            label: 'Settings',
            template: (item: any, options: any) => {
                return (
                    <a className={options.className} target={item.target} onClick={options.onClick}>
                        <Row style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"left"}}>
                        <div style={{ padding: 0, textAlign: 'center',width:"20%" }}>
                            <span><img style={{ width: "70%" }} src="./assets/icon/setting.png" alt=""/></span>
                        </div>
                        <div style={{ width: "60%",justifyContent:"left",marginLeft:"10%" }}>
                            <span style={{ width: "100%" }} className={options.labelClassName}>{item.label}</span>
                        </div>
                        <div style={{width:"10%"}}>
                        </div>
                        </Row>
                    </a>
                );
            },
            disabled: true,
        },
    ];

    let history = useHistory()

    const handleMenuClick = (key: string, index: string) => {
        history.push(
            { pathname: "/portal/" + key + "/", search: index })
            setPara(["","portal",key, ""]);
            let inputs:any = document.getElementById('myCheckbox1');
            inputs.checked = false;
    }

    const handleNewsMenuClick = () => {
        window.open('/news', '_blank');
    }

    const handleHomeMenuClick = () => {
        history.push("/portal");
    }

    const handleMenuItemClick = (url: string) => {
  
        history.push("/portal/" + url);
        setPara(window.location.pathname.split('/'))
    }


    return <div className="whole_left_navigation"  >
        <div className="left-navigation-container" >
            <TieredMenu model={items} style={{}} />
            <PanelMenu model={items} style={{}}/>
        </div>
    </div>
}
