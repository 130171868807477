import React, { useEffect, useState } from 'react'
import './Portfolio_style.css'
import '../App.css'
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Modal } from 'react-bootstrap';
import { useInView } from "react-intersection-observer";
import Calendar from 'react-calendar';
import { getAdvicePortfolio, getAdvicePortfolioForRange, getPreviousDayStatus } from '../../Api/buySell/portfolio';
import moment from 'moment';
import { DatePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";


function MyVerticallyCenteredModal(props: any) {
    return (
        <Modal
            className='modelCal'
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body >
                <div style={{ borderRadius: '0.5rem' }} >
                    <Calendar onChange={props.selectDate} value={props.selectedDate}
                        maxDate={new Date()} prev2Label={null}
                        next2Label={null}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

const { RangePicker } = DatePicker;

interface PortfolioAnalyticsPageProps { 
    theme:any
}

 export const PortfolioAnalyticsPage:React.FC<PortfolioAnalyticsPageProps>= ({theme}) =>{
    const [visibleLeft, setVisibleLeft] = useState<any>(false)
    const { ref: myRef, inView: myElementIsVisisble } = useInView();
    const [selectedNavItem,setSelectedNavItem] = useState<any>("daily_allocation")
    const [calenderPickerDate,setCalenderPickerDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD'))
    const [selectedDate,setSelectedDate] = useState<any>()
    const [advices,setAdvices] = useState<any>([])
    const [rangeStartDate, setRangeStartDate] = useState<any>("");
    const [rangeEndDate, setRangeEndDate] = useState<any>("");
    const [endDateFilter, setEndDateFilter] = useState<any>(moment(new Date(new Date().setDate(new Date().getDate() - 1))).format('YYYY-MM-DD'))
    const [startDateFilter, setStartDateFilter] = useState<any>(moment(new Date(new Date().setDate(new Date().getDate() - 30))).format('YYYY-MM-DD'))
    const [riskedModel, setRiskedModel] = useState<any>([]);
    const [maxCluster,setMaxCluster] = useState<any>("");
    const [totalInvestment,setTotalInvestment] = useState<any>("");
    const [prevStatus,setPrevStatus] = useState<any>([]);

    const selectDate = (date: any) => {
        setCalenderPickerDate((moment(date).format('YYYY-MM-DD')))
        setSelectedDate(new Date(moment(date).format('YYYY-MM-DD')))
        setVisibleLeft(false)
    }

    useEffect(()=>{
        getAdvices()
    },[selectedDate,startDateFilter,endDateFilter,selectedNavItem])

    useEffect(()=>{
        Reset()
    },[])

    const Reset=()=>{
        setSelectedDate(new Date(moment(new Date()).format('YYYY-MM-DD')))
        setEndDateFilter(moment(new Date(new Date().setDate(new Date().getDate() - 30))).format('YYYY-MM-DD'));
        setStartDateFilter(moment(new Date(new Date().setDate(new Date().getDate() - 1))).format('YYYY-MM-DD'));
        setTotalInvestment("")
        setMaxCluster("")
        setRiskedModel("")
    }
   

    const getAdvices = async()=>{
        if(selectedNavItem === "daily_allocation"){
            let date = moment(selectedDate).format('YYYY-MM-DD')
            let advice_portfolio:any  = await getAdvicePortfolio(date)
            setAdvices(advice_portfolio)

            if (advice_portfolio.length > 0){
                let previousDayStatus:any = await getPreviousDayStatus(date)
                setPrevStatus(previousDayStatus)

                let unique:any = []
                let clusterList:any = []
                let sumOptiomumInvestment:any = 0
                if (advice_portfolio.length > 0){
                   
                    advice_portfolio.map((element: any) => {
                        sumOptiomumInvestment = sumOptiomumInvestment +  element?.n_optimum_investment
                        const max_cluster = clusterList.includes(element?.s_cluster)
                        const isDuplicate = unique.includes(element?.s_risky_model);
                        if (!isDuplicate) {
                            unique.push(element?.s_risky_model);
                        }
                        if (!max_cluster) {
                            clusterList.push(element?.s_cluster);
                        }
                    });
                }
                let lastcluster:any = clusterList.sort().pop()
    
                setTotalInvestment(sumOptiomumInvestment)
                setMaxCluster(lastcluster)
                setRiskedModel(unique)
            }else{
                setTotalInvestment(0)
                setMaxCluster("")
                setRiskedModel("")
            }


           
           
           

        }else if (selectedNavItem === "allocation_history"){

            let historical_portfolio:any = await getAdvicePortfolioForRange(startDateFilter,endDateFilter)
            setAdvices(historical_portfolio)
        }
    }





    const getRangeChartColor= (theme:any,weight:any)=>{
        let color:any
        if (theme == 'dark' || theme == 'b/w'){
            color = `linear-gradient(to right, rgb(255 189 132 / 82%) 0%, rgb(241 149 74) ${weight}%, rgb(255, 255, 255) ${weight}%, white 100%)`
        }else{
            color = `linear-gradient(to right, rgb(79 129 171) 0%, #6eace5 ${weight}%, rgb(203 217 226) ${weight}%, #bfd4de 100%)`
        }
        return color
    }



    const handleDateChange = (values: any) => {
        if (!values) {
            return;
        }
        setRangeStartDate(moment(values[0])?.format('YYYY-MM-DD'));
        setRangeEndDate(moment(values[1])?.format('YYYY-MM-DD'));

    }

    const handleApply = () => {
        let startDate:any =  rangeStartDate;
        let endDate:any =  rangeEndDate;
        setStartDateFilter(startDate);
        setEndDateFilter(endDate);
    
    }



    let namesArr:any = {};
    const rowSpan:any = advices.reduce((result:any,item:any, key:any) => {
        
      if (namesArr[item.dt_date] === undefined) {
        namesArr[item.dt_date] = key;
        result[key] = 1;
        
      } else {
        const firstIndex:any = namesArr[item.dt_date];

        if (firstIndex === key - 1 || (item.dt_date === advices[key - 1].dt_date && result[key - 1] === 0)) {
          result[firstIndex]++;
          result[key] = 0;
        } else {
          result[key] = 1;
          namesArr[item.dt_date] = key;
        }
      }
      return result;
    }, []);

 

    return (
        <div className="container-custom strategy-tracker" >
            <div style={{padding:"2rem"}}>
                <div className='product-type-top-div'>
                    <span className='product-type'>Portfolio Analytics</span>
                    {selectedNavItem !== "allocation_history"? <> 
                    <div style={{display:'flex',flexDirection: 'row',justifyContent: 'end', marginTop: '-3rem'}}>
                        {myElementIsVisisble === false  ? <span style={{ justifyContent: "end", width: "6%", display: "flex", position: "sticky", top: "9rem", zIndex: 0 }} onClick={() => setVisibleLeft(true)}>
                                <img style={{ margin: "-1rem 2rem 3rem 0rem" }} className='calanderDiv' src="./assets/icon/icons-view-schedule.png" alt="" />
                            </span> : ""}
                            <div >
                        {myElementIsVisisble === true ? <span onClick={() => setVisibleLeft(true)}>
                            <img className='calanderDiv' src="./assets/icon/icons-view-schedule.png" alt="" />
                        </span> : ""}
                        <MyVerticallyCenteredModal calenderPickerDate={calenderPickerDate} selectDate={selectDate} selectedDate={selectedDate} onHide={() => setVisibleLeft(false)} show={visibleLeft} />
                    </div>
                    </div></>:selectedNavItem === "allocation_history"?<>
                    <div className="d-flex datepicker" >
                                <div style={{ marginRight: "1rem", marginBottom: "1rem" }}>
                                    <RangePicker
                                        onChange={(values: any) => {
                                            handleDateChange(values)
                                        }}
                                        disabledDate={(current) => {
                                            let customDate = moment().format("YYYY-MM-DD");
                                            return (
                                                current && current >= moment(customDate, "YYYY-MM-DD")
                                            );
                                        }}
                                    />
                                </div>
                                <div className="" >
                                    <button type="button" className="btn apply_button"
                                        onClick={handleApply}>Apply</button>
                                </div>
                            </div></>
                :""}
                </div>

           
                <div className='navbar' >
                    <button id = "daily_allocation"    onClick={(e:any)=>{setSelectedNavItem(e.target.id)}} className={selectedNavItem ==="daily_allocation" ? "selected_navitem":'navitem'} style={{marginLeft: '-1rem'}}>Daily Allocation</button>
                    <button id = "allocation_history"  onClick={(e:any)=>{setSelectedNavItem(e.target.id)}} className={selectedNavItem ==="allocation_history" ? "selected_navitem":'navitem'}>Allocation History</button>
                    <button id = "performance"  disabled className={selectedNavItem ==="performance" ? "selected_navitem":'disabled_navitem'}>Performance</button>
                    <button id = "introduction" disabled className={selectedNavItem ==="introduction" ? "selected_navitem":'disabled_navitem'}>Introduction</button>
                </div>
                <div className='navBorder'></div>
           
                    {selectedNavItem === "daily_allocation"? <>
                        <div className='displayStat'>
                            <div className = 'displayStatitem'>
                                <span >Investment</span>
                                <span style={{fontWeight:"bold",fontSize: '1.85rem'}}>{totalInvestment != undefined && totalInvestment != "" && `${(totalInvestment/100000).toFixed(2)} L` }</span>
                                { (prevStatus != undefined && totalInvestment != "") && prevStatus.map((item:any)=> {
                                    let Investment_Diff:any =((totalInvestment - item?.prev_investment)/100000).toFixed(2)
                                return <>
                                    <span> {Investment_Diff < 0 ? 
                                            <span style={{color: "#f53d3d"}}>
                                                <FontAwesomeIcon icon={faArrowDown}/> {`${Investment_Diff} L`} </span>:  
                                            Investment_Diff >= 0 ? 
                                            <span style={{color: "#2d9547"}}>
                                                <FontAwesomeIcon icon={faArrowUp} /> {`${Investment_Diff} L`}</span>:"" } 
                                    </span>
                                    </>
                                })}
                            </div>
                            <div className = 'displayStatitem'>
                                <span >Cluster</span>
                                <span style={{fontWeight:"bold",fontSize: '1.85rem'}}>{maxCluster != undefined && maxCluster.replace(/[^0-9]/g, "")}</span>
                                { prevStatus != undefined && maxCluster != "" && prevStatus.map((item:any)=> {
                                    let cluster_Diff:any = maxCluster.replace(/[^0-9]/g, "") - item?.prev_max_cluster.replace(/[^0-9]/g, "")
                                return <>
                                        {cluster_Diff < 0 ? <span style={{color: "#f53d3d"}}> <FontAwesomeIcon icon={faArrowDown} /> {cluster_Diff} </span>: 
                                            cluster_Diff >= 0 ? <span style={{color: "#2d9547"}}> <FontAwesomeIcon icon={faArrowUp} />  {cluster_Diff}</span>:"" } 
                                        </>
                                })}
                            </div>
                            {/* <div>
                                <span className = 'displayStatitem'>Return</span>
                            </div>
                            <div>
                                <span className = 'displayStatitem'>Drawdown</span>
                            </div> */}
                            <div className = 'displayStatitem'>
                                <span >Weight</span>
                            </div>
                            <div className = 'displayStatitem' >
                                <span >Warning</span>
                                <span style={{fontWeight:"bold",fontSize: '1.85rem'}}>{riskedModel}</span>
                            </div>
                                            
                        </div>
                        <div style={{marginTop:"10rem"}}>
                                <p className='table_title'>Allocation for {moment(selectedDate).format("MMM Do, YYYY")} </p>
                            <div className='tableDiv' style={{ overflowY: "auto", overflowX: "auto"}}>
                                <table >
                                    <thead style={{position:"sticky", top: "0"}}> 
                                        <tr className='tableHeaderRow'>
                                            <th className='theader' style={{minWidth:'15rem'}}>Strategy</th>
                                            <th className='theader' style={{minWidth:'22rem'}}> Optimum Weight</th>
                                            <th className='theader' style={{minWidth:'22rem'}}>Advised Allocated Money</th>
                                            <th className='theader' style={{minWidth:'18rem'}}>Single Lot Price</th>
                                            <th className='theader' style={{minWidth:'15rem'}}>Advised Lot</th>
                                            <th className='theader' style={{minWidth:'12rem'}}>Cluster</th>
                                            <th className='theader' style={{minWidth:'12rem'}}>Equal Lot</th>
                                            <th className='theader' style={{minWidth:'12rem'}}>Equal Weight</th>
                                            <th className='theader' style={{minWidth:'20rem'}}>Equal Allocated Money</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tableBody'>{ advices.length > 0 ? advices.map((item:any)=>{
                                        return(<>
                                            <tr className='tableBodyRow'>
                                                <td className='tblbody' > {item?.s_model_code}</td>
                                                <td className='tblbody' style={{display: 'flex',width: '100%',alignItems: 'center'}}>
                                                    {<input type='range' className='progress' step="1" style={{width:"75%",marginRight:'1rem', pointerEvents: 'none', background :`${getRangeChartColor(theme,item?.optimum_weight.toFixed(2))}`}}
                                                        disabled  value={item?.optimum_weight}></input>} 
                                                    <span style={{width:'25%'}}>{item?.optimum_weight.toFixed(2)}%</span>
                                                </td>
                                                <td className='tblbody' >{item?.n_optimum_investment}</td>
                                                <td className='tblbody' > {item?.n_single_lot_investment}</td>
                                                <td className='tblbody' > {item?.n_optimum_lot}</td>
                                                <td className='tblbody' > {item?.s_cluster}</td>
                                                <td className='tblbody' >{item?.n_equi_lot}</td>
                                                <td className='tblbody' > {item?.eq_weight !=undefined || item?.eq_weight != null ?  `${(item?.eq_weight).toFixed(2)}%`:""}</td>
                                                <td className='tblbody' >{item?.n_equi_investment}</td>
                                            </tr>
                                
                                        </>)
                                    })                   
                                        :<p style={{margin:"1rem"}}>No records found</p>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    

                    </>:selectedNavItem === "allocation_history"?<>
                     <div className='displayStat'> 
                     {/* {
                         statDisplay.map((item:any)=>{
                             return(
                                 <>
                                     <span className='displayStatitem'>{item}</span>
                                 </>
                             )
                         })
                     } */}
                  </div>
                 <div style={{marginTop:"10rem"}}>
                         <p className='table_title'>Allocation from {moment(startDateFilter).format("MMM Do, YYYY")} to {moment(endDateFilter).format("MMM Do, YYYY")}  </p>
                     <div className='tableDiv' style={{ overflowY: "auto", overflowX: "auto"}}>
                         <table >
                             <thead style={{position:"sticky", top: "0"}}> 
                                 <tr className='tableHeaderRow'>
                                    <th className ='theader'  style={{minWidth:'15rem'}}>Date</th>
                                     <th className='theader' style={{minWidth:'15rem'}}>Strategy</th>
                                     <th className='theader' style={{minWidth:'22rem'}}>Optimum Weight</th>
                                     <th className='theader' style={{minWidth:'22rem'}}>Advised Allocated Money</th>
                                     <th className='theader' style={{minWidth:'18rem'}}>Single Lot Price</th>
                                     <th className='theader' style={{minWidth:'15rem'}}>Advised Lot</th>
                                     <th className='theader' style={{minWidth:'12rem'}}>Cluster</th>
                                     <th className='theader' style={{minWidth:'12rem'}}>Equal Lot</th>
                                     <th className='theader' style={{minWidth:'12rem'}}>Equal Weight</th>
                                     <th className='theader' style={{minWidth:'20rem'}}>Equal Allocated Money</th>
                                 </tr>
                             </thead>
                             <tbody className='tableBody'>{ advices.length > 0 ? advices.map((item:any,index:any)=>{
                                 return(<>

                                     <tr className='tableBodyRow' >
                                        {rowSpan[index]>0 && <td className='tblbody' rowSpan={rowSpan[index]} > {item?.dt_date}</td>}
                                         <td className='tblbody' > {item?.s_model_code}</td>
                                         <td className='tblbody' style={{display: 'flex',width: '100%',alignItems: 'center'}}>
                                             {<input type='range' className='progress' step="1" style={{width:"75%",marginRight:'1rem', pointerEvents: 'none', background :`${getRangeChartColor(theme,item?.optimum_weight.toFixed(2))}`}}
                                                 disabled  value={item?.optimum_weight}></input>} 
                                             <span style={{width:'25%'}}>{item?.optimum_weight.toFixed(2)}%</span>
                                         </td>
                                         <td className='tblbody' >{item?.n_optimum_investment}</td>
                                         <td className='tblbody' >{item?.n_single_lot_investment}</td>
                                         <td className='tblbody' >  {item?.n_optimum_lot}</td>
                                         <td className='tblbody' > {item?.s_cluster}</td>
                                         <td className='tblbody' >{item?.n_equi_lot}</td>
                                         <td className='tblbody' >{item?.eq_weight !=undefined || item?.eq_weight != null ?  `${(item?.eq_weight).toFixed(2)}%`:""}</td>
                                         <td className='tblbody' >{item?.n_equi_investment}</td>
                                     </tr>
                         
                                 </>)
                             })                   
                                 :<p style={{margin:"1rem"}}>No records found</p>}
                             </tbody>
                         </table>
                     </div>
                 </div>
                    </>:
                    ""}
            </div>
        </div>


    )
}