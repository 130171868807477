import jwt_decode from 'jwt-decode';
import { getNotifi, getPreviousPrivilege, requestLogin, requestValid, reqSimultaneous, requestIsActiveFalse, requestSimultaneous, reqTokenIsActiveFalse, reqlogoutByUserName, reqLogoutByToken, requestIsValidFalse } from '../buySell/portal';

export function getTokenFromStorage() {
    return window.localStorage.getItem("token");
}

export function setTokenToStorage(token: string) {
    return window.localStorage.setItem("token", token);
}

export function setItemToStorage(item: string, value: string) {
    return window.localStorage.setItem(item, value);
}

export function validateToken(token: string) {
    let decoded = jwt_decode(token);
}

export function getUserObject() {
    let isLoggedIn = false;
    let decoded: any;
    let token = getTokenFromStorage() || ''
    if(token && token !== null) {
        isLoggedIn = true;
    } 
    try {
        decoded = jwt_decode(token);
    } catch (error) {
        
    }
    return {
        isLoggedIn: isLoggedIn,
        name: decoded?.name,
        email_id: decoded?.email_id,
        privileges: decoded?.all_privilege?.split(',')
    }
    
}

export async function login(user: any) {
    let res = await requestLogin(user);
    if(res.status && res.status === "success") {
        setItemToStorage("userId", res.userId);
        setItemToStorage("token", res.token);        
        return true;
    }else {
        return false;
    }
}

export async function logout(token:any) {
    localStorage.removeItem("token")
    let res:any = await reqLogoutByToken(token)
    return res;
}

export async function checkSimultaneous(userName:any){
    let res:any = await reqSimultaneous(userName);
    return res;
}
export async function logOutPrevious(userName:any){
    let res:any = await requestIsActiveFalse(userName)
    return res;
}
export async function logoutByUserName(userName:any){
    localStorage.removeItem("token")
    let res:any = await reqlogoutByUserName(userName)
    
}

export async function checkValid(token: any, userName: any){
    let is_valid_response = await requestValid(token, userName);
    return is_valid_response;
}

export async function getSimultaneousValue(userName:any){
    let simultaneous_response = await requestSimultaneous(userName);
    return simultaneous_response;
}


export async function doIsValidFalse(userName:any){
    return await requestIsValidFalse(userName);
}

export async function matchPrivilege(userName:any, token:any){
    let matched:boolean = true;
    let privilegeFromToken:any = [] 
    //PRIVILEGES FROM USERTOKEN
    let decoded: any = jwt_decode(token);
    privilegeFromToken = decoded?.all_privilege;
    if(privilegeFromToken?.length>0){
        privilegeFromToken = privilegeFromToken.split(',');
    }
    
    //PRIVILEGES FROM DATABASE
    let newArr:any=[];
    let privilegeFromDB = await getPreviousPrivilege(userName);
    if(privilegeFromDB?.arr.length>0){
        privilegeFromDB?.arr.map((item:any)=>{
            newArr.push(item.s_code)
        })
        if(newArr.length > 0 && privilegeFromToken.length == 0){
            matched = false;
        }
        if(newArr.length == 0 && privilegeFromToken.length > 0){
            matched = false;
        }
    
        if(privilegeFromToken.length>0 && newArr.length >0){
            newArr = newArr?.sort((a:any, b:any)=>a-b);
            privilegeFromToken = privilegeFromToken?.sort((a:any, b:any)=>a-b);
            let str1 = JSON.stringify(newArr);
            let str2 = JSON.stringify(privilegeFromToken);
            let ans = str1.localeCompare(str2);
        
            if(ans !== 0){
                matched = false;
            }
        }
    }else if(privilegeFromDB?.arr?.length == 0 && privilegeFromToken?.length == 0){
        matched = true;
    }else if(privilegeFromDB?.arr?.length == 0 && privilegeFromDB?.on === false){
        matched = true;
    }else{
        matched = false;
    }
    return matched;
}

export async function getNotificationDetails(userName:any){
    await getNotifi(userName);
}