import { axiosBuySell, axiosUSD } from "../axios";
import { getUserObject } from "../Service/auth.service";

let userObj:any = getUserObject();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////INTRADAY HISTORICAL////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// export function getIntradaySITargetSl(id:any,asset:any,date:any,version:any)
// {
//     return axiosBuySell.post(`/intraday/get_Intraday_targetsl`,
//     {
//         "id":id,
//         "asset":asset,
//         "date":date,
//        "version": version
//     })
//             .then((res) => {
//                 return res.data;
//         }).catch((err) => console.log(err))
// }


//change have donehere
//intraday- and historical-targetsl-------------------------------------------------------------------------------------------
export function getIntradayliveSITargetSl(id:any,asset:any,date:any,version:any)
{
    return axiosBuySell.post(`/intraday/get_target_stoploss`,
    {
        "id":id,
        "asset":asset,
        "date":date,
        "version":version
        // "username": userObj.email_id
    })
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}



//changes have done here
//intraday-stock indxex-live and historical mtm-drawdown
export function getIntradaySImtmDrawdown(id:any,asset:any,date:any,version:any)
{
    return axiosBuySell.post(`/intraday/get_m2m_drawdown`,{

        "id":id,
        "asset":asset,
        "date":date,
       "version": version
    //    "username": userObj.email_id
    })
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

export function getSEdate(months:any, asset:any, type:any){
    return axiosUSD.post(`/intraday/get_startDate_endDate`,{"month": months, "asset":asset, "type":type,"username": userObj.email_id})
    .then((res)=>{
        return res;
    }).catch((e)=>console.log("ERROR:", e)
    
    )
}

export function getIntradaySIAdvices(startDate: any, endDate:any,assetType:string,version:any)
{
     return axiosBuySell.post(`/intraday/get_trades`,{
        "startDate":startDate,
        "endDate":endDate,
        "asset":assetType,
        "version": version,
        "username": userObj.email_id
    })
        .then((res) => {
            return res.data;
        }).catch((err) => console.log(err))
}
export function getTotalAssets(startDate:any, endDate:any,assetType:string,version:any)
{
     return axiosBuySell.post(`/intraday/get_pl_percent`,{"startDate":startDate,"endDate":endDate,"asset":assetType,"version":version,"username": userObj.email_id})
        .then((res) => {
            return res.data;
        }).catch((err) => console.log(err))
}
export function getIntradayCalls(startDate:any, endDate:any,assetType:string, version:any)
{
     return axiosBuySell.post(`/intraday/get_calls`,{ "startDate":startDate,"endDate":endDate,"asset":assetType,"version":version,"username": userObj.email_id})
        .then((res) => {
            return res.data;
        }).catch((err) => console.log(err))
}
export function getIntradaySr(startDate:any, endDate:any,assetType:string,version:any)
{
     return axiosBuySell.post(`/intraday/get_sr`,{ "startDate":startDate,"endDate":endDate,"asset":assetType,"version":version,"username": userObj.email_id})
        .then((res) => {
            return res.data;
        }).catch((err) => console.log(err))
}
export function getIntradayPl(startDate:any, endDate:any,assetType:string, version:string)
{
     return axiosBuySell.post(`/intraday/get_pl`,{"startDate":startDate,"endDate":endDate,"asset":assetType,"version":version,"username": userObj.email_id })
        .then((res) => {
            return res.data;
        }).catch((err) => console.log(err))
}
// for live trade in Intraday Stock Index //
////////////////////////////////////////////////////////////////////////////////



export function getliveIntradayAdvices(assetType:any,version:any)
{
    return axiosBuySell.post(`/intraday/get_live_trades`,{"asset":assetType,"version":version,"username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}

export function getLiveCalls(assetType:any,version:any)
{
    return axiosBuySell.post(`/intraday/get_live_calls`,{"asset":assetType,"version":version,"username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}



export function getLiveSr(assetType:any, version:any)
{
    return axiosBuySell.post(`/intraday/get_live_sr`,{"asset":assetType,"version":version,"username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}


export function getLivePl(assetType:any, version:any)
{
    return axiosBuySell.post(`/intraday/get_live_pl`,{"asset":assetType,"version":version,"username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}


export function getLiveAssetWisePL(assetType:any, version:any)
{
    return axiosBuySell.post(`/intraday/get_live_pl_percent`,{"asset":assetType,"version":version,"username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}


export function getIntradayParameter(id:any,asset:any,version:any,date:any)
{
    return axiosUSD.post(`/intraday/get_parameter`,{"asset":asset,"version":version,"date":date,"id":id,"username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err)=>console.log(err))
}


//######################################################################################################################
// -------------------------------#            commodity live              #---------------------------------------------
//######################################################################################################################


export function getliveComodityAdvices(assetType:any,version:any)
{
    return axiosBuySell.post(`/commodity/get_live_trades`,{"asset":assetType,"version":version,"username":userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}


export function getliveIntradayPlCommodity(assetType:any,version:any)
{
    return axiosBuySell.post(`/commodity/get_live_pl`,{"asset":assetType,"version":version,"username":userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}




export function getliveIntradaySrCommodity(assetType:any,version:any)
{
    return axiosBuySell.post(`/commodity/get_live_sr`,{"asset":assetType,"version":version,"username":userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}


export function getliveIntradayCallsCommodity(assetType:any,version:any)
{
    return axiosBuySell.post(`/commodity/get_live_calls`,{"asset":assetType,"version":version,"username":userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}


export function getLiveTotalPlPercentCommodity(assetType:any,version:any)
{
    return axiosBuySell.post(`/commodity/get_live_pl_percent`,{"asset":assetType,"version":version,"username":userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}

//live targetsl commodity
export function getliveIntradayTargetslnCommodity(id:any,asset:any,version:any,date:any,trade_type:any)
{
    return axiosBuySell.post(`/commodity/get_target_stoploss`,
    {
        "date":date,
        "asset":asset,
        "version":version,
        "trade_type":trade_type,
        "id":id
        // "username":userObj.email_id
    })
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}


//live mtm-drawdown charts
export function getCmMtmDrawdownCommodity(id:any,asset:any,version:any,date:any)
{
    return axiosBuySell.post(`/commodity/get_m2m_drawdown`,
    {
        "asset":asset,
        "date":date,
        "version":version,
        "id":id
        // "username":userObj.email_id
    })
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}

// ---------------------------------------commodity stocks------------------------------------------------------------------------------------------------------------------------------------------

export function getCommodityParameter(id:any,asset:any,version:any,date:any)
{
    return axiosUSD.post(`/commodity/get_parameter`,
    {
        "asset":asset,
       "version": version,
       "date":date,
       "id":id ,
       "username":userObj.email_id
   })
        .then((res:any)=>{
            return res.data
        }).catch((err)=>console.log(err))
}
export function get_Intraday_calls_Commodity(startDate:any, endDate:any,asset:any,version:any)
{
    return axiosBuySell.post(`/commodity/get_calls`,
    {
        "asset":asset,
        "startDate": startDate,
        "endDate":endDate,
        "version":version,
        "username":userObj.email_id
   }
    )
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}
export function get_Intraday_sr_Commodity(startDate:any,endDate: any,asset:any,version:any)
{
    return axiosBuySell.post(`/commodity/get_sr`,
    {
        "asset":asset,
        "startDate": startDate,
        "endDate": endDate,
        "version":version,
        "username":userObj.email_id
   }
    )
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}
export function get_Intraday_pl_Commodity(startDate:any,endDate: any,asset:any,version:any)
{
    return axiosBuySell.post(`/commodity/get_pl`,
    {
        "asset":asset,
        "startDate": startDate,
        "endDate": endDate,
        "version":version,
        "username":userObj.email_id
   }
    )
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}
export function get_Intraday_advice_Commodity(startDate:any,endDate: any,asset:any,version:any)
{
    return axiosBuySell.post(`/commodity/get_trades`,
    {
        "asset":asset,
        "startDate": startDate,
        "endDate": endDate,
        "version":version,
        "username":userObj.email_id
   }
    )
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}
//historical mtm-drawdown charts
export function get_Intraday_MtmDrawdown_Commodity(id:any,asset:any,date:any)
{
    return axiosBuySell.post(`/commodity/get_m2m_drawdown`,
    {
     
        "date":date,
        "asset":asset,
        "id":id
        // "username": userObj.email_id 
    } )
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}

//historical targetsl charts
// export function get_Intraday_targetsln_Commodity(id:any,asset:any,date:any)
// {
//     return axiosBuySell.post(`/commodity/get_Intraday_live_targetsl_Commodity`,
//     {
     
//         "date":date,
//         "asset":asset,
//         "id":id 
//     })
//         .then((res:any)=>{
//             return res.data
//         }).catch((err) => console.log(err))
// }


export function get_Intraday_pl_percentage_Commodity(startDate:any, endDate:any,asset:any,version:any)
{
    return axiosBuySell.post(`/commodity/get_pl_percent`,{
        "asset":asset,
        "startDate": startDate,
        "endDate":endDate,
        "version":version,
        "username": userObj.email_id
    })
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}

// export function getSelectedAsset(assetType:any,version:any)
// {
//     if (assetType == 'intraday-stock-index')
//     {
//         return axiosBuySell.post(`/intraday/livecallsAssets`,{
//             "asset_pair":assetType,
//             "version":'AI',
//             "username": userObj.email_id
//         })
//             .then((res:any)=>{
//                 return res.data
//             }).catch((err) => console.log(err))
//     }

    
//     else if(assetType == 'comodity-future'){
//         return axiosBuySell.post(`/commodity/live_calls_Assets`,{
//             "asset_pair" : assetType,
//             "username":userObj.email_id
//         })
//             .then((res:any)=>{
//                 return res.data
//             }).catch((err) => console.log(err))
//     }
//     else if(assetType == 'fx-future')
//     {
//         return axiosUSD.post(`currency/get_Live_Intraday_callsAssets`,{"asset":assetType,"version":version,"username":userObj.email_id})
//         .then((res:any)=>{
//             return res.data
//         }).catch((err)=>console.log(err))
//     }
// }



//#########################################################################################################################################
// for Currency Future Intraday.....................................
//                                              CURRENCY hISTORICAL
//#########################################################################################################################################
export function getCurrencyPlPercentage(startDate:any, endDate:any,asset:any,version:any)
{
    return axiosUSD.post(`/currency/get_pl_percent`,
    {
        "asset":asset,
        "startDate": startDate,
        "endDate":endDate,
        "version": version,
        "username":userObj.email_id
    }
    )
        .then((res:any)=>{
            return res.data
        }).catch((err)=>{
            console.log(err);
            
        })
}

export function getCurrencySr(startDate:any, endDate:any,asset:any,version:any)
{
    return axiosUSD.post(`/currency/get_sr`,
    {
        "asset":asset,
        "startDate": startDate,
        "endDate":endDate,
        "version": version,
        "username":userObj.email_id
    }
    )
        .then((res:any)=>{
            return res.data
        }).catch((err)=>{
            console.log(err);
            
        })
}

export function getCurrencyCalls(startDate:any, endDate:any,asset:any,version:any)
{
    return axiosUSD.post(`/currency/get_calls`,
    {
        "asset":asset,
        "startDate": startDate,
        "endDate":endDate,
        "version": version,
        "username":userObj.email_id
    }
    )
        .then((res:any)=>{
            return res.data
        }).catch((err)=>{
            console.log(err);
            
        })
}
export function getCurrencyPl(startDate:any,endDate: any,asset:any,version:any)
{
    return axiosUSD.post(`/currency/get_pl`,
    {
        "asset":asset,
        "startDate": startDate,
        "endDate": endDate,
        "version": version,
        "username":userObj.email_id
    }
    )
        .then((res:any)=>{
            return res.data
        }).catch((err)=>
            console.log(err))
}
export function getCurrencyAdviceStatus(startDate:any,endDate: any,asset:any,version:any)
{
    return axiosUSD.post(`/currency/get_trades`,
    {
        "asset":asset,
        "startDate": startDate,
        "endDate": endDate,
        "version": version,
        "username":userObj.email_id
    })
        .then((res:any)=>{
            return res.data
        }).catch((err)=>console.log(err))
}




//changes have done here //comment 
//currency-historical- targetsl--------------------------------------------------------------------------------------------------currency-historical targetsl
// export function getCurrencyTargetSl(id:any,asset:any,version:any,date:any) //------------------------one(get_Intraday_targetsl)
// {
//     return axiosUSD.post(`/currency/get_Intraday_targetsl`,{

//         "asset":asset,
//         "version": version,
//         "date":date,
//         "id":id 
//     })
//         .then((res:any)=>{
//             return res.data
//         }).catch((err)=>console.log(err))
// }



//changes have done here

//##########################################################################################################################################################################
//                                                          CURRENCY TARGET_STOPLOSS AND PARAMETER

//##########################################################################################################################################################################
//currency-live and historical tyargetsl
export function getCurrencyLiveTargetSl(id:any,asset:any,version:any,date:any)
{
    return axiosUSD.post(`/currency/get_target_stoploss`,{
        "asset":asset,
        "version": version,
        "date":date,
        "id":id 
        // "username":userObj.email_id

    })
        .then((res:any)=>{
            return res.data
        }).catch((err)=>console.log(err))
}



export function getCurrencyParameter(id:any,asset:any,version:any,date:any)
{
    return axiosUSD.post(`/currency/get_parameter`,{
        "asset":asset,
        "version": version,
        "date":date,
        "id":id,
        "username":userObj.email_id

    })
        .then((res:any)=>{
            return res.data
        }).catch((err)=>console.log(err))
}


//changes have done here
//mtm_drawdown for currency
export function getCurrencyMtmDrawdown(id:any,asset:any,version:any,date:any)
{
    return axiosUSD.post(`/currency/get_m2m_drawdown`,{
        "asset":asset,
        "version": version,
        "date":date,
        "id":id
        // "username":userObj.email_id

    })
        .then((res:any)=>{
            return res.data
        }).catch((err)=>console.log(err))
}



//#########################################################################################################################################################################
//                                                                    CURRENCY LIVE
//#########################################################################################################################################################################
//----------------for currency intraday live ....................

export function getLiveCurrencyTotalplPercentage(assetType:any,version:any){
    return axiosUSD.post(`/currency/get_live_pl_percent`,{"asset":assetType,"version":version,"username":userObj.email_id})
    .then((res:any)=>{
        return res.data
    }).catch((err)=>console.log(err))
}
export function getLiveCurrencyCalls(assetType:any,version:any){
    return axiosUSD.post(`/currency/get_live_calls`,{"asset":assetType,"version":version,"username":userObj.email_id})
    .then((res:any)=>{
        return res.data
    }).catch((err)=>console.log(err))
}
export function getLiveCurrencySr(assetType:any,version:any){
    return axiosUSD.post(`/currency/get_live_sr`,{"asset":assetType,"version":version,"username":userObj.email_id})
    .then((res:any)=>{
        return res.data
    }).catch((err)=>console.log(err))
}
export function getLiveCurrencyPl(assetType:any,version:any){
    return axiosUSD.post(`/currency/get_live_pl`,{"asset":assetType,"version":version,"username":userObj.email_id})
    .then((res:any)=>{
        return res.data
    }).catch((err)=>console.log(err))
}
export function getLiveCurrencyAdvices(assetType:any,version:any){
    return axiosUSD.post(`/currency/get_live_trades`,{"asset":assetType,"version":version,"username":userObj.email_id})
    .then((res:any)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

// export function getCurrencyTotalplPercentage(assetType:any,version:any){
//     return axiosUSD.post(`/currency/get_Live_Intraday_totalplpercentage?asset=${assetType}&version=${version}`)
//     .then((res:any)=>{
//         return res.data
//     }).catch((err)=>console.log(err))
// }
// export function getCurrencyTotalplPercentage(assetType:any,version:any){
//     return axiosUSD.post(`/currency/get_Live_Intraday_totalplpercentage?asset=${assetType}&version=${version}`)
//     .then((res:any)=>{
//         return res.data
//     }).catch((err)=>console.log(err))
// }