import './style.css';
import moment from 'moment';
import {Modal} from 'react-bootstrap';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TargetSlChartProps {
  dataset: any,
  type:any
  theme:any
  selectedTradeDetails:any
}

export const TargetSlChart: React.FC<TargetSlChartProps> = ({ dataset,type,theme,selectedTradeDetails }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let labelsArr = []
  let dataArr:any = []
  let dataArrT:any = []
  let dataArrSl:any = []
  const chartArr:any=[]
  const ColorArray:any=[]


  let axis_lable_color :any 
  let border_color :any

  if(theme == 'dark'){
    axis_lable_color = '#fff'
    border_color = '#262626'
  }
  else if (theme == 'light'){
    axis_lable_color = '#000'
    border_color = '#a2cdf4'
}
else if(theme == 'b/w'){
  axis_lable_color = '#fff'
  border_color = '#ffffff30'
}
for (let i in dataset) {
  if(type == 'intraday-stock-index' || type == 'intraday-stock-future' || type == 'fx-future' || type === 'fx-option' || type == 'comodity-future' || type === "options-stock" || type === "options-index" )
  {      
    labelsArr.push(moment(new Date(dataset[i]?.dt_date+" "+dataset[i]?.time)).format('DD\'MMM HH:mm'))
    dataArr.push(Number(dataset[i]?.close_price).toFixed(3))
    dataArrT.push(Number(dataset[i]?.target).toFixed(3))
    dataArrSl.push(Number(dataset[i]?.stop_loss).toFixed(3))
  }
  else if(type == 'overall'  ){  
    labelsArr.push(moment(new Date(dataset[i].date)).format('DD\'MMM HH:mm'))
    dataArr.push(Number(dataset[i].transaction_price))
    dataArrT.push(Number(dataset[i].target).toFixed(3))
    dataArrSl.push(Number(dataset[i].stop_loss).toFixed(3))
  }
  else if( type==="fx-future-multiday" )
  {
    labelsArr.push(moment(new Date(dataset[i]?.time)).format('DD\'MMM HH:mm'))
    dataArr.push(Number(dataset[i]?.close_price).toFixed(2))
    dataArrT.push(Number(dataset[i]?.target).toFixed(2))
    dataArrSl.push(Number(dataset[i]?.stop_loss).toFixed(2))
  }
  else if( type==="multiday-equity" )
  {
    labelsArr.push(moment(new Date(dataset[i]?.time)).format('DD\'MMM HH:mm'))
    dataArr.push(Number(dataset[i]?.close_price).toFixed(2))
    dataArrT.push(Number(dataset[i]?.target).toFixed(2))
    dataArrSl.push(Number(dataset[i]?.stop_loss).toFixed(2))
  }
  else{
    if(dataset[i]?.date?.length>10 ){
      labelsArr.push(moment(new Date(dataset[i]?.date)).format('DD\'MMM HH:mm'))
    }
    else{
      labelsArr.push(moment(new Date(dataset[i].date)).format('DD\'MMM HH:mm'))
    }
    dataArr.push(Number(dataset[i]?.transaction_price).toFixed(2))
    dataArrT.push(Number(dataset[i]?.target).toFixed(2))
    dataArrSl.push(Number(dataset[i]?.stop_loss).toFixed(2))
  }
}
  
if(( type==="multiday-equity")&&(selectedTradeDetails?.s_version=='QESADM'))
{
  chartArr.push({
    name: "Price",
    data: dataArr
  },
  {
    name: "Stop Loss",
    data: dataArrSl
  })
  ColorArray.push('#268fb5',  '#cf3e3e')
}
else
{
  chartArr.push({
    name: "Price",
    data: dataArr
  },
  {
    name: "Target",
    data: dataArrT
  },
  {
    name: "Stop Loss",
    data: dataArrSl
  })
  ColorArray.push('#268fb5', '#289623', '#cf3e3e')
}

  const data1: any = {

    series: chartArr,
    options: {
      chart: {
        animations: {
          enabled: false,
          easing: 'linear',
          speed: 1800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 10
          }
        },
        type: 'line',
        dropShadow: {
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        },
        tooltip: {
          enabled: false,
        }

      },
      colors: ColorArray,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,

      },
      title: {
        text: 'Target Stop Loss (Point/Lot)',
        align: 'left',
        style: {
          color: axis_lable_color
        }
      },
      grid: {
        borderColor: border_color, 
        row: {
           colors: ['transparent', 'transparent'],
          opacity: 0.5
        },
      },
      markers: {
        size: 0
      },
      animations: {
        enabled: false
      },
      xaxis: {
        categories: labelsArr,
        tickPlacement: 'between',
        tickAmount:8,
        labels: {
          show: true,
          style: {
            colors: axis_lable_color
          }
        },
        axisBorder: {
          show: true,
          color: '#78909C',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: false,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0
        },

      },
      yaxis: {
        title: {
        },
        labels: {
          show: true,

          style: {
            colors: axis_lable_color
          }
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0
        },

      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
        labels: {
          colors: axis_lable_color
        }
      }
    },

  };

  return (<>
    <div style={{ height: "10%" }}>
      <span className="chart_view_button" style={{ float: "right", padding: "0rem", border: "1px solid white" }} onClick={handleShow}>
        <FontAwesomeIcon icon={faExpandArrowsAlt} />
      </span>
    </div>
    <div className="drawdown-chart">
      <ReactApexChart id="targetSlApexChart" options={data1.options} series={data1.series} type="line" height="100%" />
      <Modal show={show} onHide={handleClose} style={{ backdropFilter: "blur(3px)"}} size="xl" className='drawdown-chart'>
          <Modal.Header closeButton>
            <Modal.Title style={{width:"100%"}}>

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="chartItem" style={{width:"87%",height:"70%",margin:"2% 7%",position:"absolute"}}>
                <ReactApexChart options={data1.options} series={data1.series} type="area" height='100%' />
            </div>
          </Modal.Body>
      </Modal>
    </div>
  </>
  )
}