import './LogoContainer.css'; 

interface AddContainerProps {}

export const LogoContainer: React.FC<AddContainerProps> = () => {

    return (
        <div className="logo-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 align-self-center">
                        <div className="logo">
                            <a href="/">
                                <img src="assets/icon/vista_logo.png" alt="Vista Intelligence"/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-9 align-self-center">
                        <div className="banner1">
                                <img style={{borderRadius: 6}} width="890" height="93" src="assets/icon/dummy-ad.jpg" alt="Vista Intelligence"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}