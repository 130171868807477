import { axiosBuySell } from "../axios";
import { getUserObject } from "../Service/auth.service";

let userObj: any = getUserObject();


//##########################################################################################################################################################################
//                                                           HISTORICAL APIS
//##########################################################################################################################################################################

export function getIntradayStockFutureAdvices(startDate: any, endDate: any, assetType: string, version: any) {
    return axiosBuySell.post(`/stock_future/get_trades`, {
        "startDate": startDate,
        "endDate": endDate,
        "asset": assetType,
        "version": version,
        "username": userObj.email_id
    })
        .then((res) => {
            return res.data;
        }).catch((err) => console.log(err))
}

export function getIntradayStockFutureCalls(startDate: any, endDate: any, assetType: string, version: any) {
    return axiosBuySell.post(`/stock_future/get_calls`, {
        "startDate": startDate,
        "endDate": endDate,
        "asset": assetType, 
        "version": version,
        "username": userObj.email_id
    })
        .then((res) => {
            return res.data;
        }).catch((err) => console.log(err))
}

export function getIntradayStockFutureSr(startDate:any, endDate:any,assetType:string,version:any)
{
     return axiosBuySell.post(`/stock_future/get_sr`,{
         "startDate":startDate,
         "endDate":endDate,
         "asset":assetType,
         "version":version,
         "username": userObj.email_id})
        .then((res) => {
            return res.data;
        }).catch((err) => console.log(err))
}

export function getIntradayStockFuturePl(startDate:any, endDate:any,assetType:string, version:string)
{
     return axiosBuySell.post(`/stock_future/get_pl`,{
        "startDate":startDate,
        "endDate":endDate,
        "asset":assetType,
        "version":version,
        "username": userObj.email_id })
        .then((res) => {
            return res.data;
        }).catch((err) => console.log(err))
}
export function getIntradayStockFuturePlPercentage(startDate:any, endDate:any,assetType:string, version:string)
{
     return axiosBuySell.post(`/stock_future/get_pl_percent`,{
        "startDate":startDate,
        "endDate":endDate,
        "asset":assetType,
        "version":version,
        "username": userObj.email_id })
        .then((res) => {
            return res.data;
        }).catch((err) => console.log(err))
}

export function getStockFutureContractDate()
{
        return axiosBuySell.post(`/stock_future/get_Contract_Date_stock_future`)
                .then((res) => {
                    return res.data;
            }).catch((err) => console.log(err))
}





//##########################################################################################################################################################################
//                                                           LIVE APIS
//##########################################################################################################################################################################

export function getliveIntradayStockFutureAdvices(assetType:any,version:any)
{
    return axiosBuySell.post(`/stock_future/get_live_trades`,{
        "asset":assetType,
        "version":version,
        "username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}

export function getliveIntradayStockFutureCalls(assetType:any,version:any)
{
    return axiosBuySell.post(`/stock_future/get_live_calls`,{
        "asset":assetType,
        "version":version,
        "username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}

export function getliveIntradayStockFutureSR(assetType:any,version:any)
{
    return axiosBuySell.post(`/stock_future/get_live_sr`,{
        "asset":assetType,
        "version":version,
        "username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}

export function getliveIntradayStockFuturePL(assetType:any,version:any)
{
    return axiosBuySell.post(`/stock_future/get_live_pl`,{
        "asset":assetType,
        "version":version,
        "username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}

export function getliveIntradayStockFuturePLPercentage(assetType:any,version:any)
{
    return axiosBuySell.post(`/stock_future/get_live_pl_percent`,{
        "asset":assetType,
        "version":version,
        "username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err) => console.log(err))
}



// export function live_calls_assets_stock_future()
// {
//         return axiosBuySell.post(`/stock_future/live_calls_assets_stock_future`)
//                 .then((res:any)=>{
//                       return res.data 
//                 }).catch((err)=>console.log(err))
// }


//##########################################################################################################################################################################
//                                                            PARAMETER, TARGET STPOLOSSS,MTM DRAWDOWN
//##########################################################################################################################################################################

export function getIntradayStockFutureParameter(id:any,asset:any,version:any,date:any)
{
    return axiosBuySell.post(`/stock_future/get_parameter`,{
        "asset":asset,
        "version":version,
        "date":date,
        "id":id,
        "username": userObj.email_id})
        .then((res:any)=>{
            return res.data
        }).catch((err)=>console.log(err))
}

////////////////Charts

export function getIntradayStockFutureTargetSl(id:any,asset:any,date:any,version:any)
{
    return axiosBuySell.post(`/stock_future/get_target_stoploss`,
    {
        "id":id,
        "asset":asset,
        "date":date,
        "version":version
        // "username": userObj.email_id
    })
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}


export function getIntradayStockFuturemtmDrawdown(id:any,asset:any,date:any,version:any)
{
    return axiosBuySell.post(`/stock_future/get_m2m_drawdown`,{

        "id":id,
        "asset":asset,
        "date":date,
       "version": version
    //    "username": userObj.email_id
    })
            .then((res) => {
                return res.data;
        }).catch((err) => console.log(err))
}

