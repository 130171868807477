import './style.css';
import {  useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faEye,faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkSimultaneous, logOutPrevious } from '../../../Api/Service/auth.service';
import Button from 'react-bootstrap/Button';


interface LoginLandingPageProps {
    handleLogin: any
    theme:any
}
function MyVerticallyCenteredModal(props:any) {
    
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered className='simul'
        style={{backdropFilter: "blur(3px)"}}
      >
        <Modal.Body>
            <Row style={{marginTop:"2.5rem"}}>
                <div className='col-lg-2'><img style={{width:"100%",alignItems:''}} src={props.theme === 'light'?"assets/icon/privacy.png" :"assets/icon/privacy_white.png"} alt="" /></div>
                <div className='col-lg-10'><h6 style={{color:"inherit"}}>This user is already logged in somewhere.<br/> Are you sure want to login here ?</h6></div>
            </Row>
            <div style={{display:"flex", justifyContent:"space-between", marginLeft:"30%", marginTop:"2.5rem",width:"160px"}}>
                <div><Button variant="primary" style={{width:"70px"}} onClick={props.handleLogOutPrevious}>Yes</Button></div>
                <div><Button variant="secondary" style={{width:"80px"}} onClick={()=>props.setModalShow(false)}>Cancel</Button></div>
            </div>
        </Modal.Body>
      </Modal>
    );
  }

export const LoginLandingPage: React.FC<LoginLandingPageProps> = ({ handleLogin,theme }) => {
    const [email, setEmail] = useState<any>()
    const [password, setPassword] = useState<any>()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const notify = (value:any) => toast(value);
    const [passwordIcon,setPasswordIcon] = useState<any>(faEye)
    const [modalShow, setModalShow] = useState<any>(false);
    const [chkBoxError, setChkBoxError] = useState<any>(false)
    let [countTrue, setCountTrue]= useState<any>(0)
    const [requiredPassword, setRequiredPassword] = useState(false)
    const [requiredEmail, setRequiredEmail] = useState(false)


    const handleLogOutPrevious = async()=>{
        let res:any = await logOutPrevious(email);
        if(res){
            return handleLogin(email, password);
        }
    }
    
    const handleLoginBtnClick = async() => {
        if(email !== undefined && password !== undefined && requiredEmail === false && requiredPassword === false){

            let hasSimultanious:any = await checkSimultaneous(email);
            if(hasSimultanious[0]?.count == '0'){
                if(chkBoxError === true){
                    return handleLogin(email, password);
                }else{
                    notify("Please agree to the terms");
                }
            }else{
                setModalShow(true)
            }
        }else{
            notify("Please enter required field");
        }
        
    }

    const handleSetPassword = async(event:any)=>{
        if(event ==='' || event===null ){
            setRequiredPassword(true)
        }else{
            setRequiredPassword(false)
            setPassword(event)
        }
    }
    
    const handleSetEmail = async(event:any)=>{
        if(event ==='' || event===null ){
            setRequiredEmail(true)
        }else{
            setRequiredEmail(false)
            setEmail(event)
        }
    }
   
    let input:any = document.getElementById("agreeChk");
    let EnterBtn:any = document.getElementById("myBtn")
    input?.addEventListener("keypress", function(event:any) {
        if (event.key === "Enter") {
            setChkBoxError(true)
            setCountTrue(countTrue++);
            if(countTrue ==2){

                event.preventDefault();
                EnterBtn.click();
            }
        }
    });


    const showPassword = () =>{
        let x:any = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
            setPasswordIcon(faEyeSlash)
        } else {
            x.type = "password";
            setPasswordIcon(faEye)
        }
    }


    return (
        <div className="landing-page" style={{marginTop:"50%"}}>
            <div className='welcome_text' style={{marginBottom:"10%"}}>
                <span style={{fontSize:"1.8rem"}}>Welcome to Vista FeedSense</span>
            </div>
            <div className="login-form">
                <Row>
                    <div className='col-lg-1' id="col-lg-1" ></div>
                    <div className='col-lg-10' id="col-lg-10" >
                        <form>
                        
                            <div className="form-group" >
                                <input style={{ fontStyle: "Nunito !important" }} type="text" id="email" className="form-control custom-input input_form_box_login input_div" placeholder="Email" onChange={(e: any) => handleSetEmail(e.target.value)}/>
                            </div>
                            {requiredEmail ? <span style={{color: "red"}}>Please enter Email</span> : ''}
                            
                            <div className="form-group" style={{display:"flex",alignItems:"center"}}>
                                <input type="password" id="password" className="form-control custom-input input_form_box_login password_div" placeholder="Password" onChange={(e: any) => handleSetPassword(e.target.value)} />
                                <FontAwesomeIcon icon={passwordIcon} className="password_icon" onClick={()=>showPassword()}/>
                            </div>
                            {requiredPassword ? <span style={{color: "red"}}>Please enter password</span> : ''}
                            <div className="agree-terms" style={{marginTop:"2rem"}}>
                            {/* <input type="checkbox" id="agreeChk" className={"agree " + (chkBoxError && "error-agree-chk")}/> */}
                                <input type="checkbox" id="agreeChk" className="agree" checked={chkBoxError} onChange={()=>setChkBoxError(!chkBoxError)}/>
                                <span> I read and agree to Vista AI terms of use.</span>
                            </div>
                            <div className="align-center"  style={{marginTop:"1.8rem"}}>
                                <button onClick={() => {handleLoginBtnClick() }} type="button" id="myBtn" className="btn login_using_buttons login_button" style={{ animation: "none " }}>Login</button>
                            </div>
                        </form>
                        <div style={{ margin: "8% 8% 4% 8%", borderBottom: "1px solid beige" }}></div>
                        <div className='privacy_policy_text' onClick={handleShow}> Privacy policy</div>
                        <Modal show={show} className="privacy_policy_modal" onHide={handleClose} style={{ userSelect: "none" }} size="xl"  >
                            <Modal.Header closeButton>
                                <Modal.Title style={{ width: "100%", fontWeight: "bolder" }}>
                                    <img src="assets/icon/vista_logo.png"  />
                                    <span>FeedSense – Vista Intelligence Privacy Statement</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{ width: "96%%", height: "100%", fontFamily: "Nunito" }}>

                                    <div className="privacy-policy-headers">Privacy of Information:</div>
                                    FeedSense application is owned and operated by Vista Intelligence through mobile phone devices or handheld devices. Any Personal Information provided by you or any personal information collected online via the FeedSense application is important to us and the same is protected with the utmost care by us. Vista Intelligence does not guarantee the protection of any information including but not limited to the Personal Information collected offline or through any other source and/or through any other third-party websites for which a link is available on the Vista Intelligence website for the purpose of either advertisement or for the purpose of other information for interested persons. These third-party websites are not under Vista Intelligence’s control and therefore any Personal Information provided on such websites is not captured at Vista Intelligence’s end and therefore no protection is available for Personal Information provided by you on such third-party websites. We are not responsible and/or liable for the disclosure of your Personal Information through such third-party websites. You are therefore requested to verify the privacy policies on such third-party websites before proceeding.
                                    <div className="privacy-policy-headers" >Personal Information:</div>
                                    "Personal Information" shall mean and include all such information whereby you can be identified as a particular individual by your name, phone number, email address, or any additional set of information such as unique device identifiers like IMEI, MEID, IMSI, or ESN, Media Access Control “MAC” address, and Internet Protocol or “IP” address etc.
                                    <div className="privacy-policy-headers">Collection and Use of your Personal Information:</div>
                                    We are committed at all times to ensuring the privacy of your Personal Information. We may obtain your information through your mobile phone device, or handheld device which may include information that can be used to identify you as a particular individual. The said information will be used by us to improve your experience of the FeedSense application service and update you about the same.
                                    <div className="privacy-policy-headers">Device Information:</div>
                                    We collect information from or about the phones, or other devices where you install or access Vista Intelligence FeedSense services. We may associate the information we collect from your devices, which helps us to provide consistent Services on your devices. Here are some examples of the device information we collect: Attributes such as the operating system, hardware version, device settings, and device identifiers. We also collect and analyze the International Mobile Equipment Identity (IMEI), operating system platform, and version of your device. We may also collect technical information to help us identify your device for diagnostic purposes only. The personally identifiable information is used by us to resolve disputes; troubleshoot problems; help to provide a safe service; to prevent abuse of the FeedSense Application.
                                    <div className="privacy-policy-headers">Cookies Information:</div>
                                    When you visit the Vista Intelligence site, we may send one or more cookies - a small text file containing a string of alphanumeric characters - to your handheld device/ computer that uniquely identifies your browser. A session cookie is temporary and disappears after you close your browser. You can configure your web browser to refuse all cookies or to indicate when a cookie is being sent. However, the Site may not function properly if the ability to accept cookies is disabled.
                                    <div className="privacy-policy-headers">Vista Intelligence Commitment to Data Security:</div>
                                    Vista Intelligence uses the best practice in the industry to preserve the integrity and security of your Personal Information. Vista Intelligence cannot, however, ensure or warrant the security of any information you transmit from your device or on the Vista Intelligence website for FeedSense services. Once we receive your information, Vista Intelligence makes reasonable efforts to ensure the security of Vista Intelligence systems. However, please note that this is not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of Vista Intelligence's physical, technical, or managerial safeguards. If we learn of a security breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. We may post a notice on Vista Intelligence Site or update through the FeedSense Application service if a security breach occurs.
                                    <div className="privacy-policy-headers">Who has access to the personal information?</div>
                                    Vista Intelligence is bound by our commitments to the protection of your privacy/Personal Information and therefore Vista Intelligence does not provide access to your Personal Information to any person or third party. Notwithstanding Vista Intelligence’s commitment to the protection of your Personal Information, we may share your Personal Information with Vista Intelligence solicitors, lawyers, auditors, or any law enforcement authority on the direction for investigation, resolution of any dispute between us, or such governmental and local authorities on-demand or request. In such event, we shall not be held responsible for any breach of your privacy protection provided under this Privacy Statement.
                                    <div className="privacy-policy-headers">How can I manage information about myself?</div>
                                    We store data for as long as it is necessary to provide services to you, including those described above. Information associated with your account will be retained with us even after you have discontinued Vista Intelligence services and confidentiality will be maintained.
                                    <div className="privacy-policy-headers">Your Discretion:</div>
                                    If you do not agree with Vista Intelligence Privacy Statement or Terms of Service, you can uninstall the FeedSense application. If you have any queries please contact us via email to <a style={{ fontStyle: "italic", color: "#3880ff" }}>info@vistaintelligence.ai</a> with any questions or comments about this Privacy Statement, your personal information, your consent, or your opt-in or opt-out choices will be protected as confidential.
                                    <div className="privacy-policy-headers">Your Consent:</div>
                                    By using Vista Intelligence Site and/ or FeedSense Application services and by providing your information, you consent to the collection and use of the information you disclose for availing FeedSense Services in accordance with this Privacy Statement, including but not limited to your consent for sharing your information as per this Privacy Statement.
                                    <div className="privacy-policy-headers">Changes and updates of Privacy Statement:</div>
                                    This Privacy Statement may be revised periodically and this will be reflected by the “Revision date" below. Please revisit this page to stay aware of any changes. Your continuous use of FeedSense Service constitutes your agreement to this Privacy Statement and any amendments therein.
                                    <div style={{ fontWeight: "bolder", textAlign: "right" }}>Last Revised on 25 April 2022</div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </Row>
            </div>
            <MyVerticallyCenteredModal
                show={modalShow}
                setModalShow={setModalShow}
                handleLogOutPrevious={handleLogOutPrevious}
                theme={theme}
            />

        </div>
    )
}