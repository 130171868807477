import React, { useEffect, useRef, useState } from 'react';
import {useLocation } from 'react-router';
import { setInterval } from 'timers';
import { getCmMtmDrawdownCommodity, getCommodityParameter, getCurrencyLiveTargetSl, getCurrencyMtmDrawdown, getCurrencyParameter, getIntradayliveSITargetSl, getIntradayParameter, getIntradaySImtmDrawdown, getLiveAssetWisePL, getLiveCalls, getliveComodityAdvices, getLiveCurrencyAdvices, getLiveCurrencyCalls, getLiveCurrencyPl, getLiveCurrencySr, getLiveCurrencyTotalplPercentage, getliveIntradayAdvices, getliveIntradayCallsCommodity, getliveIntradayPlCommodity, getliveIntradaySrCommodity, getliveIntradayTargetslnCommodity, getLivePl, getLiveSr, getLiveTotalPlPercentCommodity } from '../../Api/buySell/intradayStockIndex';
import { getAdvices,  getcostDetails, getLiveNewsSentiment,getNoOfCalls, getPL,getReturnByDays, getSentiment, getSuccessRatio, liveOpenListOfTrades, liveOpenListOfTradesParameter, liveOpenSelectedCodeReturnPr } from '../../Api/buySell/strategyTracker';
import {  getAllTweets } from '../../Api/News/news';
import { userContext } from '../../Context/userContext';
import { StrategyTrackerLayout } from '../../layouts/StrategyTrackerLayout';
import './style.css';
import moment from 'moment';
import { getLiveCallsOptions, getLiveListOfTradesOptions, getLivePlOptions, getLiveSrOptions, getMtmDrawdownOptions, getOptionsParameter, getTargetSlOptions, getLivePlPercent } from '../../Api/buySell/optionsAPI';
import { parameterList, assetArray,  selectVersionValues, removeDuplicates, optFutParameterList, APP_VERSION ,sentimentMaker} from '../../util/utils';
import { getUserObject, setItemToStorage } from '../../Api/Service/auth.service';
import { getIntradayStockFuturemtmDrawdown, getIntradayStockFutureParameter, getIntradayStockFutureTargetSl, getliveIntradayStockFutureAdvices, getliveIntradayStockFutureCalls, getliveIntradayStockFuturePL, getliveIntradayStockFuturePLPercentage, getliveIntradayStockFutureSR } from '../../Api/buySell/stockfuture';
import { getFXOptionsParameter, getLiveFXOptionCalls, getLiveFXOptionPl, getLiveFXOptionSr, getLiveListOfTradesFXOptions, getMtmDrawdownFXOptions,  get_Live_pl_percentage_FXOption } from '../../Api/buySell/fxOptions';
import { getFXMultidayMtmDrawdown, getFXMultidayParameterlist, getFXMultidayTsl, getLiveFXMultidayAdviceStatus, getLiveFXMultidayCalls, getLiveFXMultidayPl, getLiveFXMultidayPlPercentage, getLiveFXMultidaySuccessRatio } from '../../Api/buySell/usdMultiday';
import { getEquityMtmDrawdown, getEquityParameterlist, getEquityTsl, getLiveEquityAdviceStatus, getLiveEquityCAlls, getLiveEquityPl, getLiveEquityPlPercentage, getLiveEquitySR } from '../../Api/buySell/equity_spot_multiday';
import { getReleaseNote } from '../../Api/buySell/portal';



interface StrategyTrackerPageProps {
    lastPong: any
    theme :any
}
    
let backupDate:any = moment(new Date()).format('YYYY-MM-DD');
let curVersion:any = 'all';
let selectedTrade:any = 0;
let selectedAdvicesStatusType = "derivative";
let selectedSentimentType:any = 'updated';


export const StrategyTrackerPage: React.FC<StrategyTrackerPageProps> = ({ lastPong,theme }) => {

    const [assetsData, setAssetsData] = useState<any>([])
    const [selectedAsset, setSelectedAsset] = useState<any>('all')
    const [advices, setAdvices] = useState<any>([])
    const [parameters, setParameters] = useState<any>([])
    const [noOfCalls, setNoOfCalls] = useState<any>(0)
    const [pL, setPL] = useState<any>(0)
    const [successRatio, setSuccessRatio] = useState<any>(0)
    const [targetSlChartData, setTargetSlChartData] = useState<any>([])
    const [mtmDrawdownChartData, setMtmDrawdownChartData] = useState<any>([])
    const [allTweets, setAllTweets] = useState<any>()
    const [version, setVersion] = useState<any>(curVersion)
    const [dropdownVersion, setDropdownVersion] = useState<any>('all')
    const location = useLocation<any>()
    const [type, setType] = useState<any>(location.search.slice(1, location.search.length))
    const [currentTime, setCurrentTime] = useState<any>(new Date().toLocaleTimeString('en-US', { hour12: false }))
    const [tradetype, setTradeType] = useState<any>('Total')
    const [revisedDatalist, setRevisedDataList] = useState<any>()
    const [selectedAdviceStatus, setSelectedAdviceStatus] = useState<any>(selectedTrade)
    const [selectedAdvice, setSelectedAdvice] = useState<any>(0)
    const currentLocation = useRef<any>()
    const [loading_adv, setLoading_adv] = useState<boolean>(true);
    const [loading_tarsl, setLoading_tarsl] = useState<boolean>(false);
    const [loading_param, setLoading_param] = useState<boolean>(false);
    const [loading_mtm, setLoading_mtm] = useState<boolean>(false);
    const [clicked, setClicked] = useState<boolean>(false);
    const [versionsDataLive,setVersionsDataLive] =useState<any>([]);
    const [calenderPickerDate, setCalenderPickerDate] = useState<any>(backupDate);
    const [returnPercentage,setReturnPercentage] = useState<any>()
    const [returnPerc21,setReturnPerc21] = useState<any>(null)
    const [returnPerc252,setReturnPerc252] = useState<any>()
    const [selectedCode, setSelectedcode] = useState<any>([])
    const [sentimentScore, setSentimentScore] = useState<any>([])
    const [advicesStatusType,setAdvicesStatusType]=useState<any>(selectedAdvicesStatusType)
    const [disableRefreshButton,setDisableRefreshButton]= useState<boolean>(false)
    const [alltradeDetails, setAllTradeDetails] = useState<any>()
    const [alltradeDetailsWithCost, setAllTradeDetailsWithCost] = useState<any>()
    const [allReturnper, setAllReturnper] = useState<any>([])  
    const [allHeader, setAllHeader] = useState<any>([])         
    const [mainAdvices, setMainAdvices] = useState<any>([])   
    const [callsCount, setCallsCount] = useState<any>({}) 
    const [selectedTradeDetails,setselectedTradeDetails]=useState<any>({});
    const [rwCost,setRwCost] = useState(true)
    const [minChargeDate,setMinChargeDate] = useState('')
    const [viewCharts, setViewCharts] = useState<boolean>(false) 
    const [niftyData, setNiftyData] = useState<any>()
    const [releaseNoteShow, setReleaseNoteShow] = useState<any>(false);
    const [releaseDetails,setReleaseDetails] = useState<any>([])
    const [releaseShowCount,setReleaseShowCount] = useState<any>(0)
    const [loadReturn,setLoadReturn] = useState<any>(false)
    const [loadR21,setLoadR21] = useState<any>(false)
    const [loadR252,setLoadR252] = useState<any>(false)
    const [checkLength,setCheckLength] = useState<any>(selectedCode.length)
    const [todaysLiveIdentifiers,setTodaysLiveIdentifiers] = useState<any>([])
    const[loadCalls,setLoadCalls] = useState(true)
    const[loadSr,setLoadSr] = useState(true)
    const[loadPl,setLoadPl] = useState(true)
    const [news21acc,setNews21acc] = useState<any>(null)
    const [news252acc,setNews252acc] = useState<any>(null)
    const [eco21acc,setEco21acc] = useState<any>(null)
    const [eco252acc,setEco252acc] = useState<any>(null)
    const [accuracy, setAccuracy] = useState<any>({})
    const [newsPremarketSentiment,setNewsPremarketSentiment] = useState<any>()
    const [newsSignals,setNewsSignals] = useState<any>({})
    const [jSentimentLength, setJsentimentLength] = useState<any>()
    const [totalPL, setTotalPL] = useState<any>({})
    const [sentimentType, setSentimentType] = useState<any>(selectedSentimentType)
    const [newsSentimentResponse, setNewsSentimentResponse] = useState<any>({})

    
    backupDate = calenderPickerDate;
    selectedTrade = selectedAdviceStatus;
    selectedAdvicesStatusType = advicesStatusType;
    selectedSentimentType = sentimentType;

    let default_return_obj:any ={return_per:null,
        risk_reward:[{'f&o':null,'futures':null,'options':null,'lft':null,'equity':null}],
        return_after_cost_perc:null,
        return_after_cost:[{'f&o':null,'futures':null,'options':null,'lft':null,'equity':null}]
    } 
    
    const getEconomicVariableSentiment = async()=>{
        try{
            await getSentiment(backupDate)
            .then((res:any)=>{
                if(res?.sentiment_score?.length>0){
                    setSentimentScore(res?.sentiment_score)
                }else{
                    setSentimentScore([{"n_sentiment_score": 'NA'}])
                }
            })
            .catch((e:any)=>{
                console.log(e);
            })
        }catch(e:any){
            console.log(e);
        }
    }

    const getCost = async()=>{   
        let cost_date = await getcostDetails()
        setMinChargeDate(cost_date?.min)
    }  
    


    // const getNewsSentiment = async()=>{
    //     try{
    //         await getLiveNewsSentiment(backupDate)
    //         .then(async(res:any)=>{
    //             if(!res.error){
    //                 if (res != null){
    //                     if(Object.keys(res?.news_sentiment).length>0){
    //                         setNewsSentimentResponse(res)
    //                         let res2:any = await sentimentMaker(res, calenderPickerDate, selectedSentimentType)
    //                         setNewsPremarketSentiment(res2?.news_sentiment?.n_premarket_sentiment)
    //                         setAccuracy(res2?.news_sentiment?.j_accuracy)
    //                         setNewsSignals(res2?.news_sentiment.signal_data)
    //                         setJsentimentLength(res2?.news_sentiment?.j_sentiment_length)
    //                         setTotalPL(res2?.news_sentiment?.j_pl)

    //                     }
    //                     else{
    //                         setNewsSignals([])
    //                         setAccuracy({})
    //                         setTotalPL({})
    //                     }
    //                     setNews21acc(res?.n_news_21_acc)
    //                     setEco21acc(res?.n_eco_21_acc)
    //                     setNews252acc(res?.n_news_252_acc)
    //                     setEco252acc(res?.n_eco_252_acc)
    //                 }else{  
    //                     setNews21acc(null)
    //                     setEco21acc(null)
    //                     setNews252acc(null)
    //                     setEco252acc(null)
    //                 }
    //             }
    //         }).catch((e:any)=>{
    //             console.log(e);
    //         })
    //         // await getNifty50IndexData(backupDate)
    //         // .then((res:any)=>{
    //         //     if((res !== null || res !== undefined || res.length > 0)){
    //         //         if (res?.niftyData){
    //         //             setNiftyData(res.niftyData[0].j_ohlc)
    //         //         }else{
    //         //             setNiftyData({})
    //         //         }
    //         //     }
    //         // }).catch((e:any)=>{console.log(e)})
    //     }catch(e:any){
    //         console.log(e);
    //     }
    // }
    const getNewsSentiment = async()=>{
        try{
            await getLiveNewsSentiment(backupDate)
            .then(async (res:any)=>{
                if(!res.error){
                    if (res != null){
                        if(Object.keys(res?.news_sentiment).length>0){
                            setNewsSentimentResponse(res)
                            let res2:any = await sentimentMaker(res, calenderPickerDate, selectedSentimentType)
                            if(Object.keys(res2?.news_sentiment).length > 0){
                                setNewsPremarketSentiment(res2?.news_sentiment?.n_premarket_sentiment)
                                setAccuracy(res2?.news_sentiment?.j_accuracy)
                                setTotalPL(res2?.news_sentiment?.j_pl)
                                setNewsSignals(res2?.news_sentiment.signal_data)
                                setJsentimentLength(res2?.news_sentiment?.j_sentiment_length)
                            }
                            setNews21acc(res2?.n_news_21_acc)
                            setEco21acc(res2?.n_eco_21_acc)
                            setNews252acc(res2?.n_news_252_acc)
                            setEco252acc(res2?.n_eco_252_acc)
                        }
                        else{
                            setNewsSignals([])
                            setAccuracy({})
                            setTotalPL({})
                            setNews21acc(null)
                            setEco21acc(null)
                            setNews252acc(null)
                            setEco252acc(null)
                        }
                    }
                }
            }).catch((e:any)=>{
                console.log(e);
            })
        }catch(e:any){
            console.log(e);
        }
    }
    async function toggleSentiments(){
        let res2:any = await sentimentMaker(newsSentimentResponse, calenderPickerDate, selectedSentimentType)
        setNewsPremarketSentiment(res2?.news_sentiment?.n_premarket_sentiment)
        setAccuracy(res2?.news_sentiment?.j_accuracy)
        setTotalPL(res2?.news_sentiment?.j_pl)
        setNewsSignals(res2?.news_sentiment.signal_data)
        setJsentimentLength(res2?.news_sentiment?.j_sentiment_length)
    }

    useEffect(()=>{
    if(Object.keys(newsSentimentResponse).length>0){
        toggleSentiments();
    }
    },[sentimentType])

   useEffect(()=>{
        getCost()
   },[minChargeDate])
    
  useEffect(()=>{
        getEconomicVariableSentiment()
        setNewsSignals([])
        getNewsSentiment()
    },[calenderPickerDate])

    const asset_key_value: any ={
        'NIFTY-LONG' :'NIFTY-LONG',
        'NIFTY-SHORT' :'NIFTY-SHORT',
        'NIFTY-I.NFO-SHORT' :'NIFTY-SHORT',	
        'BANKNIFTY-I.NFO-SHORT' :'BANKNIFTY-SHORT',
        'NIFTY-I.NFO-LONG' :'NIFTY-LONG',	
        'BANKNIFTY-I.NFO-LONG' :'BANKNIFTY-LONG',
        'INFY-I.NFO-SHORT' :'INFY-SHORT',	
        'RELIANCE-I.NFO-SHORT' :'RELIANCE-SHORT',	
        'ALL':'ALL',
        'GOLD-I.MCX':'GOLD',
        'SILVER-I.MCX':'SILVER',
        'CRUDEOIL-I.MCX':'CRUDEOIL',
        'NIFTY-I.NFO':'NIFTY',
        'BANKNIFTY-I.NFO':'BANKNIFTY',
        'USDINR-I.CDS':'USDINR',
        'USDINR-I.CDS-Short':'USDINR-SHORT',
        'USDINR-I.CDS-Long':'USDINR-LONG',
        'NIFTY-I.NFO_TCS-I.NFO' : 'NIFTY_TCS',
        'INFY-I.NFO_TCS-I.NFO' :'INFY_TCS',
        'NIFTY-I.NFO_BANKNIFTY-I.NFO' : 'NIFTY_BANKNIFTY',
        'INFY-I.NFO' :'INFY',
        'RELIANCE-I.NFO':'RELIANCE',
        'DRREDDY-I.NFO' :'DRREDDY'  
    }

    let accessproducts = getUserObject();
    const privileges= accessproducts.privileges;
    
    const handleVersions = (type:any)=>{
        let versions:any=[] 
        privileges.map((item:any) => {
            if((item.length>9) && (item.slice(1,3).concat(item.slice(-2,-1)) === selectVersionValues(type)))
            {   
                versions.push(item.slice(0,3).concat(item.slice(6)));     
                versions= removeDuplicates(versions)         
            }
            if(item.slice(1,3).concat(item.slice(-1))===selectVersionValues(type)){
                versions.push(item.slice(0,3).concat(item.slice(6)));
                versions= removeDuplicates(versions)
            }
        })
        if(type === 'options-index')
            versions= removeDuplicates(versions)
        setVersionsDataLive(versions)
    }


    useEffect(()=>{
        handleVersions(type)
    },[type])

    useEffect(() => {
        if (type === "options-stock" || type === "options-index" || type === "fx-option") {
            setTradeType('Total')
            setDropdownVersion('all')
            setVersion('all')
        }else{
            setDropdownVersion('all')
            setVersion('all')
        }
    }, [type])

    useEffect(()=>{
        setType(location.search.slice(1, location.search.length))
        currentLocation.current = location.search.slice(1, location.search.length)
        setSelectedAdviceStatus(0)
        setPL(null)
        setNoOfCalls(null)
        setSuccessRatio(null)
        setLoadCalls(true)
        setLoadSr(true)
        setLoadPl(true)
    }, [location])

    useEffect(() => {
        if(type != location.search.slice(1, location.search.length)){
            setAdvices([]);
            setLoading_adv(true)
            setSelectedAdvice(0);
        }
        getAllTweets().then((result: any) => {
            setAllTweets(result)
        })
    }, [location])
    
    window.localStorage.setItem("auto_refresh",JSON.stringify({selectedAsset,type,version,dropdownVersion,sentimentScore}));

    const handleReleaseNote = () =>{
        let release_show_count:any = 0
        try{
            let count:any = window.localStorage.getItem("release_show_count");
            release_show_count = parseInt(count)
        }
        catch{
            setItemToStorage("release_show_count","0")
        }
        if (window.localStorage.getItem("version") !== APP_VERSION){
            setItemToStorage("version",APP_VERSION)
            setItemToStorage("release_show_count","0")
        }
        else{
            if (release_show_count < 3)
            {
                setReleaseShowCount(releaseShowCount+1)
                fetchReleaseDetails(APP_VERSION)
                setItemToStorage("release_show_count",String(release_show_count+1))
            }
        }
    }

    useEffect(() => {
        if(type === 'all'){
            handleReleaseNote()
            let curTime:any;
            let counter:any = 0;
            const interval = setInterval(() => {
                curTime = new Date().toLocaleTimeString('en-US', { hour12: false });
                let current_asset_type = window.location.search.slice(1,(window.location.search.length))
                let storagevalues:any= window.localStorage.getItem("auto_refresh");
                let refreshvalues:any=JSON.parse(storagevalues);
                if(refreshvalues?.type === 'all' ){
                    if(curTime >= '09:00:00' && curTime <= '17:00:00'){
                        if(refreshvalues?.type == 'all' || refreshvalues?.type == 'ALL')
                        {
                            getCallsAdviceSrPl(refreshvalues?.selectedAsset,current_asset_type,refreshvalues?.dropdownVersion) 
                            // setNiftyData(null)
                            setNewsSignals([])
                            getNewsSentiment()
                        }
                       
                        if(refreshvalues?.sentimentScore[0]?.n_sentiment_score == 'NA'){
                            getEconomicVariableSentiment()
                        }
                        let current_time = new Date().toLocaleTimeString('en-US', { hour12: false })
                        setCurrentTime(current_time)
                    }else{
                        counter++;
                        if(counter === 5){
                            if(refreshvalues?.type == 'all' || refreshvalues?.type == 'ALL')
                            {
                                getCallsAdviceSrPl(refreshvalues?.selectedAsset,current_asset_type,refreshvalues?.dropdownVersion) 
                            }
                           
                            let current_time = new Date().toLocaleTimeString('en-US', { hour12: false })
                            setCurrentTime(current_time)
                            counter=0;
                        }
                    }
                }
            },1*60*1000);
            return () => {
                clearInterval(interval)
            }
        }
    }, [currentLocation]);


    const handleRefresh = () =>{
        setLoading_adv(true)
        setLoading_param(true)
        getEconomicVariableSentiment()
        setClicked(true); 
        setNiftyData(null)
        getNewsSentiment();
        setCallsCount({'hft' : 0 ,'derivative' : 0,'spot':0})
        getCallsAdviceSrPl(selectedAsset,type,dropdownVersion)
        let current_time = new Date().toLocaleTimeString('en-US', { hour12: false })
        setCurrentTime(current_time)
        setLoadReturn(true)
        setReturnPercentage(default_return_obj)
        setTimeout(()=>{	
            setClicked(false)
            setDisableRefreshButton(true)
        },500)  
        setTimeout(()=>{	
                setDisableRefreshButton(false)
        },5000) 
    }

    useEffect(()=>{
        setViewCharts(false)
        setAllReturnper([])
        setSelectedAdvice(0)
    },[type,calenderPickerDate])

    useEffect(() => {	
        setAssetsData([])	
        getTotalAsset(dropdownVersion)	
        fetchSelectedAsset(dropdownVersion)
        setPL(null)
        setNoOfCalls(null)
        setSuccessRatio(null)
        setLoadCalls(true)
        setLoadSr(true)
        setLoadPl(true)	
    }, [type, dropdownVersion, lastPong])


    useEffect(()=>{
        setLoading_param(true)
        setSelectedAdviceStatus(0)
        setSelectedAdvice(0);
    },[advicesStatusType])
    

    useEffect(() => {
        setAllReturnper([])
        backupDate = calenderPickerDate;
        setCalenderPickerDate(calenderPickerDate);
        setMainAdvices([])
        setAdvices([])
        setPL(null)
        setNoOfCalls(null)
        setSuccessRatio(null)
        setLoadCalls(true)
        setLoadSr(true)
        setLoadPl(true)	
        setCallsCount({'hft' : 0 ,'derivative' : 0,'spot':0})
        setLoading_adv(true)
        setSelectedAdviceStatus(0)
        if(selectedAsset == 'all' || selectedAsset == 'ALL'){
            getCallsAdviceSrPl(selectedAsset,type,dropdownVersion) 
        }
        else{
            getCallsAdviceSrPl(selectedAsset,type,version) 
        }
    }, [type,selectedAsset,tradetype,dropdownVersion,version,lastPong, calenderPickerDate])

    useEffect(()=>{
        
        setLoadReturn(true)
        setReturnPercentage(default_return_obj)
        if(selectedCode.length > 0){
            handleFilterSelectedAdvices()
        }else{
            setLoadReturn(false)
            setReturnPercentage(default_return_obj)
        }
        setCheckLength(selectedCode.length)
    },[selectedCode])


    useEffect(()=>{ 
        setLoadR21(true)
        setLoadR252(true)
        setReturnPerc21(default_return_obj)
        setReturnPerc252(default_return_obj)

        if(selectedCode.length > 0){
            getPrevDaysReturn()
        }
        else {
            setLoadReturn(false)
            setLoadR21(false)
            setLoadR252(false)
            setReturnPerc21(default_return_obj)
            setReturnPercentage(default_return_obj)
            setReturnPerc252(default_return_obj)
        }
    },[checkLength,calenderPickerDate])

    useEffect(() => {
        parameterData()
        setselectedTradeDetails(advices[selectedAdvice])
    }, [selectedAdvice, advices])


    useEffect(()=>{
        if(viewCharts === true){chartData()}
    },[viewCharts,selectedAdvice, advices])


    useEffect(()=>{
        let advices_filter_arr:any = [] 

        advices_filter_arr = mainAdvices?.filter((item:any) =>{

            getNoofCalls(mainAdvices)
            if(selectedAdvicesStatusType == 'derivative')
            {
                return (item?.s_version).slice(1,3) !== 'ES' &&  item?.s_version != 'AIODDI'
            }
            else if(selectedAdvicesStatusType == 'hft')
            {
                return item?.s_version == 'AIODDI'
            }
            else{
                return (item?.s_version).slice(1,3) === 'ES'
            }
        })
        setAdvices(advices_filter_arr)
        
    },[advicesStatusType, mainAdvices])

   
    const handleFilterSelectedAdvices = async()=>{
        let return_per_array:any = []
        
        if (mainAdvices.length > 0)
        {
            return_per_array = mainAdvices?.filter((item:any) => {
                if (item?.identifier.slice(2,3)==='F'){
                    return selectedCode?.includes(item?.identifier.slice(0,-1))
                }
                else if (item?.identifier.slice(1,3)==='ES'){
                    return selectedCode?.includes(item?.identifier.slice(0,-1))
                }
                else{ 
                    return selectedCode?.includes(item?.identifier)
                }
            })
        
            if(calenderPickerDate){
                if(return_per_array?.length > 0){
                    await liveOpenSelectedCodeReturnPr(return_per_array,calenderPickerDate).then((res: any) => {
                        if(res?.return_per !== null)
                        {
                            setReturnPercentage(res?.return_per)
                            setLoadReturn(false)
                        }
                    })
                }else{
                    setReturnPercentage(default_return_obj)
                    setLoadReturn(false)
                }
            }
        }
    }

    const getPrevDaysReturn = async()=>{
          
            let uniqueObject:any = []
            
            uniqueObject = selectedCode.map((item:any)=>{
                    return {"identifier":item}
            })
            if(uniqueObject?.length > 0 && calenderPickerDate){

                        
                await getReturnByDays(uniqueObject,calenderPickerDate,21).then((res: any) => {
                    if(res?.return_per !== null)
                    {
                        setReturnPerc21(res?.return_per)
                        setLoadR21(false)
                    }
                })
                await getReturnByDays(uniqueObject,calenderPickerDate,252).then((res: any) => {
                    if(res?.return_per !== null)
                    {
                        setReturnPerc252(res?.return_per)
                        setLoadR252(false)
                    }
                })
            }else{
                setReturnPerc21(default_return_obj)
                setReturnPerc252(default_return_obj)
            }
    }
    
    const getNoofCalls=(total_trades:any)=>{
        let lft_calls = 0
        let fo_calls = 0
        let equity_calls = 0
        total_trades.map((item:any)=>{
            if (item?.s_version === 'AIODDI'){
                lft_calls = lft_calls + 1
            }
            else if((item?.s_version).slice(1,3) !== 'ES' &&  item?.s_version != 'AIODDI'){
                fo_calls = fo_calls + 1
            }
            else if((item?.s_version).slice(1,3) === 'ES'){
                equity_calls = equity_calls + 1
            }
        })
        setCallsCount({'hft' : lft_calls ,'derivative' : fo_calls,'spot':equity_calls})
   
    }

    const fetchSelectedAsset = (version: any) => {
        setSelectedAsset('all')
        setVersion(version)
    }

    const fetchReleaseDetails = async (version:any)=>{
        await getReleaseNote(version).then((res: any) => {
            if ((res != undefined) || (res != null))
            {
                setReleaseDetails(res)
                setReleaseNoteShow(true)
            }
        })
    } 
    
    const getTotalAsset = (version: any) => {
       if (type === 'intraday-stock-index') {
            getLiveAssetWisePL(type, version).then((res: any) => {
                if (res?.length > 0) {
                    if (res[0] != null) {
                        setAssetsData(res)
                    }
                }
            })
        }
        else if (type === 'intraday-stock-future') {
            getliveIntradayStockFuturePLPercentage(type, version).then((res: any) => {
                if (res?.length > 0) {
                    if(res?.length > 1)
                    {
                        res?.sort((first: any, second: any) => {
                            return second?.pl - first?.pl;
                        })
                        res.push({
                            "asset_name": 'ALL',
                            "s_version" : 'all'
                        })
                    }
                    if (res[0] != null) {
                        setAssetsData(res)
                    }
                }
            })
        }
        else if (type === 'fx-future') {
            getLiveCurrencyTotalplPercentage(type, version).then((res: any) => {
                if (res?.length > 0) {

                    if(res?.length > 1)
                    {
                        res?.sort((first: any, second: any) => {
                            return second?.pl - first?.pl;
                        })
                        res.push({
                            "asset_name": 'ALL',
                            "s_version" : 'all'
                        })
                    }

                    if (res[0] != null) {
                        setAssetsData(res)
                    }
                }
            })
        }
        else if (type === 'comodity-future') {
            getLiveTotalPlPercentCommodity(type,version).then((res: any) => {
                if (res?.length > 0) {

                    if(res?.length > 1)
                    {
                        res?.sort((first: any, second: any) => {
                            return second?.pl - first?.pl;
                        })
                        res.push({
                            "asset_name": 'ALL',
                            "s_version" : 'all'
                        })
                    }
                    if (res[0] != null) {
                        setAssetsData(res)
                    }
                    

                }
            })
        }
        else if(type === "options-stock" || type === "options-index")
        {
            getLivePlPercent(version,tradetype,type).then((res:any)=>{
                if(res?.length > 0)
                {
                    let obj = {
                        "asset_name": "ALL",
                        "s_version": version,
                        "trade_type": "Total"
                    }
                    if(res?.length > 1)
                    {
                        res?.sort((first: any, second: any) => {
                            return second?.pl - first?.pl;
                        })
                        res.push(obj)
                    }
                    if(res[0] != null)
                    {
                        setAssetsData(res)
                    }
                }
            })
        }
        else if(type === 'fx-option'){
            get_Live_pl_percentage_FXOption(version, tradetype, type).then((res:any)=>{
                if(res?.length > 0){
                    let obj = {
                        "asset_name": "ALL",
                        "s_version": version,
                        "trade_type": "Total"
                    }
                    if(res?.length > 1){
                        res?.sort((first: any, second: any) => {
                            return second?.pl - first?.pl;
                        })
                        res.push(obj)
                    }
                    if(res[0] != null){
                        setAssetsData(res)
                    }
                }
            })
        }
        else if (type === 'multiday-equity') {
            getLiveEquityPlPercentage(type, version).then((res: any) => {
                if (res?.length > 0) {
                    let obj = {
                        "asset_name": "ALL",
                        "s_version": version,
                        "trade_type": "Total"
                    }
                    if(res?.length > 1)
                    {
                        res?.sort((first: any, second: any) => {
                            return second?.pl - first?.pl;
                        })
                        res.push(obj)
                    }
                    if (res[0] != null) {
                        setAssetsData(res)
                    }
                }
                else {
                    setAssetsData([])
                }
            })
        }
        else if (type === 'fx-future-multiday') {
            getLiveFXMultidayPlPercentage(type, version).then((res: any) => {
                if (res?.length > 0) {
                    let obj = {
                        "asset_name": "ALL",
                        "s_version": version,
                        "trade_type": "Total"
                    }
                    if(res?.length > 1)
                    {
                        res?.sort((first: any, second: any) => {
                            return second?.pl - first?.pl;
                        })
                        res.push(obj)
                    }
                    if (res[0] != null) {
                        setAssetsData(res)
                    }
                }
                else {
                    setAssetsData([])
                }
            })
        }
    }

    const getCallsAdviceSrPl =async (selectedAsset: any, type: any, version:any) => {
        
        let asset_value: any
        if (selectedAsset == 'ALL' || selectedAsset == 'all') {
            asset_value = type
        }
        else {
            asset_value = selectedAsset
        }
        if (type === "intraday-stock-index") {
            await getliveIntradayAdvices(asset_value, version).then((res: any) => {
                setLoading_adv(false);
                if (res?.length > 0) {
                    setAdvices(res)
                }
                else {
                    setAdvices([])
                }
                
            })
            await getLiveCalls(asset_value, version).then((res: any) => {
                
                if (res?.length > 0) {
                    setNoOfCalls(res[0]?.count)
                }
                else {
                    setNoOfCalls(0)
                }
                setLoadCalls(false)
            })
            await getLiveSr(asset_value, version).then((res: any) => {
                if (res != null) {
                    setSuccessRatio(res?.sr)
                }
                else {
                    setSuccessRatio(0)
                }
                setLoadSr(false)
            })
            await getLivePl(asset_value, version).then((res: any) => {
                if (res != null) {
                    setPL(res?.sum)
                }
                else {
                    setPL(0)
                }
                setLoadPl(false)
            })

        }
        else if (type === "intraday-stock-future") {
            await getliveIntradayStockFutureAdvices(asset_value, version).then((res: any) => {
                setLoading_adv(false);
                if (res?.length > 0) {
                    setAdvices(res)
                }
                else {
                    setAdvices([])
                }
                
            })
            await getliveIntradayStockFutureCalls(asset_value, version).then((res: any) => {
                if (res!=null) {
                    setNoOfCalls(res?.count)
                }
                else {
                    setNoOfCalls(0)
                }
                setLoadCalls(false)
            })
            await getliveIntradayStockFutureSR(asset_value, version).then((res: any) => {
                if (res != null) {
                    setSuccessRatio(res?.sr)
                }
                else {
                    setSuccessRatio(0)
                }
                setLoadSr(false)
            })
            await getliveIntradayStockFuturePL(asset_value, version).then((res: any) => {
                if (res != null) {
                    setPL(res?.sum)
                }
                else {
                    setPL(0)
                }
                setLoadPl(false)
            })

        }
        else if (type === 'fx-future') {

            await getLiveCurrencyCalls(asset_value, version).then((res: any) => {  
                if (res?.length > 0) {
                    setNoOfCalls(res[0]?.count)
                }
                else {
                    setNoOfCalls(0)
                }
                setLoadCalls(false)
            })
            await getLiveCurrencySr(asset_value, version).then((res: any) => {
                
                if ((res != null || res != undefined) && res[0]?.sr != null) {
                    setSuccessRatio(res[0]?.sr)
                }
                else {
                    setSuccessRatio(0)
                }
                setLoadSr(false)
            })
            await getLiveCurrencyPl(asset_value, version).then((res: any) => {
                if (res?.length > 0) {
                    setPL(res[0]?.sum)
                }
                else {
                    setPL(0)
                }
                setLoadPl(false)
            })
            await getLiveCurrencyAdvices(asset_value, version).then((res: any) => {
                    setLoading_adv(false);
                if (res?.length > 0) {
                    setAdvices(res)
                }
                else {
                    setAdvices([])
                }
                
            })
        }
        else if (type === "options-stock" || type === "options-index") {
            await getLiveListOfTradesOptions(version, tradetype, asset_value).then((res: any) => {
                setLoading_adv(false);
                if (res?.length > 0) {
                    setAdvices(res)
                }
                else {
                    setAdvices([])
                }
                
            })
            await getLiveCallsOptions(version, tradetype, asset_value).then((res: any) => {
                if (res != null) {
                    setNoOfCalls(res?.count)
                }
                else {
                    setNoOfCalls(0)
                }
                setLoadCalls(false)
            })
            await getLiveSrOptions(version, tradetype, asset_value).then((res: any) => {
                if (res != null) {
                    setSuccessRatio(res?.sr)
                }
                else {
                    setSuccessRatio(0)
                }
                setLoadSr(false)
            })
            await getLivePlOptions(version, tradetype, asset_value).then((res: any) => {
                if (res != null) {
                    setPL(res?.sum)
                }
                else {
                    setPL(0)
                }
                setLoadPl(false)
            })

        }
        else if (type === 'comodity-future') {
            
            await getliveComodityAdvices(asset_value,version).then((res: any) => {
                setLoading_adv(false);
                setAdvices(res)
                
            })
            await getliveIntradayPlCommodity(asset_value,version).then((res: any) => {
                if (res != null) {
                    setPL(res?.sum)
                }
                else {
                    setPL(0)
                }
                setLoadPl(false)
            })
            await getliveIntradaySrCommodity(asset_value,version).then((res: any) => {
                if (res != null) {
                    setSuccessRatio(res?.sr)
                }
                else {
                    setSuccessRatio(0)
                }
                setLoadSr(false)
            })
            await getliveIntradayCallsCommodity(asset_value,version).then((res: any) => {
    
                if (res != null || res != undefined) {
                    setNoOfCalls(res?.count)
                }
                else {
                    setNoOfCalls(0)
                }
                setLoadCalls(false)
            })
        }
        else if (type === "all") {
                let identifier_arr:any = []
                liveOpenListOfTrades(backupDate).then((res: any) => {
                    if(!res?.error && res){
                        (res?.final_response)?.map((item:any) => {
                            identifier_arr.push(item?.identifier)
                        })
                        let uniques = Array.from(new Set(identifier_arr))
                        setLoading_adv(false);
                        setAllReturnper(res?.final_response)
                        setMainAdvices(res?.final_response)
                        setAllHeader(uniques)
                        setAllTradeDetails(res?.allTradeDetails)
                        setAllTradeDetailsWithCost(res?.allTradeDetailsWithCost)
                    }
                })

        }
        else if (type === "stock-index" || type === "stock-future") {

            getAdvices(asset_value).then((res) => {
                setLoading_adv(false);
                setAdvices(res)
                
            })
            getNoOfCalls(asset_value).then((res) => {
                if (res?.length > 0) {
                    setNoOfCalls(res)
                }
                else {
                    setNoOfCalls(0)
                }
                setLoadCalls(false)
            })
            getPL(asset_value).then((res) => {
                if (res != null) {
                    setPL(res)
                }
                else {
                    setPL(0)
                }
                setLoadPl(false)
            })
            getSuccessRatio(asset_value).then((res) => {
                if (res != null) {
                    setSuccessRatio(res)
                }
                else {
                    setSuccessRatio(0)
                }
                setLoadSr(false)
            })
        }
        else if(type === 'fx-option'){
            await getLiveListOfTradesFXOptions(version, tradetype, asset_value).then((res: any) => {
                setLoading_adv(false);
                if (res?.length > 0) {
                    setAdvices(res)
                }
                else {
                    setAdvices([])
                }
                
            })
            await getLiveFXOptionCalls(version, tradetype, asset_value).then((res:any)=>{
                if (res?.length > 0) {
                    setNoOfCalls(res[0]?.count)
                }
                else {
                    setNoOfCalls(0)
                }
                setLoadCalls(false)
            })
            await getLiveFXOptionSr(version, tradetype, asset_value).then((res: any) => {
                if (res) {
                    setSuccessRatio(res?.sr)
                }
                else {
                    setSuccessRatio(0)
                }
                setLoadSr(false)
            })
            await getLiveFXOptionPl(version, tradetype, asset_value).then((res: any) => {
                if (res?.length > 0) {
                    setPL(res[0]?.value)
                }
                else {
                    setPL(0)
                }
                setLoadPl(false)
            })
        
        }
        else if(type === 'fx-future-multiday'){
            await getLiveFXMultidayCalls(asset_value, version).then((res: any) => {
                if (res?.length > 0) {
                    if (res[0] != null) {
                        setNoOfCalls(res[0]?.count)
                    }
                }
                else {
                    setNoOfCalls(0)
                }
                setLoadCalls(false)
            })
            await getLiveFXMultidaySuccessRatio(asset_value, version).then((res: any) => {
                if (res?.length > 0) {
                    if (res[0] != null) {
                        setSuccessRatio(res[0]?.sr)
                    }
                }
                else {
                    setSuccessRatio(0)
                }
                setLoadSr(false)
            })

            await getLiveFXMultidayPl(asset_value, version).then((res: any) => {
                if (res?.length > 0) {
                    if (res[0] != null) {
                        setPL(res[0]?.sum)
                    }
                }
                else {
                    setPL(0)
                }
                setLoadPl(false)
            })
            await getLiveFXMultidayAdviceStatus(asset_value, version).then((res: any) => {
                setLoading_adv(false);
                if (res?.length > 0) {
                    if (res[0] != null) {
                        setAdvices(res)
                    }
                }
                else {
                    setAdvices([])
                }
                
            })
        }
        else if (type === 'multiday-equity'){
            await getLiveEquityCAlls(asset_value, version).then((res: any) => {
                if (res?.length > 0) {
                    if (res[0] != null) {
                        setNoOfCalls(res[0]?.count)
                    }
                }
                else {
                    setNoOfCalls(0)
                }
                setLoadCalls(false)
                
            })

            await getLiveEquitySR(asset_value, version).then((res: any) => {
                if (res?.length > 0) {
                    if (res[0] != null) {
                        setSuccessRatio(res[0]?.sr)
                    }
                }
                else {
                    setSuccessRatio(0)
                }
                setLoadSr(false)
            })

            await getLiveEquityPl(asset_value, version).then((res: any) => {
                if (res?.length > 0) {
                    if (res[0] != null) {
                        setPL(res[0]?.sum)
                    }
                }
                else {
                    setPL(0)
                }
                setLoadPl(false)
            })
            await getLiveEquityAdviceStatus(asset_value, version).then((res: any) => {
                setLoading_adv(false);
                if (res?.length > 0) {
                    if (res[0] != null) {
                        setAdvices(res)
                    }
                }
                else {
                    setAdvices([])
                }
                
            })

        }
    }

    const parameterData = () => {

        setRevisedDataList(null)
        setParameters([])
        setLoading_tarsl(true);
        setLoading_param(true);
        setLoading_mtm(true);
        
        let open_date = moment(advices[selectedAdvice]?.dt_date).format('YYYY-MM-DD')

        if (advices.length > 0) {
            if (type === "all") {

                let Obj: any = {
                    "date": advices[selectedAdvice]?.open_date,
                    "asset": advices[selectedAdvice]?.asset,
                    "id": advices[selectedAdvice]?.uuid,
                    "version": advices[selectedAdvice]?.s_version,
                    "s_asset_group_trade_type": advices[selectedAdvice]?.s_asset_group_trade_type
                }

               liveOpenListOfTradesParameter(Obj)
               .then((res: any) => {

                    if (res) {
                        
                        if(((advices[selectedAdvice]?.identifier).slice(0,-1)) === 'QFOUSDSBI')
                        {
                            setRevisedDataList(optFutParameterList(res[0]?.j_target_stoploss))
                        }
                        else{
                            setRevisedDataList(parameterList(res[0]?.j_target_stoploss))
                        }
                        setParameters(res)
                    }
                    setLoading_param(false);
                    setLoading_tarsl(false);    
                    setLoading_mtm(false);
                })
            }
            else if (type === "intraday-stock-index") {

                getIntradayParameter(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                    
                    if (res?.length > 0) {
                        setRevisedDataList(parameterList(res[0]?.j_target_stoploss_revision))
                        setParameters(res)
                    }
                    setLoading_param(false);
                })
            }
            else if (type === "intraday-stock-future") {
                getIntradayStockFutureParameter(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                    
                    if (res?.length > 0) {
                        setRevisedDataList(parameterList(res[0]?.j_target_stoploss_revision))
                        setParameters(res)
                    }
                    setLoading_param(false);
                    
                })
            }
            else if (type === 'fx-future') {

                getCurrencyParameter(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                    if (res?.length > 0) {
                        setRevisedDataList(parameterList(res[0]?.j_target_stoploss))
                        setParameters(res)
                    }
                    setLoading_param(false);
                })
            }
            else if (type === "comodity-future")                
            {
                getCommodityParameter(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                    
                    if (res?.length > 0) {
                        setRevisedDataList(parameterList(res[0]?.s_target_stoploss_revision_list))
                        setParameters(res) 
                    }
                    setLoading_param(false);
                    
                })
            }
            else if (type === "options-stock" || type === "options-index") {
                getOptionsParameter(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.open_date, advices[selectedAdvice]?.s_version).then((res: any) => {
                    if (res?.length > 0) {
                        setRevisedDataList(parameterList(res[0].j_target_stoploss_list))
                        setParameters(res)
                    }
                    setLoading_param(false);
                })
            }
            else if(type === "fx-option"){
                getFXOptionsParameter(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.open_date, advices[selectedAdvice]?.s_version).then((res: any) => {
                    if (res?.length > 0) {

                        setRevisedDataList(optFutParameterList(res[0].j_target_stoploss_list))
                        setParameters(res)
                    }
                    setLoading_param(false);
                })
            }
            else if(type === "fx-future-multiday") 
            {
                getFXMultidayParameterlist(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                    if (res?.length > 0) {
                        setRevisedDataList(parameterList(res[0]?.j_target_stoploss))
                        setParameters(res)
                    }
                    setLoading_param(false);
                })
            }
            else if(type === "multiday-equity") {
                getEquityParameterlist(advices[selectedAdvice]?.id, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                    if (res?.length > 0) {
                            setRevisedDataList(parameterList(res[0]?.s_target_stoploss_revision_list))
                            setParameters(res)
                        }
                    setLoading_param(false);
                })
            }
            
        }
        else {
            setMtmDrawdownChartData([])
            setTargetSlChartData([])
            setRevisedDataList(null)
            setParameters([])
            setLoading_tarsl(false);
            setLoading_param(false);
            setLoading_mtm(false);
        }
    }

    const chartData = () => {
        setLoading_tarsl(true);
        setLoading_mtm(true);
        // let open_date = moment(advices[selectedAdvice]?.dt_date).format('YYYY-MM-DD')

        if (advices.length > 0) {
            if (type === "intraday-stock-index") 
            {
                if(viewCharts === true)
                {
                    getIntradayliveSITargetSl(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.open_date, advices[selectedAdvice]?.s_version).then((res: any) => {
                        if (res?.length > 0) {
                            setTargetSlChartData(res)
                        }
                        else {
                            setTargetSlChartData([])
                        }
                        setLoading_tarsl(false);
                    })
                    getIntradaySImtmDrawdown(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.open_date, advices[selectedAdvice]?.s_version).then((res: any) => {
                        
                        
                        if (res?.length > 0) {
                            setMtmDrawdownChartData(res)
                        }
                        else {
                            setMtmDrawdownChartData([])
                        }
                        setLoading_mtm(false);
                    })
                }

            }
            else if (type === "intraday-stock-future") {
                if(viewCharts === true)
                {
                    getIntradayStockFutureTargetSl(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.open_date, advices[selectedAdvice]?.s_version).then((res: any) => {
                        
                        
                        if (res?.length > 0) {
                            setTargetSlChartData(res)
                        }
                        else {
                            setTargetSlChartData([])
                        }
                        
                        setLoading_tarsl(false);
                    })
                    getIntradayStockFuturemtmDrawdown(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.open_date, advices[selectedAdvice]?.s_version).then((res: any) => {
                        
                        
                        if (res?.length > 0) {
                            setMtmDrawdownChartData(res)
                        }
                        else {
                            setMtmDrawdownChartData([])
                        }
                        setLoading_mtm(false);
                    })
                }
            }
            else if (type === 'fx-future') {
                if(viewCharts === true)
                {
                    getCurrencyLiveTargetSl(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                        
                        if (res?.length > 0) {
                            setTargetSlChartData(res)
                        }
                        else {
                            setTargetSlChartData([])
                        }
                        setLoading_tarsl(false);
                    })
                    getCurrencyMtmDrawdown(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                        
                        if (res?.length > 0) {
                            setMtmDrawdownChartData(res)
                        }
                        else {
                            setMtmDrawdownChartData([])
                        }
                        setLoading_mtm(false);
                    })
                }
            }
            else if (type === "comodity-future")                
            {
                if(viewCharts === true)
                {
                    getliveIntradayTargetslnCommodity(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset,advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date, advices[selectedAdvice]?.trade_type).then((res: any) => {
                        if (res?.length > 0) {
                            setTargetSlChartData(res)
                        }
                        else {
                            setTargetSlChartData([])
                        }
                        setLoading_tarsl(false);
                    })
                    getCmMtmDrawdownCommodity(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset,advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                        
                        if (res?.length > 0) {
                            setMtmDrawdownChartData(res)
                        }
                        else {
                            setMtmDrawdownChartData([])
                        }
                        setLoading_mtm(false);
                        
                    })
                }
            }
            else if (type === "options-stock" || type === "options-index") {
                
                if(viewCharts === true)
                { 
                    getTargetSlOptions(advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.asset,advices[selectedAdvice]?.uuid,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.tradetype).then((res: any) => {
                        if (res?.length > 0) {
                            setTargetSlChartData(res)
                        }
                        else {
                            setTargetSlChartData([])
                        }
                        setLoading_tarsl(false);
                    })
                    getMtmDrawdownOptions(advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.asset,advices[selectedAdvice]?.uuid,advices[selectedAdvice]?.s_version).then((res: any) => {
                        
                        if (res?.length > 0) {
                            setMtmDrawdownChartData(res)
                        }
                        else {
                            setMtmDrawdownChartData([])
                        }
                        setLoading_mtm(false);
                    })    
                }
            }
            else if(type === "fx-option"){
                if(viewCharts === true)
                {
                    getMtmDrawdownFXOptions(advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.asset,advices[selectedAdvice]?.uuid,advices[selectedAdvice]?.s_version).then((res: any) => {
                        if (res?.length > 0) {
                            setMtmDrawdownChartData(res)
                        }
                        else {
                            setMtmDrawdownChartData([])
                        }
                        setLoading_mtm(false);
                    })
                }
               
            }
            else if(type === "fx-future-multiday") 
            {
                if(viewCharts === true)
                {
                    getFXMultidayMtmDrawdown(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                        
                        if (res?.length > 0) {
                            setMtmDrawdownChartData(res)
                        }
                        else {
                            setMtmDrawdownChartData([])
                        }
                        setLoading_mtm(false);
                        
                    })
                    getFXMultidayTsl(advices[selectedAdvice]?.uuid, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                        
                        if (res?.length > 0) {
                            setTargetSlChartData(res)
                        }
                        else {
                            setTargetSlChartData([])
                        }
                        setLoading_tarsl(false);
                    })
                }
            
            }
            else if(type === "multiday-equity") {
                if(viewCharts === true)
                {
                    getEquityMtmDrawdown(advices[selectedAdvice]?.id, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                        if (res?.length > 0) {
                        setMtmDrawdownChartData(res)
                        }
                        else {
                            setMtmDrawdownChartData([])
                        }
                        setLoading_mtm(false);
                    
                    })
                    getEquityTsl(advices[selectedAdvice]?.id, advices[selectedAdvice]?.asset, advices[selectedAdvice]?.s_version, advices[selectedAdvice]?.open_date).then((res: any) => {
                        if (res?.length > 0) {
                            setTargetSlChartData(res)
                        }
                        else {
                            setTargetSlChartData([])
                        }
                        setLoading_tarsl(false);
                    })
                }
                
                
            }
        }

        else {
            setMtmDrawdownChartData([])
            setTargetSlChartData([])
            setRevisedDataList(null)
            setLoading_tarsl(false);
            setLoading_mtm(false);
        }
    }

    const handleAdviceClick = (e: any) => {
        setSelectedAdvice(e)
    }

    const handleAssetClick = (item: any, version: any) => {
        let asset_name = item.asset_name
  
        if (type === "options-stock" || type === "options-index" || type ==='fx-option') {
            if (asset_name == 'ALL' || asset_name == 'all') {
                setVersion('all')
                curVersion = 'all';
                setSelectedAsset('all')
                setTradeType('Total')
                setSelectedAdviceStatus(0)
            }
            else {
                 asset_name = assetArray(asset_name)
                 setVersion(version)
                 curVersion = version;  
                 setSelectedAsset(asset_name)
                 setTradeType(item.trade_type)
                 setSelectedAdviceStatus(0)
            }
        }
        else {
            if (asset_name == 'ALL' || asset_name == 'all') {
                setVersion(version)
                curVersion = version; 
                setSelectedAsset('all');
                setSelectedAdviceStatus(0)
            }
            else {
                setVersion(version)
                curVersion = version; 
                setSelectedAsset(asset_name);
                setSelectedAdviceStatus(0)
            }
        }
            
    }

    return <userContext.Consumer>
        {(user) => {
            return <StrategyTrackerLayout  handleAssetClick={handleAssetClick} selectedAsset={selectedAsset} type={type} assets={assetsData} advices={advices}
            parameters={parameters} noOfCalls={noOfCalls} pL={pL} handleAdviceClick={handleAdviceClick} selectedAdvice={selectedAdvice}
            targetSlChartData={targetSlChartData} mtmDrawdownChartData={mtmDrawdownChartData} successRatio={successRatio} allTweets={allTweets} setVersion={setVersion}
            version={version} asset_key_value={asset_key_value} currentTime={currentTime} setDropdownVersion={setDropdownVersion} setTradeType={setTradeType}
            revisedDatalist={revisedDatalist} setSelectedAdviceStatus={setSelectedAdviceStatus} selectedAdviceStatus={selectedAdviceStatus}
            trade_type={tradetype} theme={theme} handleRefresh={handleRefresh} setAdvices={setAdvices} loading_tarsl={loading_tarsl} loading_param={loading_param}
            loading_mtm={loading_mtm} clicked={clicked} setCalenderPickerDate={setCalenderPickerDate} calenderPickerDate={calenderPickerDate} backupDate={backupDate}
            returnPercentage={returnPercentage} setSelectedcode={setSelectedcode} versionsDataLive={versionsDataLive} sentimentScore={sentimentScore}
            selectedTrade={selectedTrade} advicesStatusType={advicesStatusType} setAdvicesStatusType={setAdvicesStatusType} setLoading_adv={setLoading_adv}
            loading_adv={loading_adv} disableRefreshButton={disableRefreshButton} alltradeDetails={alltradeDetails} alltradeDetailsWithCost={alltradeDetailsWithCost}
            allReturnper={allReturnper} allHeader={allHeader} setAllHeader={setAllHeader} mainAdvices={mainAdvices} callsCount={callsCount} selectedTradeDetails={selectedTradeDetails}
            viewCharts={viewCharts} setViewCharts={setViewCharts} rwCost={rwCost} setRwCost={setRwCost} minChargeDate={minChargeDate} releaseNoteShow={releaseNoteShow}
            setReleaseNoteShow={setReleaseNoteShow} releaseDetails={releaseDetails} newsPremarketSentiment={newsPremarketSentiment} accuracy={accuracy} newsSignals={newsSignals} niftyData={niftyData}
            returnPerc21={returnPerc21} returnPerc252={returnPerc252} loadR21={loadR21} loadR252={loadR252} loadReturn={loadReturn} privileges={privileges}
            setTodaysLiveIdentifiers={setTodaysLiveIdentifiers} loadCalls={loadCalls} loadSr={loadSr} loadPl={loadPl} news21acc={news21acc} news252acc={news252acc} eco21acc={eco21acc} eco252acc={eco252acc}
            jSentimentLength={jSentimentLength} totalPL={totalPL} setSentimentType={setSentimentType} sentimentType={sentimentType}/>
        }}
    </userContext.Consumer>

}
