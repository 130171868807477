import { frontendRoutes } from '../../../../constants/frontendRoutes';
import { News } from '../../../../Models/News';
import { add3Dots, getColorBySentiment, parseDateInDisplayFormat2 } from '../../../../util/utils';
import './NewsItem.css';

interface MediumNewsItemType7Props {
    article: News
}

export const MediumNewsItemType7: React.FC<MediumNewsItemType7Props> = ({article}) => {

    return (
        <div className="medium-thumbnail-type7 col-lg-6">
            <div className="post_img" style={{borderLeft: `5px solid ${getColorBySentiment(article.sentiment)}`}}>
                <div className="img_wrap">
                    <a href={frontendRoutes.newsHome + article.id}>
                        <img src={article.imageLink} />
                    </a>
                </div>
            </div>
            <div className="single_post_text" >
                <div className="meta">
                    <a href={"/news/keyword/"+article.category+"/category"} target="_blank" rel="noopener noreferrer">{article.category}</a>
                    <a href="#" className="meta-date">{parseDateInDisplayFormat2(article.publishedDate)}</a>
                    <p className="meta-publisher">{article.publisher}</p>
                </div>
                <h4>
                    <a target="_blank" rel="noopener noreferrer" href={frontendRoutes.newsHome + article.id}>
                        {article.title}
                    </a>
                </h4>
                <div className="space-10"></div>
                <p>{add3Dots(article.summary, 60)}</p>
            </div>
        </div>
    )
}