import { NewsHomeLayout } from '../../layouts/NewsHomeLayout';
import './style.css';

const NewsHome: React.FC = () => {
  return (
    <div className="main-container-ion-page">
      <NewsHomeLayout/>
    </div>
  );
};

export default NewsHome;
