import React, { useRef } from 'react';
import { LoginLandingPage } from '../../components/PortalHome/LoginLandingPage';
import './style.css';
import { ToastContainer } from 'react-toastify';


interface PortalHomeLayoutProps {
    isLoggedIn: boolean;
    handleLogin: any;
    isSignInModalVisible: any;
    setIsSignInModalVisible: any
    user: any
    theme:any 
}

export const PortalHomeLayout: React.FC<PortalHomeLayoutProps> = ({ isLoggedIn, handleLogin,
    isSignInModalVisible, setIsSignInModalVisible, user,theme }) => {
    const nodeRef = useRef<HTMLDivElement>(null)
    const rootElement = document.getElementById("root");
    function toggleModals() {
        setIsSignInModalVisible(!isSignInModalVisible)
    }

    const handleOutsideModalClick = (e: any) => {

        if (nodeRef.current?.contains(e.target)) {
            return;
        }
        toggleModals();
    }

    return <>
        {/* {createPortal(<SignInModal nodeRef={nodeRef} handleOutsideModalClick={handleOutsideModalClick} handleLogin={handleLogin}
            isVisible={isSignInModalVisible} />, rootElement!)} */}
        <div className="portal-home">
        <ToastContainer className="toast_container"/>
            {/* <TopBar isLoggedIn={isLoggedIn} user={user} theme = {theme} setTheme = {setTheme}  /> */}
            <div className="container-custom row" id="container-custom" >
                <div className=" imgDiv" >
                <img src="./assets/images/vistaWordCloud.jpeg" alt=""  />
                    {/* {theme=="light"?<img src="./assets/images/ai_light.jpg" alt=""  />
                    :theme=="dark"?<img src="./assets/images/ai_purple.jpg" alt="" />
                    :<img src="./assets/images/ai_grey.jpg" alt=""/>} */}
                     {/* {theme=="light"?<img src="./assets/images/diwali.png"  alt=""  />
                    :theme=="dark"?<img src="./assets/images/diwali.png"  alt="" />
                    :<img src="./assets/images/diwali.png" alt=""/>} */}
                    {/* <img src="./assets/images/ai_light.jpg" alt="" style={{ width: "100%"  }} /> */}
                </div>
                <div className=" Login_page ">
                    <LoginLandingPage handleLogin={ handleLogin} theme={theme}/>
                </div>
            </div>
            {/* </div> */}
        </div>
    </>
}