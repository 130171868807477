import { axiosUsdMultiday } from "../axios";
import { getUserObject } from "../Service/auth.service";


let userObj:any = getUserObject();

export function getLiveFXMultidayCalls(type: any, version: any) {
    return axiosUsdMultiday.post(`usdinr_multiday/get_live_calls`, {
        "version": version,
        "asset": type,
        "username": userObj.email_id
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log(err))
}

export function getLiveFXMultidayPl(type: any, version: any) {
    return axiosUsdMultiday.post(`usdinr_multiday/get_live_pl`, {
        "version": version,
        "asset": type,
        "username": userObj.email_id
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log(err))
}


export function getLiveFXMultidayAdviceStatus(type: any, version: any) {
    return axiosUsdMultiday.post(`usdinr_multiday/get_live_trades`, {
        "version": version,
        "asset": type,
        "username": userObj.email_id
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log(err))
}

export function getLiveFXMultidaySuccessRatio(type: any, version: any) {
    return axiosUsdMultiday.post(`usdinr_multiday/get_live_sr`, {
        "version": version,
        "asset": type,
        "username": userObj.email_id
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log(err))
}

export function getLiveFXMultidayPlPercentage(type: any, version: any) {
    return axiosUsdMultiday.post(`usdinr_multiday/get_live_pl_percent`, {
        "version": version,
        "asset": type,
        "username": userObj.email_id
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log("error came in api", err))
}




// ====================================================HISTORICAL ======================================

export function getFXMultidaycalls(type:any,startDate:any, endDate:any, version:any)
{    
    return axiosUsdMultiday.post(`usdinr_multiday/get_calls`,{
        "version": version,
        "asset": type,
        "startDate": startDate,
        "endDate":endDate,
        "username": userObj.email_id
    }).then((res:any) => {
                return res?.data;
        }).catch((err:any) => console.log(err))
}



export function getFXMultidayPl(type:any,startDate:any, endDate:any,version:any)
{    
    return axiosUsdMultiday.post(`usdinr_multiday/get_pl`,{
        "version": version,
        "asset": type,
        "startDate": startDate,
        "endDate":endDate,
        "username": userObj.email_id
    }).then((res:any) => {
                return res?.data;
        }).catch((err:any) => console.log(err))
}


export function getFXMultidaySR(type:any,startDate:any,endDate: any,version:any)
{
    return axiosUsdMultiday.post(`usdinr_multiday/get_sr`,{
        "version": version,
        "asset": type,
        "startDate": startDate,
        "endDate": endDate,
        "username": userObj.email_id
    }).then((res:any) => {
                return res?.data;
        }).catch((err:any) => console.log(err))
}


export function getFXMultidayAdvice(startDate:any,endDate: any, component: any ,version: any)
{
    return axiosUsdMultiday.post(`usdinr_multiday/get_trades`,{
        "version": version,
        "asset": component,
        "startDate": startDate,
        "endDate": endDate,
        "username": userObj.email_id
    }).then((res:any) => {
                return res?.data;
        }).catch((err:any) => console.log(err))
}

export function getFXMultidayPlPercentage(startDate:any, endDate:any,type:any, version:any)
{    
    return axiosUsdMultiday.post(`usdinr_multiday/get_pl_percent`,{
        "version": version,
        "asset": type,
        "startDate": startDate,
        "endDate":endDate,
        "username": userObj.email_id
    }).then((res:any) => {
                return res?.data;
        }).catch((err:any) => console.log(err))
}

export function getFXMultidayParameterlist(id: any, asset: any, version: any, date: any) {
    return axiosUsdMultiday.post('usdinr_multiday/get_parameter', {
        "id": id,
        "asset": asset,
        "version": version,
        "date": date,
        "username": userObj.email_id
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log(err))
}

export function getFXMultidayTsl(id: any, asset: any, version: any, date: any) {
    return axiosUsdMultiday.post('usdinr_multiday/get_target_stoploss', {
        "id": id,
        "asset": asset,
        "version": version,
        "date": date,
        "username": userObj.email_id
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log(err))
}

export function getFXMultidayMtmDrawdown(id: any, asset: any, version: any, date: any) {
    return axiosUsdMultiday.post('usdinr_multiday/get_m2m_drawdown', {
        "id": id,
        "asset": asset,
        "version": version,
        "date": date,
        "username": userObj.email_id
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log(err))
}