import React, { useEffect, useState } from 'react';
import { getInvestment, getLotsizeDetails, insertRow } from '../../Api/buySell/strategyTracker';
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import moment from 'moment';
import { toast } from 'react-toastify';

interface FormData {
    identifier: string,
    dt_date:any,
    dt_open_time: any,
    dt_exit_time: any,
    traded_lots: any,
    lot_size: any,
    s_reason:any,
    n_open_transaction_price: any,
    exit_price:any,
    trade_pl: any,
    trade_pl_rs:any,
    trade_return_per:any,
    margin_blocked: any,
    comment:any
  
}

interface ModalFormProps {
  modalIsOpen:any
  setModalIsOpen:any
  totalIdentifier:any
  onClose: () => void;
}

const ModalForm: React.FC<ModalFormProps> = ({onClose,totalIdentifier,modalIsOpen,setModalIsOpen }) => {
  const [formData, setFormData] = useState<FormData>({
    identifier: '',
    dt_date: null,
    dt_open_time: null,
    dt_exit_time: null,
    traded_lots: '',
    lot_size: '',
    s_reason: '',
    n_open_transaction_price:'',
    exit_price: '',
    trade_pl: '',
    trade_pl_rs:'',
    trade_return_per: '',
    margin_blocked:'',
    comment:''
    });

  const [date,setDate] = useState<any>(moment(new Date()).format("YYYY-MM-DD"))
  const [entryTime,setEntryTime] = useState<any>()
  const [exitTime,setExitTime] = useState<any>()
  const [Indentifier,setIdentifier] = useState<any>()
  const [reason, setReason] = useState<any>();
  const [emptyFieldBoolean, setEmptyFieldBoolean] = useState<boolean>(false)

  const [ investmentDetails, setInvestmentDetails ] = useState<any>({})
  const [ lotsizeDetails, setLotsizeDetails ] = useState<any>([])
  const notify = (value:any) => toast(value); 

  useEffect(() => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();

    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

    setEntryTime(formattedTime);
    setExitTime(formattedTime)
  }, []);

  useEffect(() => {

        fetchingInvestmentDetails(date)
        fetchingLotSizeDetails(date)
  },[modalIsOpen, date])


  const fetchingLotSizeDetails = async (date:any) => {
    await getLotsizeDetails(date).then((res:any) => {
        setLotsizeDetails(res)
    })
  }


  const fetchingInvestmentDetails = async (date:any) => {
    await getInvestment(date).then((res:any) => {
        setInvestmentDetails(res)
    })
  }


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: keyof FormData) => {

        setFormData({ ...formData, [fieldName]: e.target.value });
  };


    const handleInvestment = (identifier:any) => {

        let {symbol, investment_instrument} = handleIdentifier(identifier)
        let investment:any = investmentDetails[symbol+'_'+investment_instrument]
        let lotsize:any = lotsizeDetails[symbol]

        setFormData({ ...formData, ['margin_blocked']: investment, ['lot_size'] : lotsize , ['identifier'] : identifier, ['traded_lots'] : 1, ['n_open_transaction_price']:0, ['exit_price']:0, ['trade_pl']: 0, ['trade_return_per']:'', ['trade_pl_rs']:0});
    }

    const handleIdentifier = (identifier:any) => {
        let investment_instrument:any;
        let first_char = identifier.slice(1,3);
        let symbol:any = identifier.slice(3,6)
        let last_char = identifier.slice(-1);
        
        if(first_char ==="IF" || first_char ==="CF"|| first_char === "FF" || first_char ===  "SF"){
            investment_instrument = 'future'
            
        }
        else if((first_char ==="IO" || first_char ==="SO"|| first_char === "FO") && last_char === "S"){
            investment_instrument = 'opt_short'
            
        }
        else if((first_char ==="IO" || first_char ==="SO"|| first_char === "FO") && last_char === "L"){
            investment_instrument = 'opt_long'
        }

        setFormData({ ...formData, ['identifier']: identifier});


        return {symbol, investment_instrument}
    }

    const handlePL = (e: React.ChangeEvent<HTMLInputElement>, fieldName:any) => {

        let {symbol, investment_instrument} =handleIdentifier(formData['identifier'])
        let investment:any = investmentDetails[symbol+'_'+investment_instrument]

        let open_price:any =fieldName === 'n_open_transaction_price' ?  e.target.value : formData['n_open_transaction_price']
        let lot_size:any = fieldName === 'lot_size'? e.target.value :  formData['lot_size']
        let no_of_lots:any = fieldName === 'traded_lots'? e.target.value : 1
        let exit_price:any = fieldName === 'exit_price' ? e.target.value : formData['exit_price']
        let trade_type:any = formData['identifier'].slice(-1)
        let margin_blocked:any = fieldName === 'margin_blocked' ? e.target.value : investment
        let trade_pl :any =0


        if( (open_price !== null || open_price !== '') && (exit_price !== null || exit_price !== ''))
        {

            if (trade_type === 'L'){
                trade_pl = exit_price - open_price
            }
            else if(trade_type === 'S'){
                trade_pl = open_price - exit_price
            }
        }

        let trade_return:any = fieldName === 'trade_return_per' ? e.target.value :  ((((trade_pl * lot_size) * 100) / margin_blocked))

        setFormData({ ...formData,['trade_return_per']:trade_return, ['traded_lots']:no_of_lots, ['lot_size']:lot_size, ['n_open_transaction_price']: open_price, ['exit_price']:exit_price, ['trade_pl']: trade_pl, ['trade_pl_rs'] : (trade_pl * lot_size), ['margin_blocked'] : margin_blocked * no_of_lots});
    }


  const handleSubmit = async() => {

    formData['dt_open_time'] = entryTime
    formData['dt_exit_time'] = exitTime
    formData['dt_date'] = date
    formData['identifier'] = Indentifier
    formData['s_reason'] = reason
    
    let form_values:any = []
    let form_keys:any = Object.keys(formData)

    let filtered_keys:any = form_keys.filter((item:any)=> item !== 'comment')
    filtered_keys.map((item:any) => {form_values.push(formData[item as keyof object]) })

    let empty_fields:any = (form_values.includes('') || form_values.includes(null) || form_values.includes(undefined))
    
    if(empty_fields === false)
    {
        let insert_response:any = await insertRow(formData)
        if(insert_response === true)
        {
            setFormData({
                identifier: '',
                dt_date: null,
                dt_open_time: null,
                dt_exit_time: null,
                traded_lots: '',
                lot_size: '',
                s_reason: '',
                n_open_transaction_price:'',
                exit_price: '',
                trade_pl: '',
                trade_pl_rs:'',
                trade_return_per: '',
                margin_blocked:'',
                comment:''
                })
                setModalIsOpen(false)
        }
        onClose(); 
        notify("Trade added successfully")
    }
    else{
        setEmptyFieldBoolean(true)
    }
  }


  const handleModalClose = () =>{
    setEmptyFieldBoolean(false)
    setModalIsOpen(false)
  }

  let reasons:any =['Target Hit', 'Stoploss Hit', 'TSL Hit', 'Others', 'EOD']

return(
    <div >
        <Modal show={modalIsOpen} dialogClassName='back_office_modal' onHide={handleModalClose} style={{ userSelect: "none" }} size="lg"  >
            <Modal.Header closeButton style={{color : "black"}} > 
            </Modal.Header>
            <Modal.Body>
            <div className="col-lg-12 main_section">
                <Container  className="main_div" id="createUserDiv">
                    <Form  className="form_div" >
                        <Row >
                            <Form.Group className="header">
                            <span style={{fontSize: "2rem",fontWeight: "100"}}>New Trade Entry</span>
                            </Form.Group>
                        </Row>
                        <div className="form_data_section" >
                            <div className="mb-3 " id="createUserInfo">
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <div>
                                            <Form.Label id="titleName">Identifier</Form.Label>
                                            {totalIdentifier?.length > 0?<select name="" id="" className="form-control reason" onChange={(e:any) => {
                                                setIdentifier(e.target.value);handleInvestment(e.target.value);
                                                }}>
                                                    <option value='' selected disabled>Choose identifier</option>
                                                    {totalIdentifier?.map((value:any)=>(
                                                        <option  value={value} key={value}>
                                                        {value}
                                                        </option>
                                                    ))}
                                            </select>:<select></select>}
                                        </div>
                                        <div className='row_diff'>
                                            <Form.Label id="titleName">No of Lots</Form.Label>
                                            <Form.Control type="Number" value={formData.traded_lots} placeholder="Enter No Of Lots" onChange = {(e:any)=>{handleInputChange(e,'traded_lots'); handlePL(e, 'traded_lots')}}/>
                                        </div>
                                        <div className='row_diff'>
                                            <Form.Label id="titleName">Exit Time</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="time" value={exitTime} onChange = {(e)=>{setExitTime(e.target.value) }}/>
                                            </div>
                                        </div>
                                        <div className='row_diff'>
                                            <Form.Label id="titleName">Total P/L (₹)</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="text" value={formData.trade_pl_rs} placeholder='Enter Total P/L' onChange = {(e:any)=>{handleInputChange(e,'trade_pl_rs')}}/>
                                            </div>
                                        </div>

                                        <div className='row_diff'>
                                            <Form.Label id="titleName"> Close Reason</Form.Label>
                                            <select name="" id="" className="form-control reason" onChange={(e:any) => {
                                                setReason(e.target.value);
                                                }}>
                                                    <option value='' selected disabled>Choose close reason</option>
                                                    {reasons?.map((value:any)=>(
                                                        <option value={value} key={value}>
                                                        {value}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>

                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <div>
                                            <Form.Label id="titleName">Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="duedate"
                                                placeholder="Enter date"
                                                value={date}
                                                onChange={(e) => setDate(e.target.value)}
                                            />
                                        </div>
                                        <div className='row_diff'>
                                            <Form.Label id="titleName">Open Time</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="time" value={entryTime} onChange = {(e)=>{setEntryTime(e.target.value) }}/>
                                            </div>
                                        </div>
                                        <div className='row_diff'>
                                            <Form.Label id="titleName">Close Price</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="text" value={formData.exit_price} placeholder="Enter Close Price" onChange = {(e:any)=>{handleInputChange(e,'exit_price'); handlePL(e,'exit_price')}}/>
                                            </div>
                                        </div>
                                        
                                        {/* <div>
                                            <Form.Label id="titleName">Exit Date</Form.Label>
                                            <Calendar style={{width: "100%"}} id="basic" value={exitDate} onChange={(e) =>setExitDate(e.target.value)} showIcon placeholder="Select date" />
                                        </div> */}
                                        <div className='row_diff'>
                                            <Form.Label id="titleName">Return (%)</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="text" value={formData.trade_return_per} placeholder='Enter Return(%)' onChange = {(e:any)=>{handleInputChange(e,'trade_return_per');handlePL(e,'trade_return_per')}}/>
                                            </div>
                                        </div>
                                        <div className='row_diff'>
                                            <Form.Label id="titleName">Comment</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="text" value={formData.comment} placeholder='Enter Comment' onChange = {(e:any)=>{handleInputChange(e,'comment')}}/>
                                            </div>
                                        </div>


                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <div>
                                            <Form.Label id="titleName">Lot Size</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="number" value={formData.lot_size} placeholder='Enter Lot Size' onChange = {(e:any)=>{handleInputChange(e,'lot_size'); handlePL(e, 'lot_size')}}/>
                                            </div>
                                        </div>
                                        <div className='row_diff'>
                                            <Form.Label id="titleName">Transaction Price</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="text" value={formData.n_open_transaction_price} placeholder="Enter Transaction Price" onChange = {(e:any)=>{handleInputChange(e,'n_open_transaction_price'); handlePL(e, 'n_open_transaction_price') }}/>
                                            </div>
                                        </div>
                                        <div className='row_diff'>
                                            <Form.Label id="titleName">P/L</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="text" value={formData.trade_pl} placeholder="Enter P/L" onChange = {(e:any)=>{handleInputChange(e,'trade_pl')}}/>
                                            </div>
                                        </div>
                                        <div className='row_diff'>
                                            <Form.Label id="titleName">Margin Blocked</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="text" value={formData.margin_blocked} placeholder="Enter Total Margin" onChange = {(e:any)=>{handleInputChange(e,'margin_blocked')}}/>
                                            </div>
                                        </div>


                                    </Form.Group>


                                </div>
                            <div className='mb-3 submit_button'>
                                <Button variant="primary" type="button" style={{width:"40%", height :"4rem", borderRadius : "0.5rem"}} className="form_submit_button" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </div>
                            <div className='empty_field_text'>
                                <span>{emptyFieldBoolean === true ? 'Please enter data in all the fields' : '' }</span>
                            </div>
                        </div>
                    </Form>
                </Container>
            </div>
            </Modal.Body>
        </Modal>
    </div>

        )
};

export default ModalForm;

