import { useParams } from 'react-router-dom';
import { LogoContainer } from '../../components/shared/LogoArea/LogoContainer';
import './style.css';
import {useEffect, useReducer, useRef, useState } from 'react';
import { parseDateInDisplayFormat2 } from '../../util/utils';
import { SentimentChart } from '../../components/shared/SentimentChart';
import { RelatedNews } from '../../Models/RelatedNews';
import { RelatedNewsContainer } from '../../components/NewsArticle/RelatedNewsContainer';
import { frontendRoutes } from '../../constants/frontendRoutes';
import { sentimentIcon } from '../../components/shared/sentimentIcon';
import { getNewsById, getRelatedNewsById, getSentimentChartDataById } from '../../Api/News/news';

interface NewsArticleProps {}

export const NewsArticle: React.FC<NewsArticleProps> = (props) => {
    const params: any = useParams(); 
    const [loadedArticle, setLoadedArticle] = useState<any>({})
    const[labels, setLabels] = useState([]);
    const[negative, setNegative] = useState([]);
    const[neutral, setNeutral] = useState([]);
    const[positive, setPositive] = useState([]);
    const [relatedNewsIndex, setRelatedNewsIndex] = useState(1)

    const[relatedNewsArrByDate, setRelatedNewsArrByDate] = useState([[]])
    const relatedNewsRef = useRef<HTMLDivElement>(null)
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        getNewsById(params.id).then((res) => {
            setLoadedArticle({id: res._id, category: res.category, imageLink: res.image_link, 
                logoUrl: res.logo, publishedDate: res.datePublished, publisher: res.publisher, 
                sentiment: res.sentiment, title: res.title, summary: res.summary, 
                articleLink: res.article_link, keywords: res.keywords})
        })
    }, loadedArticle)

    useEffect(() => {
        getSentimentChartDataById(params.id, 1).then((res) => {
            handleSentimentData(res.sentiments)})
    }, [])

    useEffect(() => {
        getRelatedNewsById(params.id, 4, relatedNewsIndex).then((res) => {            
            handleRelatedNews(res)
            setRelatedNewsIndex(relatedNewsIndex + 1)
        })
    }, [])

    function handleRelatedNews(relatedNewsFromApi: any) {
        let reatedNewsArr: any = relatedNewsArrByDate;
        let tempArr: RelatedNews[] = []
        let dateModified = null
        relatedNewsFromApi.articles.map((data: any) => {
            
            dateModified = new Date(data.dateModified)
            let relatedNews = {id: data._id, author: data.author, dateModified: dateModified, logoUrl: data.logo, publisher: data.publisher,
                sentiment: data.sentiment, title: data.title}

            if (tempArr.length === 0) {
                tempArr.push(relatedNews)
            } else if (tempArr[0].dateModified.toDateString() === dateModified.toDateString()) {
                tempArr.push(relatedNews)
            } else {
                reatedNewsArr.push(tempArr)
                tempArr = [relatedNews]
            }
        })
        if(tempArr.length > 0){
            reatedNewsArr.push(tempArr)
        }
        setRelatedNewsArrByDate(reatedNewsArr)
    }

    const Keyword = ({keyword}: any) => {
        return (
            <a href={frontendRoutes.newsByKeyword + keyword} target="_blank" rel="noopener noreferrer">
                <div className="keyword-element">
                    {keyword}
                </div>
            </a>
        )
    }

    const keywords = () => {
        if(!loadedArticle.keywords) return '';
        let keywords: any = []
        
        loadedArticle.keywords.map((data: string) => {
            keywords.push(<Keyword keyword={data} />)
        })
        return keywords;
    }

    let handleSentimentData = (sentimentData: any) => {
        let negatives: any = []
        let neutrals: any = []
        let positives: any = []
        let labels: any = []
        sentimentData.map((data: any) => {
            negatives.push(data.negative)
            neutrals.push(data.neutral)
            positives.push(data.positive)
            labels.push(data.date)
        })
        
        setNegative(negatives)
        setNeutral(neutrals)
        setPositive(positives)
        setLabels(labels)
    }

    const dataForChart:any = {
        labels: labels,
        datasets: [
            {
              stack: "stack1",
              label: 'Positive',
              data: positive,
              backgroundColor: '#D6E9C6', 
            },
            {
                stack: "stack1",
              label: 'Neutral',
              data: neutral,
              backgroundColor: '#FAEBCC' 
            },
            {
                stack: "stack1",
              label: 'Negative',
              data: negative,
              backgroundColor: '#EBCCD1' 
            }
        ]
    };

    const options:any = {
        responsive: true,
        legend: {
            position: 'right' 
        },

        scales: {
            xAxes: [{
                stacked: true 
            }],
            yAxes: [{
                stacked: true, 
                ticks: {
                    beginAtZero: true,          
                    stepSize: 1
                },
                type: 'linear',
            }]
        },
        maintainAspectRatio: false,
    }

    const handleChartDuration = (week: number) => {
        if(week === 1){
            getSentimentChartDataById(params.id, 1).then((res) => {
                handleSentimentData(res.sentiments);
            })
        }
        if(week === 2){
            getSentimentChartDataById(params.id, 2).then((res) => {
                handleSentimentData(res.sentiments)
            })
        }
        if(week === 3){
            getSentimentChartDataById(params.id, 3).then((res) => {
                handleSentimentData(res.sentiments)
            })
        }
    }

    const handleLoadMore = () => {        
        getRelatedNewsById(params.id, 4, relatedNewsIndex).then((res) => {            
            handleRelatedNews(res)
            setRelatedNewsIndex(relatedNewsIndex + 1)
        })
        
        forceUpdate()
    }

    return (
        <div className="article-page">
            <LogoContainer />
            <div style={{height:20}}></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <h2>{loadedArticle.title}</h2>
                        <div className="article-meta">
                            <h3>Smart Summary</h3>
                            {/* <p>All the facts, consisely told in 127 words </p> */}
                            <p><b>Published on : </b>{parseDateInDisplayFormat2(loadedArticle.publishedDate)}</p>
                            <p><b>Publisher : </b>{loadedArticle.publisher}</p>
                        </div>
                        <div className="summary">
                            <p>{loadedArticle.summary}</p>
                            <p>
                                <a href={loadedArticle.articleLink} target="_blank" rel="noopener noreferrer">
                                    For Full Article Click Here
                                </a>
                            </p>
                            <p>
                                <label>Overall Sentiment</label>
                            </p>
                            <div>
                                <p >
                                    <div className="sentiment-icon">{sentimentIcon(loadedArticle.sentiment)['icon']}</div>
                                    <div className="sentiment-text">{sentimentIcon(loadedArticle.sentiment)['text']}</div>
                                </p>
                            </div>
                        </div>
                        <div className="keyword">
                            <p className="title">Keywords</p>
                            <div>
                                {keywords()}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="sentiment-section">
                            <div className="heading">
                                <h3 className="widget-title">
                                    Sentiment Analytics
                                </h3>
                                <p className="pb-2">Inspect how this story actually affected the market (and vice versa)</p>
                            </div>
                            <SentimentChart dataForChart={dataForChart} options={options} handleChartDuration={handleChartDuration} />
                        </div>
                    </div>
                </div>
                <div style={{height:20}}></div>
                <div className="row related-articles" ref={relatedNewsRef}>
                    <div className="col-12">
                        <div className="title2">
                            <h3>Prominent Articles & Tweets</h3>
                        </div>
                        {relatedNewsArrByDate.map((data) => {                            
                            return <RelatedNewsContainer relatedNewsArticles={data} />
                        })}
                        <div style={{textAlign: 'center'}}>
                            <button className="load-more" onClick={handleLoadMore}>Load More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}