import { axios } from "../axios";

let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoic3VydS5nQGdtYWlsLmNvbSIsInNlc3Npb25fdG9rZW4iOiI3Mzg1Mjg4MzIiLCJpYXQiOjE1OTMwNzI5NjF9.RN03IEpWbLIUuexFdKnUFdVEUltmwR-RHdYCpRRytto"

export function getNewsBySector(sector: string): Promise<any> {
    return axios.get(`/news/getnews?category=${sector}&skip=0&limit=10`)
        .then((res) => {
            return res.data;
        })
}

export function getNewsSectors(): Promise<any> {
    return axios.get("/getwordcloud?type=category")
        .then((res) => {
            let data = res.data.filter;
            let keys = Object.keys(data);
            let arr: any = []
            keys.map((item, i) => {
                arr.push({sector: item, value: data[item]})
            })
            arr.sort((a: any, b: any) => {
                if ( a.value < b.value ){
                    return 1;
                  }
                  if ( a.value > b.value ){
                    return -1;
                  }
                  return 0;
            })
            return arr;
        }
        )
}

export function getNewsById(id: string): Promise<any> {
    return axios.get(`/news/id/?id=${id}`)
        .then((res) => {
            return res.data;
        })
}

export function getSentimentChartDataById(id: string, weeks: number): Promise<any> {
    return axios.get(`/news/sentiment_analytics/?id=${id}&weeks=${weeks}`)
        .then((res) => {
            return res.data;
        })
}

export function getRelatedNewsById(id: string, weeks: number, index: number): Promise<any> {
    return axios.get(`/news/related_news/?id=${id}&weeks=${weeks}&index=${index}`)
        .then((res) => {
            return res.data;
        })
}

export function getNiftySentimentGraph(days: number) {
    return axios.get(`/niftysentiment/?category=NIFTY 50&days=${days}`)
        .then((res) => {
            return res.data.twitter_sentiment;
    })
}

export function getCategoryTags(): Promise<any> {
    return axios.get("/getwordcloud?type=category")
        .then((res) => {
            let data = res.data.filter;
            let keys = Object.keys(data);
            let arr: any = []
            keys.map((item, i) => {
                arr.push({value: item, count: data[item]})
            })
            
            return arr;
    }
    )
}

export function getCompanyTags(): Promise<any> {
    return axios.get("/getwordcloud?type=company")
        .then((res) => {
            let data = res.data.filter;
            let keys = Object.keys(data);
            let arr: any = []
            keys.map((item, i) => {
                arr.push({value: item, count: data[item]})
            })
            arr.sort((a: any, b: any) => {
                if ( a.count < b.count ){
                    return 1;
                  }
                  if ( a.count > b.count ){
                    return -1;
                  }
                  return 0;
            })
            return arr.slice(0, 20);
    }
    )
}

export function getKeywordTags(): Promise<any> {
    return axios.get("/getwordcloud?type=category")
        .then((res) => {
            let data = res.data.filter;
            let keys = Object.keys(data);
            let arr: any = []
            keys.map((item, i) => {
                arr.push({value: item, count: data[item]})
            })
            
            return arr;
    }
    )
}

export function getResultsByCategory(publisher: number) {
    return axios.get(`/news/getnews?category=${publisher}&skip=0&limit=30`)
        .then((res) => {
            return res.data;
    })
}

export function getFeedsenseData() {
    return axios.get(`/feedsense`)
        .then((res) => {
            return res.data;
    })
}

export function getAllRecomendedNews() {
    return axios.get(`/news?access_token=${token}`)
        .then((res) => {
            return res.data.recommended_articles;
    })
}

export function getAllTweets() {
    return axios.get(`/feedsense`)
        .then((res) => {
            return res.data.tweets;
    })
}

export function requestLogin(email: string, password: string) {
    return axios.post(`/user/login`, {user_id: email, password: password})
        .then((res) => {
            return res.data;
    })
}
export function getNewsBySectorForTopbar(): Promise<any> {
    return axios.get(`/news/getnews?category=Trade&skip=0&limit=40`)
        .then((res) => {
            return res.data;
        })
}