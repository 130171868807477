import { RelatedNews } from '../../Models/RelatedNews';
import './RelatedNewsContainer.css';
import { RelatedNewsItem } from './RelatedNewsItem';

interface RelatedNewsContainerProps {
    relatedNewsArticles: RelatedNews[]
}

export const RelatedNewsContainer: React.FC<RelatedNewsContainerProps> = ({relatedNewsArticles}) => {
    if(relatedNewsArticles.length === 0) return (
        <>
        </>
    )
    return (
        <div className="related-news-container row">
            <div className="container-head">
                <label className=" weekday">{relatedNewsArticles[0].dateModified.toLocaleString('default', { weekday: 'long' })}</label>
                <span className=" date">{relatedNewsArticles[0].dateModified.toLocaleString('default', {dateStyle: 'medium'})}</span>
            </div>
            <div className="row" style={{minWidth: '100%'}}>
                {relatedNewsArticles.map((news) => {
                    return (
                        <RelatedNewsItem relatedNews={news} />
                    )
                })}
            </div>
        </div>
    )
}