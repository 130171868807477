import './NiftySentimentChart.css';
import { Line } from 'react-chartjs-2';
import { useRef } from 'react';
import { getNiftySentimentChartColor } from '../../../../util/utils';

interface NiftySentimentChartProps {
    dataArr: any,
    labelsArr: any
}

export const NiftySentimentChart: React.FC<NiftySentimentChartProps> = ({dataArr, labelsArr}) => {
    
    const ref = useRef();

    const data:any = {
        labels: labelsArr,
        datasets: [
          {
            label: 'Sentiment',
            fill: true,
            lineTension: 0.2,
            backgroundColor: 'rgb(255,255,255,0.4)',
            borderColor: `${getNiftySentimentChartColor(dataArr)}`,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: `${getNiftySentimentChartColor(dataArr)}`,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: `${getNiftySentimentChartColor(dataArr)}`,
            pointHoverBorderColor: `${getNiftySentimentChartColor(dataArr)}`,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: dataArr
            
          }
        ]
      };

      const options:any = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false
        },
        borderWidth: 2,
        maintainAspectRatio: false,
        scales: {
            
            y: {
              display: true,
              title: {
                display: true,
                text: 'Sentiment'
              },
              grid: {
                borderDash: [1, 1],
                lineWidth: 2
             }
            },
            x: {
              grid: {
                display: false
             }
            }
        },
        plugins: {
            legend: {
              display: false
            }
          }
      }

    return (
        <div className="nifty-sentiment-chart-container">
            {/* <h2>Line Example</h2> */}
            <Line ref={ref}  data={data} options={options}/>
        </div>
    )
}