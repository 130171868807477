import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { userContext } from '../../Context/userContext';
import { fetch_back_advice, getInvestment, insertData, liveOpenListOfTrades, update_back_advice } from '../../Api/buySell/strategyTracker';
import { BackofficeLayout } from '../../layouts/BackOfficeLayout';
import { toast } from 'react-toastify';

interface BackOfficePageProps {
    theme:any
}

let backupDate:any = moment(new Date()).format('YYYY-MM-DD');

export const BackOfficePage: React.FC<BackOfficePageProps> =({ theme }) => {
    const [feedsensePl, setFeedsensePl] = useState<any>([])
    const [tradePl, setTradedPl] = useState<any>([])
    const [totaltradePlAfterCost, setTotalTradedPlAfterCost] = useState<any>([])
    const [totalFeedsensePlAfterCost, setTotalFeedsensePlAfterCost] = useState<any>([])  
    const [ totalMargin, setTotalMargin] = useState<any>([])
    const [selectedDate,setSelectedDate] = useState<any>()
    const [calenderpickerdate, setCalenderpickerdate] = useState<any>(backupDate);
    const [editBoolean, setEditBoolean] = useState<boolean>(false)
    const [rowUpdateBoolean, setRowUpdateBoolean] = useState<any>(null)
    const [loading, setLoading] = useState<any>(false);
    const [modalIsOpen, setModalIsOpen] = useState<any>(null);
    const [advices, setAdvices] = useState<any>([])
    const [ feedsenseAdvices, setFeedsenseAdvices ] = useState<any>([])
    const [ backOfficeAdvices, setBackOfficeAdvices ] = useState<any>([])
    const [ backOfficeBoolean, setBackOfficeBoolean ] = useState<boolean>(false)
    const [selectedTradeType, setSelectedTradeType] = useState<any>('Intraday');
    const [investment, setInvestment] = useState<any>([])

    const notify = (value:any) => toast(value); 

    backupDate = calenderpickerdate;
    
    useEffect(() => {
        if((backOfficeBoolean === true))
        {
            calculatingPlAndMargin()
        }
    },[backOfficeBoolean])
    

    useEffect(() =>{
            setFeedsenseAdvices([])
            setFeedsensePl([]);
            setTradedPl([]);
            setTotalTradedPlAfterCost([]);
            setTotalMargin([]);
            setTotalFeedsensePlAfterCost([]);
            calculatingPlAndMargin()
    },[selectedTradeType])

    useEffect(() => {
        if(modalIsOpen === false)
        {
            feedsenseLiveAdvices(calenderpickerdate)
            calculatingPlAndMargin()
        }
    },[modalIsOpen])
   
    useEffect(() => {
        fetchingInvestmentDetails(calenderpickerdate)
        feedsenseLiveAdvices(calenderpickerdate)
    },[calenderpickerdate,selectedTradeType])

    const fetchingInvestmentDetails = async (date:any) => {
        await getInvestment(date).then((res:any) => {
            setInvestment(res)
        })
      }
    const insertDataIntoTable = async(advice_trades:any)=>{

        let insert_response:any = await insertData(advice_trades)
        if(insert_response === true)
        {
            setBackOfficeBoolean(true)
        }
    }

    const handleInvestment = (identifier:any) => {
        let investment_instrument:any;
        let first_char = identifier.slice(1,3);
        let symbol:any = identifier.slice(3,6)
        let last_char = identifier.slice(-1);
        
        if(first_char ==="IF" || first_char ==="CF"|| first_char === "FF" || first_char ===  "SF"){
            investment_instrument = 'future'
            
        }
        else if((first_char ==="IO" || first_char ==="SO"|| first_char === "FO") && last_char === "S"){
            investment_instrument = 'opt_short'
            
        }
        else if((first_char ==="IO" || first_char ==="SO"|| first_char === "FO") && last_char === "L"){
            investment_instrument = 'opt_long'
        }

        return investment[symbol+'_'+investment_instrument]
    }

    const fetchBackOfficeAdvices = async (calenderpickerdate:any, selectedTradeType:any) => {
        let fetch_backoffice_advice:any = await fetch_back_advice(calenderpickerdate, selectedTradeType)
        setBackOfficeAdvices(fetch_backoffice_advice?.back_office_response)
        return fetch_backoffice_advice?.back_office_response;
    }

    const feedsenseLiveAdvices = async (calenderpickerdate:any) => {
        let advices_array:any = []
        let date:any = moment(calenderpickerdate).format('YYYY-MM-DD')
        let current_date:any = moment(new Date()).format('YYYY-MM-DD')
        let feedsense_live_advices:any = await liveOpenListOfTrades(date)

        let advices_without_equity:any = feedsense_live_advices.final_response?.filter((item:any)=>{
            return (item?.identifier).slice(1,3) !== 'ES'
        })

        if(calenderpickerdate === current_date && advices_without_equity?.length === 0)
        {
            setLoading(false)
        }
        else{
            setLoading(true)
        }

        if((feedsense_live_advices?.final_response)?.length > 0)
        {
            await feedsense_live_advices?.final_response?.map((item:any) => {
                if((item['s_version'] !== 'AIODDI') && (item['identifier'].slice(1,3) !== 'ES') && (item['identifier']?.slice(-2,-1)!='M'))
                {
                    item['s_trade_id'] = item['identifier']+'_'+item['open_date']+'_'+item['dt_open_time']
                    advices_array.push(item)
                }
            })
        }

        if((advices_array?.length > 0) && (selectedTradeType === 'Intraday')){
            setFeedsenseAdvices(advices_array)        
            await insertDataIntoTable(advices_array)  
        }
        else if(selectedTradeType === 'Multiday'){
            setBackOfficeBoolean(true)
        }
    }


    const getFloatValue = (value:any, identifier:any) => {
        return value !== null? parseFloat(value.toString().split('.')[0]+'.'+value.toString().split('.')[1]) : value
   
}

    const calculatingPlAndMargin = async() => {
        let feedsense_day_total_pl:any = 0;
        let traded_day_total_pl:any = 0;
        let total_day_margin:any = 0;
        let feedsense_pl_after_cost:any = 0;
        let traded_total_pl_after_cost:any = 0;
        let final_backoffice_advice:any = []
        let backOfficeAdvices:any = await fetchBackOfficeAdvices(calenderpickerdate, selectedTradeType)

        if (selectedTradeType === 'Intraday')
        {
            try{

                let backoffice_advice:any = (feedsenseAdvices)?.map((item_feedsense:any) => {
                    const trade_advice:any = backOfficeAdvices.find((item_trade:any) => item_trade.s_trade_id  ===  item_feedsense.s_trade_id); 
                        traded_day_total_pl += (getFloatValue((trade_advice?.traded_return_per), trade_advice?.s_product_code) * trade_advice?.n_margin_blocked)/100 ;
                        feedsense_day_total_pl += (getFloatValue((item_feedsense?.return_per), trade_advice?.s_product_code) * handleInvestment(trade_advice?.s_product_code))/100;
                        total_day_margin += trade_advice?.n_margin_blocked;
                        traded_total_pl_after_cost += (getFloatValue(trade_advice?.traded_return_after_cost, trade_advice?.s_product_code) * trade_advice?.n_margin_blocked )/100;
                        feedsense_pl_after_cost += (getFloatValue((item_feedsense?.return_after_cost), trade_advice?.s_product_code) * handleInvestment(trade_advice?.s_product_code))/100;
                    return {...item_feedsense, ...trade_advice}
                });
                final_backoffice_advice.push(...backoffice_advice)
            }
            catch(error:any){
                console.log("Error in catch intraday section -> ", error)
            }
        }
        else if(selectedTradeType === 'Multiday')
        {
            if(backOfficeAdvices?.length > 0)
            {
                backOfficeAdvices.forEach((item_trade: any) => {
                    if(item_trade.traded_return_per !== null)
                    {
                        traded_day_total_pl += ((getFloatValue((item_trade?.traded_return_per), item_trade?.s_product_code) * item_trade.n_margin_blocked)/100);
                        total_day_margin += item_trade.n_margin_blocked;
                        traded_total_pl_after_cost +=((getFloatValue((item_trade?.traded_return_after_cost), item_trade?.s_product_code) *item_trade.n_margin_blocked)/100);
                    }
                    final_backoffice_advice.push({ ...item_trade });
                });
            }
        }

        setFeedsenseAdvices(final_backoffice_advice)
        setFeedsensePl(feedsense_day_total_pl.toFixed(2));
        if(selectedTradeType === 'Intraday')
        {
            setTradedPl(traded_day_total_pl.toFixed(2))
        }
        else{
            setTradedPl(traded_day_total_pl)
        }
        setTotalTradedPlAfterCost(traded_total_pl_after_cost.toFixed(2));
        setTotalMargin(total_day_margin);
        setTotalFeedsensePlAfterCost(feedsense_pl_after_cost);
        setBackOfficeBoolean(false)
        setRowUpdateBoolean(false)
        setLoading(false)
    }
    const updateRowInDatabase = async(updatedData:any) => {
        let updated_response :any = await update_back_advice(updatedData)
        if(updated_response === true)
        {
            calculatingPlAndMargin()
            notify('Trade updated successfully')
        }
      };

    return <userContext.Consumer>
        {(user) => {
            return  <BackofficeLayout theme={theme} advices={feedsenseAdvices} setSelectedDate={setSelectedDate} setAdvices={setAdvices} feedsensePl={feedsensePl} 
            tradePl={tradePl} setCalenderpickerdate={setCalenderpickerdate} calenderpickerdate={calenderpickerdate} selectedDate ={selectedDate} updateRowInDatabase={updateRowInDatabase} 
            setEditBoolean={setEditBoolean} setRowUpdateBoolean={setRowUpdateBoolean}totalMargin={totalMargin}  
            totaltradePlAfterCost={totaltradePlAfterCost} totalFeedsensePlAfterCost={totalFeedsensePlAfterCost} loading = {loading} modalIsOpen = {modalIsOpen}
            setModalIsOpen = {setModalIsOpen}
            setTradedPl = {setTradedPl}
            setFeedsensePl = {setFeedsensePl}
            setTotalMargin = {setTotalMargin}
            setTotalTradedPlAfterCost = {setTotalTradedPlAfterCost}
            setTotalFeedsensePlAfterCost = {setTotalFeedsensePlAfterCost}
            backOfficeAdvices = {backOfficeAdvices}
            setSelectedTradeType = {setSelectedTradeType}
            selectedTradeType = {selectedTradeType}
            calculatingPlAndMargin = {calculatingPlAndMargin}
            feedsenseLiveAdvices = {feedsenseLiveAdvices}
        />
        }}    
    </userContext.Consumer>
}

