import React, { useEffect, useState } from 'react';
import { useLocation} from 'react-router';
import { userContext } from '../../Context/userContext';
import { HistoricalPerformanceLayout } from '../../layouts/HistoricalPerformanceLayout';
import { mergeMonthWiseData, optFutParameterList, parameterList, removeDuplicates, selectVersionValues } from '../../util/utils';
import './style.css';
import moment from 'moment';
import { getCurrencyAdviceStatus, getCurrencyCalls, getCurrencyMtmDrawdown, getCurrencyPl, getCurrencyPlPercentage, getCurrencySr, getCurrencyLiveTargetSl, getIntradayCalls, getIntradayPl, getIntradaySIAdvices, getIntradaySImtmDrawdown, getIntradayliveSITargetSl, getIntradaySr, getTotalAssets, get_Intraday_advice_Commodity, get_Intraday_calls_Commodity, getCmMtmDrawdownCommodity, get_Intraday_pl_Commodity, get_Intraday_pl_percentage_Commodity,getSEdate, get_Intraday_sr_Commodity, getliveIntradayTargetslnCommodity, getIntradayParameter, getCurrencyParameter, getCommodityParameter } from '../../Api/buySell/intradayStockIndex';
import { getCallsOptions, getListOfTradesOptions, getMtmDrawdownOptions, getOptionsParameter, getPlOptions, getSrOptions, getTargetSlOptions, getPlPercent } from '../../Api/buySell/optionsAPI';
import { getIntradayStockFutureAdvices, getIntradayStockFutureCalls, getIntradayStockFuturemtmDrawdown, getIntradayStockFutureParameter, getIntradayStockFuturePl, getIntradayStockFuturePlPercentage, getIntradayStockFutureSr, getIntradayStockFutureTargetSl } from '../../Api/buySell/stockfuture';
import { getUserObject } from '../../Api/Service/auth.service';
import { getCallsFXOptions, getFXOptionAdviceStatus, getFXOptionPlPercentage, getFXOptionsParameter, getMtmDrawdownFXOptions, getPlFXOptions, getSrFXOptions } from '../../Api/buySell/fxOptions';
import { getFXMultidayAdvice, getFXMultidayMtmDrawdown, getFXMultidayParameterlist, getFXMultidayPl, getFXMultidayPlPercentage, getFXMultidaySR, getFXMultidayTsl, getFXMultidaycalls } from '../../Api/buySell/usdMultiday';
import { getEquityMtmDrawdown, getEquityParameterlist, getEquityTsl, getHistEquityAdvicesStatus, getHistEquityCAlls, getHistEquityPl, getHistEquityPlPercentage, getHistEquitySR } from '../../Api/buySell/equity_spot_multiday';



interface HistoricalPerformancePageProps { 
    theme:any 
}

export const HistoricalPerformancePage: React.FC<HistoricalPerformancePageProps> = ({theme}) => {
    

    const location = useLocation<any>()
    const [months, setMonths] = useState<any>(12)
    const [calls, setCalls] = useState<any>({ months: [] })
    const [srData, setSrData] = useState<any>({ months: [] })
    const [plData, setPlData] = useState<any>({ months: [] })
    const [assetDataHeatmap, setAssetDataHeatmap] = useState<any>([])
    const [advices, setAdvices] = useState<any>([])
    const [assets, setAssets] = useState<any>([])
    const [asset, setAsset] = useState<any>("all")
    const [selectedAdvice, setSelectedAdvice] = useState<any>(0)
    const [selectedAdviceStatus, setSelectedAdviceStatus] = useState<any>(0)
    const [parameters, setParameters] = useState<any>({})
    const [parameterListChartData,setParameterListChartData] = useState<any>([])
    const [mtmDrawdownChartData, setMtmDrawdownChartData] = useState<any>([])
    const [type, setType] = useState<any>(location.search.slice(1, location.search.length))
    const [dateFilter, setDateFilter] = useState<any>('no_of_months') 
    const [startDateFilter, setStartDateFilter] = useState<any>()
    const [endDateFilter, setEndDateFilter] = useState<any>()
    const [fyYear, setFyYear] = useState<any>()
    const [intradayTargetSlChartData, setIntradayTargetSlChartData] = useState<any>([])
    const [PtTargetSlChartData, setPtTargetSlChartData] = useState<any>([])
    const [intradayMtmDrawdownChartData, setIntradayMtmDrawdownChartData] = useState<any>([])
    const [version,setVersion] = useState<any>('all')
    const [dropdownversion,setDropdownVersion] = useState<any>('all')
    const [tradetype, setTradeType] = useState<any>('Total')
    const [SFmultidayTargetSlChartData, setSFMultidayTargetSlChartData] = useState<any>([])
    const [multidayTargetSlChartData, setMultidayTargetSlChartData] = useState<any>([])
    const [rangeStartDate, setRangeStartDate] = useState<any>("");
    const [rangeEndDate, setRangeEndDate] = useState<any>("");
    const [dateRecieved,setDateRecieved] = useState<boolean>(false);
    const [backupStartDate, setbackupStartDate] = useState<any>();
    const [backupEndDate, setbackupEndDate] = useState<any>();
    const [load_heat_map, setLoad_heat_map] =useState<any>(true);
    const [versionsData,setVersionsData] =useState<any>([]);
    const [first_load,setFirst_load] = useState<any> (false);
    const [load_hist_calls, setLoad_hist_calls] = useState<any>(false)
    const [load_hist_sr, setLoad_hist_sr] = useState<any>(false)
    const [load_hist_pl, setLoad_hist_pl] = useState<any>(false)
    const [load_hist_targetsl, setLoad_hist_targetsl] = useState<any>(false)
    const [load_hist_mtm, setLoad_hist_mtm] = useState<any>(false);
    const [load_hist_param,setLoad_hist_param]= useState<any>(false);
    const [selectedTradeDetails,setselectedTradeDetails]=useState<any>({});
    const [ viewCharts, setViewCharts ] = useState<boolean>(false)

    const asset_key_value: any ={
        'NIFTY-LONG' :'NIFTY-LONG',
        'NIFTY-SHORT' :'NIFTY-SHORT',
        'NIFTY-I.NFO-SHORT' :'NIFTY-SHORT',
        'NIFTY-I.NFO-LONG' :'NIFTY-LONG',	
        'BANKNIFTY-I.NFO-SHORT' :'BANKNIFTY-SHORT',
        'ALL':'ALL',
        'GOLD-I.MCX':'GOLD',
        'SILVER-I.MCX':'SILVER',
        'CRUDEOIL-I.MCX':'CRUDEOIL',
        'NIFTY-I.NFO':'NIFTY',
        'BANKNIFTY-I.NFO':'BANKNIFTY',
        'USDINR-I.CDS':'USDINR',
        'NIFTY-I.NFO_TCS-I.NFO' : 'NIFTY_TCS',
        'INFY-I.NFO_TCS-I.NFO' :'INFY_TCS',
        'NIFTY-I.NFO_BANKNIFTY-I.NFO' : 'NIFTY_BANKNIFTY',
        'INFY-I.NFO' :'INFY',
        'RELIANCE-I.NFO':'RELIANCE',
        'DRREDDY-I.NFO' : 'DRREDDY' 
    }

    let accessproducts=getUserObject();
    const privileges= accessproducts.privileges
    

    const handleVersions = (type:any)=>{
        let versions:any=[] 
        privileges.map((item:any) => {
            if((item.length>9) && (item.slice(1,3).concat(item.slice(-2,-1))===selectVersionValues(type)))
            {   
                versions.push(item.slice(0,3).concat(item.slice(6)));    
                versions= removeDuplicates(versions)          
            }

             if(item.slice(1,3).concat(item.slice(-1))===selectVersionValues(type))
            {
                
                versions.push(item.slice(0,3).concat(item.slice(6)));
                versions= removeDuplicates(versions)
            }
        })
        if(type==='options-index')
          versions= removeDuplicates(versions)      
            
        setVersionsData(versions)
    }

    useEffect(()=>{
        handleVersions(type)
        setselectedTradeDetails(advices[selectedAdvice])
    },[type,advices,selectedAdvice])



    useEffect(()=>{
        setViewCharts(false)
        setFirst_load(true);
    },[type])

    useEffect(()=>{
        if(dateFilter === 'no_of_months'){
            setMonths(12)
            fetchStartAndEndDate(months, asset, type);
        }
    },[dateFilter])

    useEffect(() => {
        setStartDateFilter('')
        setEndDateFilter('')
        setType(location.search.slice(1, location.search.length))
        setAsset("all")
        setVersion('all')
        setDropdownVersion('all')
        setTradeType('Total')
        setSelectedAdviceStatus(0)
    }, [location])


    useEffect(()=>{
        
        if(dateFilter === 'no_of_months'){
            fetchStartAndEndDate(months, asset, type);
        }else{
            setStartDateFilter(backupStartDate);
            setEndDateFilter(backupEndDate);
            setDateRecieved(!dateRecieved);  
        }
        
    },[months, asset, type, version,tradetype])
 
    useEffect(()=>{
        setAssetDataHeatmap([])
        setAssets([])
        setAdvices([])
    },[type])
    

    useEffect(() => {
        if(startDateFilter && endDateFilter){
                if(asset === "all")
                {
                    fetchCompanyWiseProfitData(dropdownversion,startDateFilter,endDateFilter)
                }
                else{
                    fetchCompanyWiseProfitData(version,startDateFilter,endDateFilter)
                }
            } 
    }, [ dateRecieved, asset, type, fyYear,version,dropdownversion, tradetype])


    useEffect(() => {
        if(startDateFilter && endDateFilter){
            if(asset == 'all' || asset == 'ALL')
            {
                if(startDateFilter  && endDateFilter){
                    fetchCalls(dropdownversion,startDateFilter, endDateFilter)
                    fetchSr(dropdownversion,startDateFilter, endDateFilter)
                    fetchPl(dropdownversion,startDateFilter, endDateFilter)
                }
            }
            else{
                if(startDateFilter && endDateFilter){
                    fetchCalls(version,startDateFilter, endDateFilter)
                    fetchSr(version,startDateFilter, endDateFilter)
                    fetchPl(version,startDateFilter, endDateFilter)
                }
                
            }
        }
    }, [dateRecieved, asset, type,tradetype, fyYear,version,dropdownversion])

    useEffect(() => {   
        if(startDateFilter && endDateFilter){
            if (type === 'intraday-stock-index' ) {
                if(asset === "all" || asset === "ALL")
                {
                    fetchIntradaySIAdvices(startDateFilter,endDateFilter,type,dropdownversion)
                }
                else{
                    fetchIntradaySIAdvices(startDateFilter,endDateFilter,asset,version)
                }
            }
            else if (type === 'intraday-stock-future' ) {
                if(asset === "all" || asset === "ALL")
                {
                    fetchIntradayStockFutureAdvices(startDateFilter,endDateFilter,type,version)
                }
                else{
                    fetchIntradayStockFutureAdvices(startDateFilter,endDateFilter,asset,version)
                }
            }
            else if(type === 'fx-future')
            {
                if(asset === "all" || asset === "ALL")
                {
                    IntradayCurrencyAdvices(startDateFilter,endDateFilter,type,dropdownversion)
                }
                else{
                    IntradayCurrencyAdvices(startDateFilter,endDateFilter,asset,version)
                }
            }
            else if(type === 'fx-option'){
                if(asset === "all" || asset === "ALL")
                {
                    IntradayFXOptionAdvices(startDateFilter,endDateFilter,dropdownversion,tradetype,type)
                }
                else{
                    IntradayFXOptionAdvices(startDateFilter,endDateFilter,version,tradetype,asset)
                }
            }
            else if(type === "comodity-future")
            {
                if(asset === "all" || asset === "ALL")
                {
                    fetchCommodityAdvice(startDateFilter,endDateFilter,type,version)
                }
                else{
                    fetchCommodityAdvice(startDateFilter,endDateFilter,asset,version)
                }
            }
            else if(type === "options-stock" || type === "options-index")
            {   
                if(asset === "all" || asset === "ALL")
                {
                    fetchOptionsAdvice(startDateFilter,endDateFilter,dropdownversion,tradetype,type)   
                }
                else{
                    fetchOptionsAdvice(startDateFilter,endDateFilter,version,tradetype,asset)
                }
            }
            else if (type === "stock-index" ||type === "multiday-equity"|| type === 'stock' || type === "stock-future"  || type === "overall"  || type === "all" || type==="fx-future-multiday" ) {
                let input;
                if(asset === "all" )
                {
                    input = type;
                }
                else{
                    input = asset;
                }   
                fetchAdvices(startDateFilter,endDateFilter,type,input,version)
            }
           
        }
    }, [type,asset,dateRecieved,fyYear,version,dropdownversion,tradetype])

    useEffect(() => {
        if(viewCharts===true)
        {
            chartData()
        }
    },[viewCharts, selectedAdvice,asset, version, advices])

    useEffect(() => {
        parameterData()
    },[ months, asset, advices, selectedAdvice,version])

    const parameterData = () => {
        let open_date = moment(advices[selectedAdvice]?.open_date).format('YYYY-MM-DD')
        if(startDateFilter && endDateFilter){
            if (type === 'intraday-stock-index') {
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchIntradaySIParameter(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.s_version)
            }
            else if (type === 'intraday-stock-future') {
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchIntradayStockFutureParameter(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.s_version)
            }
            else if(type === 'fx-future')
            {
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchCurrencyParameter(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
            }
            else if(type === "comodity-future")
            {
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchCommodityParameter(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
            }
            else if(type === "options-stock" || type === "options-index" )
            {  
                fetchOptionParameter(advices[selectedAdvice]?.uuid,advices[selectedAdvice]?.asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
            }
            else if(type === 'fx-option'){
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchFXOptionParameter(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
            }else if(type ==='fx-future-multiday'){
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchFXMultiDayParameter(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
            }
            else if(type ==='multiday-equity')
            {
                fetchEquityParameterlist(advices[selectedAdvice]?.uuid,advices[selectedAdvice]?.asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
            }
            
        }
    }
   

    const chartData = () => {
        let open_date = moment(advices[selectedAdvice]?.open_date).format('YYYY-MM-DD')
        let close_date =  moment(advices[selectedAdvice]?.trade_close_date).format('YYYY-MM-DD')
        if(startDateFilter && endDateFilter){
            if (type === 'intraday-stock-index') {
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchIntradaySITargetSl(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.s_version)
                fetchIntradaySImtmDrawdown(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.s_version)
            }
            else if (type === 'intraday-stock-future') {
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchIntradayStockFutureTargetSl(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.s_version)
                fetchIntradayStockFuturemtmDrawdown(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.s_version)
            }
            else if(type === 'fx-future')
            {
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchCurrencyTargetSl(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
                fetchCurrencyMtmDrawdown(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
            }
            else if(type === "comodity-future")
            {
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchCommodityTargetSl(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.tradetype)
                fetchCommoditymtmDrawdown(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
            }
            else if(type === "options-stock" || type === "options-index" )
            {  
                fetchOptionsTargetSl(advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.asset,advices[selectedAdvice]?.uuid,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.tradetype)
                fetchOptionsmtmDrawdown(advices[selectedAdvice]?.open_date,advices[selectedAdvice]?.asset,advices[selectedAdvice]?.uuid,advices[selectedAdvice]?.s_version)
            }
            else if(type === 'fx-option'){
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchFXOptionsmtmDrawdown(advices[selectedAdvice]?.open_date,adv_asset,advices[selectedAdvice]?.uuid,advices[selectedAdvice]?.s_version)
            }else if(type ==='fx-future-multiday'){
                let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(advices[selectedAdvice]?.asset)]
                fetchFXMultiDayMtmDrawDown(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date);
                fetchFXMultiDayTSL(advices[selectedAdvice]?.uuid,adv_asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
            }
            else if(type ==='multiday-equity')
            {
                fetchEquityMtmDrawDown(advices[selectedAdvice]?.uuid,advices[selectedAdvice]?.asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date);
                fetchEquityMultiDayTSL(advices[selectedAdvice]?.uuid,advices[selectedAdvice]?.asset,advices[selectedAdvice]?.s_version,advices[selectedAdvice]?.open_date)
            }
        }
    }
    
    
    const fetchCompanyWiseProfitData = async (version:any, startDate:any, endDate:any) => {
        setLoad_heat_map(true);
        let assetWiseProfitsBS:any = []
        if(type === "intraday-stock-index" || type === "overall")
        {
            if (asset == "all"){
                assetWiseProfitsBS = await getTotalAssets(startDate,endDate,type,version)
            }
            else{
                assetWiseProfitsBS = await getTotalAssets(startDate,endDate,asset,version)
            }
        }
        else if(type === "intraday-stock-future" )
        {
            if (asset == "all"){
                assetWiseProfitsBS = await getIntradayStockFuturePlPercentage(startDate,endDate,type,version)
            }
            else{
                assetWiseProfitsBS = await getIntradayStockFuturePlPercentage(startDate,endDate,asset,version)
            }
        }
        else if(type === 'fx-future')
        {
            if (asset == "all"){
                assetWiseProfitsBS = await getCurrencyPlPercentage(startDate,endDate,type,version)
            }
            else{
                assetWiseProfitsBS = await getCurrencyPlPercentage(startDate,endDate,asset,version)
            }
        }
        else if(type === "comodity-future")
        {
            if (asset == "all" ||asset == 'ALL'){
                assetWiseProfitsBS = await get_Intraday_pl_percentage_Commodity(startDate,endDate,type,version)
            }
            else{
                assetWiseProfitsBS = await get_Intraday_pl_percentage_Commodity(startDate,endDate,asset,version)
            }
        }
        else if(type === "options-stock" || type === "options-index")
        {
            if (asset == "all" ||asset == 'ALL'){
                assetWiseProfitsBS = await getPlPercent(startDate,endDate,version,tradetype,type) 
            }
            else{

                assetWiseProfitsBS = await getPlPercent(startDate,endDate,version,tradetype,asset)
            }  
        }
        else if(type === "fx-option"){
            assetWiseProfitsBS = await getFXOptionPlPercentage(startDate,endDate,version,tradetype,type)
        }
        else if(type ==='fx-future-multiday'){
            assetWiseProfitsBS = await getFXMultidayPlPercentage(startDate,endDate,type,version)
        }
        else if(type ==='multiday-equity'){
            assetWiseProfitsBS = await getHistEquityPlPercentage(startDate,endDate,type,version)
        }


        
       
            let assetWiseProfits = assetWiseProfitsBS
        
        
        assetWiseProfits?.sort((first: any, second: any) => {
            return second?.pl_percentage - first?.pl_percentage;
        })
        let historicalPercentageDataCarousel: any = []
        let historicalPercentageDataHeatmap: any = []
        let assets: any = []
        let plPercentage

        for (let i = 0; i < assetWiseProfits?.length; i++) {
            let data = assetWiseProfits[i]
            let direction = "+"
            if (parseFloat(data?.sum) < 0) {
                direction = "-"
            }
            
            let algo = ""
            if(data?.s_version == 'AI')
            {
                algo = "AI"
            }
            else if(data?.s_version === 's_opt_strangle')	
            {	
                if(type === "options-stock" || type === "options-index")
                {
                algo = "Q1"
                }

            }
            else if(data?.s_version==='s_markov_0.1') {
                if(type === "options-stock" || type === "options-index" || type === 'fx-option')
                {
                algo = "Q2"
                }
                else{
                    algo = "Q1"
                }	
            }
                plPercentage = data?.pl_percentage
            
            if ((type === "stock-index" || type === "overall" || type === "stock" || type === "fx-future-multiday") && asset == 'all' )
            {
                
                historicalPercentageDataCarousel.push({
                    name: asset_key_value[data?.asset], algo:  "",
                    percentage: parseFloat(data?.pl_percentage).toFixed(2) + "%", direction: direction
                })
                historicalPercentageDataHeatmap.push({
                    name: asset_key_value[data.asset], 
                    algorithm:  algo,
                    percentage: parseFloat(data?.pl_percentage).toFixed(2), 
                    valueInPts: data?.sum.toFixed(2),
                    value: data?.pl_in_rs.toFixed(2),
                    plPercentage:plPercentage,
                    version: data?.s_version,
                    identifier: data?.identifier
                    
                })
                assets.push(data?.asset)
            }else if ((type === "comodity-future" ) && asset == 'all' ) {
                historicalPercentageDataCarousel.push({
                    name: asset_key_value[data?.asset], algo:  "",
                    percentage: parseFloat(data?.pl_percentage).toFixed(2) + "%", direction: direction
                })
                
                historicalPercentageDataHeatmap.push({
                    name: asset_key_value[data.asset], 
                    algorithm:  algo,
                    percentage: parseFloat(data?.pl_percentage).toFixed(2), 
                    valueInPts: data?.sum,
                    value: data?.pl_in_rs,
                    plPercentage:plPercentage,
                    version: data?.s_version,
                    identifier: data?.identifier
                    
                })
                assets.push(data?.asset)
                
            }
            else if(type === "intraday-stock-index" && asset === "all" || type === 'fx-future' && asset === "all"  || type === "intraday-stock-future" && asset==='all')
            {   
                historicalPercentageDataCarousel.push({
                    name: asset_key_value[data?.asset], algo: '',
                    percentage: parseFloat(data?.pl_percentage).toFixed(2) + "%", direction: direction
                })
                historicalPercentageDataHeatmap.push({
                    name: asset_key_value[data?.asset],
                    algorithm:  algo,
                    percentage: parseFloat(data?.pl_percentage).toFixed(2),
                    valueInPts: data?.sum.toFixed(2),
                    value: data?.pl_in_rs.toFixed(2),
                    plPercentage: plPercentage,
                    version:data?.s_version,
                    identifier: data?.identifier

                })
                assets.push(data?.asset)
                
            }
           
                       
            else if(type === "options-stock" || type === "options-index" || type === 'fx-option'){
               let assetName:any = data.asset.split('-')[0]
                historicalPercentageDataCarousel.push({
                    name: Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(assetName)],
                    tradetype:data.trade_type,
                    algo: algo,
                    version: data.s_version,
                    percentage: parseFloat(data?.pl_percentage).toFixed(2) + "%",
                    direction: direction  
                })
                historicalPercentageDataHeatmap.push({
                    name: Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(assetName)],
                    tradetype:data.trade_type,
                    algorithm: algo,
                    version : data.s_version,
                    percentage: parseFloat(data?.pl_percentage)?.toFixed(2),
                    valueInPts: Number(data?.sum)?.toFixed(2),
                    value: Number(data?.pl_in_rs).toFixed(2),
                    plPercentage: plPercentage,
                    identifier: data?.identifier
                    
                })
                assets.push(asset_key_value[data?.asset])
            }
            else if(type === 'multiday-equity'){
                
                let assetName:any = data.asset
                 historicalPercentageDataCarousel.push({
                     name: assetName,
                     tradetype:data.trade_type,
                     algo: "",
                     version: data.s_version,
                     percentage: parseFloat(data?.pl_percentage).toFixed(2) + "%",
                     direction: direction  
                 })
                 historicalPercentageDataHeatmap.push({
                     name: assetName,
                     tradetype:data.trade_type,
                     algorithm: algo,
                     version : data.s_version,
                     percentage: parseFloat(data?.pl_percentage)?.toFixed(2),
                     value: Number(data?.sum)?.toFixed(2),
                     plPercentage: plPercentage,
                     identifier: data?.identifier
                     
                 })
                 assets.push(assetName)
             }
             
            
        }
        
        if ((type === "stock-index" || type === "overall" ||  type === "intraday-stock-future" || type==="stock-future" || type === 'fx-future'  ||type === 'intraday-stock-index'|| type === "stock" || type === "comodity-future" )  ) 
        {
          if (assetWiseProfitsBS?.length > 1)
          {
              if(version == 'all') {
                 let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf("ALL")]
                 historicalPercentageDataHeatmap.push({  
                     name: adv_asset,
                     algorithm: "",
                     type:type,
                     percentage: (parseFloat(sum(assetWiseProfitsBS?.map((x:any)=>x?.pl_percentage)))/assetWiseProfitsBS?.length)?.toFixed(2), 
                     value: '',
                    plPercentage: '', 
                    version: 'all'
                  })
              }
              else{
                  let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf('ALL')]     
                  historicalPercentageDataHeatmap.push({  
                      name: adv_asset,
                      algorithm: "",
                      type:type,
                      plPercentage: '',
                      percentage: (parseFloat(sum(assetWiseProfitsBS?.map((x:any)=>x?.pl_percentage)))/assetWiseProfitsBS?.length)?.toFixed(2), 
                      value: '',
                      version: assetWiseProfitsBS[0]?.s_version
                   })
              }
          }
        }
        else if(type === "multiday-equity"){
            if (assetWiseProfitsBS?.length > 1)
            {
                if(version == 'all') {
                    let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf("ALL")]
                    historicalPercentageDataHeatmap.push({  
                        name: adv_asset,
                        algorithm: "",
                        type:type,
                        percentage: (parseFloat(sum(assetWiseProfitsBS?.map((x:any)=>x?.pl_percentage)))/assetWiseProfitsBS?.length)?.toFixed(2), 
                        value: '',
                       plPercentage: '', 
                       version: 'all'
                     })
                 }
                 else{
                     let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf('ALL')]     
                     historicalPercentageDataHeatmap.push({  
                         name: adv_asset,
                         algorithm: "",
                         type:type,
                         plPercentage: '',
                         percentage: (parseFloat(sum(assetWiseProfitsBS?.map((x:any)=>x?.pl_percentage)))/assetWiseProfitsBS?.length)?.toFixed(2), 
                         value: '',
                         version: assetWiseProfitsBS[0]?.s_version
                      })
                 }
            }
        }
        
        else if(type === "options-stock" || type === "options-index" || type === "overall" || type==='fx-option' ){
            if (assetWiseProfitsBS?.length > 1)
            {
                if(version == 'all') {
                historicalPercentageDataHeatmap.push({  
                    name: 'ALL',
                    algorithm: "",
                    tradetype:tradetype,
                    type:type,
                    percentage: (parseFloat(sum(assetWiseProfitsBS?.map((x:any)=>x?.pl_percentage)))/assetWiseProfitsBS?.length)?.toFixed(2), 
                    value: '',
                    plPercentage: '', 
                    version: 'all'
                    })
                    
                }
                else{
                    let adv_asset = Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf('ALL')]
                    historicalPercentageDataHeatmap.push({  
                        name: adv_asset,
                        algorithm: "",
                        tradetype:tradetype,
                        type:type,
                        plPercentage: '',
                        percentage: (parseFloat(sum(assetWiseProfitsBS?.map((x:any)=>x?.pl_percentage)))/assetWiseProfitsBS?.length)?.toFixed(2), 
                        value: '',
                        version: assetWiseProfitsBS[0]?.s_version
                     })
                }
            }
        }
        
        if ((type === "stock-index" || type === "intraday-stock-future" || type==="stock-future" || type === "overall" || type === "stock" || type === "intraday-stock-index" || type === "fx-future"  
        || type === "comodity-future" || type === "options-stock" || type === "options-index" || type === 'fx-option' ||type ==='fx-future-multiday'|| type === "multiday-equity")  && asset == 'all') {
            setAssetDataHeatmap(historicalPercentageDataHeatmap)
            setAssets(assets)
        }
        setFirst_load(false);
        setLoad_heat_map(false);
    }

    const sum = (list: any) =>{
        let val = list.reduce((a:any, b:any) => a + b, 0)
        return val
    }


    const fetchCalls = async (version:any,startDate:any,endDate:any) => {
        let callsBS = { total: '0', months: [] }
        let callsPT = { total: '0', months: [] }
        setLoad_hist_calls(true);
        setCalls([])
       
        let TotalCallsResponse:any = []
        if (type === "intraday-stock-index" || type === "overall" ) {
            if(asset === "all" || asset === "ALL")
            {
                TotalCallsResponse = await getIntradayCalls(startDate,endDate,type,version)
            }
            else{
                
                TotalCallsResponse = await getIntradayCalls(startDate,endDate,asset,version)
            }
            callsBS.total = TotalCallsResponse?.total
            callsBS.months = TotalCallsResponse?.data

        }
        else if (type === "intraday-stock-future" || type === "overall" ) {
            if(asset === "all" || asset === "ALL")
            {
                TotalCallsResponse = await getIntradayStockFutureCalls(startDate,endDate,type,version)
            }
            else{
                
                TotalCallsResponse = await getIntradayStockFutureCalls(startDate,endDate,asset,version)
            }
            callsBS.total = TotalCallsResponse?.total
            callsBS.months = TotalCallsResponse?.data

        }
        else if(type === 'fx-future' || type === "overall")
        {
            if(asset === "all" || asset === "ALL")
            {                   
                TotalCallsResponse = await getCurrencyCalls(startDate,endDate,type,version)
            }
            else{
                
                TotalCallsResponse = await getCurrencyCalls(startDate,endDate,asset,version)
            }
            callsBS.total = TotalCallsResponse?.total
            callsBS.months = TotalCallsResponse?.data 
        }
        else if(type === "comodity-future" || type === "overall")
        {
            if(asset === "all" || asset === "ALL")
            {  
                TotalCallsResponse = await get_Intraday_calls_Commodity(startDate,endDate,type,version)
            }
            else{
                TotalCallsResponse = await get_Intraday_calls_Commodity(startDate,endDate,asset,version)
            }
            callsBS.total = TotalCallsResponse?.total
            callsBS.months = TotalCallsResponse?.data
        }
        else if( type === "stock-index" || type === "stock-future" || type === "overall" || type === "stock" ) {
        }
        else if( type === "options-stock" || type === "options-index"  ) {
            if(asset === "all" || asset === "ALL")
            {
                TotalCallsResponse = await getCallsOptions(startDate,endDate,version,tradetype,type)
            }
            else{
                TotalCallsResponse = await getCallsOptions(startDate,endDate,version,tradetype,asset)
            }
            
            callsBS.total = TotalCallsResponse?.total
            callsBS.months = TotalCallsResponse?.data
        }
        else if( type === 'fx-option') {
            if(asset === "all" || asset === "ALL")
            {
                TotalCallsResponse = await getCallsFXOptions(startDate,endDate,version,tradetype,type)
            }
            else{
                TotalCallsResponse = await getCallsFXOptions(startDate,endDate,version,tradetype,asset)
            }
            
            callsBS.total = TotalCallsResponse?.total
            callsBS.months = TotalCallsResponse?.data
        }
        else if(type ==="fx-future-multiday"){

            if(asset === "all" || asset === "ALL")
            {  
                TotalCallsResponse = await getFXMultidaycalls(type,startDate,endDate,version)
            }
            else{
     
                TotalCallsResponse = await getFXMultidaycalls(asset,startDate,endDate,version)
            }
            callsBS.total = TotalCallsResponse?.total
            callsBS.months = TotalCallsResponse?.data

        }
        else if(type ==="multiday-equity"){

            if(asset === "all" || asset === "ALL")
            {  
                TotalCallsResponse = await getHistEquityCAlls(type,version,startDate,endDate)
            }
            else{
     
                TotalCallsResponse = await getHistEquityCAlls(asset,version,startDate,endDate)
            }
            callsBS.total = TotalCallsResponse?.total

        }
    
        setCalls({
            total: parseInt(callsBS?.total) + parseInt(callsPT?.total),
            months: mergeMonthWiseData('calls', callsBS?.months, callsPT?.months)
        })        
        setLoad_hist_calls(false);
    }

    const fetchSr = async (version:any,startDate:any,endDate:any) => {
        let callsBS = { total: '0', months: [] }
        let callsPT = { total: '0', months: [] }
        setLoad_hist_sr(true);
        setSrData([])
       
        let TotalSrResponse:any = []
            if (type === "intraday-stock-index" || type === "overall" ) {
                if(asset === "all" || asset === "ALL")
                {
                    TotalSrResponse = await getIntradaySr(startDate,endDate,type,version)
                }
                else{
                    TotalSrResponse = await getIntradaySr(startDate,endDate,asset,version)
                }
                callsBS.total = TotalSrResponse?.total
                callsBS.months = TotalSrResponse?.data

            }
            else if (type === "intraday-stock-future" || type === "overall" ) {
                if(asset === "all" || asset === "ALL")
                {
                    TotalSrResponse = await getIntradayStockFutureSr(startDate,endDate,type,version)
                }
                else{
                    TotalSrResponse = await getIntradayStockFutureSr(startDate,endDate,asset,version)
                }
                callsBS.total = TotalSrResponse?.total
                callsBS.months = TotalSrResponse?.data

            }
            else if(type === 'fx-future' || type === "overall")
            {
                if(asset === "all" || asset === "ALL")
                {
                    TotalSrResponse = await getCurrencySr(startDate,endDate,type,version)
                }
                else{
                    TotalSrResponse = await getCurrencySr(startDate,endDate,asset,version)
                }
                callsBS.total = TotalSrResponse?.total
                callsBS.months = TotalSrResponse?.data
            }
            else if (type === "comodity-future" || type === "overall" )
            {
                if(asset === "all" || asset === "ALL")
                {
                    TotalSrResponse = await get_Intraday_sr_Commodity(startDate,endDate,type,version)
                }
                else{
                    TotalSrResponse = await get_Intraday_sr_Commodity(startDate,endDate,asset,version)
                }
                callsBS.total = TotalSrResponse?.total
                callsBS.months = TotalSrResponse?.data
   
            }
            else if(type === "stock-index" || type === "stock-future" || type === "overall" || type === "stock" ) {
            }
            else if( type === "options-stock" || type === "options-index"  ) {
                if(asset === "all" || asset === "ALL")
                { 
                    TotalSrResponse = await getSrOptions(startDate,endDate,version,tradetype,type)
                }
                else{
                    TotalSrResponse = await getSrOptions(startDate,endDate,version,tradetype,asset)
                }
                callsBS.total = TotalSrResponse?.total
                callsBS.months = TotalSrResponse?.data
            }
            else if( type === "fx-option") {
                if(asset === "all" || asset === "ALL")
                { 
                    TotalSrResponse = await getSrFXOptions(startDate,endDate,version,tradetype,type)
                }
                else{
                    TotalSrResponse = await getSrFXOptions(startDate,endDate,version,tradetype,asset)
                }
                callsBS.total = TotalSrResponse?.total
                callsBS.months = TotalSrResponse?.data
            }
            else if(type === "multiday-equity")
            {
                TotalSrResponse = await getHistEquitySR(asset,version,startDate,endDate) 
                callsBS.total = TotalSrResponse?.total_sr
            }
            else if(type === "fx-future-multiday")
            {
                if(version === "all" || version === "ALL")
                {  
                    TotalSrResponse = await getFXMultidaySR(asset,startDate,endDate,version)
                }
                else{
                    TotalSrResponse = await getFXMultidaySR(asset,startDate,endDate,version) 
                }
                callsBS.total = TotalSrResponse?.total_sr
                callsBS.months = TotalSrResponse?.data
            }

        setSrData({
            total: Number(callsBS?.total).toFixed(2),
            months: mergeMonthWiseData('sr', callsBS?.months, callsPT?.months)
        })
        setLoad_hist_sr(false);
    }

    const fetchPl = async (version:any,startDate:any,endDate:any) => {
        // let assetType = type
        let callsBS = { total: '0', months: [] }
        let callsPT = { total: '0', months: [] }
        setLoad_hist_pl(true)
        setPlData({ total: '0', months: [] })
        let TotalPlResponse:any = []
        if (type === "intraday-stock-index"  || type === "overall" ) {
            if(asset === "all" || asset === "ALL")
            {
                TotalPlResponse = await getIntradayPl(startDate,endDate,type,version)
            }
            else{
                TotalPlResponse = await getIntradayPl(startDate,endDate,asset,version)
            }
            
            callsBS.total = TotalPlResponse?.total
            callsBS.months = TotalPlResponse?.data

        }
        else if (type === "intraday-stock-future"  || type === "overall" ) {
            if(asset === "all" || asset === "ALL")
            {
                TotalPlResponse = await getIntradayStockFuturePl(startDate,endDate,type,version)
            }
            else{
                TotalPlResponse = await getIntradayStockFuturePl(startDate,endDate,asset,version)
            }
            
            callsBS.total = TotalPlResponse?.total
            callsBS.months = TotalPlResponse?.data

        }
        else if(type === 'fx-future' || type === "overall")
        {
            if(asset === "all" || asset === "ALL")
            {
                TotalPlResponse = await getCurrencyPl(startDate,endDate,type,version)
            }
            else{
                TotalPlResponse = await getCurrencyPl(startDate,endDate,asset,version)
            }               
            callsBS.total = TotalPlResponse?.total
            callsBS.months = TotalPlResponse?.data
        }
        else if (type === "comodity-future" || type === "overall")
        {
            if(asset === "all" || asset === "ALL")
            {
                TotalPlResponse = await get_Intraday_pl_Commodity(startDate,endDate,type,version)
            }
            else{
                TotalPlResponse = await get_Intraday_pl_Commodity(startDate,endDate,asset,version)
            }
            
            callsBS.total = TotalPlResponse?.total
            callsBS.months = TotalPlResponse?.data

        }
        else if(type === "options-stock" || type === "options-index") {
            if(asset === "all" || asset === "ALL")
            {
                    TotalPlResponse = await getPlOptions(startDate,endDate, version,tradetype,type)
            }
            else{
                TotalPlResponse = await getPlOptions(startDate,endDate, version,tradetype,asset)
            }
            callsBS.total = TotalPlResponse?.total
            callsBS.months = TotalPlResponse?.data
        }
        else if(type === "fx-option") {
            if(asset === "all" || asset === "ALL")
            {
                    TotalPlResponse = await getPlFXOptions(startDate,endDate, version,tradetype,type)
            }
            else{
                TotalPlResponse = await getPlFXOptions(startDate,endDate, version,tradetype,asset)
            }
            callsBS.total = TotalPlResponse?.total
            callsBS.months = TotalPlResponse?.data
        }
        else if(type === "fx-future-multiday")
        {
            if(asset === "all" || asset === "ALL")
            {
                TotalPlResponse = await getFXMultidayPl(type,startDate,endDate,version)
            }
            else{
                TotalPlResponse = await getFXMultidayPl(asset,startDate,endDate,version)
            }
            
            callsBS.total = TotalPlResponse?.total
            callsBS.months = TotalPlResponse?.data
        }
        else if(type === "multiday-equity"){
            
            
            if(asset === "all" || asset === "ALL")
            {
                TotalPlResponse = await getHistEquityPl(type,version,startDate,endDate)
            }
            else{
                TotalPlResponse = await getHistEquityPl(asset,version,startDate,endDate)
            }
            
            callsBS.total = TotalPlResponse?.total
            callsBS.months = TotalPlResponse?.data
        }

           
        setPlData({
            total:Number(callsBS?.total)+ Number(callsPT?.total),
            months: mergeMonthWiseData('pl', callsBS?.months, callsPT?.months)
        })
        setLoad_hist_pl(false)
    }


    
    const fetchStartAndEndDate = async(months:any, asset:any, type:any)=>{
        if(type === 'multiday-equity'){
            var d = new Date();
            let prevdate =d.setMonth(d.getMonth() - months);
            setStartDateFilter(moment(prevdate)?.format('YYYY-MM-DD'));
            setEndDateFilter(moment(new Date())?.format('YYYY-MM-DD'));
            setDateRecieved(!dateRecieved)
        }else{
            let startEndDate:any = await getSEdate(months, asset, type);
            setStartDateFilter(startEndDate?.data?.start_date);
            setEndDateFilter(startEndDate?.data?.end_date);
            setDateRecieved(!dateRecieved)
        }
    }
 

        const fetchCommodityAdvice = async (startDate:any,endDate:any,asset:any,version:any)=>{
            setSelectedAdvice(0) 
            let commodityAdvice:any = await get_Intraday_advice_Commodity(startDate, endDate,asset,version)
            commodityAdvice?.map((v:any)=>{v.asset = asset_key_value[v?.asset]})
            setAdvices(commodityAdvice)
        }
    
        const fetchOptionsAdvice = async (startDate:any,endDate:any,version:any,tradetype:any,asset:any)=>{
            setSelectedAdvice(0) 
            let OptionsAdvice:any = await getListOfTradesOptions(startDate, endDate,version,tradetype,asset)
            setAdvices(OptionsAdvice)
        }
        const fetchIntradaySIAdvices = async (startDate:any,endDate:any,asset:any,version:any) => {
            setSelectedAdvice(0)
            let intradayAdvice: any = await getIntradaySIAdvices(startDate, endDate,asset,version)
        
            if(intradayAdvice?.length > 0)
            {
                intradayAdvice?.map((v:any)=>{v.asset = asset_key_value[v?.asset]})
                setAdvices(intradayAdvice)
            }
            else{
                setAdvices([])
            }
    
        }

        const fetchIntradayStockFutureAdvices = async (startDate:any,endDate:any,asset:any,version:any) => {
            setSelectedAdvice(0)
            let intradayAdvice: any = await getIntradayStockFutureAdvices(startDate, endDate,asset,version)
        
            if(intradayAdvice?.length > 0)
            {
                intradayAdvice?.map((v:any)=>{v.asset = asset_key_value[v?.asset]})
                setAdvices(intradayAdvice)
            }
    
        }


        const IntradayCurrencyAdvices = async (startDate:any,endDate:any,asset:any,version:any) => {
            setSelectedAdvice(0)
            let intradayAdvice: any = await getCurrencyAdviceStatus(startDate, endDate,asset,version)

            if(intradayAdvice?.length > 0)
            {
                intradayAdvice?.map((v:any)=>{v.asset = asset_key_value[v?.asset]})
                setAdvices(intradayAdvice)
            }
    
        }
        const IntradayFXOptionAdvices = async (startDate:any,endDate:any,version:any,tradetype:any,asset:any) => {
            setSelectedAdvice(0)
            let intradayAdvice: any = await getFXOptionAdviceStatus(startDate, endDate,version,tradetype,asset)
            if(intradayAdvice?.length > 0)
            {
                intradayAdvice?.map((v:any)=>{v.asset = asset_key_value[v?.asset]})
                setAdvices(intradayAdvice)
            }else{
                setAdvices([])
            }
    
        }
    
    
    
        const fetchAdvices = async (startDate:any,endDate:any, assetType: any,asset:any,version:any) => {
            setSelectedAdvice(0)
            let adv:any = []
            let component = ''
            
             if (type === "stock-index" || type === "stock-future" || type==='intraday-stock-future'|| type === "overall"  ) {
                component = type
            }
            else if(type === "multiday-equity"){
                adv = await getHistEquityAdvicesStatus(startDate, endDate,asset === "all" || asset === "ALL" ? assetType : asset,version)
            }
            else if(type === "fx-future-multiday"){
                adv = await getFXMultidayAdvice(startDate, endDate,asset === "all" || asset === "ALL" ? assetType : asset,version)
            }
            if(type !== "multiday-equity"){

                adv?.map((v:any)=>{v.asset = asset_key_value[v?.asset]})
            }
            setAdvices(adv)
            if(adv.length <= 0)
            {
                setPtTargetSlChartData(null)
                setMtmDrawdownChartData(null)
            }
            
    
        }    
        
        


        const fetchCommodityTargetSl = async (id:any,asset:any,version:any,date:any,trade_type:any) => {    
            setLoad_hist_targetsl(true);
            
            let targetSlChartData = await getliveIntradayTargetslnCommodity(id,asset,version,date,trade_type)
            setIntradayTargetSlChartData(targetSlChartData)
            setLoad_hist_targetsl(false);
        }
        const fetchCommoditymtmDrawdown = async (id:any,asset:any,version:any,date:any) => {
            setLoad_hist_mtm(true);
            let mtmDrawdownChartData = await getCmMtmDrawdownCommodity(id,asset,version,date)
            setIntradayMtmDrawdownChartData(mtmDrawdownChartData)
            setLoad_hist_mtm(false);
        }
        const fetchCommodityParameter = async(id:any,asset:any,version:any,date:any)=>{
            setLoad_hist_param(true)
            getCommodityParameter(id,asset,version,date).then((res: any) => {
                    if (res?.length > 0) {
                        setParameterListChartData(parameterList(res[0]?.s_target_stoploss_revision_list))
                        setParameters(res)
                    }else{
                        setParameterListChartData(0)
                        setParameters(0)
                    }
                    setLoad_hist_param(false)
            })
            
        }
    
        const fetchOptionsTargetSl = async (date:any,asset:any,id: any,version:any,tradetype:any) => { 
            setLoad_hist_targetsl(true);
            let targetSlChartData = await getTargetSlOptions(date,asset,id,version,tradetype)
            setIntradayTargetSlChartData(targetSlChartData)
            setLoad_hist_targetsl(false);
        }
        const fetchOptionsmtmDrawdown = async (date:any,asset:any,id: any, version:any) => {
            setIntradayMtmDrawdownChartData([])
            setLoad_hist_mtm(true);
            let mtmDrawdownChartData =[]
            mtmDrawdownChartData = await getMtmDrawdownOptions(date,asset,id,version)
            
            setLoad_hist_mtm(false);
            setIntradayMtmDrawdownChartData(mtmDrawdownChartData)
        }
        const fetchOptionParameter = async(id:any,asset:any,version:any,date:any)=>{
            setLoad_hist_param(true)
            getOptionsParameter(id,asset,date,version).then((res: any) => {
                    if (res?.length > 0) {
                        setParameterListChartData(parameterList(res[0]?.j_target_stoploss_list))
                        setParameters(res)
                    }
                    setLoad_hist_param(false)
            })
        }

        
        const fetchFXOptionsmtmDrawdown = async (date:any,asset:any,id: any, version:any) => {
            setIntradayMtmDrawdownChartData([])
            setLoad_hist_mtm(true);
            let mtmDrawdownChartData =[]
            mtmDrawdownChartData = await getMtmDrawdownFXOptions(date,asset,id,version)
            setLoad_hist_mtm(false);
            setIntradayMtmDrawdownChartData(mtmDrawdownChartData)
        }
        const fetchFXOptionParameter = async(id:any,asset:any,version:any,date:any)=>{
            setLoad_hist_param(true)
            await getFXOptionsParameter(id,asset,date,version).then((res: any) => {
                    if (res?.length > 0) {
                        setParameterListChartData(optFutParameterList(res[0]?.j_target_stoploss_list))
                        setParameters(res)
                    }else{
                        setParameters([])
                    }
                    setLoad_hist_param(false)
            })
        }
        const fetchFXMultiDayParameter = async(id:any,asset:any,version:any,date:any)=>{
            getFXMultidayParameterlist(id,asset,version,date).then((res: any) => {
                if (res?.length > 0) {
                    setParameterListChartData(parameterList(res[0]?.j_target_stoploss))
                    setParameters(res)
                }
            })
        }
        const fetchFXMultiDayTSL = async(id:any,asset:any,version:any,date:any)=>{
            setLoad_hist_targetsl(true);
            let targetSlChartData = await getFXMultidayTsl(id,asset,version,date)
            setSFMultidayTargetSlChartData(targetSlChartData)
            setLoad_hist_targetsl(false);
            
        }
        const fetchFXMultiDayMtmDrawDown = async(id:any, asset:any, date :any, version:any) => {
            setLoad_hist_mtm(true);
            let multidayMtmDrawdownChartData = await getFXMultidayMtmDrawdown(id,asset,date,version)
            setMtmDrawdownChartData(multidayMtmDrawdownChartData) 
            setLoad_hist_mtm(false);
        }
    
        
        const fetchIntradaySITargetSl = async (id:any,asset:any,date:any,version:any) => {  
            setLoad_hist_targetsl(true);
            let targetSlChartData = await getIntradayliveSITargetSl(id,asset,date,version)
            setIntradayTargetSlChartData(targetSlChartData)
            setLoad_hist_targetsl(false);
        }
        const fetchIntradaySImtmDrawdown = async (id:any,asset:any,date:any,version:any) => {
            setLoad_hist_mtm(true);
            let mtmDrawdownChartData = await getIntradaySImtmDrawdown(id,asset,date,version)
            setIntradayMtmDrawdownChartData(mtmDrawdownChartData)
            setLoad_hist_mtm(false);
        }
        const fetchIntradaySIParameter = async(id:any,asset:any,date:any,version:any)=>{
            setLoad_hist_param(true)
            getIntradayParameter(id,asset,version,date).then((res: any) => {
                    if (res?.length > 0) {
                        setParameterListChartData(parameterList(res[0]?.j_target_stoploss_revision))
                        setParameters(res)
                    }
                    setLoad_hist_param(false)
            })
        }


        const fetchIntradayStockFutureTargetSl = async (id:any,asset:any,date:any,version:any) => {  
            setLoad_hist_targetsl(true);
            let targetSlChartData = await getIntradayStockFutureTargetSl(id,asset,date,version)
            setIntradayTargetSlChartData(targetSlChartData)
            setLoad_hist_targetsl(false);
        }

        const fetchIntradayStockFuturemtmDrawdown = async (id:any,asset:any,date:any,version:any) => {
            setLoad_hist_mtm(true);
            let mtmDrawdownChartData = await getIntradayStockFuturemtmDrawdown(id,asset,date,version)
            setIntradayMtmDrawdownChartData(mtmDrawdownChartData)
            setLoad_hist_mtm(false);
        }

        const fetchIntradayStockFutureParameter = async (id:any,asset:any,date:any,version:any) => {
                getIntradayStockFutureParameter(id,asset,version,date).then((res: any) => {
                if (res?.length > 0) {
                    setParameterListChartData(parameterList(res[0]?.j_target_stoploss_revision))
                    setParameters(res)
                }
        })
        }
    
        const fetchCurrencyTargetSl = async (id:any,asset:any,date:any,version:any) => {  
            setLoad_hist_targetsl(true);
            let targetSlChartData = await getCurrencyLiveTargetSl(id,asset,date,version)
            setIntradayTargetSlChartData(targetSlChartData)
            setLoad_hist_targetsl(false);
        }
        const fetchCurrencyMtmDrawdown = async (id:any,asset:any,date:any,version:any) => {
            setLoad_hist_mtm(true);
            let mtmDrawdownChartData = await getCurrencyMtmDrawdown(id,asset,date,version)
            setIntradayMtmDrawdownChartData(mtmDrawdownChartData)
            setLoad_hist_mtm(false);
        }
        const fetchCurrencyParameter = async(id:any,asset:any,version:any,date:any)=>{
            setLoad_hist_param(true)
            getCurrencyParameter(id,asset,version,date).then((res: any) => {
                    if (res?.length > 0) {
                        setParameterListChartData(parameterList(res[0]?.s_target_stoploss_revision_list))
                        setParameters(res)
                    }
                    setLoad_hist_param(false)
            })
        }
    
     

         const fetchEquityParameterlist = async(id:any,asset:any,version:any,date:any)=>{
            setLoad_hist_param(true)
            getEquityParameterlist(id,asset,version,date).then((res: any) => {
                if (res?.length > 0) {
                    setParameterListChartData(parameterList(res[0]?.s_target_stoploss_revision_list))
                    setParameters(res)
                }
                setLoad_hist_param(false)

        })
        }
        const fetchEquityMtmDrawDown = async(id:any, asset:any, date :any, version:any) => {
            setLoad_hist_mtm(true);
            let multidayMtmDrawdownChartData = await getEquityMtmDrawdown(id,asset,date,version)
            setMtmDrawdownChartData(multidayMtmDrawdownChartData) 
            setLoad_hist_mtm(false);
        }
        const fetchEquityMultiDayTSL = async(id:any, asset:any, date :any, version:any) => {
            setLoad_hist_targetsl(true);
            let targetSlChartData = await getEquityTsl(id,asset,date,version)
            setMultidayTargetSlChartData(targetSlChartData) 
            setLoad_hist_targetsl(false);
        }

  
    
  

    const handleAdviceClick = (id: number) => {

        setSelectedAdvice(id)
    }

    const handleApply = () => {
        let startDate:any =  rangeStartDate;
        let endDate:any =  rangeEndDate;
        setFirst_load(true);
        setSelectedAdviceStatus(0)
        setStartDateFilter('')
        setEndDateFilter('')
        setStartDateFilter(startDate);
        setEndDateFilter(endDate);
        setDateRecieved(!dateRecieved);
    }

    

    return <userContext.Consumer>
        {(user) => {
            return <HistoricalPerformanceLayout type={type} selectedAsset={asset} calls={calls} setMonths={setMonths}
            srData={srData} plData={plData}assetDataHeatmap={assetDataHeatmap} advices={advices} setAsset={setAsset} assets={assets}
            selectedAdvice={selectedAdvice} handleAdviceClick={handleAdviceClick}
            parameters={parameters}  parameterListChartData={parameterListChartData}
            mtmDrawdownChartData={mtmDrawdownChartData} dateFilter={dateFilter} setDateFilter={setDateFilter} setStartDateFilter={setStartDateFilter}
             setEndDateFilter={setEndDateFilter}
            handleApply={handleApply} setFyYear={setFyYear} intradayMtmDrawdownChartData={intradayMtmDrawdownChartData}
            intradayTargetSlChartData={intradayTargetSlChartData} 
            PtTargetSlChartData={PtTargetSlChartData}  setVersion={setVersion} 
            version={version} asset_key_value={asset_key_value} dropdownversion={dropdownversion} setDropdownVersion={setDropdownVersion}
            tradetype={tradetype} setTradeType={setTradeType}
            multidayTargetSlChartData={multidayTargetSlChartData} setRangeStartDate={setRangeStartDate}
            setRangeEndDate={setRangeEndDate}  theme={theme} load_hist_calls={load_hist_calls}
            load_hist_sr={load_hist_sr} load_hist_pl={load_hist_pl} load_hist_targetsl={load_hist_targetsl}
            load_hist_mtm={load_hist_mtm} load_hist_param={load_hist_param}
             setbackupStartDate={setbackupStartDate} setbackupEndDate={setbackupEndDate} 
            selectedAdviceStatus={selectedAdviceStatus} setSelectedAdviceStatus={setSelectedAdviceStatus} 
            load_heat_map={load_heat_map} setAssetDataHeatmap={setAssetDataHeatmap}
            first_load={first_load} setFirst_load={setFirst_load} versionsData={versionsData} SFmultidayTargetSlChartData={SFmultidayTargetSlChartData} selectedTradeDetails={selectedTradeDetails}
            viewCharts={viewCharts} setViewCharts={setViewCharts}/>
        }}

    </userContext.Consumer>


}









