import { FeedsenseModules } from "../constants/FeedsenseModules";
import { FeedsenseModuleComponents } from "../constants/FeedsenseModuleComponents";

export class UserPrivilegeService {

    public static hasAccessToComponent(userPrivilegeCodes: string[], component: string): boolean {
        let allPrivilegesUserHas: string[] = []

        if (userPrivilegeCodes != null){
            userPrivilegeCodes?.forEach(privilegeCode => {
                let code:any = privilegeCode.split("");
                code = code[1]+code[2]+code[code.length-1];
                allPrivilegesUserHas = allPrivilegesUserHas.concat(privileges[code])
            });
        }
        if (allPrivilegesUserHas.includes(component)) {
            return true
        }
        else{
            return false
        }
    }
    public static hasAccessToItem(userPrivileges:string[],component:string): boolean {

        let syntexArr = ["I","M","2"]
        let PrivilegeItemArr:any = []
        userPrivileges.forEach((item:any)=> 
        { 
            if(!syntexArr.includes(item.slice(item.length -1))){
                PrivilegeItemArr = PrivilegeItemArr.concat(privileges[item])
            }
        })
        if (PrivilegeItemArr.includes(component))
        {
             return true
        }
        else{
            return false
        }
    }
        public static hasAccessToBackOffice(userPrivileges:string[]): boolean {

            if(userPrivileges.includes('PORTFOLIO')){
                return true
            }
            else
            {
                return false
            }


    }
}




const privileges: any = {

        FFI: [FeedsenseModuleComponents.FXFuture, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription,
        FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance],

        FFM: [FeedsenseModuleComponents.FXFutureMultiday, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription,
            FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance],
    
        IOI:[FeedsenseModuleComponents.OptionsIndex, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription,
            FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance],
    
        SOI:[FeedsenseModuleComponents.OptionsStock, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription,
        FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance],
    
        CFI:[FeedsenseModuleComponents.ComodityIndex, FeedsenseModuleComponents.ComodityFuture, FeedsenseModules.Profile, FeedsenseModules.Settings,
            FeedsenseModules.Subscription, FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance],
    
        SFI:[FeedsenseModuleComponents.IntradayStockFuture, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription,
            FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance], 
    
        IFI:[FeedsenseModuleComponents.IntradayStockIndex, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription,
            FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance],
    
        IFM: [FeedsenseModuleComponents.StockIndex, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription,
            FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance],

        SFM: [FeedsenseModuleComponents.MultidayStockFuture, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription,
        FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance],

        FOI: [FeedsenseModuleComponents.FXOption, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription,
            FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance],

        ESM: [FeedsenseModuleComponents.MultidayEquityFuture, FeedsenseModules.Profile, FeedsenseModules.Settings, FeedsenseModules.Subscription,
            FeedsenseModules.StrategyTracker, FeedsenseModules.HistoricalPerformance],

        PORTFOLIO: [FeedsenseModules.PortfolioAnalytics],
        SUBSCRIPTION: [FeedsenseModules.Subscription],
        SETTINGS: [FeedsenseModules.Settings]


}