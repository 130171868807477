import { Redirect, Route, BrowserRouter as Router} from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import NewsHome from './pages/NewsHomePage';
import { StrategyTrackerPage } from './pages/StrategyTrackerPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FeedsenseModules } from './constants/FeedsenseModules';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { ArticlesByKeyword } from './pages/ArticlesByKeywordPage';
import { NewsArticle } from './pages/NewsArticlePage';
import { PortalHomePage } from './pages/PortalLoginPage';
import { HistoricalPerformancePage } from './pages/HistoricalPerformancePage'
import { userContext } from './Context/userContext';
import { getTokenFromStorage, getUserObject } from './Api/Service/auth.service';
import { PortfolioAnalyticsPage } from './PortfolioAnalytics/components/PortfolioAnalyticsPage';
import { ProfilePage } from './pages/ProfileSection';
import { UserPrivilegeService } from './util/UserPrivilegeService';
import { Privacypolicy } from './privacy-policy';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { uniqueArray } from './util/utils';
import "./theme/Switch_theme.scss"
import "./App.css"
import { get_Cookie } from './Cookies/get_cookie';
import { LeftNavigation } from './components/PortalHome/LeftNavigation';
import { TopBar } from './components/PortalHome/TopBar';
import data from "../src/data.json"
import { BackOfficePage } from './pages/BackOfficePage';


let path:any = window.location.href.split('?')[1];
let news:any = window.location.pathname.split('/')[1];
let pathname:any = window.location.pathname;
let portfolio:any = window.location.pathname.split('/')[2];


function PrivateRoute({ children, ...rest }: any) {
  
  return (
    <Route {...rest} render={() => {
      let userObj = getUserObject();
      return (userObj.isLoggedIn === true && UserPrivilegeService.hasAccessToComponent(uniqueArray(userObj.privileges), rest.module)) ||
      (userObj.isLoggedIn === true && (path === 'all' || news === 'news' || pathname ==='/'|| pathname ==='/portal' || portfolio==='portfolio-analytics'  || portfolio==='back-office'))
        ? children
        : <Redirect to='/portal' />
    }} />
  )
}
const defaultoption={
  loop:true,
  autoplay:true,
  animationData:data,
  redererSettings:{
      preserveAspectratio:"xMidYMid slice",
  },
};

const App = () => {

  let location: any = window.location.pathname.split("/");

  let userObj = getUserObject();
  const [lastPong, setLastPong] = useState<any>([]);
  const [para, setPara] = useState<any>(window.location.pathname.split("/"))
  


  let new_theme = get_Cookie('Selected_theme')

  let default_theme = new_theme == undefined ? 'dark' : new_theme

  const [theme, setTheme] = useState(default_theme);
  
  const token: any = getTokenFromStorage()
  

  return (
    <userContext.Provider value={{ user: getUserObject() }}>
      <>
          <Router>
            <IonRouterOutlet style={{ overflowY: `${userObj.isLoggedIn === true ? 'auto' : 'none'}` }}>
              {userObj.isLoggedIn === true && 
              <PrivateRoute exact path="/news" module={FeedsenseModules.News}>
                <NewsHome />
              </PrivateRoute>}
              <PrivateRoute exact path="/news/keyword/:keyword" module={FeedsenseModules.News}>
                <ArticlesByKeyword />
              </PrivateRoute>
              <PrivateRoute exact path="/news/keyword/:keyword/:type" module={FeedsenseModules.News}>
                <ArticlesByKeyword />
              </PrivateRoute>
              <PrivateRoute exact path="/news/:id" module={FeedsenseModules.News}>
                <NewsArticle />
              </PrivateRoute>
              {location[1] != 'news' && <div className='homeDiv' style={{ display: "grid",minHeight:"100vh", gridTemplateRows: '6.4rem 100%', height: `${userObj.isLoggedIn === true ? "" : "100%"}` }}>
               <TopBar isLoggedIn={userObj.isLoggedIn} user={userObj} theme={theme} setTheme={setTheme} para={para} setPara={setPara} />
                <div className='NavigationBar' style={{ display: "grid", gridTemplateColumns: `${userObj.isLoggedIn === true ? "17% auto" : "auto"}` }}>
                  {userObj.isLoggedIn === true  && <LeftNavigation activeMenu={para[2]} isLoggedIn={userObj.isLoggedIn} user={userObj} setPara={setPara} />}
                  <Route exact path="/">
                    <Redirect to="/portal" />
                  </Route>
                  <Route exact path="/overview">
                    <Redirect to="/portal" />
                  </Route>
                  <Route exact path="/privacy-policy">
                    <Privacypolicy />
                  </Route>
                  <Route exact path="/portal">
                    {token ? <Redirect to={`/portal/strategy-tracker/?all`} /> :
                      <PortalHomePage theme={theme} />}
                  </Route>
                  <PrivateRoute exact path="/portal/strategy-tracker/" module={FeedsenseModules.StrategyTracker}>
                    <StrategyTrackerPage lastPong={lastPong} theme={theme} />
                  </PrivateRoute >
                  <PrivateRoute exact path="/portal/historical-performance/" module={FeedsenseModules.HistoricalPerformance}>
                    <HistoricalPerformancePage theme={theme} />
                  </PrivateRoute>
                  <PrivateRoute exact path="/portal/portfolio-analytics" module={FeedsenseModules.PortfolioAnalytics} >
                    <PortfolioAnalyticsPage theme={theme} />
                  </PrivateRoute>
                  <PrivateRoute exact path="/portal/back-office" module={FeedsenseModules.Backoffice} >
                    <BackOfficePage theme={theme} />
                  </PrivateRoute>
                  <Route path="/pages/profile" >
                    <ProfilePage theme={theme} />
                  </Route>
                </div>
              </div>}
            </IonRouterOutlet>
          </Router>
      </>
    </userContext.Provider>
  )
};

export default App;


