import { LeftArrow } from '../../../Carousel/ArrowButtons/LeftArrow';
import { RightArrow } from '../../../Carousel/ArrowButtons/RightArrow';
import './container.css';
import Carousel from 'react-multi-carousel';
import { MediumNewsItemType3 } from './MediumNewsItemType3';
import { useState } from 'react';
import { FeedSense } from '../../../../Models/FeedSense';
import { FeedSenseSlide } from './FeedSenseSlide';
import { News } from '../../../../Models/News';
import { SmaillIconsSlide } from './SmaillIconsSlide';

interface NewsContainerType3Props {
    articles1: any[],
    articles2: any[],
    twitterFeedSenseArr: any[],
    title1: string
    title2: string
}

export const NewsContainerType3: React.FC<NewsContainerType3Props> = ({articles1, articles2, twitterFeedSenseArr, title1, title2}) => {
    const [noOfFeedSenseIcons, setnoOfFeedSenseIcons] = useState(6);
    const [noOfSmaillIconsInACol, setnoOfSmaillIconsInACol] = useState(3);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const responsiveFeedSense = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const responsiveSmallIcons = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
        const { carouselState: { currentSlide } } = rest;
        return (
          <div className="carousel-button-group">
            <LeftArrow onClick={() => previous()} />
            <RightArrow onClick={() => next()} />
          </div>
        );
    };

    let twitterFeedSenseSlides:any = []
    let feedSense: FeedSense;
    let feedSenseArr: FeedSense[];
    let data: any;
    for(let i = 0; i < twitterFeedSenseArr.length; i += noOfFeedSenseIcons){
        feedSenseArr = []
        for(let j = i; j < i + noOfFeedSenseIcons; j++){
            data = twitterFeedSenseArr[j]
            if(!data) continue
            feedSense = {userName: data.user.name, createdAt: data.created_at, displayUrl: data.user.display_url, 
                fullText: data.full_text, userUrl: data.user.user_url, sentiment: data.sentiment}
            feedSenseArr.push(feedSense)
        }
        twitterFeedSenseSlides.push(
            <FeedSenseSlide feedSenseSubArr={feedSenseArr} />
        )
    }

    let smallIconsSlides:any = [];
    for(let i = 0; i < articles1.length; i += noOfSmaillIconsInACol){
        let articleArr:News[] = []
        for(let j = i; j < i + noOfSmaillIconsInACol; j++){
            data = articles1[j]
            if(!data) continue
            let article:News = {id: data._id, title: data.title, imageLink: data.image_link, category: data.category,
                publishedDate: data.datePublished, sentiment: data.sentiment, logoUrl:data.logo, publisher: data.publisher}
            articleArr.push(article)
        }
        smallIconsSlides.push(
            <SmaillIconsSlide articleSubArr={articleArr} />
        )
    }

    return (
        <div className="setction-type3 mb40 fifth_bg">
            <div className="container">
                <div className="row">
                    <div className="row section-type3-left-part col-8">
                        <div className="top-part" style={{width: "100%"}}>
                            <div className="heading" id={title2.split(" ").join('')}>
                                <h2 className="widget-title">
                                    {title2}
                                </h2>
                            </div>
                            <Carousel ssr
                                partialVisbile
                                deviceType={"desktop"}
                                itemClass="image-item"
                                responsive={responsive} 
                                customButtonGroup={<ButtonGroup />}
                                arrows={false} renderButtonGroupOutside={true}
                            >
                                {articles2.map((article) => {
                                    return(<MediumNewsItemType3 article={{id: article._id, title: article.title, imageLink: article.image_link, category: article.category,
                                        publishedDate: article.datePublished, sentiment: article.sentiment, summary: article.summary,
                                        logoUrl: article.logo, publisher: article.publisher}} />)
                                })}
                            </Carousel>
                        </div>
                        <div className="border_black"></div>
                        <div style={{width:"100%"}} className="row bottom-part">
                            <div className="heading" id={title1.split(" ").join('')}>
                                <h2 className="widget-title">
                                    {title1}
                                </h2>
                            </div>
                            <div className="row" style={{width:"100%", marginLeft:0}}>
                                <Carousel ssr
                                partialVisbile
                                deviceType={"desktop"}
                                itemClass="small-icon-type3-carousel-item"
                                responsive={responsiveSmallIcons} 
                                customButtonGroup={<ButtonGroup />}
                                arrows={false} renderButtonGroupOutside={true}
                                >
                                    {smallIconsSlides}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                    <div className="section-type3-right-part col-4">
                        <div className="heading">
                            <h2 className="widget-title">
                                Feed Sense
                            </h2>
                        </div>
                        <div >
                            <Carousel ssr
                                partialVisbile
                                deviceType={"desktop"}
                                itemClass="image-item"
                                responsive={responsiveFeedSense} 
                                customButtonGroup={<ButtonGroup />}
                                arrows={false} renderButtonGroupOutside={true}
                            >
                                {twitterFeedSenseSlides}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}