import Carousel from 'react-multi-carousel';
import { HeatMapData } from "./HeatMapData.interface"
import { HeatMapItem } from "./HeatMapItem"
import "./style.css"

interface HeatMapProps {
    heatMapData: HeatMapData[]
    heatmapItemClick?: any
    selectedAsset?: any
    type:any
    version:any
    tradetype:any
    theme:any 
}

export const HeatMap: React.FC<HeatMapProps> = ({heatMapData, selectedAsset, heatmapItemClick, type,version,tradetype,theme,}) => {
    // const [showUpto, setShowUpto] = useState(20)
    // const 
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    
    return <>
        <Carousel ssr className="heatmap1 heat-map-container" 
            partialVisbile
            deviceType={"desktop"}
            itemClass="image-item"
            responsive={responsive}
            arrows={false} renderButtonGroupOutside={false}
            >
            {heatMapData?.map((data: any, i:any) => {
                if(i < 20) {
                    return <HeatMapItem key={i} heatMapData={data} selectedAsset={selectedAsset} version={version}
                    heatmapItemClick={heatmapItemClick} type={type} tradetype={tradetype} theme={theme} identifier = {data.identifier}/>
                }
            })}
            </Carousel>  
        {heatMapData.length>0 &&<div className="Note">&nbsp;
        { type=="fx-future"?"*Note: (INR = Indian rupee)"
                                                :type=="overall"?<>
                                                                    <a>*Note: pts denote points and  INR = Indian rupee</a>
                                                                </>
                                                                :type=="options"?<>
                                                            </>
                                                            :""}
        </div>}
    </>
}