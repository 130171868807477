import './style.css';
import { Bar } from 'react-chartjs-2';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

interface SentimentChartProps {
    dataForChart: any,
    options: any,
    handleChartDuration: any
}

export const SentimentChart: React.FC<SentimentChartProps> = ({dataForChart, options, handleChartDuration}) => {
    
    return (
        <>
            <div className="sentiment-chart">
                <Bar   data={dataForChart} options={options}/>
            </div>
            <div className="row">
                <div className="col-3 chart-resolution">
                <DropdownButton id="dropdown-basic-button" title="Options" 
                size="sm" >
                    <Dropdown.Item onClick={() => handleChartDuration(1)}>1 W</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleChartDuration(2)}>2 W</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleChartDuration(3)}>3 W</Dropdown.Item>
                </DropdownButton>
                </div>
                <div className="col-9 chart-tracing">Sentiment Analysis Graph</div>
            </div>
        </>
    )
}