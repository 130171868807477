import React from 'react';
import '../Arrow.css';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LeftArrowProps {
    onClick: any
}

export const LeftArrow:React.FC<LeftArrowProps> = ({onClick}) => {
    return (
        <div className="left-arrow  arrow-button" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft}/>
        </div>
    )
}