import './style.css';
import Carousel from 'react-multi-carousel';
import { PLStatusCard } from "./PLStatusCard"
import {assetArray} from '../../../../../src/util/utils';

interface PLCarouselProps {
    PLStatusData: any ,
    selectedCard: string,
    handleAssetClick: any,
    type:any,
    version:any
    asset_key_value:any
    trade_type:any
}

export const PLCarousel: React.FC<PLCarouselProps> = ({PLStatusData, selectedCard, handleAssetClick,type,version,asset_key_value, trade_type}) =>  { 

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (<Carousel ssr className="pl-status-carousel" 
            partialVisbile
            deviceType={"desktop"}
            itemClass="image-item"
            responsive={responsive}
            arrows={true} renderButtonGroupOutside={false}
            >
                {PLStatusData?.map((data: any) => {
                    let asset_name:any; 
                    
                    if(type === "multiday-equity"){
                        asset_name = data?.asset_name
                    }else{
                        asset_name =assetArray(data?.asset_name);
                    }
                    return(<PLStatusCard selected={type === "options-stock" || type === "options-index" || type === "fx-option" ?selectedCard?.toUpperCase()  == asset_name?.toUpperCase()  && version?.toUpperCase()  == (data?.s_version)?.toUpperCase()  && trade_type?.toUpperCase() == (data?.trade_type)?.toUpperCase()  : selectedCard?.toUpperCase() == asset_name?.toUpperCase() && version?.toUpperCase() == (data?.s_version)?.toUpperCase()  ? true : false}  
                        name={data} version={data?.s_version} asset_key_value={asset_key_value} identifier = {data?.identifier}
                        profitAmount={type =='fx-future-multiday' ||type == 'fx-future' || type === "fx-option" ?((data?.sum)!=null ? "₹ "+ parseFloat(data?.sum).toFixed(2): ''):((data?.sum) != null && ((data?.s_version).toUpperCase() !== 'ALL')) ? "₹ "+ parseFloat(data?.sum)?.toFixed(2): ''}
                        plInPts ={type =='fx-future-multiday' ||type == 'fx-future' || type === "fx-option" ?((data?.pl_in_pts)!=null ? parseFloat(data?.pl_in_pts).toFixed(4)+ " pts": ''):((data?.pl_in_pts) != null && ((data?.s_version).toUpperCase() !== 'ALL')) ? parseFloat(data?.pl_in_pts)?.toFixed(2) + " pts": ''}
                        profitPercentage={type=='comodity-future'?(((data?.pl) != null && (data?.s_version)?.toUpperCase() !== 'ALL') ? (data?.pl) + "%" : ""):(((data?.pl != null) && (data?.s_version)?.toUpperCase() !== 'ALL') ? Number(data?.pl)?.toFixed(2) + "%" : "")}
                        timePeriod={data?.days ? data?.days + " days" : ""} handleAssetClick={handleAssetClick} type = {type} selectedCard = {selectedCard}/>)
                    })}
            </Carousel>)
}