import './style.css';
import ReactApexChart from 'react-apexcharts'

interface ChartProps {
    dataset: any,
    name: string
    chartFor?: 'Calls'
    theme :any
}

export const Chart: React.FC<ChartProps> = ({dataset, name, chartFor,theme}) => {
    let dataArr: any = []
    let labelsArr: any = []
    let colors: any = []
    dataset?.map((data: any) => {
        dataArr.push(Number(data?.value)?.toFixed(2))
        labelsArr.push(data.month + "' " + data.year?.slice(2, 5))
        if(data.value < 0) {
          colors.push('red')
        }else {
          colors.push('green')
        }
    })

    let axis_lable_color :any 
    let border_color :any
    
    if(theme == 'dark' || theme == 'b/w'){
        axis_lable_color = '#F5F5DC'
        border_color = '#262626'
    }
    else if (theme == 'light'){
        axis_lable_color = '#000'
        border_color = '#a2cdf4'
    
    }
    if(theme == 'b/w'){
      axis_lable_color = '#F5F5DC'
      border_color = '#ffffff30'
  }
    
    const data1: any = {
          
      series: [{
        name:name,
        data: dataArr
      }],
      options: {
          title: {
          },
          chart: {
              type: 'bar',
              animations: {
                enabled: false,
                easing: 'linear',
                speed: 1800,
                animateGradually: {
                    enabled: true,
                    delay: 50
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 100
                },
              },
              toolbar: {
                show: false,
                tools:{
                  download:false
                }
              }
          },
          colors: [function({ value, seriesIndex, w }: any) {
            if (value < 0) {
                return '#c72222'
            } else {
                return '#2fc457'
            }
          }, function({ value, seriesIndex, w }: any) {
            if (value < 111) {
                return '#7E36AF'
            } else {
                return '#D9534F'
            }
          }],
          grid: {
            borderColor: border_color,
            row: {
              opacity: 0.5
            },
          },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            columnWidth: '70%'
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          tickAmount:7,
          categories: labelsArr,
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: true,
            style:{
              colors:axis_lable_color
            }
          }
        },
        yaxis: {
          tickAmount:10,

          labels:{
            show:true,
            style:{
              colors:axis_lable_color
            }
          },
          title: {
            text: name,
            style: {
              color: axis_lable_color
            }
          },
         
        },
      },
    };
     
    return (
        <>
            <div style={{margin:"1rem 0.5rem 0.5rem 0.5rem"}}>
                <ReactApexChart id="charts" options={data1.options} series={data1.series} type="bar" height="100%" />
            </div>
        </>
    )
}
