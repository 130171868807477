import { useEffect, useState } from 'react';
import './TwitterStreamContainer.css';
import data from '../../../dummy_data/feed-sense.json'
import Carousel from 'react-bootstrap/Carousel'
import { Arrow } from '../../Carousel/Arrow';
import { faTwitter, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { add3Dots } from '../../../util/utils';
import moment from 'moment';

interface TwitterStreamContainerProps {}

export const TwitterStreamContainer: React.FC<TwitterStreamContainerProps> = () => {
    const [tweets, setTweets] = useState([{}])
    const [index, setIndex] = useState(0);

    const handlePrev = () => {
        if(index === 0) return
        setIndex(index - 1)
    }
    const handleNext = () => {
        if(index === tweets.length - 1) {
            setIndex(1)
            return;
        }
        setIndex(index + 1)
    }

    const handleSelect = (selectedIndex: any, e: any) => {
        setIndex(selectedIndex);
      };

    useEffect(() => {
        setTweets(data.tweets)
        
        
    })

    let slides: any[] = [];
    tweets.map((data: any, i) => {
        slides.push(
            <Carousel.Item >
                <p>{add3Dots(data.full_text, 80)}</p>
            </Carousel.Item>
        )
    })

    return (
        <div className="topbar" id="top">
            <div className="container" >
                <div className="row">
                    <div className="col-md-6 col-lg-8 align-self-center">
                        <div className="streaming-tweets-area">
                            <p className="twee">
                                Tweets
                            </p>
                            
                            <Carousel nextLabel="next" activeIndex={index} controls={false} indicators={false} onSelect={handleSelect}
                            className="twitter-stream-carousel" interval={15000}>
                                {slides}
                            </Carousel>
                            <Arrow handleNextClick={handleNext} handlePrevtClick={handlePrev} />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 align-self-center">
                    <div className="top_date_social text-right">
                        <div className="paper_date">
                            <p>{moment().format('MMMM Do YYYY')}</p>
                        </div>
                        <div className="social1">
                            <ul className="inline">
                                <li><a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
                                </li>
                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF}/></a>
                                </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-black"></div> 
        </div>
    )
}