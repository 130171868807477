import React, { useState } from 'react';
import './container.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { MediumNewsItemType2 } from './MediumNewsItemType2';
import { LeftArrow } from '../../../Carousel/ArrowButtons/LeftArrow';
import { RightArrow } from '../../../Carousel/ArrowButtons/RightArrow';

interface NewsContainerType2Props {
    articles: any[],
    title: string
}

export const NewsContainerType2: React.FC<NewsContainerType2Props> = ({articles, title}) => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
        const { carouselState: { currentSlide } } = rest;
        return (
          <div className="carousel-button-group">
            <LeftArrow onClick={() => previous()} />
            <RightArrow onClick={() => next()} />
          </div>
        );
    };

    return (
        <div className="setction-type2 mb40 fifth_bg">
            <div className="container" id={title.split(" ").join('')}>
                <div className="row">
                    <div className="col-12" style={{paddingLeft:0}}>
                        <div className="heading">
                            <h2 className="widget-title">
                                {title}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ width: "104%"}}>
                    <Carousel ssr
                        partialVisbile
                        deviceType={"desktop"}
                        itemClass="image-item"
                        responsive={responsive}
                        customButtonGroup={<ButtonGroup />}
                        arrows={false} renderButtonGroupOutside={true}
                    >
                        {articles.map((article) => {
                            return(<MediumNewsItemType2 article={{id: article._id, title: article.title, imageLink: article.image_link, category: article.category,
                                publishedDate: article.datePublished, sentiment: article.sentiment, summary: article.summary,
                                logoUrl: article.logo, publisher: article.publisher}} />)
                        })}
                    </Carousel>
                </div>
            </div>
        </div>
    )
}