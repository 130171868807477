import React from 'react';
import './Arrow.css';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ArrowProps {
    handlePrevtClick: any, 
    handleNextClick: any
}

export const Arrow: React.FC<ArrowProps> = ({ handlePrevtClick, handleNextClick }) => {

    return (
        <div className="arrow-holder">
            <div className="left-arrow  arrow-button" onClick={handlePrevtClick}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </div>
            <div className="right-arrow arrow-button" onClick={handleNextClick}>
                <FontAwesomeIcon icon={faChevronRight}/>
            </div>
        </div>
    )
}