import './CategoriesNavBar.css';
import { useEffect, useRef, useState } from 'react';
import { getNewsSectors } from '../../../Api/News/news';


interface CategoriesNavBarProps {
    isLoggedIn: boolean,
    handleLogout: any,
    handleLogin: any
}

export const CategoriesNavBar: React.FC<CategoriesNavBarProps> = ({isLoggedIn, handleLogin, handleLogout}) => {
    const [isSignInModalVisible, setIsSignInModalVisible] = useState(false)
    const [isProfileModalVisible, setIsProfileModalVisible] = useState(false)
    const nodeRef = useRef<HTMLDivElement>(null)
    const profileModalRef = useRef<HTMLDivElement>(null)
    const [categories, setCategories] = useState([])
    
    const rootElement = document.getElementById("root");

    useEffect(() => {
        setIsProfileModalVisible(false);
        setIsSignInModalVisible(false);
    },[isLoggedIn])

    function toggleModals() {
        isLoggedIn ? setIsProfileModalVisible(!isProfileModalVisible) : setIsSignInModalVisible(!isSignInModalVisible);
    }

    const hadleProfileClick = () => {
        toggleModals();
    }

    const handleOutsideModalClick = (e: any) => {
      
        
        if(nodeRef.current?.contains(e.target)) {
           return; 
        }
        toggleModals();
    }

    useEffect(() => {
        getNewsSectors().then((res) => {
            setCategories(res.slice(0, 5))
        })
    },[])

    return (<></> 
    )
}