import { NoOfCallsCard } from '../../components/PortalHome/HistoricalPerformance/Cards2/NoOfCallsCard';
import { SuccessRatioCard } from '../../components/PortalHome/HistoricalPerformance/Cards2/SuccessRatioCard';
import { PLCard } from '../../components/PortalHome/HistoricalPerformance/Cards2/PLCard';
import "./style.css"
import { HeatMap } from "../../components/PortalHome/HeatMap"
import { AdviceStatus } from "../../components/PortalHome/HistoricalPerformance/Advices";
import { useLocation} from "react-router";
import { MtmChart } from "../../components/PortalHome/HistoricalPerformance/Charts/MtmChart";
import { DrawdownChart } from "../../components/PortalHome/HistoricalPerformance/Charts/DrawdownChart";
import { TargetSlChart } from "../../components/PortalHome/HistoricalPerformance/Charts/TargetSlChart";
import Card from "react-bootstrap/Card"
import 'react-datepicker/dist/react-datepicker.css'
import {useState } from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import moment from "moment";
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import {productTypePair } from "../../util/utils";
import BeatLoader from "react-spinners/BeatLoader";
import React from "react";
import ParameterChart from '../../components/PortalHome/HistoricalPerformance/ParameterList/ParameterChart';


const { RangePicker } = DatePicker;


interface HistoricalPerformanceLayoutProps {
    load_heat_map:any
    type:any
    selectedAsset:any 
    calls:any 
    setMonths:any 
    srData:any 
    plData:any 
    parameterListChartData:any 
    assetDataHeatmap:any 
    advices:any 
    setAsset:any 
    assets:any 
    handleAdviceClick:any 
    selectedAdvice:any 
    parameters:any 
    mtmDrawdownChartData:any 
    setStartDateFilter:any 
    setEndDateFilter:any 
    dateFilter:any 
    setDateFilter:any 
    handleApply:any 
    setFyYear:any 
    intradayTargetSlChartData:any 
    intradayMtmDrawdownChartData:any 
    PtTargetSlChartData:any 
    setVersion:any 
    asset_key_value:any 
    version:any 
    setDropdownVersion:any 
    dropdownversion:any
    tradetype:any 
    setTradeType:any 
    multidayTargetSlChartData:any 
    setRangeStartDate:any 
    SFmultidayTargetSlChartData:any 
    setRangeEndDate:any 
    theme:any
    load_hist_calls:any 
    load_hist_sr:any 
    load_hist_pl:any 
    load_hist_targetsl:any 
    load_hist_param:any 
    load_hist_mtm:any 
    setbackupStartDate:any 
    setbackupEndDate:any
    selectedAdviceStatus:any 
    setSelectedAdviceStatus:any 
    setAssetDataHeatmap:any 
    first_load:any 
    setFirst_load:any 
    versionsData:any 
    selectedTradeDetails:any
    viewCharts:any
    setViewCharts:any
}

export const HistoricalPerformanceLayout: React.FC<HistoricalPerformanceLayoutProps> = ({ load_heat_map, type, selectedAsset, calls, setMonths,
    srData,plData,parameterListChartData,assetDataHeatmap, advices, setAsset, assets, handleAdviceClick, 
    parameters, mtmDrawdownChartData, setStartDateFilter,setEndDateFilter, dateFilter, setDateFilter, handleApply,
    setFyYear, intradayTargetSlChartData, intradayMtmDrawdownChartData, PtTargetSlChartData,
    setVersion, asset_key_value, version, setDropdownVersion, dropdownversion,
    tradetype, setTradeType, multidayTargetSlChartData, setRangeStartDate,SFmultidayTargetSlChartData, setRangeEndDate,theme,
    load_hist_calls, load_hist_sr, load_hist_pl, load_hist_targetsl, load_hist_param, load_hist_mtm, setbackupStartDate, setbackupEndDate,
    selectedAdviceStatus, setSelectedAdviceStatus, setAssetDataHeatmap, first_load, setFirst_load,versionsData, selectedTradeDetails,
    viewCharts, setViewCharts, selectedAdvice }) => {

    const [tmpFyYear, setTmpFyYear] = useState<any>('2020')
    let params: any = useLocation();
    params.type = params.search.slice(1, params.search.length)

    const handleDateChange = (values: any) => {
        if (!values) {
            return;
        }
        setRangeStartDate(moment(values[0])?.format('YYYY-MM-DD'));
        setRangeEndDate(moment(values[1])?.format('YYYY-MM-DD'));
        setbackupStartDate(moment(values[0])?.format('YYYY-MM-DD'));
        setbackupEndDate(moment(values[1])?.format('YYYY-MM-DD'));
    }

    const handleFy = () => {

        let item = parseInt(tmpFyYear)
        let startMonth: any = '03';
        let startDate: any = '01';
        let startFyDate = new Date()
        startFyDate.setDate(startDate)
        startFyDate.setMonth(startMonth)
        startFyDate.setFullYear(item)
        setStartDateFilter(startFyDate)
        let endMonth: any = '02';
        let endDate: any = '01';
        let endYear: number = item + 1
        let endFyDate = new Date()
        endFyDate.setDate(endDate)
        endFyDate.setMonth(endMonth)
        endFyDate.setFullYear(endYear)
        setEndDateFilter(endFyDate)
        setFyYear(tmpFyYear)
    }
    


    return <>
        <div className="container-custom whole_container hist-performance-layout">
            <Row className='hist-performance-upper-row'  >
                <Col style={{}}><span className="product-type" >{productTypePair(type)}</span></Col>
           
                <Col>
                {versionsData.length>1 && <>
                    {type === 'fx-future' ? <div className="row" style={{ justifyContent: "flex-end",alignItems: "center" }}>
                            <div className="select_version">Select version </div>
                            <div style={{ display: "inline-block", marginBottom: "0.5rem" }}>

                                <select name="" id="" className="select_dropdown" style={{ marginTop: "0.8rem" }} value={dropdownversion}
                                    onChange={(e) => {
                                        setDropdownVersion(e.target.value);
                                        setAsset('all')
                                        setSelectedAdviceStatus(0)
                                        setFirst_load(true);
                                    }}>
                                        <option value="all">ALL</option>
                                        
                                    {versionsData?.map((value: any) => (
                                        <option value={value} key={value}>
                                    {value}
                                    </option>
                                ))}
                                </select>
                            </div>
                        </div> : type === 'intraday-stock-index' || type ==='intraday-stock-future' ? <div className="row" style={{ justifyContent: "flex-end",alignItems: "center" }}>
                            <div className="select_version">Select version </div>
                            <div style={{ display: "inline-block", marginBottom: "0.5rem" }}>

                                <select name="" id="" className="select_dropdown" style={{ marginTop: "0.8rem" }} value={dropdownversion}
                                    onChange={(e) => {
                                        setDropdownVersion(e.target.value);
                                        setVersion(e.target.value)
                                        setAsset('all')
                                        setSelectedAdviceStatus(0)
                                        setFirst_load(true);
                                    }}>
                                    <option value="all">ALL</option>
                                    {versionsData?.map((value: any) => (
                                    <option value={value} key={value}>
                                    {value}
                                    </option>
                                ))}
                                </select>
                            </div>
                        </div>: type === 'multiday-stock-index' ? <div className="row" style={{ justifyContent: "flex-end",alignItems: "center" }}>
                            <div className="select_version">Select version </div>
                            <div style={{ display: "inline-block", marginBottom: "0.5rem" }}>

                                <select name="" id="" className="select_dropdown" style={{ marginTop: "0.8rem" }} value={dropdownversion}
                                    onChange={(e) => {
                                        setDropdownVersion(e.target.value);
                                        setVersion(e.target.value)
                                        setAsset('all')
                                        setSelectedAdviceStatus(0)
                                        setFirst_load(true);
                                    }}>
                                    {versionsData?.map((value: any) => (
                                    <option value={value} key={value}>
                                    {value}
                                    </option>
                                ))}
                                </select>
                            </div>
                        </div> : type === "options-index" || type === "options-stock" ?
                            <div className="row" style={{ justifyContent: "flex-end",alignItems: "center" }}>
                                <div className="select_version">Select version </div>
                                <div style={{ display: "inline-block", marginBottom: "0.5rem" }}>

                                    <select name="" id="" className="select_dropdown" style={{ marginTop: "0.8rem" }}
                                        onChange={(e) => {
                                            setDropdownVersion(e.target.value);
                                            setVersion(e.target.value)
                                            setTradeType('Total')
                                            setAsset('all')
                                            setSelectedAdviceStatus(0)
                                            setFirst_load(true);
                                        }}>
                                        <option value="all" selected>All</option>
                                        {versionsData.length>0 && versionsData?.map((value: any) => (
                                            <option value={value} key={value}>
                                            {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            :type === "multiday-equity" ?
                            <div className="row" style={{ justifyContent: "flex-end",alignItems: "center" }}>
                                <div className="select_version">Select version </div>
                                <div style={{ display: "inline-block", marginBottom: "0.5rem" }}>

                                    <select name="" id="" className="select_dropdown" style={{ marginTop: "0.8rem" }}
                                        onChange={(e) => {
                                            setDropdownVersion(e.target.value);
                                            setVersion(e.target.value)
                                            setTradeType('Total')
                                            setAsset('all')
                                            setSelectedAdviceStatus(0)
                                            setFirst_load(true);
                                        }}>
                                        <option value="all" selected>All</option>
                                        {versionsData.length>0 && versionsData?.map((value: any) => (
                                            <option value={value} key={value}>
                                            {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>:
                             type === "comodity-future" ?
                             <div className="row" style={{ justifyContent: "flex-end" }}>
 
                                 <div className='select_version'>Select version </div>
                                 <div style={{ display: "inline-block" }}>
 
                                     <select name="" id="" className="select_dropdown" style={{ marginTop: "8px" }}
                                         onChange={(e) => {
                                             setDropdownVersion(e.target.value);
                                             setVersion(e.target.value)
                                             setTradeType("Total")
                                             setSelectedAdviceStatus(0)
                                         }}>
                                         <option value="all" selected>All</option>
                                         {versionsData?.map((value: any) => (
                                             <option value={value} key={value}>
                                                 {value}
                                             </option>
                                         ))}
 
                                     </select>
                                 </div>
                             </div> : ""}
                </>}
                </Col>
            </Row>

            <div className="row " >
                <div className="TotalAsset" style={{ width: "20%" }}>
                    <div className="total_symbol1">
                        Total {
                            "Assets : " + assets.length
                        }
                    </div>
                </div>
                <div className="dateRange">
                    <div style={{ marginRight: "1.5rem", marginBottom: "1rem" }}>
                        <select name="" id="" className="form-control total_symbol" onChange={(e) => setDateFilter(e.target.value)}>
                            <option value="no_of_months" className="total_symbol">No of months</option>
                            <option value="custom" className="total_symbol">Date range</option>
                        </select>
                    </div>
                    <div>
                        {dateFilter === 'custom' ?
                            <div className="d-flex">
                                <div style={{ marginRight: "1rem", marginBottom: "1rem" }}>
                                    <RangePicker
                                        onChange={(values: any) => {
                                            handleDateChange(values)
                                            setAsset('all') 
                                            setTradeType('Total')   
                                        }}
                                        disabledDate={(current) => {
                                            let customDate = moment().format("YYYY-MM-DD");
                                            return (
                                                current && current >= moment(customDate, "YYYY-MM-DD")
                                            );
                                        }}
                                    />
                                </div>
                                <div className="" >
                                    <button type="button" className="btn apply_button"
                                        onClick={handleApply}>Apply</button>
                                </div>
                            </div> : dateFilter === 'no_of_months' ?
                                <select name="" id="" className="form-control total_symbol" style={{ width: "23rem", marginBottom: "0.5rem" }} onChange={(e) => {
                                    setMonths(e.target.value);
                                    setAsset('all')
                                    setTradeType('Total')
                                    setSelectedAdviceStatus(0)
                                    setFirst_load(true)
                                    setAssetDataHeatmap([])
                                    setStartDateFilter("");
                                    setEndDateFilter("");
                                }}>
                                    <option value="6">6 Months</option>
                                    <option value="12" selected>12 Months</option>
                                    <option value="24" >24 Months</option>
                                    <option value="36" >36 Months</option>
                                    <option value="48" >48 Months</option>
                                    <option value="60" >60 Months</option>
                                </select>
                                : <div className="row">
                                    <div className="col-lg-6">
                                        <select name="" id="" className="form-control total_symbol" style={{ width: 260 }} onChange={(e) => {
                                            setTmpFyYear(e.target.value);
                                        }}>
                                            <option value="2017">2017</option>
                                            <option value="2018">2018</option>
                                            <option value="2019">2019</option>
                                            <option value="2020">2020</option>
                                            <option value="2021">2021</option>

                                        </select>
                                    </div>
                                    <div className="col-lg-4">
                                        <button type="button" className="btn apply_button" style={{ borderRadius: '2rem', width: "100px" }}
                                            onClick={handleFy}>Apply</button>
                                    </div>
                                    <div className="col-lg-2">

                                    </div>
                                </div>
                        }
                    </div>
                </div>

            </div>

            <div className="heamapDesign" style={{ minHeight: "9rem" }}>
                {(load_heat_map) && (first_load === true) ?
                    <div className="HeatMap-spinner-container" >
                        <BeatLoader color="#dc9948" size={10} />
                    </div> :
                    <HeatMap heatMapData={assetDataHeatmap} type={type} tradetype={tradetype} heatmapItemClick={(data: any) => {

                        setSelectedAdviceStatus(0)
                        if (data.version === undefined) {
                            data.version = '';
                        }
                        else {
                            if (data.name === 'all' || data.name === 'ALL') {
                                if (type === "options-stock" || type === "options-index" || type === 'fx-option') {
                                    if (dropdownversion == 'all') {
                                        setAsset(data?.name);
                                        setVersion('all');
                                        setTradeType('Total');
                                    }
                                    else {
                                        setAsset(data?.name);
                                        setVersion(data?.version);
                                        setTradeType('Total');
                                    }
                                }
                                else {
                                    if (dropdownversion == 'all') {
                                        setAsset(type);
                                        setVersion('all');
                                    }
                                    else {
                                        setAsset(type);
                                        setVersion(data?.version);
                                    }
                                }
                                setStartDateFilter();
                                setEndDateFilter();
                                
                            }
                            else {
                                if (type === "options-stock" || type === "options-index" || type === 'fx-option') {
                                    let tradetype = data?.tradetype;
                                    setAsset(data?.name);
                                    setVersion(data?.version);
                                    setTradeType(tradetype);
                                }
                                else {
                                    if(type === "multiday-equity"){
                                        setAsset(data?.name)
                                    }else{
                                        setAsset(Object.keys(asset_key_value)[Object.values(asset_key_value).indexOf(data?.name)]);
                                    }
                                    setVersion(data?.version);
                                }
                                setStartDateFilter();
                                setEndDateFilter();
                            }
                        }
                    }}

            selectedAsset={selectedAsset} version={version} theme={theme} />}
            </div>
            <div style={{ height: 10 }}></div>
            <div className="row">
                <div className="col-lg-0"></div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-4" style={{ paddingRight: 0, marginTop: "1rem" }}>
                            {load_hist_calls && type != "multiday-equity" ?
                                <Card className="custom-card card2">
                                    <div className="spinner-container" style={{ height: '55rem',width:"100%",justifyContent:"center",alignItems:"center" }}>
                                        <BeatLoader color="#dc9948" />
                                    </div>
                                </Card> :
                                load_hist_calls && type === "multiday-equity" ?
                                <Card className="custom-card card2">
                                    <div className="spinner-container" style={{ height: '5rem',width:"100%",justifyContent:"center",alignItems:"center" }}>
                                        <BeatLoader color="#dc9948" />
                                    </div>
                                </Card>: <NoOfCallsCard calls={calls} theme={theme} type={type}/>}
                        </div>
                        <div className="col-lg-4" style={{ paddingRight: 0, marginTop: "1rem" }}>
                            {load_hist_sr && type != "multiday-equity"?
                                <Card className="custom-card card2">
                                    <div className="spinner-container" style={{ height: '55rem',width:"100%",justifyContent:"center",alignItems:"center" }}>
                                        <BeatLoader color="#dc9948" />
                                    </div>
                                </Card> :load_hist_sr && type === "multiday-equity"?
                                <Card className="custom-card card2">
                                    <div className="spinner-container" style={{ height: '5rem',width:"100%",justifyContent:"center",alignItems:"center" }}>
                                        <BeatLoader color="#dc9948" />
                                    </div>
                                </Card>:<SuccessRatioCard sr={srData} theme={theme} type={type}/>}
                        </div>
                        <div className="col-lg-4" style={{ paddingRight: 0, marginTop: "1rem" }}>
                            {load_hist_pl && type != "multiday-equity"?
                                <Card className="custom-card card2">
                                    <div className="spinner-container" style={{ height: '55rem',width:"100%",justifyContent:"center",alignItems:"center" }}>
                                        <BeatLoader color="#dc9948" />
                                    </div>
                                </Card> :load_hist_pl && type === "multiday-equity"?
                                <Card className="custom-card card2">
                                    <div className="spinner-container" style={{ height: '5rem',width:"100%",justifyContent:"center",alignItems:"center" }}>
                                        <BeatLoader color="#dc9948" />
                                    </div>
                                </Card> :<PLCard plData={plData} type={type} theme={theme} />}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: 15 }}></div>
            <Row>
                <Col lg={9}>
                    <AdviceStatus advices={advices} handleAdviceClick={handleAdviceClick}  version={version} dropdownversion={dropdownversion}
                        type={type} selectedAsset={selectedAsset} asset_key_value={asset_key_value} theme={theme}
                        selectedAdviceStatus={selectedAdviceStatus} setSelectedAdviceStatus={setSelectedAdviceStatus} />

                </Col>
                <Col lg={3}>
                <ParameterChart type={type} load_hist_param={load_hist_param} advices={advices} parameters={parameters} 
                        selectedAdvice={selectedAdvice} parameterListChartData={parameterListChartData} theme={theme} />
                </Col>
            </Row>
            <div style={{ height: 15 }}></div>
            {( type !== 'all' && viewCharts === false && advices.length > 0?
            <>
                <div className='charts_main_div' style={{width :"100%", display:"flex", justifyContent:"center", alignItems:"center"}} onClick={()=>{setViewCharts(true)}}>
                {viewCharts===false?
                    <div className="view_charts_btn" style={{width:"17rem", height:"4rem", borderRadius:"0.5rem",color :"white", fontSize:"1.5rem", fontWeight:"bold"}}>
                        <img className="profile_img" src="assets/icon/charts.gif" style={{height: "4rem", width :"30%",padding:0,marginLeft:"0.5rem"}}></img>
                        <span className='view_charts' style={{width :"70%", marginLeft :"1.6rem"}}>View Charts</span>
                    </div>:" "
                }
                </div>
            <div style={{ height: 10 }}></div>
            </>
             : " ")}
            
            {viewCharts === true ?
                <Card className="historicalCards" >
                    <Card.Body >
                    {(type !== 'all' ?
                        <>
                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "1.5rem", height: "4rem", borderRadius: "0.5rem", color: "white", fontSize: "2.5rem" }} onClick={() => { setViewCharts(false) }}>
                                    {/* <span className="profile_img" style={{ cursor: "pointer" }}>x</span> */}
                                    <img className="icon_img" src="./assets/icon/close_icon.png" style={{height:"1.5rem", width :"1.5rem", cursor: "pointer"}}/>
                                </div>
                            </div>
                        </>
                        : " ")}
                        <div className="row" style={{ marginTop: "2rem", marginBottom: "6rem" }}>
                            {type !== 'fx-option' && <div className="col-lg-4">

                                {load_hist_targetsl ?
                                    <div className="Hist_spinner-container" >
                                        <BeatLoader color="#dc9948" />
                                    </div> :
                                    type == 'intraday-stock-index' ||  type == 'intraday-stock-future' || type == 'fx-future' || type === "fx-option" || type === "options-stock" || type === "options-index" || type == 'comodity-future' || type == 'overall' ?<TargetSlChart dataset={intradayTargetSlChartData} type={type} theme={theme} selectedTradeDetails={selectedTradeDetails}/>
                                    : type == 'overall' ?<TargetSlChart dataset={PtTargetSlChartData} type={type} theme={theme} selectedTradeDetails={selectedTradeDetails}/>
                                    :type==="fx-future-multiday" ? <TargetSlChart dataset={SFmultidayTargetSlChartData} type={type} theme={theme} selectedTradeDetails={selectedTradeDetails}/> 
                                    :<TargetSlChart dataset={multidayTargetSlChartData} type={type} theme={theme} selectedTradeDetails={selectedTradeDetails}/>}
                            </div>}
                            
                            <div className={`${type==='fx-option'?"col-lg-6":"col-lg-4"}`}>
                                {load_hist_mtm ?
                                    <div className="Hist_spinner-container" >
                                        <BeatLoader color="#dc9948" />
                                    </div>
                                    :
                                    type == 'intraday-stock-index' ||  type == 'intraday-stock-future' ||type == 'fx-future'|| type === "fx-option" || type == 'comodity-future' || type === "options-stock" || type === "options-index" ? <MtmChart dataset={intradayMtmDrawdownChartData} type={type} theme={theme} />
                                        : <MtmChart dataset={mtmDrawdownChartData} type={type} theme={theme} />}
                            </div>
                            <div className={`${type==='fx-option'?"col-lg-6":"col-lg-4"}`}>
                                {load_hist_mtm ?
                                    <div className="Hist_spinner-container" >
                                        <BeatLoader color="#dc9948" />
                                    </div> :type == 'intraday-stock-index' ||  type == 'intraday-stock-future' || type == 'fx-future'|| type === "fx-option" || type == 'comodity-future' || type === "options-stock" || type === "options-index" ? <DrawdownChart dataset={intradayMtmDrawdownChartData} type={type} theme={theme} />
                                        : <DrawdownChart dataset={mtmDrawdownChartData} type={type} theme={theme} />}
                            </div>

                        </div>
                    </Card.Body>
                </Card>
            : " "}
        </div>
    </>
}