import React, { useEffect, useState } from 'react';
import { getUserSubscription, requestLogin } from '../../Api/buySell/portal';
import {setItemToStorage } from '../../Api/Service/auth.service';
import { userContext } from '../../Context/userContext';
import { PortalHomeLayout } from '../../layouts/PortalLoginLayout';
import './style.css';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APP_VERSION } from '../../util/utils';

interface PortalHomePageProps {
    theme :any,}

export const PortalHomePage: React.FC<PortalHomePageProps> = ({theme}) => {
    const [isSignInModalVisible, setIsSignInModalVisible] = useState(false)
    const notify = (msg:any) => toast(msg);
    const [activeUser, setActiveUser] = useState<boolean>(false);
    let token = window.localStorage.getItem("token");

    document.addEventListener('visibilitychange', function() {
        if(document.hidden)
            setActiveUser(false);
        else
            setActiveUser(true);
    });
    
    useEffect(()=>{
        if(token){
            window.location.reload();
        }
    },[activeUser]);



const handleLogin = async (email:any, password:any) => {
    let res = await requestLogin({userId: email, password: password});

    let expiry_resp:any = await getUserSubscription(email)

    if(res?.status && res?.status === "success") {
        if((expiry_resp[0]?.is_active === false) && (expiry_resp[0]?.expiry == 1 && (res?.status !== "blocked"))){
            notify('User subscription expired')
        }
        else if(expiry_resp[0]?.is_active === true){
            setItemToStorage("userId", res?.userId);
            setItemToStorage("token", res?.token);        
            setIsSignInModalVisible(false);
            if (window.localStorage.getItem("version") !== APP_VERSION){
                setItemToStorage("version",APP_VERSION)
                setItemToStorage("release_show_count","0")
            }
            window.location.href = `/portal/strategy-tracker/?all`;
        }
       
    }
    else if(res?.status === 'failed'){
        notify(`Wrong password, ${res?.attempt_left} login attempt left`)
    }
    else if(res?.status && res?.status === "blocked"){
        notify("User is blocked")
    }
    else if(res?.status === 'invalid'){
        notify(`Invalid user`)
    }
}

    return <userContext.Consumer>
            {(user) => {
                return <PortalHomeLayout isLoggedIn={user.user.isLoggedIn} handleLogin={handleLogin} 
                isSignInModalVisible={isSignInModalVisible} setIsSignInModalVisible={setIsSignInModalVisible} user={user} theme={theme}  />
            }}
            
        </userContext.Consumer>
}


