import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { axios } from "../../Api/axios";
import './style.css';

interface ProfileSectionLayoutProps {
    user: any
    theme:any 
}

export const ProfileSectionLayout: React.FC<ProfileSectionLayoutProps> = (props) => {

    let [email, setEmail] = useState<any>(props.user.user.email_id);
    let [firstname, setFirstname] = useState<any>();
    let [lastname, setLastname] = useState<any>();


    useEffect(() => {
        axios.get(`https://adminapi.vistaintelligence.ai/user/user_details?email_id=${email}`)
            .then((res: any) => {
                if (res.data.length > 0) {
                    setFirstname(res.data[0].first_name)
                    setLastname(res.data[0].last_name)
                    setEmail(res.data[0].email_id)
                }
            })
    }, [])




    return <>
        <div >
            <div className="row " >
                <div className=" whole_container" style={{width:"100%", height:"100vh", padding:"2rem"}}>
                    <Col className=" main_body" style={{width:"100%"}}>
                        <Card className="card_body" style={{minHeight:"30rem"}}>
                            <Card.Body>
                                <div className="col-lg-3 col-md-3 col-sm-3 card_body_div image_portal_section">
                                        <div className="img_div">
                                            <img src= '/assets/images/dummy-profile.png' className="previewimg" />
                                        </div>
                                </div>
                                <div className="col-lg-8 card_body_div user_profile_details">
                                    <Card.Title className="card_title">
                                        <div className="row">
                                            <div className="col-lg-6 header_section">
                                                <h3>Profile</h3>
                                            </div>
                                        </div>
                                    </Card.Title>
                                    <Card.Text className="user_details">
                                        <Form className="col-lg-10 user_form">
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label style={{ fontWeight: "bold" }} column sm="4" lg="3" md="4">
                                                    Name
                                                </Form.Label>
                                                <Col sm="9" md="9" lg="9">
                                                    <Form.Control type="text" className="form_box" value={firstname + " " + lastname} disabled={true} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label style={{ fontWeight: "bold" }} column sm="4" lg="3" md="4">
                                                    Email
                                                </Form.Label>
                                                <Col sm="9" md="9" lg="9">
                                                    <Form.Control className="form_box" type="email" value={email}  
                                                     />
                                                </Col>
                                            </Form.Group>
                                           
                                            {
                                                <div style={{fontWeight : "bold",marginLeft : "25%"}}>
                                                    <h5>To change the password please contact to the administrator</h5>
                                                </div>
                                            }

                                          </Form>

                                    </Card.Text>
                                </div>

                            </Card.Body>
                        </Card>

                    </Col>
                </div>
            </div>
        </div>
    </>
}