import { LeftArrow } from "../../Carousel/ArrowButtons/LeftArrow";
import { RightArrow } from "../../Carousel/ArrowButtons/RightArrow";
import './style.css';

export const ArrowButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
    return (
      <div className="carousel-button-group arrow-button-group">
        <LeftArrow onClick={() => previous()} />
        <RightArrow onClick={() => next()} />
      </div>
    );
};