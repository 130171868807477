import React from "react"; 
import { ProfileSectionLayout } from "../../layouts/ProfileSectionLayout";
import { userContext } from '../../Context/userContext';

interface profileProps {
    theme:any 
}
export const ProfilePage:React.FC<profileProps> = ({theme}) =>{
    return <userContext.Consumer>
        {(user) => {
            return <ProfileSectionLayout user={user} theme={theme} />
        }}
        </userContext.Consumer>
}
