import './style.css';
import ReactApexChart from 'react-apexcharts'
import moment from 'moment';
import { Modal} from 'react-bootstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';

interface StopLossChartProps {
  dataset: any,
  type:any
  theme :any
  selectedTradeDetails  :any
}

export const StopLossChart: React.FC<StopLossChartProps> = ({ dataset,type,theme, selectedTradeDetails}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const chartArr:any = []
  const colourArray:any =[]
  let length = dataset?.length
  let dataArrPrice: any = []
  let dataArrSl: any = []
  let dataArrTarget: any = []
  let labels: any = []

  let axis_lable_color :any 
  let border_color :any
  let axis_border_color :any

  if(theme == 'dark'){
    axis_lable_color = '#fff'
    border_color = '#262626'
   
    axis_border_color = '#ffffff'
}
else if (theme == 'light'){
    axis_lable_color = '#000'
    border_color = '#a2cdf4'
 
    axis_border_color = '#659ac2'
}
else if(theme == 'b/w'){
  axis_lable_color = '#fff'
  border_color = '#ffffff30'
  axis_border_color = '#ffffff'
}
  
  
  dataset?.map((data: any) => {
    if( type === 'intraday-stock-index' || type==="intraday-stock-future" || type === "options-stock" || type === "options-index"|| type === 'comodity-future')
    {
      labels.push(moment(new Date(data?.dt_date+" "+data?.time)).format('DD\'MMM HH:mm').toString())
      dataArrPrice.push(Number(data?.close_price)?.toFixed(2))
      dataArrSl.push(Number(data?.stop_loss)?.toFixed(2))
      dataArrTarget.push(Number(data?.target)?.toFixed(2))
    }

    else if(type=== 'fx-option' || type === 'fx-future')
    {
      labels.push(moment(new Date(data?.dt_date+" "+data?.time)).format('DD\'MMM HH:mm').toString())
      dataArrPrice.push(Number(data?.close_price)?.toFixed(4))
      dataArrSl.push(Number(data?.stop_loss)?.toFixed(4))
      dataArrTarget.push(Number(data?.target)?.toFixed(4))
    }
    else if(type === "stock-index" || type === "stock-future" || type === "overall" || type === "all"){
      labels.push(moment(new Date(data?.date)).format('DD\'MMM HH:mm'))
      dataArrPrice.push(data?.transaction_price?.toFixed(2))
      dataArrSl.push(data?.stoploss?.toFixed(2))
      dataArrTarget.push(data?.target?.toFixed(2))
    }
    else if(type==="fx-future-multiday" ){

      labels.push(moment(new Date(data?.time)).format('DD\'MMM HH:mm'))
      dataArrPrice.push(Number(data?.close_price).toFixed(4))
      dataArrSl.push(Number(data?.stop_loss).toFixed(4))
      dataArrTarget.push(Number(data?.target).toFixed(4))
    }
    else if(type==="multiday-equity" ){

      labels.push(moment(new Date(data?.time)).format('DD\'MMM HH:mm'))
      dataArrPrice.push(Number(data?.close_price).toFixed(4))
      dataArrSl.push(Number(data?.stop_loss).toFixed(4))
      dataArrTarget.push(Number(data?.target).toFixed(4))
    }

  })

  if((type === "multiday-equity")&&(selectedTradeDetails?.s_version == 'QESADM'))
  {
   chartArr.push(
     {
       name: "Price",
       data: dataArrPrice
     },
     {
       name: "Stop Loss",
       data: dataArrSl
     }
   )
   colourArray.push('#268fb5',  '#cf3e3e')
  
  }
  else{
   chartArr.push(
     {
       name: "Price",
       data: dataArrPrice
     },
     {
       name: "Target",
       data: dataArrTarget
     },
     {
       name: "Stop Loss",
       data: dataArrSl
     }
   )
   colourArray.push('#268fb5', '#289623', '#cf3e3e')
  }
 





let concatArr:any = []
concatArr = dataArrPrice.concat(dataArrTarget)
concatArr = concatArr.concat(dataArrSl)

  const data1: any = {

    series: chartArr,
    options: {
      chart: {
        animations: {
          enabled: false,
          easing: 'linear',
          speed: 1800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 10
          }
        },
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        },
        tooltip: {
          enabled: false,
        }


      },

      colors: colourArray,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,

      },
      title: {
        text: "Target Stoploss",
        align: 'left',
        style: {
          fontSize: '18px',
          color: axis_lable_color
        }
      },
      grid: {
        borderColor: border_color, 
        row: {
          opacity: 0.5
        },
      },
      markers: {
        size: 0
      },

      xaxis: {
        categories: labels,

        tickPlacement: 'between',
        tickAmount:8,
        labels: {
          show: true,
          style: {
            colors: axis_lable_color
          }
        },
        axisBorder: {
          show: true,
          color: axis_border_color,
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: false,
          borderType: 'solid',
          color: axis_border_color,
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
      },
      yaxis: {
        title: {
        },

        labels: {
          show: true,

          style: {
            colors: axis_lable_color
          }
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
        labels: {
          colors: axis_lable_color
        }
      }
    },


  };

  return (
    <>
      <div style={{margin:"1rem", height:"5%"}}>
        <span className="chart_view_button" style={{ float: "right", padding: "0rem", border: "1px solid white" }} onClick={handleShow}>
          <FontAwesomeIcon icon={faExpandArrowsAlt} />
        </span>
      </div>
      <div id="stoploss_chart" style={{ marginTop: "1.2rem",marginBottom:"4.5rem"}}>
        <ReactApexChart id="targetSlApexChart" options={data1.options} series={data1.series} type="line" height="100%" />
        <Modal show={show} onHide={handleClose} style={{ backdropFilter: "blur(3px)"}} size="xl" >
          <Modal.Header closeButton>
            <Modal.Title style={{width:"100%"}}>
              <div style={{width:"30%"}}>
                {dataset[length-1]?.s_symbol}
              </div>
              {/* <div style={{width:"30%",fontSize:"1rem"}}>Total PL for this trade: {dataset[length-1]?.n_pl}</div> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='chartItem' style={{width:"87%",height:"80%",margin:"2% 7%",position:"absolute"}}>
                <ReactApexChart options={data1.options} series={data1.series} type="line" height='100%' />
            </div>
          </Modal.Body>

       </Modal>
      </div>
    </>
  )
}