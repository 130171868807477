import React, { useEffect, useRef, useState } from 'react';
import './container.css';
import Carousel from 'react-bootstrap/Carousel'
import Card from 'react-bootstrap/Card'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import data from '../../../../dummy_data/data.json'
import { SmallNewsItemType1 } from './SmallNewsItemType1';
import { add3Dots, getColorBySentiment, parseDateInDisplayFormat2 } from '../../../../util/utils';
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Filter } from './Filter';
import { frontendRoutes } from '../../../../constants/frontendRoutes';
import { withRouter } from 'react-router-dom';
import { getCategoryTags, getCompanyTags, getKeywordTags } from '../../../../Api/News/news';
import { sentimentIcon } from '../../../shared/sentimentIcon';

interface NewsContainerType1Props {
    articles: any
}

const NewsContainerType1: React.FC<NewsContainerType1Props> = ({articles}) => {
    const [key, setKey] = useState('recomended');
    const [noOfIcons, setNoOfIcons] = useState(4);
    const [keywordTags, setKeywordTags] = useState([{}]);
    const [publisherTags, setPublisherTags] = useState([{}]);
    const [companyTags, setCompanyTags] = useState([{}]);
    const [categoryTags, setCategoryTags] = useState([{}]);

    useEffect(() => {
        setKeywordTags([
            { value: 'Mucormycosis', count: 38 },
            { value: 'CapitalVia Global Research', count: 30 },
            { value: 'NPA', count: 28 },
            { value: 'ARC', count: 25 },
            { value: 'SBI', count: 33 },
            { value: 'MSP', count: 18 },
            { value: 'Stock', count: 35 },
            { value: 'Yash Gupta', count: 30 },
            { value: 'Angel Broking', count: 23 },
            { value: 'Gandhi Nagar', count: 29 },
            { value: 'Bajaj Healthcare', count: 28 },
            { value: 'Posaconazole API', count: 40 },
        ])
        setPublisherTags([
            { value: 'Financial Express', count: 38 },
            { value: 'mint', count: 30 },
            { value: 'Business Insider', count: 28 },
            { value: 'ET Now', count: 25 },
            { value: 'Money COntrol', count: 33 },
            { value: 'ITC', count: 18 },
            { value: 'The Economic Times', count: 20 },
        ])
        getCompanyTags().then((res) => {
            setCompanyTags(res)
        })
        getCategoryTags().then((res) => {
            setCategoryTags(res)
        })
    }, [])

    let slides: any = []
    let articlesThumbnails:any = []
    articles.map((data: any, i: number) => {        
        slides.push(
            <Carousel.Item>
                <Card style={{ width: '100%', height: '550px' }} className="post-type1">
                    <div className="gradient-left-top">
                        <div className="image-corner-sentiment">
                            <p >
                                <div className="sentiment-icon">{sentimentIcon(data.sentiment)['icon']}</div>
                                <div className="sentiment-text">{sentimentIcon(data.sentiment)['text']}</div>
                            </p>
                        </div>
                        <div className="post_img gradient1">
                            <Card.Img variant="top" src={data.image_link} style={{ height: '550px' }}/>
                        </div>
                    </div>
                    <Card.Body>
                        <div className="single_post_text">
                            <div className="meta meta_separator1">
                                <a href={"/news/keyword/"+data.category+"/category"} target="_blank" rel="noopener noreferrer">{data.category}</a>
                                <a href="#" className="meta-date">{parseDateInDisplayFormat2(data.datePublished)}</a>
                                
                                <p >{data.publisher}</p>
                            </div>
                            <Card.Title>
                                {/* <FontAwesomeIcon icon={faCircle} style={{color: getColorBySentiment(data.sentiment),
                                margin: "0px 10px 0px 0px"}}></FontAwesomeIcon> */}
                                <a href={frontendRoutes.newsHome + data._id} target="_blank" 
                                rel="noopener noreferrer" >{data.title}</a>
                            </Card.Title>
                            <Card.Text>
                                {add3Dots(data.summary, 150)}
                            </Card.Text>
                        </div>
                    </Card.Body>
                </Card> 
            </Carousel.Item>
        )
        
        if(i < noOfIcons) {
            articlesThumbnails.push(
                <SmallNewsItemType1 article={{id: data._id, title: data.title, imageLink: data.image_link, category: data.category,
                publishedDate: data.datePublished, sentiment: data.sentiment, logoUrl:data.logo, publisher: data.publisher}} />
            )
        }
        if(i < noOfIcons - 1) {
            articlesThumbnails.push(
                <>
                    <div className="space-15"></div>
                    <div className="border_black"></div>
                    <div className="space-15"></div>
                </>
            )
        }
    })

    let handleFilterBy = () => {
        setKey('recomended')
    }

    const applyButton = <Button variant="primary" onClick={handleFilterBy}>Apply</Button>

    

    return (
        <div className="SectionType1 mb20 fifth_bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="slider_demo2 slick-initialized slick-slider">
                            <Carousel fade interval={15000} controls={false} indicators={false}>
                                {slides}
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-lg-4">
                    <Tabs className="custom-tabs"
                        id="controlled-tab"
                        activeKey={key}
                        onSelect={(k: any) => setKey(k)}
                        >
                        <Tab eventKey="recomended" title="Recommended">
                            <div style={{marginTop: "25px"}}>
                                {articlesThumbnails}
                                <div className="read-more">
                                    <a href="/news/keyword/recomended/category" target="_blank" rel="noopener noreferrer">
                                        READ MORE...
                                    </a>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="filter" title="Filter">
                            <Filter keywordTags={keywordTags} publisherTags={publisherTags} companyTags={companyTags} categoryTags={categoryTags}/>
                        </Tab>
                    </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsContainerType1;