import moment from 'moment'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { Card } from 'react-bootstrap'
import BeatLoader from 'react-spinners/BeatLoader'
import { getColorParameterCharts } from '../../../../util/utils'
import { faHourglassHalf, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ParameterProps {
    loading_param: any,
    type: any,
    advices: any,
    selectedAdviceStatus: any,
    theme: any,
    openTradeAdvices: any,
    selectedAdvice: any,
    parameters: any,
    revisedDatalist: any
    advicesStatusType: any
}

const ParameterCard: React.FC<ParameterProps> = ({ loading_param, type, advices, selectedAdviceStatus, theme, openTradeAdvices, selectedAdvice, parameters, revisedDatalist, advicesStatusType}) => {
    let axis_lable_color: any
    let border_color: any
    let m2mTOShow: any = [];
    if (type.toUpperCase() === 'ALL' && advices.length > 0) {
        let identifier: any = ["QSORELVTIS", "QIONIFVTIS", "QIOBANVTIS"]
        if (selectedAdviceStatus === 0 && advices[0]?.m2m_array) {
            if (identifier.includes(advices[0]?.identifier)) {
                if (Object.keys(advices[0]?.m2m_array?.m2m).length > 1) {
                    let obj1: any = {};
                    let divider: any = Math.floor((advices[0]?.m2m_array?.m2m?.length) / 10)
                    if (divider == 0) {
                        divider = 1;
                    }
                    for (let index = 0; index < advices[0]?.m2m_array?.m2m.length; index = index + divider) {
                        let key: any = Object.keys(advices[0]?.m2m_array?.m2m[index])
                        let val: any = Object.values(advices[0]?.m2m_array?.m2m[index])

                        obj1 = { ...obj1, [key]: val[0] }
                    }
                    m2mTOShow = Object.values(obj1);
                }
            }
            else if (Object.keys(advices[0]?.m2m_array).length != 0 && advices[0]?.m2m_array.constructor === Object) {
                if ((Object.keys(advices[0]?.m2m_array).length > 1)) {
                    let values: any = Object.values(advices[0]?.m2m_array);
                    m2mTOShow = values;
                }
            }
        }
        else {
            if (selectedAdviceStatus?.m2m_array) {
                if (Object.keys(selectedAdviceStatus?.m2m_array).length != 0 && selectedAdviceStatus?.m2m_array.constructor === Object) {
                    if (identifier.includes(selectedAdviceStatus?.identifier)) {
                        if (Object.keys(selectedAdviceStatus?.m2m_array?.m2m).length > 1) {
                            let obj1: any = {};
                            let divider: any = Math.floor((selectedAdviceStatus?.m2m_array?.m2m?.length) / 10)
                            if (divider == 0) {
                                divider = 1;
                            }
                            for (let index = 0; index < selectedAdviceStatus?.m2m_array?.m2m.length; index = index + divider) {
                                let key: any = Object.keys(selectedAdviceStatus?.m2m_array?.m2m[index])
                                let val: any = Object.values(selectedAdviceStatus?.m2m_array?.m2m[index])

                                obj1 = { ...obj1, [key]: val[0] }
                            }
                            m2mTOShow = Object.values(obj1);
                        }
                    }
                    else {
                        if (Object.keys(selectedAdviceStatus?.m2m_array).length > 1) {
                            m2mTOShow = Object.values(selectedAdviceStatus?.m2m_array)
                        }
                    }
                }
            }
        }
        if (m2mTOShow[m2mTOShow.length - 1] == 0) {
            m2mTOShow[m2mTOShow.length - 1] = 0.000000000012
        }
    }

    let options: any = {
        series: [{ data: m2mTOShow },],
        chart: {
            type: "area",
            opacity: 0.5,
            height: 100,
            toolbar: {
                show: false
            },
            tooltip: {
                enabled: false,
            }
        },
        colors: [getColorParameterCharts(m2mTOShow[0], m2mTOShow[m2mTOShow?.length - 1], theme), '#fff'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 3,
            lineCap: 'square',
        },
        grid: {
            borderColor: border_color,
            row: {
                opacity: 0.5
            },
        },
        markers: {
            size: 0
        },
        xaxis: {
            tickPlacement: 'between',
            tickAmount: 8,
            labels: {
                show: false,
                style: {
                    colors: axis_lable_color
                }
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
           
              }
        },
        yaxis: {
            marker: {
                size: 8,
            },
            labels: {
                show: false,

                style: {
                    colors: axis_lable_color
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: 10,
            offsetX: 15,
            labels: {
                colors: axis_lable_color
            }
        }
    };
    let apexChartValue: any = Object.values(options.series[0])[0];


   
    const handlevalue = (value: any, valuesType: any) => {

        if (value === '' || value === undefined || value === null) {
            return ''
        }
     
        if (valuesType === 'pl' ) {
            if (value >= 0) {
                if (theme == 'dark' || theme == 'b/w') {
                    return <span style={{ color: "lightgreen", fontWeight: 'bold' }}>{value.toFixed(2)}</span>
                } else {
                    return <span style={{ color: "darkgreen", fontWeight: 'bold' }}>{value.toFixed(2)}</span>
                }
            }
            else if (value < 0) {
                return <span style={{ color: "orangered", fontWeight: 'bold' }}>{value.toFixed(2)}</span>
            }
            else if (value == '') {
                return <span></span>
            }
            else {
                return <span style={{ color: "whitesmoke", fontWeight: 'bold' }}>{value.toFixed(2)}</span>
            }
        }else if(valuesType === 'return'){
            if (value >= 0) {
                if (theme == 'dark' || theme == 'b/w') {
                    return <span style={{ color: "lightgreen", fontWeight: 'bold' }}>{value.toFixed(2)}%</span>
                } else {
                    return <span style={{ color: "darkgreen", fontWeight: 'bold' }}>{value.toFixed(2)}%</span>
                }
            }
            else if (value < 0) {
                return <span style={{ color: "orangered", fontWeight: 'bold' }}>{value.toFixed(2)}%</span>
            }
            else if (value == '') {
                return <span></span>
            }
            else {
                return <span style={{ color: "whitesmoke", fontWeight: 'bold' }}>{value.toFixed(2)}%</span>
            }
        }
        
        else {
            return value
        }
    }


    return (
        <div style={{ height: "100%" }}>
            <Card style={{ backgroundColor: "rgb(80 69 87 / 67%)", height: "100%", width: "100%" }}>

                {loading_param ?
                    <div className="spinner-container" style={{ height: '100%', width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <BeatLoader color="#dc9948" />
                    </div>
                    : <Card.Body style={{ padding: "0rem 0rem 1rem 1rem !important" }}>
                        <div id='paraChart'>
                            <Card.Title className={type == 'all' ? 's-parameters' : ''} style={{ marginTop: "1rem", marginBottom: "1rem" }}>Parameters</Card.Title>
                            {apexChartValue.length > 1 && type == 'all' && advices[selectedAdvice] !== undefined && openTradeAdvices.length > 0 ? <ReactApexChart className="apex_charts" options={options} series={options.series} type="area" height={80} /> : ""}
                        </div>
                        <Card.Text style={{ fontSize: '1.4rem', fontFamily: 'Nunito-Regular' }}>
                            {advices?.length > 0 && openTradeAdvices.length > 0 && advices[selectedAdvice] !== undefined ? <>
                                <div style={{ fontWeight: 'bold' }}>
                                    <p>{advices[selectedAdvice]?.identifier} </p>
                                    {type === "comodity-future" || (type === "all" && advices[selectedAdvice]?.s_asset_group_trade_type === 'commodity-future') ? <p><span>Expiry: </span>{parameters[0]?.s_expiry}</p> : ""}
                                </div>

                                <FontAwesomeIcon icon={faHourglassHalf} />
                                <span style={{ fontWeight: 'bold' }}>&nbsp;&nbsp;
                                    {parameters[0]?.dt_open_time != null ?
                                        <>
                                            {advices[selectedAdvice]?.identifier.slice(8, 9) === 'M' ? (moment(parameters[0]?.dt_open_time)).format('DD \' MMM \YYYY HH:mm') :
                                                (moment((parameters[0]?.dt_open_time), 'HH:mm:ss').format('HH:mm'))}
                                        </> : ''}
                                    <span style={{ fontSize: '1.2rem' }}> ⇢ </span>
                                    {parameters[0]?.dt_end_time != null ?
                                        <>
                                            {advices[selectedAdvice]?.identifier.slice(8, 9) === 'M' ? (moment(parameters[0]?.dt_end_time)).format('DD \' MMM \YYYY HH:mm') :
                                                (moment((parameters[0]?.dt_end_time), 'HH:mm:ss').format('HH:mm'))}
                                        </> : 'Trade is open'}
                                </span>
                                <div >
                                    {(type === "all" && advicesStatusType === "spot") || type === "multiday-equity" ?
                                        <div >
                                            {(parameters[0]?.j_dividend_list === undefined || parameters[0]?.j_dividend_list === null) ? <span></span> :
                                                <span  style={{display:'flex'}}><span style={{ fontWeight: 900 }}>Dividend : </span> &nbsp;{Object.keys(parameters[0]?.j_dividend_list).map((item: any) => {
                                                    return <div style={{ display: "flex", flexDirection: "row", alignItems: "center" , justifyContent: "center" }}>
                                                        <p> <span style={{ fontWeight: 900 }}>{moment(item).format('DD\'MMM \YYYY')}</span></p>&nbsp;<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;<p>{handlevalue(parameters[0].j_dividend_list[item],'pl')}</p>
                                                    </div>
                                                })}</span>}
                                        </div> : ""}
                                </div>

                                {type === "all" && advices[selectedAdvice]?.identifier.slice(0, 9) === 'QFFUSDPBM' ? <>
                                    <p><span style={{fontWeight:'bold'}}>1D Return :</span>&nbsp;{advices[selectedAdvice]?.oneDay_return_per != undefined ? <>{handlevalue(advices[selectedAdvice]?.oneDay_return_per,"return")}</>:""}</p>
                                </> :""}
                                {type === "options-stock" || type === "options-index" || type === "fx-option" || (type === 'all' && (advices[selectedAdvice]?.s_asset_group_trade_type === 'options' || advices[selectedAdvice]?.s_asset_group_trade_type === 'fx-option'))
                                    ? <>{parameters[0]?.ce_strike !== null ? (<p><span>CE Strike : </span>{parameters[0]?.ce_strike}</p>) : ''}
                                        {parameters[0]?.pe_strike !== null ? (<p><span>PE Strike : </span>{parameters[0]?.pe_strike}</p>) : ''}
                                        <p><span>Expiry Date : </span>{parameters[0]?.expiry_date}</p>
                                    </>
                                    : ''
                                }
                                 {revisedDatalist?.length > 0 ? <p style={{fontWeight: 900}}>Target & Stoploss : </p> :""}
                                {revisedDatalist?.length > 0 ?
                                    <div className='parameterScroll' style={{ fontFamily: 'monospace', maxHeight: "35rem", overflowY: "auto" }}>
                                        {revisedDatalist?.map((element: any, index: any) => {
                                            return <>
                                                <p>{advices[selectedAdvice]?.identifier.slice(8, 9) === 'M' ?
                                                    <><span style={{ fontWeight: 900 }}>{(moment(element?.time).format('DD\'MMM \YYYY HH:mm'))}</span></> :
                                                    <><span style={{ fontWeight: 900 }}>{(moment((element?.time), 'HH:mm:ss').format('HH:mm'))}</span></>}
                                                {
                                                    advices[selectedAdvice]?.identifier === 'QFOUSDSBIS' ?<>
                                                    {element?.Target === undefined || element?.Target === null || element?.Target == '' ?
                                                        "" : <span>{', Target(Fut) '}<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;{advices[selectedAdvice]?.identifier?.slice(1, 2) === 'F'?
                                                        Number(element?.Target).toFixed(4):Number(element?.Target).toFixed(2)}</span>}
                                                    {element?.Stoploss_fut === undefined || element?.Stoploss_fut === null || element?.Stoploss_fut === '' ?
                                                        "" : <span>{', Stoploss(Fut) '}<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;{advices[selectedAdvice]?.identifier?.slice(1, 2) === 'F'?
                                                        Number(element?.Stoploss_fut).toFixed(4): Number(element?.Stoploss_fut).toFixed(2)}</span>}
                                                    {element?.Stoploss_opt === undefined || element?.Stoploss_opt === null || element?.Stoploss_opt === '' ?
                                                        "" : <span>{', Stoploss(Opt) '}<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;{advices[selectedAdvice]?.identifier?.slice(1, 2) === 'F'?
                                                        Number(element?.Stoploss_opt).toFixed(4): Number(element?.Stoploss_opt).toFixed(2)}</span>}    
                                                    </>
                                                :<>
                                                    {element?.Target === undefined || element?.Target === null || element?.Target == '' ?
                                                        "" : <span>{', Target '}<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;{advices[selectedAdvice]?.identifier?.slice(1, 2) === 'F'?
                                                        Number(element?.Target).toFixed(4):Number(element?.Target).toFixed(2)}</span>}
                                                    {element?.Stoploss === undefined || element?.Stoploss === null || element?.Stoploss === '' ?
                                                        "" : <span>{', Stoploss '}<FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;{advices[selectedAdvice]?.identifier?.slice(1, 2) === 'F'?
                                                        Number(element?.Stoploss).toFixed(4): Number(element?.Stoploss).toFixed(2)}</span>}</>

                                                }
                                                    </p>
                                            </>
                                        })}
                                        <span></span>
                                    </div> : ''}
                            </> : ''}
                        </Card.Text>
                    </Card.Body>
                }
            </Card>
        </div>
    )
}

export default ParameterCard
